import React, { Component } from "react";
import { View, Text, Image } from "react-native";
import { APP_COLOURS, DEMOGRAPHICS } from "../../APP_VARS";
import Fade from "react-reveal/Fade";
import { ScrollView } from "react-native-web";

function randomRange(n, min, max) {
	var values = [],
		i = max;
	while (i >= min) values.push(i--);
	var results = [];
	var maxIndex = max;
	for (i = 1; i <= n; i++) {
		maxIndex--;
		var index = Math.floor(maxIndex * Math.random());
		results.push(values[index]);
		values[index] = values[maxIndex];
	}
	return results.filter((d) => typeof d !== "undefined");
}

function newRandomNumber(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function thousandSep(num) {
	num = num.toString().replace(/[^0-9]/g, "");
	num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	return num;
}

export default class SalesByMall extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: "",
			dataHistory: randomRange(12, 50, 100),
			overallHistory: randomRange(12, 50, 100),

			data: [
				{
					title: "Centurion",
					icon: "",
					visits: 4000,
					dataHistory: randomRange(12, 50, 100),
					percentage: newRandomNumber(0, 100) / 100,
				},
				{
					title: "Sandton city",
					icon: "",
					visits: 10000,
					dataHistory: randomRange(12, 50, 100),
					percentage: newRandomNumber(0, 100) / 100,
				},
				{
					title: "Mall of africa",
					icon: "",
					visits: 7000,
					dataHistory: randomRange(12, 50, 100),
					percentage: newRandomNumber(0, 100) / 100,
				},
				{
					title: "Rosebank",
					icon: "",
					visits: 4000,
					dataHistory: randomRange(12, 50, 100),
					percentage: newRandomNumber(0, 100) / 100,
				},
			],
		};
	}

	render() {
		let style = {
			backgroundColor: APP_COLOURS.BG3,
			borderRadius: 11,
			padding: 8,
			marginRight: 15,
			marginBottom: 5,
			shadowColor: "#000",
			shadowOffset: { width: 0, height: 8 },
			shadowOpacity: 0.44,
			shadowRadius: 9.32,
			elevation: 5,
			opacity: 0.9,
		};

		let data = this.state.data;
		let filter = data.sort((a, b) => a.visits < b.visits);
		let maxMall = filter[0];

		return (
			<Fade>
				<View
					onMouseEnter={() =>
						this.setState({
							hover: true,
						})
					}
					onMouseLeave={() => this.setState({ hover: false })}
					style={[
						style,
						{
							marginTop: 0,
							paddingLeft: 15,
							marginBottom: 60,
							justifyContent: "space-between",
							backgroundColor: APP_COLOURS.BG3,
							padding: 20,
						},
					]}>
					<View
						onMouseEnter={() =>
							this.setState({
								hover: maxMall.title,
							})
						}
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							marginBottom: 10,
						}}>
						<View
							style={{
								// width: 200,
								height: 60,
							}}>
							<Text
								style={{
									fontSize: 12,
									fontWeight: "300",
									color: "#D8D8FF",
								}}>
								Sales by mall
							</Text>
						</View>

						<View
							style={{
								// width: 80,
								// height: 60,
								borderRadius: 10,
								borderWidth: 0.5,
								padding: 8,
							}}>
							<Text
								style={{
									fontSize: 9,
									fontWeight: "300",
									color: "#D8D8FF",
								}}>
								Highest
							</Text>
							<Text
								style={{
									fontSize: 14,
									fontWeight: "700",
									color: "#FFF",
								}}>
								{maxMall.title}
							</Text>
							<Text
								style={{
									fontSize: 14,
									fontWeight: "700",
									color: "#FFF",
								}}>
								{thousandSep(maxMall.visits)}
							</Text>
						</View>
					</View>

					<View
						style={[
							{
								height: 155,
							},
						]}>
						<ScrollView>
							{data
								.sort((a, b) => a.visits < b.visits)
								.map((d, i) => {
									return (
										<View
											key={i}
											onMouseEnter={() =>
												this.setState({
													hover: d.title,
												})
											}
											style={{
												flex: 1,
												// padding: 5,
												paddingRight: 10,
												borderRadius: 4,
												marginRight: 3,
												flexDirection: "row",
												alignItems: "center",
												marginBottom: 10,
												backgroundColor:
													this.state.hover === d.title
														? APP_COLOURS.BG4
														: APP_COLOURS.BG3,
											}}>
											<View
												style={{
													width: 120,
													flexDirection: "row",
													alignItems: "center",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontWeight:
															this.state.hover === d.title
																? "300"
																: "200",
													}}>
													{d.title}
												</Text>
											</View>

											<View
												style={{
													width: 80,
													flexDirection: "row",
													alignItems: "center",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontWeight:
															this.state.hover === d.title
																? "600"
																: "200",
													}}>
													{d.visits}
												</Text>
											</View>

											<View
												style={{
													width: 100,
													backgroundColor: "#444654",
													borderRadius: 3,
													marginLeft: 10,
												}}>
												<View
													style={{
														width:
															100 * (d.visits / maxMall.visits),
														backgroundColor:
															"rgba(0, 255, 249, 1)",
														borderRadius: 3,
														height: 5,
														opacity:
															this.state.hover === d.title
																? 1
																: 0.7,
													}}></View>
											</View>
										</View>
									);
								})}
						</ScrollView>
					</View>
				</View>
			</Fade>
		);
	}
}
