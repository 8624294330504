export const APP_COLOURS = {
	PRIMARY: "#222B45",
	SECONDARY: "#FFF",
	BACKGROUND: "#EBEDEE",
	FONT_PRIMAR: "#222B45",
	FONT_SECOND: "#8F9BB3",
	BLUE: "#0676ED",
	BORDER: "#2E3A59",
	TEXTINPUT: "#7072E6",
	ORANGE: "#FFD76E",
	RED: "#FF5F5F",
};
