import React from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	TextInput,
	TouchableOpacity,
	Image,
} from "react-native";
import moment from "moment";
import { api_ } from "../../Api";
import { APP_COLOURS } from "../../APP_VARS";
import ExportToExcel from "../ExportToExcel";

function isOdd(num) {
	return num % 2;
}

class TableComp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],

			filterByName: "",
			hover: "",

			analyticsArray: [],
		};
	}

	async componentDidMount() {
		await this.getQrCodes();
	}

	async componentDidUpdate(prevProps) {
		if (prevProps.selectedQrCodes !== this.props.selectedQrCodes) {
			await this.getQrCodes();
		}
	}

	getQrCodes = async () => {
		let url = api_ + this.props.selectedQrCodes.url;
		await this.setState({
			loading: true,
			devices: [],
			showDelete: false,
			addNewDevice: false,
			viewDeviceProfile: false,
		});
		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${sessionStorage.getItem(
					"accessToken",
				)}`,
			},
			redirect: "follow",
		};
		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								data: responseData,
								loading: false,
							});

							this.createAnalyticsState(responseData);
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		const { data, analyticsArray } = this.state;

		const keys = data.length > 0 ? Object.keys(data[0]) : [];

		const data_ = data
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.allocation} ${d_.city} ${d_.region}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})
			.sort(
				(a, b) =>
					moment(b.dateCreated).unix() < moment(a.dateCreated).unix(),
			);

		const analyticsdata_ = analyticsArray
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.allocation}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})
			.sort((a, b) => a.count < b.count);

		return (
			<View
				style={[
					styles.container,
					{
						// flexDirection: "row",
					},
				]}>
				<View
					style={{
						flex: 1,
						height: window.innerHeight / 2 - 200,
						// width: "100%",
						borderWidth: 0.5,
						borderRadius: 10,
						padding: 10,
						backgroundColor: APP_COLOURS.BG3,
						margin: 10,
					}}>
					<View
						style={{
							flexDirection: "row",
						}}>
						{data_.length > 0 ? (
							<ExportToExcel
								excel_doc_name={`traq_qr_code_report-${this.state.filterByName}`}
								data={data_}
							/>
						) : null}

						<TextInput
							ref={(ref) => {
								this.filterByName = ref;
							}}
							style={{
								padding: 8,
								fontSize: 16,
								borderRadius: 9,
								color: APP_COLOURS.WHITE,
								fontWeight: "200",
								fontFamily: "Avenir",
								borderWidth: 0.3,
								borderColor: APP_COLOURS.BG3,
								backgroundColor: APP_COLOURS.BG3,
								marginRight: 5,
								paddingLeft: 15,
							}}
							placeholderTextColor={"#797979"}
							autoCapitalize='words'
							clearButtonMode='while-editing'
							editable={true}
							placeholder={`Search QR Codes `}
							onChangeText={(text) => {
								this.setState({
									filterByName: text,
								});
							}}
						/>
					</View>

					<View
						style={{
							flex: 1,
						}}>
						<ScrollView>
							<ScrollView horizontal>
								<View
									style={{
										flex: 1,
										paddingBottom: 15,
									}}>
									<View
										style={{
											flexDirection: "row",
										}}>
										{data_.length > 0
											? Object.keys(data_[0]).map(
													(dd) => (
														<View
															style={{
																borderRightWidth: 0.5,
																borderRightColor:
																	"#797979",
																// paddingRight: 10,
															}}>
															<Text
																style={{
																	fontSize: 5,
																	marginLeft: 3,
																}}>
																{dd}
															</Text>
															{/* .slice(0, 20) */}
															{data_.map(
																(d, i) => (
																	<View
																		style={{
																			backgroundColor:
																				this
																					.state
																					.hover ===
																				i
																					? "#797979"
																					: isOdd(
																							i,
																					  )
																					? APP_COLOURS.BG5
																					: APP_COLOURS.BG4,
																			paddingRight: 10,
																			paddingLeft: 3,
																		}}>
																		<Text
																			ellipsizeMode='tail'
																			style={{
																				fontSize: 10,
																				color: "#FFF",
																			}}>
																			{
																				d[
																					dd
																				]
																			}{" "}
																		</Text>
																	</View>
																),
															)}
														</View>
													),
											  )
											: null}
									</View>
								</View>
							</ScrollView>
						</ScrollView>
					</View>
				</View>
				<View
					style={{
						flex: 1,
						height: window.innerHeight / 2 - 200,
						width: "100%",
						borderWidth: 0.5,
						borderRadius: 10,
						padding: 10,
						backgroundColor: APP_COLOURS.BG3,
						flexWrap: "wrap",
						margin: 10,
					}}>
					{analyticsdata_.map((dd) => (
						<View
							style={{
								borderWidth: 0.5,
								padding: 10,
								width: 250,
								marginRight: 10,
								marginBottom: 10,
								backgroundColor: APP_COLOURS.BG4,
								borderRadius: 10,
								// flexDirection: "row",
								alignItems: "center",
							}}>
							<Text
								style={{
									fontSize: 30,
									fontWeight: "700",
									color: "#FFF",
									padding: 10,
								}}>
								{dd.count}
							</Text>
							<Text
								style={{
									fontSize: 12,
									flexWrap: "wrap",
									color: "#FFF",
									opacity: 0.7,
								}}>
								{dd.allocation}
							</Text>

							<View
								style={{
									flexDirection: "row",
									justifyContent: "space-between",
									width: "100%",
								}}>
								<Text
									style={{
										fontSize: 12,
										flexWrap: "wrap",
										color: "#FFF",
										opacity: 0.7,
									}}>
									first{" "}
									{moment(dd.earliestStartDate).format(
										"DD/MM",
									)}
								</Text>

								<Text
									style={{
										fontSize: 12,
										flexWrap: "wrap",
										color: "#FFF",
										opacity: 0.7,
									}}>
									last{" "}
									{moment(dd.latestEndDate).format("DD/MM")}
								</Text>
							</View>
						</View>
					))}
				</View>
			</View>
		);
	}

	createAnalyticsState = (data) => {
		// Define a helper function to compare dates
		const compareDates = (date1, date2, isStartDate) => {
			if (!date1) return date2;
			const d1 = new Date(date1);
			const d2 = new Date(date2);
			return isStartDate ? (d1 < d2 ? d1 : d2) : d1 > d2 ? d1 : d2;
		};

		// Create an empty object to store our analytics
		const analytics = {};

		// Iterate over each item in the data array
		data.forEach((item) => {
			const allocation = item.allocation;

			// If this allocation isn't in the analytics object yet, add it
			if (!analytics[allocation]) {
				analytics[allocation] = {
					count: 1,
					earliestStartDate: item.dateCreated,
					latestEndDate: item.dateCreated,
				};
			} else {
				// Otherwise, increment the count and update the dates
				analytics[allocation].count++;
				analytics[allocation].earliestStartDate = compareDates(
					analytics[allocation].dateCreated,
					item.dateCreated,
					true,
				);
				analytics[allocation].latestEndDate = compareDates(
					analytics[allocation].dateCreated,
					item.dateCreated,
					false,
				);
			}
		});

		// Convert the analytics object to an array
		const analyticsArray = Object.keys(analytics).map((key) => ({
			allocation: key,
			count: analytics[key].count,
			earliestStartDate: analytics[key].earliestStartDate,
			latestEndDate: analytics[key].latestEndDate,
		}));
		console.log("analyticsArray", analyticsArray);

		this.setState({
			analyticsArray: analyticsArray,
		});
	};
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 16,
		paddingTop: 30,
	},

	row: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 10,
	},
	header: {
		fontWeight: "bold",
		fontSize: 16,
	},
	cell: {
		fontSize: 16,
	},
});

export default TableComp;
