import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
import Fade from "react-reveal/Fade";
import LineChart from "./PerformanceChart/index";
import perc from "../../../../assets/images/knowledgebase.png";
import bracketLeft from "../../../../assets/images/bracketLeft.svg";
import moment from "moment";

function randomRange(n, min, max) {
	var values = [],
		i = max;
	while (i >= min) values.push(i--);
	var results = [];
	var maxIndex = max;
	for (i = 1; i <= n; i++) {
		maxIndex--;
		var index = Math.floor(maxIndex * Math.random());
		results.push(values[index]);
		values[index] = values[maxIndex];
	}
	return results.filter((d) => typeof d !== "undefined");
}

function newRandomNumber(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default class BAKnowledgeBase extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: "",
			dataHistory: randomRange(12, 50, 100),
			overallHistory: randomRange(12, 50, 100),
			data: [
				{
					title: "Brand",
					percentage: (newRandomNumber(0, 100) / 100).toFixed(2),
					dataHistory: randomRange(12, 50, 100),
				},
				{
					title: "Product",
					percentage: (newRandomNumber(0, 100) / 100).toFixed(2),
					dataHistory: randomRange(12, 50, 100),
				},
				{
					title: "Sales",
					percentage: (newRandomNumber(0, 100) / 100).toFixed(2),
					dataHistory: randomRange(12, 50, 100),
				},
				{
					title: "Service",
					percentage: (newRandomNumber(0, 100) / 100).toFixed(2),
					dataHistory: randomRange(12, 50, 100),
				},
			],
		};
	}

	render() {
		let chartSize = 90;
		let circleWidth = 20;
		let overallScore = 0;
		return (
			<Fade>
				<View
					style={{
						margin: 20,

						paddingBottom: 20,
						marginTop: 100,
					}}>
					<View
						style={{
							flexDirection: "row",
						}}>
						<View
							style={{
								flex: 1,
							}}>
							<View
								onMouseEnter={() =>
									this.setState({
										hover: "",
										dataHistory: this.state.overallHistory,
									})
								}
								style={{
									width: "100%",
									justifyContent: "space-between",
									flexDirection: "row",
									paddingBottom: 10,
									borderBottomColor: APP_COLOURS.TRANSHALO,
									borderBottomWidth: 0.5,
									alignItems: "flex-end",
									marginBottom: 10,
								}}>
								<View
									style={{
										position: "absolute",
										left: -12,
										top: -15,
									}}>
									<Text
										style={{
											fontSize: 13,
											color: APP_COLOURS.WHITE,
											fontWeight: "300",
											fontFamily: "Avenir",
										}}>
										SKILLS
										<Text
											style={{
												fontWeight: "900",
											}}>
											TRAQ
										</Text>
									</Text>
								</View>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 25,
										fontWeight: "100",
										color: APP_COLOURS.WHITE,
										marginBottom: 10,
									}}>
									Knowledge Base
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<View
									style={{
										flexDirection: "row",
									}}>
									<View
										style={{
											flexDirection: "row",
											flexWrap: "wrap",
											maxWidth: chartSize * 2.5,
										}}>
										{this.state.data.map((dd) => {
											overallScore =
												overallScore + parseFloat(dd.percentage);
											return (
												<View
													onMouseEnter={() =>
														this.setState({
															hover: dd.title,
															dataHistory: dd.dataHistory,
														})
													}
													// onMouseLeave={() =>
													// 	this.setState({ hover: "" })
													// }
													style={{
														justifyContent: "center",
														alignItems: "center",
														margin: 5,
													}}>
													<View
														style={{
															width: chartSize,
															height: chartSize,
															backgroundColor:
																"rgba(0, 255, 249, 0.05)",
															borderRadius: chartSize / 2,
															justifyContent: "center",
															alignItems: "center",
															overflow: "hidden",
															shadowColor: "#000",
															shadowOffset: {
																width: 0,
																height: 10,
															},
															shadowOpacity: 0.25,
															shadowRadius: 7.84,
															elevation: 9,
														}}>
														<View
															style={{
																width: chartSize,
																height:
																	chartSize * dd.percentage,
																backgroundColor:
																	"rgba(0, 255, 249, 1)",
																position: "absolute",
																zIndex: 15,
																bottom: 0,
																opacity:
																	this.state.hover === dd.title
																		? 1
																		: 0.5,
															}}
														/>
														<View
															style={{
																backgroundColor:
																	APP_COLOURS.BG3,
																width: chartSize - circleWidth,
																height: chartSize - circleWidth,
																borderRadius:
																	(chartSize - circleWidth) /
																	2,
																justifyContent: "center",
																alignItems: "center",
																paddingLeft: 7,
																zIndex: 20,
															}}>
															<Text
																style={{
																	// fontFamily: "Avenir",
																	fontSize: 30,
																	// fontWeight: "100",
																	fontWeight:
																		this.state.hover ===
																		dd.title
																			? "300"
																			: "100",
																	color: "#00FFF9",
																	letterSpacing: 1.2,
																}}>
																{(dd.percentage * 100).toFixed(
																	0,
																)}
																<Text
																	style={{
																		fontSize: 15,
																		fontWeight: "100",
																	}}>
																	%
																</Text>
															</Text>
														</View>
													</View>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 13,
															fontWeight:
																this.state.hover === dd.title
																	? "500"
																	: "200",
															color: APP_COLOURS.WHITE,
														}}>
														{dd.title}
													</Text>
												</View>
											);
										})}
									</View>
								</View>

								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
											marginRight: 10,
											marginLeft: 10,
										}}>
										<Image
											source={bracketLeft}
											style={{
												width: 22,
												height: 108,
												resizeMode: "contain",
											}}
										/>
									</View>
									<View>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 20,
												fontWeight: "100",
												color: APP_COLOURS.WHITE,
												opacity: 0.6,
											}}>
											Overall
										</Text>
										<Text
											onMouseEnter={() =>
												this.setState({
													hover: "",
													dataHistory: this.state.overallHistory,
												})
											}
											style={{
												fontFamily: "Avenir",
												fontSize: 50,
												fontWeight: "100",
												color: APP_COLOURS.WHITE,
												opacity: this.state.hover === "" ? 1 : 0.8,
											}}>
											{(
												(overallScore / this.state.data.length) *
												100
											).toFixed(0)}
											<Text
												style={{
													fontSize: 30,
													fontWeight: "100",
												}}>
												{" "}
												%
											</Text>
										</Text>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 10,
												fontWeight: "100",
												color: APP_COLOURS.WHITE,
												opacity: 0.8,
												maxWidth: 150,
											}}>
											Testing Staff knowledge and key areas of focus.
											Sales staff must maintain an 80% average in
											order to qualify for commission incentives
										</Text>
									</View>
								</View>
							</View>
						</View>
						<View
							style={{
								flex: 1,
							}}>
							<View
								style={{
									width: "100%",
									justifyContent: "space-between",
									flexDirection: "row",
									paddingBottom: 10,
									borderBottomColor: APP_COLOURS.TRANSHALO,
									borderBottomWidth: 0.5,
									alignItems: "flex-end",
									marginBottom: 10,
								}}>
								<View
									style={{
										position: "absolute",
										left: -12,
										top: -15,
									}}>
									<Text
										style={{
											fontSize: 13,
											color: APP_COLOURS.WHITE,
											fontWeight: "300",
											fontFamily: "Avenir",
										}}>
										SKILLS
										<Text
											style={{
												fontWeight: "900",
											}}>
											TRAQ
										</Text>
									</Text>
								</View>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 25,
										fontWeight: "100",
										color: APP_COLOURS.WHITE,
										marginBottom: 10,
									}}>
									{this.state.hover !== ""
										? this.state.hover
										: "Knowledge Base"}{" "}
									history
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<View
									style={{
										// height: 300,
										width: 450,
									}}>
									<LineChart
										// data={this.state.salesReports.map((d) =>
										// 	parseFloat(d.sales_total),
										// )}
										// data={[5, 8.8, 8.3, 5, 6, 7.8, 5.5, 9, 2, 8]}
										label={`${this.state.hover} history`}
										data={this.state.dataHistory}
										average={80}
										max={100}
									/>
								</View>
							</View>
						</View>
					</View>

					<View
						style={{
							alignItems: "flex-end",
							marginTop: 30,
						}}>
						<Text
							style={{
								fontStyle: "italic",
								fontFamily: "Avenir",
								fontSize: 10,
								fontWeight: "100",
								color: APP_COLOURS.WHITE,
							}}>
							Knowledge base & Performance report
						</Text>
						<Text
							style={{
								fontStyle: "italic",
								fontFamily: "Avenir",
								fontSize: 10,
								fontWeight: "100",
								color: APP_COLOURS.WHITE,
							}}>
							{moment().format("MMMM, YYYY")}
						</Text>
					</View>
				</View>
			</Fade>
		);
	}
}
