import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
import Fade from "react-reveal/Fade";
import targetsIllust from "../../../../assets/images/targetsIllust.png";

export default class BATargets extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Fade>
				<View
					style={{
						margin: 20,
						borderBottomColor: APP_COLOURS.TRANSHALO,
						borderBottomWidth: 0.5,
						paddingBottom: 20,
					}}>
					<Text
						style={{
							fontStyle: "Avenir",
							fontSize: 18,
							fontWeight: "500",
							color: APP_COLOURS.WHITE,
							margin: 20,
						}}>
						{" "}
						Sales targets{" "}
					</Text>
					<Image
						source={targetsIllust}
						style={{
							width: 369,
							height: 139,
							resizeMode: "contain",
						}}
					/>
				</View>
			</Fade>
		);
	}
}
