import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

export default class TabTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: true,
			tab: {
				id: "",
				title: "All",
			},
			tabs: this.props.tabs,
			tab: this.props.tabs[0],
		};
	}

	render() {
		return (
			<View>
				<View
					// onMouseEnter={() => this.setState({ hover: true })}
					// onMouseLeave={() => this.setState({ hover: false })}
					style={{
						flexDirection: "row",
						marginTop: 15,
						marginBottom: 10,
						alignItems: "center",
					}}>
					{this.state.tabs.map((d, i) => (
						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									tab: d,
								});
								this.props.selectedSubPage(d);
							}}>
							<View
								style={{
									// backgroundColor: APP_COLOURS.WHITE,
									borderWidth: 1,
									borderColor:
										this.state.tab === d
											? APP_COLOURS.WHITE
											: APP_COLOURS.PLACEHOLDER,
									marginRight: 5,
									borderRadius: 10,
									// padding: 10,
									// justifyContent: "center",
									alignItems: "center",
									minWidth: 100,
									flexDirection: "row",
									// paddingTop: this.state.hover ? 50 : 10,
									marginLeft: 2,
									padding: 10,
									opacity: this.state.tab === d ? 1 : 0.5,
									// top: 9,
								}}>
								<Text
									style={{
										fontSize: 14,
										fontWeight: "900",
										// this.state.tab === d ? "900" : "300",
										color: "#FFF",
										marginRight: 15,
										fontFamily: "Avenir",
									}}>
									{d}
								</Text>
							</View>
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	}

	async componentDidMount() {
		await this.props.selectedSubPage(this.props.tabs[0]);
	}
}
