// let e = `http://localhost:5001/cris-c9d88/us-central1/app`;
let e = `https://us-central1-cris-c9d88.cloudfunctions.net/app`;

export const api_login = e + '/api/login';
export const api_signup = e + '/api/signup';

export const api_newQuery = e + '/api/query/newquery';

export const api_userqueries = e + '/api/query/userqueries';

export const api_admin_managerqueryaddchat = e + '/api/admin/addChatItem';
