import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS, AVATARS } from "../../APP_VARS";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Font from "react-font";
// import tick from "./../../../assets/images/tick.png";
// import memberBadge from "../../../assets/images/memberBadge.svg";
// import female from "./../../../assets/images/female.png";

import SearchBar from "./SearchBar";

import traqQ from "../../../assets/images/logo512.png";
import search from "../../../assets/images/search.png";
import filterAz from "../../../assets/images/filterAz.png";
import filterNone from "../../../assets/images/filterNone.png";
import filterZa from "../../../assets/images/filterZa.png";

import timer from "../../../assets/images/timer.png";
import location from "../../../assets/images/location.png";
import charts from "../../../assets/images/charts.png";
import prize from "../../../assets/images/prize.png";
import plus from "../../../assets/images/plus.svg";

export default class UsersTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterByName: "",
			startRec: 0,
			endRec: 5,
			pageNumber: 1,
			selectedUserID: "",
			usersPerPage: 10,
			filterByPermission: "",
			sortUsers: "AtoZ",

			deleteModal: "",
		};
		this._timeout = 500;
	}

	async componentDidMount() {
		this.calTableHeight();
	}

	calTableHeight = async () => {
		await console.log(this.props.screenHeight);
		let records = (this.props.screenHeight - 50) / 51;
		let totalRec = parseInt(records);

		this.setState({
			startRec: totalRec * (this.state.pageNumber - 1),
			endRec: totalRec * this.state.pageNumber,
			usersPerPage: totalRec,
		});
	};

	render() {
		let col1 = 170,
			col2 = 100,
			col3 = 50;

		let fs = 14;

		// .filter((d_) => d_.user_type == this.state.selectedUserGroup)

		let users = [];

		let usersLength = 0;
		if (Array.isArray(this.props.users)) {
			users = this.props.users
				.filter((d_) => {
					let filter = d_;

					if (
						typeof this.state.filterByName !== "undefined" &&
						this.state.filterByName !== ""
					) {
						let text_ = this.state.filterByName;
						var outString = text_.replace(
							/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
							"",
						);

						let join_names = `${d_.last_name} ${d_.first_name}`;

						return join_names
							.toLowerCase()
							.includes(outString.toLowerCase());
					}

					return filter;
				})
				.filter((d_) => {
					let filter = d_;

					if (
						typeof this.state.filterByPermission !== "undefined" &&
						this.state.filterByPermission !== ""
					) {
						let text_ = this.state.filterByPermission;
						var outString = text_.replace(
							/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
							"",
						);

						let join_names = `${d_.permission}`;

						return join_names
							.toLowerCase()
							.includes(outString.toLowerCase());
					}

					return filter;
				})
				.sort((a, b) => {
					if (this.state.sortUsers === "AtoZ") {
						return b.last_name < a.last_name;
					} else {
						return b.last_name > a.last_name;
					}
				});
			usersLength = users.length > 0 ? users.length : 0;
		}

		// console.log(usersLength);

		return (
			<View
				style={
					{
						// flex: 1,
						// flexDirection: 'row',
						// marginTop: 16,
					}
				}>
				<View
					style={{
						width: "100%",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "flex-end",
					}}>
					<View
						style={{
							flexDirection: "row",
							flex: 1,
							alignItems: "center",
							// justifyContent: "flex-end",
							// paddingTop: 5,
							marginBottom: 5,
							height: 50,
						}}>
						<Text
							numberOfLines={2}
							style={{
								flex: 1,
								fontFamily: "Avenir",
								fontSize: 15,
								fontWeight: "600",
								flex: 1,
								flexWrap: "wrap",
								color: APP_COLOURS.WHITE,
								opacity: 0.7,
								marginRight: 10,
							}}>
							Staff members
						</Text>
						<TouchableOpacity
							style={{
								// width: 130,
								height: "auto",
								alignItems: "center",

								padding: 7,
								borderRadius: 6,
								marginRight: 10,
								height: "auto",
								backgroundColor: APP_COLOURS.BLUE,
								flexDirection: "row",
							}}
							onPress={() => {
								this.props.newStaffProfileModal();
							}}>
							<Image
								source={plus}
								style={{
									width: 11,
									height: 11,
									resizeMode: "contain",
								}}
							/>
						</TouchableOpacity>
						<View
							style={{
								width: "auto",
								height: 35,
								alignItems: "center",
								borderRadius: 5,
								padding: 10,
								marginRight: 10,
								// backgroundColor: APP_COLOURS.BLUE,
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "flex-end",
							}}>
							<Text
								numberOfLines={1}
								style={{
									fontFamily: "Avenir",
									fontSize: 15,
									fontWeight: "400",
									flexWrap: "wrap",
									color: APP_COLOURS.WHITE,
									opacity: 0.7,
									marginRight: 10,
								}}>
								({this.props.users.length}) users
							</Text>
						</View>
						<TouchableOpacity
							onPressIn={() =>
								this.setState({
									showSearch: !this.state.showSearch,
									filterByName: "",
								})
							}>
							<Image
								source={search}
								style={{
									width: 20,
									height: 20,
									resizeMode: "contain",
									marginRight: 5,
								}}
							/>
						</TouchableOpacity>
						{this.state.showSearch ? (
							<TextInput
								ref={(ref) => {
									this.filterByName = ref;
								}}
								style={{
									padding: 8,
									fontSize: 14,
									borderRadius: 20,

									color: "#FFF",
									fontWeight:
										this.state.filterByName === "" ? "200" : "500",
									fontFamily: "Avenir",

									backgroundColor: "rgba(255, 255, 255, 0.05)",
									// borderColor: APP_COLOURS.WHITE_TRANSPARENT3,
									// borderWidth: 0.3,
									marginRight: 5,
									paddingLeft: 15,
								}}
								placeholderTextColor={APP_COLOURS.WHITE_TRANSPARENT3}
								autoCapitalize='words'
								autoFocus={true}
								clearButtonMode='while-editing'
								editable={true}
								placeholder={`Type something`}
								onChangeText={(text) => {
									this.setState({
										filterByName: text,
									});
								}}
							/>
						) : null}

						<TouchableOpacity
							onPress={() =>
								this.setState({
									showDelete: !this.state.showDelete,
								})
							}
							style={{
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
								display: "none",
								borderRadius: 10,
								margin: 5,
								marginRight: 15,
							}}>
							<View style={{}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 15,
										fontWeight: "400",
										color: APP_COLOURS.BLUE,
									}}>
									Edit
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				</View>
				<View
					style={{
						flex: 1,
						paddingRight: 8,
						borderTopColor: "#797979",
						borderTopWidth: 0.3,
					}}>
					<View
						style={{
							height: this.props.screenHeight - 150,
							paddingTop: 8,
						}}>
						{usersLength > 0
							? this.filterFields([
									...new Set(
										this.props.users.map((d) => d.permission),
									),
							  ])
							: null}
						<ScrollView>
							{users
								.slice(this.state.startRec, this.state.endRec)
								.map((d, i) => (
									<View
										key={i}
										style={
											{
												// width: "100%",
												// flexDirection: "row",
											}
										}>
										<TouchableOpacity
											onLongPress={() => {
												this.setState({
													deleteModal: d,
												});
											}}
											onPress={() => {
												this.setState({
													selectedUserID:
														this.state.selectedUserID === d.id
															? ""
															: d.id,
													profileData: d,
													viewmemberProfile: true,
												});
												// console.log(d.uuid);
												this.props.selectedProfile(d);
											}}>
											<View
												key={i}
												onMouseEnter={() =>
													this.setState({
														hover: i,
													})
												}
												onMouseLeave={() =>
													this.setState({
														hover: -1,
													})
												}
												style={{
													width: "100%",
													backgroundColor:
														"rgba(255, 255, 255, 0.05)",
													opacity:
														this.state.hover === i
															? 1
															: this.state.selectedUserID ===
															  d.id
															? 1
															: 0.7,
													flexDirection: "row",
													alignItems: "center",
													borderRadius: 3,
													marginRight: 10,
													marginBottom: 2,
													paddingRight: 10,

													// backgroundColor:
													// 	this.state.hover == i
													// 		? APP_COLOURS.WHITE
													// 		: APP_COLOURS.OFFWHITE,
													flexDirection: "row",
													borderColor: "#C5C5C5",
												}}>
												<View
													style={{
														width: 5,
														borderRadius: 4,
														height: 30,
														backgroundColor:
															this.state.selectedUserID === d.id
																? APP_COLOURS.BLUE
																: "transparent",
														marginRight: 12,
													}}
												/>
												<Image
													// source={female}
													source={AVATARS[d.avatar].pp}
													style={{
														width: fs * 2.5,
														height: fs * 2.5,
														borderRadius: 15,
														resizeMode: "contain",
														backgroundColor: "#FFF",
														borderWidth: 0.3,
														borderColor: "#C5C5C5",
														shadowColor: "#ECF4FF",
														shadowOffset: {
															width: 0,
															height: 8,
														},
														shadowOpacity: 0.44,
														shadowRadius: 10.32,

														elevation: 16,
														marginRight: 0,
													}}
												/>
												<View
													style={{
														flex: 1,
														minWidth: col1,
														padding: 10,
													}}>
													<Text
														style={{
															fontSize: fs * 1.1,
															color: APP_COLOURS.WHITE,
														}}>
														{d.first_name},{" "}
														<Text
															style={{
																fontWeight: "600",
															}}>
															{d.last_name}
														</Text>
													</Text>
													<View
														style={{
															flexDirection: "row",
															alignItems: "center",
															marginTop: 3,
															display:
																d.last_seen !== "" &&
																moment().diff(
																	d.last_seen,
																	"hours",
																) < 24
																	? "flex"
																	: "none",
														}}>
														{d.status === "Shift ended" ? (
															<View
																style={{
																	width: fs * 0.4,
																	borderRadius: fs * 0.2,
																	height: fs * 0.4,
																	backgroundColor:
																		APP_COLOURS.RED,
																	marginRight: 4,
																}}
															/>
														) : (
															<View
																style={{
																	width: fs * 0.4,
																	borderRadius: fs * 0.2,
																	height: fs * 0.4,
																	backgroundColor:
																		d.status === "On lunch"
																			? APP_COLOURS.ORANGE
																			: APP_COLOURS.GREEN,
																	marginRight: 4,
																}}
															/>
														)}

														<Text
															style={{
																fontSize: fs * 0.6,
																color: APP_COLOURS.WHITE,
																fontWeight: "300",
															}}>
															{d.status}
														</Text>
													</View>
												</View>

												<View
													style={{
														width: 100,
														display:
															d.last_seen !== ""
																? "flex"
																: "none",
													}}>
													<Text
														style={{
															fontSize: 9,
															color: APP_COLOURS.WHITE,
															fontWeight: "200",
														}}>
														Last seen
													</Text>
													<Text
														style={{
															fontSize: 11,
															color: APP_COLOURS.WHITE,
															fontWeight: "600",
														}}>
														{moment(d.last_seen).fromNow()}
													</Text>
												</View>

												<View
													style={{
														// flex: 1,
														// display:
														// 	this.props.screenWidth < 1670
														// 		? "none"
														// 		: "flex",
														flexDirection: "row",
														display: "none",
													}}>
													<View
														style={{
															height: "100%",
															width: 0.5,
															backgroundColor: APP_COLOURS.WHITE,
															marginRight: 10,
														}}
													/>
													<View
														style={{
															width: 150,
														}}>
														<Text
															style={{
																fontSize: fs - 2,
																fontWeight: "100",
																color: APP_COLOURS.TEXTCOLOR,
															}}>
															{d.permission}
														</Text>
														<Text
															style={{
																fontSize: fs - 2,
																color: APP_COLOURS.TEXTCOLOR,
																fontWeight: "600",
															}}>
															{d.position}
														</Text>
													</View>

													<Image
														source={timer}
														style={{
															width: 20,
															height: 25,
															resizeMode: "contain",
															marginRight: 5,
															opacity: 0.3,
														}}
													/>
													<Image
														source={location}
														style={{
															width: 20,
															height: 25,
															resizeMode: "contain",
															marginRight: 5,
															opacity: 0.3,
														}}
													/>
													<Image
														source={prize}
														style={{
															width: 25,
															height: 20,
															resizeMode: "contain",
															marginRight: 5,
															opacity: 0.3,
														}}
													/>
													<Image
														source={charts}
														style={{
															width: 25,
															height: 25,
															resizeMode: "contain",
															marginRight: 5,
															opacity: 0.3,
														}}
													/>
												</View>
											</View>
										</TouchableOpacity>
									</View>
								))}
							{this.props.loading ? (
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										minHeight: 50,
									}}>
									<ActivityIndicator color={APP_COLOURS.BLUE} />
								</View>
							) : null}
						</ScrollView>
						{usersLength > 0 ? this.pagination(usersLength) : null}
						{this.state.deleteModal !== ""
							? this.deleteModalView()
							: null}
					</View>
				</View>
			</View>
		);
	}

	filterFields = (unique) => {
		// const unique = [...new Set(users.map((d) => d.permission))];
		return (
			<View
				style={{
					width: "100%",
					// height: "auto",
					// padding: 5,
					flexDirection: "row",
					alignItems: "center",
					// justifyContent: "flex-end",
					justifyContent: "space-between",
					marginBottom: 5,
					// position:
					// 	this.props.screenWidth < 1646 ? "absolute" : "relative",
				}}>
				{/* {this.props.screenWidth < 1646 ? ( */}
				<View
					style={{
						marginRight: 8,
					}}>
					<SearchBar
						{...this.props}
						placeHolder={`Filter by permission`}
						data={unique}
						loading={this.state.loading}
						selectedData={async (d) => {
							console.log(d);
							await this.setState({
								filterByPermission: d,
							});
							await this.calTableHeight();
						}}
					/>
				</View>
				{/* ) : (
					<View
						style={{
							// flex: 1,
							maxWidth: 400,
							borderRightWidth: 0.5,
							borderRightColor: "#797979",
						}}>
						<ScrollView horizontal showsHorizontalScrollIndicator={false}>
							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										filterByPermission: "",
									});
									await this.calTableHeight();
								}}>
								<View
									style={{
										backgroundColor:
											this.state.filterByPermission === ""
												? APP_COLOURS.BLUE
												: "transsparent",
										marginRight: 5,
										borderRadius: 5,
										padding: 10,

										justifyContent: "center",
										alignItems: "center",
									}}>
									<Text
										style={{
											fontSize: 14,
											fontWeight: "300",
											color: "#FFF",
										}}>
										All
									</Text>
								</View>
							</TouchableOpacity>
							{unique.map((d, i) => (
								<TouchableOpacity
									onPress={async () => {
										await this.setState({
											filterByPermission: d,
										});
										await this.calTableHeight();
									}}>
									<View
										style={{
											backgroundColor:
												this.state.filterByPermission === d
													? APP_COLOURS.BLUE
													: APP_COLOURS.TRANSHALO,

											marginRight: 5,
											borderRadius: 5,
											padding: 10,
											// justifyContent: "center",
											alignItems: "center",
											minWidth: 150,
											flexDirection: "row",
										}}>
										<Image
											source={traqQ}
											style={{
												width: 18,
												height: 18,
												resizeMode: "contain",
												marginRight: 15,
												opacity:
													this.state.filterByPermission === d
														? 1
														: 0.9,
											}}
										/>
										<Text
											style={{
												fontSize: 14,
												fontWeight:
													this.state.filterByPermission === d
														? "800"
														: "300",
												color: "#FFF",
											}}>
											{d}
										</Text>
									</View>
								</TouchableOpacity>
							))}
						</ScrollView>
					</View>
				)} */}

				<View
					style={{
						width: 120,
						flexDirection: "row",
						justifyContent: "flex-end",
					}}>
					<TouchableOpacity
						onPressIn={() =>
							this.setState({
								sortUsers: "AtoZ",
							})
						}>
						<Image
							source={filterZa}
							style={{
								width: 28,
								height: 20,
								resizeMode: "contain",
								marginRight: 15,
							}}
						/>
					</TouchableOpacity>

					<TouchableOpacity
						onPressIn={() =>
							this.setState({
								sortUsers: "ZtoA",
							})
						}>
						<Image
							source={filterAz}
							style={{
								width: 28,
								height: 20,
								resizeMode: "contain",
								marginRight: 15,
							}}
						/>
					</TouchableOpacity>

					<TouchableOpacity
						onPressIn={() =>
							this.setState({
								sortUsers: "",
							})
						}>
						<Image
							source={filterNone}
							style={{
								width: 20,
								height: 20,
								resizeMode: "contain",
							}}
						/>
					</TouchableOpacity>
				</View>
			</View>
		);
	};

	pagination = (usersLength) => {
		let userCountPerPage =
			parseInt(usersLength / this.state.usersPerPage) + 1;

		let pages = new Array(userCountPerPage > 0 ? userCountPerPage : 1).fill();

		return (
			<View
				style={{
					height: 20,

					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-end",
				}}>
				<ScrollView horizontal showsHorizontalScrollIndicator={false}>
					{pages.map((d, i) => (
						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									pageNumber: i + 1,
								});
								await this.calTableHeight();
							}}>
							<View
								style={{
									backgroundColor:
										this.state.pageNumber === i + 1
											? APP_COLOURS.BLUE
											: "transsparent",

									marginRight: 3,
									width: 25,
									height: 25,
									borderRadius: 5,
									padding: 5,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										fontSize: 12,
										fontWeight: "500",
										color: "#FFF",
									}}>
									{i + 1}
								</Text>
							</View>
						</TouchableOpacity>
					))}
				</ScrollView>
			</View>
		);
	};

	deleteModalView = () => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(21, 21, 21, 0.8)",
					}}>
					<ScrollView>
						<View
							style={{
								width: "100%",
								maxWidth: 400,
								height: "auto",
								backgroundColor: "#FFF",
								borderRadius: 20,
								justifyContent: "center",
								alignItems: "center",
								padding: 10,
								marginTop: 30,
								marginBottom: 30,
							}}>
							<View
								style={{
									backgroundColor: "#FFF",
									borderRadius: 15,
									padding: 15,
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											deleteModal: "",
										})
									}>
									<Image
										source={require("../../../assets/images/close.png")}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
											marginRight: 20,
										}}
									/>
								</TouchableOpacity>
								<Text
									adjustsFontSizeToFit
									style={{
										color: "#000",
										fontSize: 13,
										marginBottom: 10,
										marginTop: 10,
									}}>
									You are about to delete{" "}
									{this.state.deleteModal.first_name}{" "}
									{this.state.deleteModal.last_name}
								</Text>
								<Text
									adjustsFontSizeToFit
									style={{
										color: "#000",
										fontSize: 13,
										marginBottom: 10,
										marginTop: 10,
									}}>
									Type 'delete' to confirm user deletion
								</Text>
								<TextInput
									style={{
										marginBottom: 10,
										padding: 16,
										marginTop: 0,
										fontSize: 16,
										borderRadius: 15,
										textAlign: "center",
										color: APP_COLOURS.BLUE,
										fontWeight: "200",
										fontFamily: "Avenir",
										letterSpacing: 0.26,
										borderWidth: 0.3,
										backgroundColor: "#FFF",
										borderColor: APP_COLOURS.BLUE,
									}}
									placeholderTextColor={APP_COLOURS.BLUE}
									autoCapitalize='none'
									placeholder={`delete`}
									// value={this.state.first_name}
									onChangeText={(text) => {
										if (text === "delete") {
											this.setState({
												deleteConfirmed: true,
											});
										}
									}}
								/>

								<TouchableOpacity
									disabled={!this.state.deleteConfirmed}
									onPress={() => {
										this.props.deleteStaff(this.state.deleteModal.id);
										this.setState({
											deleteModal: "",
										});
									}}
									style={{
										flexDirection: "row",
										justifyContent: "center",
										alignItems: "center",
									}}>
									<View
										style={{
											backgroundColor: !this.state.deleteConfirmed
												? "transparent"
												: APP_COLOURS.RED,
											borderColor: APP_COLOURS.RED,
											borderWidth: 1,
											padding: 5,
											borderRadius: 9,
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "row",
											paddingRight: 9,
										}}>
										<Image
											source={require("../../../assets/images/bin.png")}
											style={{
												width: 22,
												height: 22,
												// borderRadius: 25,
												resizeMode: "contain",
												marginRight: 5,
											}}
										/>
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												color: !this.state.deleteConfirmed
													? APP_COLOURS.RED
													: "#FFF",
												fontSize: 10,
											}}>
											<Font weight={500} family='Montserrat'>
												Delete user
											</Font>
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};
}
