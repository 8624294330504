import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	Image,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import { APP_COLOURS } from "../../APP_VARS";

// import Screenshot from "./ScreenShotReport";
import { api_getAllSurveys } from "../../Api";
import survey from "./traq_survey.png";
import Con from "./creamofnature";
import InStoreQRCodeModule from "./InStoreQRcodeModule";
import WalkThrough from "./WalkThrough";

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: "",
			currentComponent: "",
			selectedSurvey: "",
			surveys: [
				// {
				// 	id: "tscon876132423",
				// 	title: "CREME OF NATURE",
				// 	page: "creamofnature",
				// 	component: "Con",
				// 	surveyType: "Private",
				// 	status: true,
				// 	url: "https://con.revlon.traq.app/",
				// },
			],
		};
	}

	componentDidMount() {
		this.getSurveys();
	}

	getSurveys = async () => {
		let url = api_getAllSurveys;

		await this.setState({
			loading: true,
			devices: [],
			showDelete: false,
			addNewDevice: false,
			viewDeviceProfile: false,
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								surveys: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		let surveys = this.state.surveys;
		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
					height: this.props.screenHeight,
				}}>
				{/* {this.sideBar()}
				 */}
				<View
					style={{
						width: 250,
						padding: 10,
						marginRight: 10,
						borderRadius: 15,
						backgroundColor: APP_COLOURS.BG,
					}}>
					{/* <Text
						style={{
							fontFamily: "Avenir",
							fontSize: 15,
							opacity: 0.8,
							fontWeight: "700",
							color: APP_COLOURS.WHITE,
							marginBottom: 15,
						}}>
						Surveys
					</Text> */}
					<View
						style={{
							width: "100%",
							// width: 30,
							// height: 30,
							alignItems: "center",
							justifyContent: "center",
							marginBottom: 50,
						}}>
						<Image
							source={survey}
							style={{
								width: 100,
								height: 35,
								resizeMode: "contain",
							}}
						/>
					</View>
					<ScrollView>
						{/* <View
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: "auto",
									padding: 7,
									alignItems: "center",
									justifyContent: "center",
									borderRadius: 5,
									minHeight: 45,
									marginBottom: 5,
									flexDirection: "row",
									borderColor: "#C5C5C5",
									// backgroundColor: "rgba(255, 255, 255, 0.01)",
								}}
								onPress={() =>
									this.setState({
										currentPage: "WalkThrough",
										currentComponent: "WalkThrough",
										selectedSurvey: "",
									})
								}>
								<View
									style={{
										flex: 1,
										padding: 3,
										// display: this.state.hover ? "flex" : "none",
										marginLeft: 9,
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 10,
											color: "#FFF",
											fontWeight: "300",
										}}>
										User guide
									</Text>
								</View>
							</TouchableOpacity>
						</View> */}
						{this.state.loading ? (
							<View
								style={{
									padding: 10,
									marginTop: 15,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<ActivityIndicator color={APP_COLOURS.BLUE} />
							</View>
						) : null}
						{surveys.map((d, i) => (
							<View
								key={i}
								style={{
									alignItems: "center",
									justifyContent: "center",
									width: "100%",
								}}>
								<TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 5,
										minHeight: 45,
										marginBottom: 5,
										flexDirection: "row",
										borderWidth:
											this.state.currentPage == d.id
												? 0.3
												: 0,
										borderColor: "#C5C5C5",
										backgroundColor:
											"rgba(255, 255, 255, 0.1)",
									}}
									onPress={() =>
										this.setState({
											currentPage: d.id,
											currentComponent: d.component,
											selectedSurvey: d,
										})
									}>
									<View
										style={{
											flex: 1,
											padding: 3,
											// display: this.state.hover ? "flex" : "none",
											marginLeft: 9,
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 14,
												color: "#FFF",
												fontWeight: "400",
											}}>
											{d.title}
										</Text>

										<View
											style={{
												flexDirection: "row",
												justifyContent: "space-between",
												marginTop: 5,
											}}>
											<View
												style={{
													flexDirection: "row",
													// position: "absolute",
													// top: 3,
													// left: 3,
													marginTop: 5,
												}}>
												<View
													style={{
														width: 10,
														height: 10,
														backgroundColor:
															d.status
																? "#2EE39C"
																: "#FF5F5F",
														borderRadius: 5,
														marginRight: 5,
													}}
												/>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 9,
														color: "#FFF",
														fontWeight: "400",
													}}>
													{d.status
														? `Active`
														: `Inactive`}
												</Text>
											</View>
											<View
												style={{
													flexDirection: "row",
													marginTop: 5,
												}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 9,
														color: "#FFF",
														fontWeight: "400",
													}}>
													<Text
														style={{
															fontFamily:
																"Avenir",
															fontSize: 9,
															color: "#FFF",
															fontWeight: "600",
														}}>
														{d.surveyType} survey
													</Text>
												</Text>
											</View>
										</View>
									</View>
								</TouchableOpacity>
							</View>
						))}
					</ScrollView>
				</View>
				<View
					style={{
						flex: 1,
						alignItems: "center",
					}}>
					<View
						style={{
							width: "100%",
							height: this.props.screenHeight,
							// maxWidth: 1000,
						}}>
						{this.state.currentComponent === "WalkThrough" ? (
							<WalkThrough {...this.props} />
						) : null}
						{this.state.currentComponent === "Con" ? (
							<Con
								{...this.props}
								selectedSurvey={this.state.selectedSurvey}
							/>
						) : null}

						{this.state.currentComponent ===
						"InStoreQRCodeModule" ? (
							<InStoreQRCodeModule
								{...this.props}
								selectedSurvey={this.state.selectedSurvey}
							/>
						) : null}
					</View>
				</View>
			</View>
		);
	}

	// sideBar = () => {
	// 	let surveys = this.state.surveys;
	// 	return (

	// 	);
	// };
}
