import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import {
	View,
	Text,
	Image,
	TouchableOpacity,
	TextInput,
	ActivityIndicator,
} from "react-native";
import Lottie from "react-lottie";
import moment from "moment";
import Fade from "react-reveal/Fade";

import Font from "react-font";

import { APP_COLOURS } from "../../APP_VARS";

export default class OrganisationAcount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
		};
	}

	async componentDidMount() {
		await this.validateSession();
	}

	render() {
		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",

					flex: 1,
					// backgroundColor: APP_COLOURS.BACKGROUND,
					padding: 20,
				}}>
				<View
					style={{
						// alignItems: 'center',
						// justifyContent: 'center',
						width: "100%",
						borderBottomWidth: 0.3,
						borderBottomColor: "#C5C5C5",
						marginTop: 5,
					}}>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 25,
							color: APP_COLOURS.WHITE,
							fontWeight: "700",
						}}>
						Organisation Profile
					</Text>
				</View>
				{this.state.laoding ? (
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								padding: 10,
								backgroundColor: APP_COLOURS.BG3,
								borderRadius: 12,
							}}>
							<ActivityIndicator color={"#FFF"} />
						</View>
					</View>
				) : (
					<View>
						<Text
							style={{
								padding: 10,
								color: "#FFF",
							}}>
							No organisation profile set up.
						</Text>
						<Text
							style={{
								padding: 10,
								color: "#FFF",
							}}>
							Please contact sales at sales@traq.app
						</Text>
					</View>
				)}
			</div>
		);
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let accessToken = await sessionStorage.getItem("accessToken");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			accessToken,
			refresh_token,
		});

		if (accessToken !== null && accessToken !== "") {
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			// this.props.history.push("/");
			sessionStorage.clear();
		}
	};

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	logOut = async () => {
		await this.props.logOut();
	};
}
