import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import {
	View,
	Text,
	Dimensions,
	Image,
	TouchableOpacity,
	ScrollView,
} from "react-native";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import { APP_COLOURS } from "../APP_VARS";
// import * as animationData from "./../../assets/lottie/lf30_editor_t3bgbljb.json";
// import * as animationData2 from "../../assets/lottie/lf20_q8buwK.json";
// import logo from "./../../assets/images/logo.png";
// import logo1 from "./../../assets/images/logo512.png";
// import logoWhite from "./../../assets/images/whiteLogo.png";
import female from "./../../assets/images/female.png";
import traqQ from "./../../assets/images/logo512.png";
import close from "./../../assets/images/close.png";
// import traqbi from "./../../assets/images/traqbi.svg";
import traqbi from "./../../assets/images/traqbi.png";
import traqbifull from "./../../assets/images/traqbifull.png";
// import traqbifull from "./../../assets/images/traqbifull.svg";

import Profile from "./Profile";
// import UserManagement from "./StaffManagement/index";
import Staff from "./STAFF/index";
import DeviceManagement from "./DeviceManagement/index";
import SurveyManagement from "./SurveyManagement/index";
import QRManagement from "./QRManagement/index";
import ProductManagement from "./ProductManagement/index";
import ProductOverview from "./ProductManagement/ProductOverview";

import CustomerOverview from "./CustomerManagement/Overview";
import CustomerSegmentation from "./CustomerManagement/CustomerSegmentation";
import CustomerResults from "./CustomerManagement/results";

import SalesOverview from "./SalesManagement/Overview";
import SalesSegmentation from "./SalesManagement/SalesSegmentation";
import SalesResults from "./SalesManagement/results";

import ELearningManagement from "./ElearningManagement/index";
import OrganisationAcount from "./OrganisationAccount/OrganisationAcount";
import TopTabs from "./TopTabs";
// import Memberships from "./Memberships/index";
// import Reports from "./reports";
// import AccessControl from "./AccessControl/index";
// import Reports from "./Reports/index";

import TechSupport from "./TechSupport/index";
import techSupportIcon from "./TechSupport/techSupport.svg";

import dot_merch from "./../../assets/images/dots/dot_merch.svg";
import dot_purple from "./../../assets/images/dots/dot_purple.svg";
import dot_reminder from "./../../assets/images/dots/dot_reminder.svg";
import dot_task from "./../../assets/images/dots/dot_task.svg";
import dot_sale from "./../../assets/images/dots/dot_sale.svg";

import BrandCrud from "./CrudBrand";
import MallsCrud from "./CrudMalls";
import RetailerCrud from "./CrudRetailer";
import AppPropertiesCrud from "./AppPropertiesCrud";
import BrandTabs from "./BrandTabs";
import LiveView from "./STAFF/LiveView";
import BABasketReport from "./Reports2/FilteredReportBrand";
import Dashboard from "./Dashboard";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user_data: "",
			email: "",
			accessToken: "",
			refresh_token: "",
			screenWidth: screenWidth,
			screenHeight: screenHeight,
			authenticating: true,
			validated: true,
			hover: true,
			currentPage: "",
			selectedBrand: "",
			selectedBrandTab: {
				id: "",
				title: "All",
			},
			currentPage: "",
			subPage: "",
			pages: [
				{
					title: "Dashboard",
					page: "dashboard",
					img: require("./../../assets/images/icons/gantt.png"),
				},
				// {
				// 	title: "Devices",
				// 	page: "devicemanagement",
				// 	img: require("./../../assets/images/icons/DevTracker.png"),
				// },
				// {
				// 	title: "eLearning",
				// 	page: "elearningmanagement",
				// 	img: require("./../../assets/images/icons/document.png"),
				// },
				// {
				// 	title: "App setup",
				// 	page: "appsetup",
				// 	img: traqQ,
				// },
				// {
				// 	title: "Overview",
				// 	page: "overview",
				// 	img: require("./../../assets/images/icons/document.png"),
				// },
				// {
				// 	title: "Accounts",
				// 	page: "payments",
				// 	img: require("./../../assets/images/icons/accounts.png"),
				// },
				// {
				// 	title: "Task management",
				// 	page: "memberships",
				// 	img: require("./../../assets/images/icons/gantt_icon.png"),
				// },
				// {
				// 	title: "Access Control",
				// 	page: "access_control",
				// 	img: require("./../../assets/images/icons/settings.png"),
				// },
				// {
				// 	title: "Reports",
				// 	page: "reports",
				// 	img: require("./../../assets/images/icons/document.png"),
				// },
				// {
				// 	title: "App setup",
				// 	page: "appsetup",
				// 	img: require("./../../assets/images/icons/settings.png"),
				// },
			],
			modules: [
				// {
				// 	title: "Devices",
				// 	page: "devicemanagement",
				// 	img: require("./../../assets/images/icons/document.png"),
				// },
				// {
				// 	title: "Brands",
				// 	page: "brandmanagement",
				// 	img: require("./../../assets/images/icons/document.png"),
				// },
				// {
				// 	title: "Store",
				// 	page: "storemanagement",
				// 	img: require("./../../assets/images/icons/document.png"),
				// },
				// {
				// 	title: "Overview",
				// 	page: "overview",
				// 	img: dot_merch,
				// },
				// {
				// 	title: "Survey",
				// 	page: "datatable",
				// 	img: dot_merch,
				// },
				// {
				// 	title: "Audit",
				// 	page: "productmanagement",
				// 	img: dot_purple,
				// },
				// {
				// 	title: "Questionnaire",
				// 	page: "customermanagement",
				// 	img: dot_reminder,
				// },
				// {
				// 	title: "SALES",
				// 	page: "salesmanagement",
				// 	img: dot_sale,
				// },
				// {
				// 	title: "SKILLS",
				// 	page: "brandmanagement",
				// 	img: require("./../../assets/images/icons/document.png"),
				// },
			],
		};
	}

	componentDidMount() {
		this.validateSession();
		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let accessToken = await sessionStorage.getItem("accessToken");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		if (accessToken !== null && accessToken !== "") {
			await this.setState({
				user_data: JSON.parse(user_data),
				email,
				accessToken,
				refresh_token,
			});
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			this.props.navigate("/");
			sessionStorage.clear();
		}
	};

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: Dimensions.get("window").height,
			screenWidth: Dimensions.get("window").width,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	logOut = async () => {
		await sessionStorage.clear();
		this.props.navigate("/");
		// return <Navigate to='/' />;
	};

	render() {
		let w = this.state.screenWidth;
		let h = this.state.screenHeight;
		// let align = 'right'
		if (this.state.authenticating) {
			return (
				<div
					style={{
						flex: 1,
						backgroundColor: APP_COLOURS.BG2,
						width: w,
						height: h,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							width: w,
							height: h,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Image
								source={require("./../../assets/images/traqbifull.png")}
								style={{
									width: 120,
									height: 120,
									resizeMode: "contain",
								}}
							/>
							{/* <Image
								source={traqbifull}
								style={{
									width: 140,
									height: 140,
									resizeMode: "contain",
								}}
							/> */}
						</View>
					</View>
				</div>
			);
		} else {
			return (
				<View
					style={{
						overflow: "hidden",
						backgroundColor: APP_COLOURS.BG2,
						position: "sticky",
						width: w,
						minWidth: 1195,
						height: h,
						padding: 15,
						flex: 1,
						// alignItems: "center",
						// justifyContent: "center",
					}}>
					<View
						style={{
							flex: 1,
							marginLeft: "auto",
							marginRight: "auto",
							flexDirection: "row",
							height: h - 50,
							width: w,
							minWidth: 1195,
							padding: 12,
							paddingBottom: 5,
							// alignItems: "center",
							// justifyContent: "center",
						}}>
						<View
							// onMouseEnter={() => this.setState({ hover: true })}
							// onMouseLeave={() => this.setState({ hover: false })}
							style={{
								width: 260,
								alignItems: "left",
								padding: 10,
								borderRadius: 20,
								backgroundColor: "#252B33",
								marginRight: 10,
							}}>
							<ScrollView
								showsVerticalScrollIndicator={false}
								contentContainerStyle={{}}>
								<TouchableOpacity
									onPress={() => {
										// this.setState({
										// 	hover: !this.state.hover,
										// });
									}}>
									<View
										style={{
											height: 90,
											// width: 170,
											// flex: 1,
											// justifyContent: "center",
											// alignItems: "center",
											justifyContent: "flex-start",
											paddingTop: 5,
											marginBottom: 10,
										}}>
										{this.state.hover ? (
											<Image
												source={traqbifull}
												style={{
													width: 100,
													height: 80,
													resizeMode: "contain",
												}}
											/>
										) : (
											<Image
												source={traqbi}
												style={{
													width: 50,
													height: 70,
													resizeMode: "contain",
													left: -5,
												}}
											/>
										)}
									</View>
								</TouchableOpacity>

								{/* <View
									style={{
										height: 70,
										// justifyContent: "center",
										// alignItems: "center",
										paddingTop: 0,
										marginBottom: 10,
										paddingLeft: 12,
									}}>
									{this.state.hover ? (
										<Image
											source={require("./../../assets/images/revlon.png")}
											style={{
												width: 90,
												height: 50,
												resizeMode: "contain",
											}}
										/>
									) : (
										<Image
											source={require("./../../assets/images/revlon.png")}
											style={{
												width: 30,
												height: 50,
												resizeMode: "contain",
											}}
										/>
									)}
								</View> */}

								<View
									style={{
										alignItems: "center",
										justifyContent: "center",
										width: "100%",
										// display:  'flex' : 'none',
									}}>
									<TouchableOpacity
										style={{
											width: "100%",
											height: "auto",
											padding: 7,
											alignItems: "center",
											borderRadius: 10,
											minHeight: 70,
											marginBottom: 10,
											flexDirection: "row",
											borderColor: "#C5C5C5",
										}}
										onPress={() => {
											// this.logOut();
											this.setState({
												currentPage: "profile",
											});
										}}>
										<Image
											source={female}
											style={{
												width: 30,
												height: 30,
												borderRadius: 25,
												resizeMode: "contain",
												backgroundColor: "#FFF",
												borderWidth: 0.3,
												borderColor: "#C5C5C5",
												shadowColor: "#ECF4FF",
												shadowOffset: {
													width: 0,
													height: 8,
												},
												shadowOpacity: 0.44,
												shadowRadius: 10.32,

												elevation: 16,
											}}
										/>
										<View
											style={{
												padding: 5,
												borderRadius: 10,
												alignItems: "center",
												flexDirection: "column",
												flex: 1,
												// alignItems: 'flex-end',
												alignItems: "flex-start",
												display: this.state.hover
													? "flex"
													: "none",
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													fontWeight: "600",
													color: APP_COLOURS.WHITE,
												}}>
												{
													this.state.user_data
														.first_name
												}{" "}
												{this.state.user_data.last_name}
											</Text>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 9,
													opacity: 0.8,
													color: APP_COLOURS.WHITE,
												}}>
												{this.state.user_data.username}
											</Text>
										</View>
									</TouchableOpacity>
								</View>

								{this.state.pages.map((d, i) => (
									<View
										key={i}
										style={{
											alignItems: "center",
											justifyContent: "center",
											width: "100%",
										}}>
										<TouchableOpacity
											style={{
												width: "100%",
												height: "auto",
												padding: 7,
												alignItems: "center",
												justifyContent: "center",
												borderRadius: 10,
												minHeight: 45,
												marginBottom: 10,
												flexDirection: "row",
												// borderWidth:
												// 	this.state.currentPage == d.page
												// 		? 0.3
												// 		: 0,
												// borderColor: "#C5C5C5",
												backgroundColor:
													this.state.currentPage ==
													d.page
														? "rgba(255, 255, 255, 0.05)"
														: "transparent",
											}}
											onPress={() =>
												this.setState({
													currentPage: d.page,
												})
											}>
											<View
												style={{
													width: 30,
													height: 30,
													alignItems: "center",
													justifyContent: "center",
												}}>
												<Image
													source={d.img}
													style={{
														width: 28,
														height: 28,
														resizeMode: "contain",
													}}
												/>
											</View>

											<View
												style={{
													flex: 1,
													padding: 5,
													display: this.state.hover
														? "flex"
														: "none",
													marginLeft: 9,
												}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 14,
														color: APP_COLOURS.TEXTCOLOR,
														fontWeight: "400",
													}}>
													{d.title}
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								))}
								{/* <View
									style={{
										height: 20,
										marginTop: 40,
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 10,
											color: APP_COLOURS.TEXTCOLOR,
											fontWeight: "200",
											display: this.state.hover
												? "flex"
												: "none",
											opacity: 0.6,
										}}>
										Modules
									</Text>
								</View> */}
								{this.state.modules.map((d, i) => (
									<View
										key={i}
										style={{
											alignItems: "center",
											justifyContent: "center",
											width: "100%",
										}}>
										<TouchableOpacity
											style={{
												width: "100%",
												height: "auto",
												padding: 7,
												alignItems: "center",
												justifyContent: "center",
												borderRadius: 10,
												minHeight: 45,
												marginBottom: 10,
												flexDirection: "row",
												// borderWidth:
												// 	this.state.currentPage == d.page
												// 		? 0.3
												// 		: 0,
												// borderColor: "#C5C5C5",
												backgroundColor:
													this.state.currentPage ==
													d.page
														? APP_COLOURS.BLUE
														: "transparent",
											}}
											onPress={() =>
												this.setState({
													currentPage: d.page,
												})
											}>
											<View
												style={{
													width: 30,
													height: 30,
													alignItems: "center",
													justifyContent: "center",
												}}>
												<Image
													source={d.img}
													style={{
														width: 26,
														height: 26,
														resizeMode: "contain",
														left: -3,
													}}
												/>
											</View>

											<View
												style={{
													flex: 1,
													padding: 5,
													paddingLeft: 0,
													display: this.state.hover
														? "flex"
														: "none",
													marginLeft: 2,
													flexDirection: "row",
												}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 13,
														color: APP_COLOURS.TEXTCOLOR,
														fontWeight:
															this.state
																.currentPage ==
															d.page
																? "900"
																: "400",
													}}>
													{d.title}
												</Text>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 13,
														color: APP_COLOURS.TEXTCOLOR,
														fontWeight: "1000",
														opacity: 0.1,
													}}>
													TRAQ
												</Text>
											</View>
										</TouchableOpacity>
									</View>
								))}
							</ScrollView>

							<View
								style={{
									alignItems: "center",
									justifyContent: "center",
									width: "100%",
								}}>
								<TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										minHeight: 45,
										marginBottom: 10,
										flexDirection: "row",
										backgroundColor: APP_COLOURS.TRANSHALO,
										// this.state.currentPage == "organisationaccount"
										// 	? APP_COLOURS.TRANSHALO
										// 	: "transparent",
									}}
									onPress={() =>
										this.setState({
											currentPage: "organisationaccount",
										})
									}>
									<View
										style={{
											width: 30,
											height: 30,
											alignItems: "center",
											justifyContent: "center",
											paddingLeft: 7,
										}}>
										<Image
											source={require("./../../assets/images/icons/accounts.png")}
											style={{
												width: 28,
												height: 28,
												resizeMode: "contain",
											}}
										/>
									</View>

									<View
										style={{
											flex: 1,
											padding: 5,
											display: this.state.hover
												? "flex"
												: "none",
											marginLeft: 9,
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 12,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "300",
											}}>
											Account
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										width: "100%",
										height: "auto",
										padding: 7,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										minHeight: 45,
										marginBottom: 10,
										flexDirection: "row",
										backgroundColor: APP_COLOURS.TRANSHALO,
										// this.state.currentPage == "organisationaccount"
										// 	? APP_COLOURS.TRANSHALO
										// 	: "transparent",
									}}
									onPress={() => this.logOut()}>
									<View
										style={{
											width: 30,
											height: 30,
											alignItems: "center",
											justifyContent: "center",
										}}>
										<Image
											source={require("./../../assets/images/logout.png")}
											style={{
												width: 23,
												height: 23,
												resizeMode: "contain",
											}}
										/>
									</View>

									<View
										style={{
											flex: 1,
											padding: 5,
											display: this.state.hover
												? "flex"
												: "none",
											marginLeft: 9,
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 12,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "300",
											}}>
											Logout
										</Text>
									</View>
								</TouchableOpacity>

								{/* <View
									style={{
										height: 200,
										width: "100%",
										backgroundColor: APP_COLOURS.TRANSHALO,
										alignItems: "center",
										justifyContent: "center",
										borderRadius: 10,
										overflow: "hidden",
									}}>
									<Image
										source={require("./../../assets/images/Kal-el_orange_splash_landscape_with_small_three_building_apartm_d6e934c4-5d49-4306-8cdb-0b89b78eb940.png")}
										style={{
											width: "100%",
											height: 200,
											resizeMode: "cover",
										}}
									/>
								</View> */}
							</View>
						</View>

						<View
							style={{
								flex: 1,
							}}>
							{this.state.currentPage === "profile" ? (
								<Profile logOut={this.logOut.bind(this)} />
							) : (
								<View />
							)}

							{this.state.currentPage === "dashboard" ? (
								<Dashboard logOut={this.logOut.bind(this)} />
							) : (
								<View />
							)}

							{this.state.currentPage === "datatable" ? (
								<View
									style={{
										flex: 1,
									}}>
									<TopTabs
										{...this.state}
										screenHeight={this.state.screenHeight}
										screenWidth={this.state.screenWidth}
										selectedSubPage={(d) =>
											this.setState({
												subPage: d,
											})
										}
										tabs={[
											"Submissions",
											"Data",
											"Users",
											"Calendar",
										]}
									/>
									<View
										style={{
											height: 7,
										}}
									/>

									{/* {this.state.subPage === "Staff" ? (
										<Staff
											screenHeight={
												this.state.screenHeight
											}
											screenWidth={this.state.screenWidth}
											logOut={this.logOut.bind(this)}
										/>
									) : null}

									{this.state.subPage === "Live view" ? (
										<LiveView
											screenHeight={
												this.state.screenHeight
											}
											screenWidth={this.state.screenWidth}
											logOut={this.logOut.bind(this)}
										/>
									) : null} */}

									<BABasketReport />
								</View>
							) : (
								<View />
							)}

							{this.state.currentPage ===
							"organisationaccount" ? (
								<OrganisationAcount
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									logOut={this.logOut.bind(this)}
								/>
							) : (
								<View />
							)}
						</View>
					</View>

					{/* {this.techSupportModal()} */}
					<View
						style={{
							width: w,
							height: 45,
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "row",
							opacity: 0.8,
							// backgroundColor: "#FFF",
							borderTopColor: APP_COLOURS.BG2,
							borderTopWidth: 0.5,
							paddingBottom: 15,
							// position: "absolute",
						}}>
						<Text
							style={{
								fontSize: 11,
								color: APP_COLOURS.WHITE,
							}}>
							© {moment().format("YYYY")} TRAQ App. Powered by{" "}
						</Text>
						<Text
							style={{
								fontSize: 12,
								paddingTop: 1,
								color: APP_COLOURS.WHITE,
							}}>
							<a
								style={{
									textDecoration: "none",
									marginTop: 20,
									color: APP_COLOURS.WHITE,
								}}
								target='_blank'
								rel='noreferrer'
								href='https://www.tetrice.co.za'>
								<Font
									weight={800}
									family='Poppins'
									color={APP_COLOURS.WHITE}>
									tetrice
								</Font>
							</a>
						</Text>
					</View>
				</View>
			);
		}
	}

	techSupportModal = () => {
		let sw = this.state.screenWidth;
		let sh = this.state.screenHeight;
		sw = sw > 600 ? 600 : sw - 30;
		sh = sh > 800 ? 800 : sh - 60;
		let align = "right";
		return (
			<>
				{!this.state.viewTechSupport ? (
					<View
						onMouseEnter={() => this.setState({ tshover: true })}
						onMouseLeave={() => this.setState({ tshover: false })}
						style={{
							position: "absolute",
							zIndex: 99,
							bottom: this.state.currentPage === "" ? 18 : 3,
							[align]: 14,
							// alignItems: "flex-end",
							justifyContent: "center",
							// paddingRight: 15,
							// shadowColor: "#000",
							// shadowOffset: { width: 0, height: 8 },
							// shadowOpacity: 0.44,
							// shadowRadius: 9.32,
							// elevation: 3,
							// borderRadius: 40,
							// borderTopRightRadius: 15,
							// backgroundColor: "transparent",
						}}>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									viewTechSupport: true,
								})
							}>
							<Image
								source={techSupportIcon}
								style={{
									width:
										this.state.currentPage === "" ||
										this.state.tshover
											? 90
											: 45,
									height:
										this.state.currentPage === "" ||
										this.state.tshover
											? 90
											: 45,
									resizeMode: "contain",
									// shadowColor: "#000",
									// shadowOffset: { width: 0, height: 8 },
									// shadowOpacity: 0.44,
									// shadowRadius: 9.32,
									// elevation: 3,
								}}
							/>
						</TouchableOpacity>
					</View>
				) : (
					<View
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
							zIndex: 999,
							position: "absolute",
							left: 0,
							top: 0,
						}}>
						<TouchableOpacity
							style={{
								width: this.state.screenWidth,
								height: this.state.screenHeight,
							}}
							onPress={() =>
								this.setState({
									viewTechSupport: false,
								})
							}>
							<View
								style={{
									// flex: 1,
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "rgba(52, 52, 52, 0.5)",
									width: this.state.screenWidth,
									height: this.state.screenHeight,
								}}></View>
						</TouchableOpacity>

						<View
							style={{
								width: sw,
								height: sh,
								position: "absolute",
								bottom: 60,
								[align]: 18,
								alignItems: "center",
								justifyContent: "center",
								position: "absolute",
								// backgroundColor: "#864AE0",
								backgroundColor: APP_COLOURS.BACKGROUND,
								borderRadius: 18,
								shadowColor: "#000",
								shadowOffset: { width: 0, height: 8 },
								shadowOpacity: 0.44,
								shadowRadius: 9.32,
								elevation: 10,
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: -15,
									left: -15,
									marginLeft: "auto",
									marginRight: "auto",
									borderRadius: 20,
									backgroundColor: "#FFF",
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											viewTechSupport: false,
											tshover: false,
										})
									}>
									<Image
										source={close}
										style={{
											width: 30,
											height: 30,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>

							<TechSupport
								screenHeight={sh}
								screenWidth={sw}
								user_data={this.state.user_data}
								logOut={this.logOut.bind(this)}
							/>
						</View>
					</View>
				)}
			</>
		);
	};
}

export const withNavigation = (Component: Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(index);
