import React, { Component } from "react";
import { View, Text } from "react-native";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
			position: "top",
		},
		title: {
			display: false,
			text: "Chart.js Line Chart",
		},
	},
};

const labels = [
	"Jan 22",
	"Feb 22",
	"Mar 22",
	"Apr 22",
	"May 22",
	"Jun 22",
	"Jul 22",
];

export default class LineChart extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Line
				style={{
					backgroundColor: "transparent",
				}}
				redraw={true}
				options={options}
				data={this.dataSet(this.props.data)}
			/>
		);
	}

	dataSet = (d) => {
		const data = {
			labels,
			datasets: [
				{
					fill: true,
					label: "Sales",
					data: d, // [1, 2, 3, 4, 4, 5, 6, 3, 4, 5, 6, 3, 3, 4, 4, 5, 6, 9],
					borderColor: "rgb(53, 162, 235)",
					backgroundColor: "rgba(53, 162, 235, 0.5)",
				},
			],
		};
		return data;
	};
}
