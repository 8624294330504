import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import moment from "moment";
import Font from "react-font";
import tick from "./../../../assets/images/tick.png";

function notSpecialChars(t) {
	return t.replace(/[^\w\s]/gi, "");
}

function numberOnly(num) {
	var cleaned = num.replace(/\D/g, "").replace(" ", "");
	return cleaned;
}

export default class DateOfBirth extends Component {
	constructor(props) {
		super(props);
		this.state = {
			day: "",
			dob: "",
			month: "",
			year: "",
			editable: true,

			monthEditable: false,
			yearEditable: false,
		};
		this._timeout = 500;
	}
	// componentDidMount() {
	// 	if (this.props.setDOB !== "") {
	// 		this.propSet();
	// 	}
	// }
	componentDidUpdate(prevProps) {
		if (prevProps.setDOB !== this.props.setDOB) {
			if (this.props.setDOB == "") {
				this.setState({
					day: "",
					dob: "",
					month: "",
					year: "",
					editable: true,
				});
			} else {
				this.propSet();
			}
		}
	}

	propSet = () => {
		let day = moment(this.props.setDOB, "DD/MM/YYYY").format("DD");
		let month = moment(this.props.setDOB, "DD/MM/YYYY").format("MM");
		let year = moment(this.props.setDOB, "DD/MM/YYYY").format("YYYY");

		// console.log(this.props.setDOB);
		// return;

		let dateStr = `${day}/${month}/${year}`;
		let date_ = moment(dateStr, "DD/MM/YYYY");
		let dob = moment(date_).isValid() ? date_ : "";

		this.setState({
			day: day,
			month: month,
			year: year,
			dob: dob,
			editable: false,
		});
	};
	render() {
		return (
			<View>
				<View
					style={{
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "row",
						borderWidth: this.state.dob !== "" ? 0 : 0.3,
						backgroundColor: "#FFF",
						borderColor: APP_COLOURS.BLACK,
						borderRadius: 15,
						// paddingLeft: 40,
						marginBottom: 5,
						padding: 5,
					}}>
					<TextInput
						ref={(ref) => {
							this.day = ref;
						}}
						editable={this.state.editable}
						style={{
							padding: 10,
							width: 60,
							fontSize: 16,
							color: APP_COLOURS.BLACK,
							fontWeight: this.state.day !== "" ? "500" : "200",
							// fontFamily: "Avenir",
							letterSpacing: 0.26,
							textAlign: "center",
						}}
						placeholderTextColor={"#797979"}
						autoCapitalize='sentences'
						clearButtonMode='while-editing'
						autoCorrect={false}
						maxLength={2}
						keyboardType='number-pad'
						placeholder={"DD"}
						onFocus={() => {
							if (this.state.editable) {
								this.day.clear();
								this.month.clear();
								this.year.clear();
								this.setState({
									day: "",
									dob: "",
									month: "",
									year: "",
								});
							}
						}}
						value={this.state.day}
						onChange={(e) => {
							e.preventDefault();
							let t = e.target.value;
							// VALIDATIONWithout manipulation, number must be zero padded
							t = notSpecialChars(t);
							console.log(t);
							t = numberOnly(t);
							console.log(t);
							if (parseInt(t) > 31 || typeof parseInt(t) !== "number") {
								return;
							} else {
								this.setState({
									day: t,
									dob: "",
									monthEditable: t.length == 2,
								});
								if (t.length == 2) {
									this.month.clear();
									this.month.focus();
								}
							}
						}}
					/>

					<Text>/</Text>

					<TextInput
						ref={(ref) => {
							this.month = ref;
						}}
						style={{
							padding: 5,
							width: 60,
							fontSize: 16,
							color: APP_COLOURS.BLACK,
							fontWeight: this.state.month !== "" ? "500" : "200",
							// fontFamily: "Avenir",
							letterSpacing: 0.26,
							textAlign: "center",
						}}
						editable={this.state.editable && this.state.monthEditable}
						placeholderTextColor={"#797979"}
						autoCapitalize='sentences'
						clearButtonMode='while-editing'
						autoCorrect={false}
						maxLength={2}
						keyboardType='number-pad'
						onFocus={() => {
							if (this.state.editable && this.state.monthEditable) {
								this.month.clear();
								this.year.clear();
								if (this.state.day === "") {
									this.day.focus();
								}
								this.setState({
									month: "",
									year: "",
									dob: "",
								});
							}
						}}
						placeholder={"MM"}
						value={this.state.month}
						onChange={(e) => {
							e.preventDefault();
							let t = e.target.value;
							// VALIDATIONWithout manipulation, number must be zero padded
							t = notSpecialChars(t);
							t = numberOnly(t);
							if (parseInt(t) > 12 || typeof parseInt(t) !== "number") {
								return;
							}
							this.setState({
								month: t,
								dob: "",
								yearEditable: t.length == 2,
							});
							if (t.length == 2) {
								this.year.clear();
								this.year.focus();
							}
						}}
					/>

					<Text>/</Text>

					<TextInput
						ref={(ref) => {
							this.year = ref;
						}}
						editable={this.state.editable && this.state.yearEditable}
						style={{
							padding: 5,
							width: 60,
							fontSize: 16,
							color: APP_COLOURS.BLACK,
							fontWeight: this.state.year !== "" ? "500" : "200",
							letterSpacing: 0.26,
							textAlign: "center",
						}}
						placeholderTextColor={"#797979"}
						autoCapitalize='sentences'
						clearButtonMode='while-editing'
						autoCorrect={false}
						maxLength={4}
						keyboardType='number-pad'
						placeholder={"YYYY"}
						onFocus={() => {
							if (this.state.editable && this.state.yearEditable) {
								this.year.clear();

								if (this.state.month === "") {
									this.month.focus();
								}
								this.setState({
									year: "",
									dob: "",
								});
							}
						}}
						value={this.state.year}
						onChange={(e) => {
							e.preventDefault();
							let t = e.target.value;
							// VALIDATIONWithout manipulation, number must be zero padded
							t = notSpecialChars(t);
							t = numberOnly(t);
							if (
								parseInt(t) > parseInt(moment().format("YYYY")) ||
								typeof parseInt(t) !== "number"
							) {
								return;
							}
							//
							//
							let dob = "";
							let dateStr = `${this.state.day}/${this.state.month}/${t}`;

							let date_ = moment(dateStr, "DD/MM/YYYY");
							// console.log(date_);
							let thisYear = moment().format("YYYY");
							if (parseInt(t) > 0 && parseInt(t) <= parseInt(thisYear)) {
								dob =
									moment(date_).isValid() && t.length === 4
										? date_
										: "";

								this.setState({
									year: t,
									dob: dob,
								});
								clearTimeout(this._timeout);
								this._timeout = setTimeout(() => {
									let dm = moment(date_).format("DD/MM/YYYY");
									this.props.setParentState(dm);
								}, 500);
							}
						}}
					/>

					{/* {this.state.dateValid ? (
                                 <Image
                                    source={tick}
                                    style={{
                                       width: 16,
                                       height: 16,
                                       resizeMode: "contain",
                                       position: "absolute",
                                       left: 15,
                                       top: 17,
                                    }}
                                 />
                              ) : null} */}
				</View>
				<View
					style={{
						flexDirection: "row",
						marginBottom: 5,
						alignItems: "center",
						justifyContent: "center",
						width: "100%",
					}}>
					<Text
						style={{
							color: APP_COLOURS.BLACK,
							fontSize: 12,
						}}>
						<Font weight={500} family='Montserrat'>
							{this.state.dob !== ""
								? `${moment().diff(this.state.dob, "years")} years old`
								: ""}
						</Font>
					</Text>
				</View>
			</View>
		);
	}
}
