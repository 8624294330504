import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
import Fade from "react-reveal/Fade";
import LineChart from "./PerformanceChart/index";
import perc from "../../../../assets/images/knowledgebase.png";
import bracketLeft from "../../../../assets/images/bracketLeft.svg";
import moment from "moment";

function randomRange(n, min, max) {
	var values = [],
		i = max;
	while (i >= min) values.push(i--);
	var results = [];
	var maxIndex = max;
	for (i = 1; i <= n; i++) {
		maxIndex--;
		var index = Math.floor(maxIndex * Math.random());
		results.push(values[index]);
		values[index] = values[maxIndex];
	}
	return results.filter((d) => typeof d !== "undefined");
}

function newRandomNumber(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default class BAManagerRatings extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: "",
			dataHistory: randomRange(12, 50, 100),
			overallHistory: randomRange(12, 50, 100),
			data: [
				{
					title: "Cleanliness",
					percentage: newRandomNumber(0, 100) / 100,
					percentageStr: `${newRandomNumber(40, 100)}%`,
					dataHistory: randomRange(12, 50, 100),
				},
				{
					title: "House keeping",
					percentage: newRandomNumber(0, 100) / 100,
					percentageStr: `${newRandomNumber(30, 100)}%`,
					dataHistory: randomRange(12, 50, 100),
				},
				{
					title: "Proficiency",
					percentage: newRandomNumber(0, 100) / 100,
					percentageStr: `${newRandomNumber(40, 100)}%`,
					dataHistory: randomRange(12, 50, 100),
				},
				// {
				// 	title: "STAFF",
				// 	percentage: newRandomNumber(0, 100) / 100,
				// 	percentageStr: `${newRandomNumber(40, 100)}%`,
				// 	dataHistory: randomRange(12, 50, 100),
				// },
			],
		};
	}

	render() {
		let barWidth = 200;
		let overallScore = 0;
		overallScore = this.state.data.reduce(
			(a, c) => a + parseFloat(c.percentageStr),
			0,
		);
		return (
			<Fade>
				<View
					style={{
						margin: 20,
						// borderBottomColor: APP_COLOURS.TRANSHALO,
						// borderBottomWidth: 0.5,
						paddingBottom: 20,
						marginTop: 40,
					}}>
					<View
						style={{
							flexDirection: "row",
						}}>
						<View
							style={{
								flex: 1,
							}}>
							<View
								onMouseEnter={() =>
									this.setState({
										hover: "",
										dataHistory: this.state.overallHistory,
									})
								}
								style={{
									width: "100%",
									justifyContent: "space-between",
									flexDirection: "row",
									paddingBottom: 10,
									borderBottomColor: APP_COLOURS.TRANSHALO,
									borderBottomWidth: 0.5,
									alignItems: "flex-end",
									marginBottom: 10,
								}}>
								<View
									style={{
										position: "absolute",
										left: -12,
										top: -15,
									}}>
									<Text
										style={{
											fontSize: 13,
											color: APP_COLOURS.WHITE,
											fontWeight: "300",
											fontFamily: "Avenir",
										}}>
										STAFF
										<Text
											style={{
												fontWeight: "900",
											}}>
											TRAQ
										</Text>
									</Text>
								</View>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 25,
										fontWeight: "100",
										color: APP_COLOURS.WHITE,
										marginBottom: 10,
									}}>
									Manager Rating
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<View>
									{this.state.data.map((dd) => {
										return (
											<View
												onMouseEnter={() =>
													this.setState({
														hover: dd.title,
														dataHistory: dd.dataHistory,
													})
												}
												style={{
													flexDirection: "row",
													marginBottom: 10,
													alignItems: "center",
													backgroundColor:
														this.state.hover === dd.title
															? APP_COLOURS.TRANSHALO
															: "transparent",
												}}>
												<View
													style={{
														width: 200,
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 16,
															fontWeight:
																this.state.hover === dd.title
																	? "300"
																	: "200",
															color: APP_COLOURS.WHITE,
														}}>
														{dd.title}
													</Text>
												</View>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 20,
														fontWeight: "700",
														color: APP_COLOURS.WHITE,
														marginLeft: 5,
														opacity:
															this.state.hover === dd.title
																? 1
																: 0.7,
													}}>
													{dd.percentageStr}
												</Text>
											</View>
										);
									})}
								</View>
							</View>
							<View
								style={{
									width: "100%",
									flexDirection: "row",
								}}>
								<View
									style={{
										marginRight: 15,
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 20,
											fontWeight: "100",
											color: APP_COLOURS.WHITE,
											opacity: 0.6,
										}}>
										Overall
									</Text>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 50,
											fontWeight: "100",
											color: APP_COLOURS.WHITE,
										}}>
										{(overallScore / this.state.data.length).toFixed(
											0,
										)}
										<Text
											style={{
												fontSize: 30,
												fontWeight: "100",
											}}>
											{" "}
											%
										</Text>
									</Text>
								</View>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 10,
										fontWeight: "100",
										color: APP_COLOURS.WHITE,
										opacity: 0.8,
										maxWidth: 150,
									}}>
									Manager rating done via manager store audits
								</Text>
							</View>
						</View>

						<View
							style={{
								flex: 1,
							}}>
							<View
								style={{
									width: "100%",
									justifyContent: "space-between",
									flexDirection: "row",
									paddingBottom: 10,
									borderBottomColor: APP_COLOURS.TRANSHALO,
									borderBottomWidth: 0.5,
									alignItems: "flex-end",
									marginBottom: 10,
								}}>
								<View
									style={{
										position: "absolute",
										left: -12,
										top: -15,
									}}>
									<Text
										style={{
											fontSize: 13,
											color: APP_COLOURS.WHITE,
											fontWeight: "300",
											fontFamily: "Avenir",
										}}>
										STAFF
										<Text
											style={{
												fontWeight: "900",
											}}>
											TRAQ
										</Text>
									</Text>
								</View>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 25,
										fontWeight: "100",
										color: APP_COLOURS.WHITE,
										marginBottom: 10,
									}}>
									{this.state.hover !== ""
										? this.state.hover
										: "Manager Rating"}{" "}
									history
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<View
									style={{
										// height: 300,
										width: 450,
									}}>
									<LineChart
										// data={this.state.salesReports.map((d) =>
										// 	parseFloat(d.sales_total),
										// )}
										// data={[5, 8.8, 8.3, 5, 6, 7.8, 5.5, 9, 2, 8]}
										label={`${this.state.hover}`}
										data={this.state.dataHistory}
										average={80}
										max={100}
									/>
								</View>
							</View>
						</View>
					</View>

					<View
						style={{
							alignItems: "flex-end",
							marginTop: 30,
						}}>
						<Text
							style={{
								fontStyle: "italic",
								fontFamily: "Avenir",
								fontSize: 10,
								fontWeight: "100",
								color: APP_COLOURS.WHITE,
							}}>
							Manager Rating report
						</Text>
						<Text
							style={{
								fontStyle: "italic",
								fontFamily: "Avenir",
								fontSize: 10,
								fontWeight: "100",
								color: APP_COLOURS.WHITE,
							}}>
							{moment().format("MMMM, YYYY")}
						</Text>
					</View>
				</View>
			</Fade>
		);
	}
}
