import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	TextInput,
	Image,
	ActivityIndicator,
	Modal,
} from "react-native";
import Font from "react-font";
import { APP_COLOURS } from "../../../APP_VARS";
import { api_getAppProperties } from "../../../Api";

export default class AppPropertiesSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterByName: "",
			selectedApptyle: {
				id: "",
				title: "",
			},
			appstyles: [],
			loading: false,
			showAddItemsForm: false,
			accessToken: "",
			width: 100,
			loading: false,
		};
	}

	selectEntity = (d) => {
		this.props.selectedEntity(d);
		this.setState({
			selectedEntity: d.title,
			selectedEntityID: d.id,
			showAddItemsForm: false,
		});
	};

	async componentDidMount() {
		// await this.getappstyles();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.selected !== this.props.selected &&
			this.props.selected === ""
		) {
			this.setState({
				filterByName: "",
				selectedApptyle: {
					id: "",
					title: "",
				},
			});
		}
	}

	getappstyles = async () => {
		let url = api_getAppProperties;
		let accessToken = await sessionStorage.getItem("accessToken");

		await this.setState({
			loading: true,
			// appstyles: [],
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log("appstyles", responseData);
							await this.setState({
								appstyles: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		return (
			<View
				style={{
					marginBottom: 10,
					// height: this.state.showAddItemsForm ? 100 : 60,
				}}>
				{!this.state.showAddItemsForm ? (
					<View
						onLayout={(e) => {
							// console.log(e.nativeEvent);
							// this.setState({
							// 	left: e.nativeEvent.layout.left,
							// 	top: e.nativeEvent.layout.top,
							// 	width: e.nativeEvent.layout.width,
							// });
						}}
						style={{
							width: "100%",
							padding: 16,
							fontSize: 16,
							borderRadius: 9,
							borderWidth: 0.3,
							borderColor: APP_COLOURS.BG3,
							backgroundColor: APP_COLOURS.BG3,
							paddingLeft: 15,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							// justifyContent: "center",
							zIndex: 10,
							// position: "absolute",
							// top: 0,
						}}>
						<TouchableOpacity
							style={{
								// alignItems: "center",
								// justifyContent: "center",
								flex: 1,
								minWidth: 200,
							}}
							onPress={async (e) => {
								e.preventDefault();

								await this.setState({
									showAddItemsForm: true,
									filterByName: "",
									top: e.nativeEvent.y - e.nativeEvent.layerY - 17,
								});
								// if (this.state.appstyles.length === 0) {
								await this.getappstyles();
								// }
							}}>
							<Text
								style={{
									color:
										this.state.selectedApptyle.title === ""
											? "#797979"
											: APP_COLOURS.WHITE,
									fontWeight: "200",
									fontFamily: "Avenir",
									fontSize: 16,
								}}>
								<Font weight={500} family='Montserrat'>
									{this.state.selectedApptyle.title === ""
										? `Select App style`
										: this.state.selectedApptyle.title}
								</Font>
							</Text>
						</TouchableOpacity>
						<View
							style={
								{
									// padding: 20,
								}
							}>
							{this.state.loading ? (
								<ActivityIndicator color={APP_COLOURS.BLUE} />
							) : (
								<Image
									source={require("./logo512.png")}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							)}
						</View>
					</View>
				) : (
					<View
						style={{
							// width: this.props.screenWidth,
							// height: this.props.screenHeight,
							// backgroundColor: "rgba(0, 0, 0, 0.6)",
							// position: "absolute",
							// top: 0,
							// left: 0,
							// height: 100,
							// width: "100%",
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.25,
							shadowRadius: 7.84,
							elevation: 12,
							borderRadius: 12,
							backgroundColor: APP_COLOURS.BG2,
						}}>
						<View style={{}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
									padding: 16,
									paddingLeft: 15,
									// borderWidth: 0.3,
									// borderColor: APP_COLOURS.BG3,
									borderRadius: 9,
									backgroundColor: APP_COLOURS.BG3,
								}}>
								<TextInput
									ref={(ref) => {
										this.textInput = ref;
									}}
									style={{
										// padding: 8,
										fontSize: 16,
										borderRadius: 9,
										color: APP_COLOURS.WHITE,
										fontWeight: "200",
										fontFamily: "Avenir",
									}}
									autoFocus
									placeholderTextColor={"#797979"}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									editable={true}
									placeholder={"Select App style"}
									value={this.state.filterByName}
									onChangeText={(text) => {
										this.setState({
											filterByName: text,
										});
									}}
								/>
								<TouchableOpacity
									onPress={() => {
										this.setState({
											filterByName: "",
										});
										this.textInput.focus();
									}}>
									<Image
										source={require("./close.png")}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										this.setState((prevState) => ({
											showAddItemsForm: false,
										}));
									}}>
									{this.state.loading ? (
										<ActivityIndicator color={APP_COLOURS.BLUE} />
									) : (
										<Image
											source={require("./logo512.png")}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
											}}
										/>
									)}
								</TouchableOpacity>
							</View>
							<View style={{ height: 200 }}>
								<ScrollView>
									<View
										style={{
											height: 10,
										}}
									/>
									{this.state.appstyles
										.filter((d_) => {
											let filter = d_;
											if (
												typeof this.state.filterByName !==
													"undefined" &&
												this.state.filterByName !== ""
											) {
												let text_ = this.state.filterByName;
												var outString = text_.replace(
													/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
													"",
												);

												let join_names = `${d_.title}`;

												return join_names
													.toLowerCase()
													.includes(outString.toLowerCase());
											}

											return filter;
										})
										.sort((a, b) => b.title < a.title)
										.map((d, i) => (
											<TouchableOpacity
												key={i}
												onPress={() => {
													this.setState(() => ({
														selectedApptyle: d,
														showAddItemsForm: false,
														filterByName: d.title,
													}));
													d.app_properties.title = d.title;
													this.props.selectedData(d);
												}}>
												<View
													style={{
														flexDirection: "row",
														padding: 8,
														borderTopColor: "#E8E8E8",
														bordertopWidth: 0.5,
													}}>
													<View
														style={{
															flex: 1,
															borderBottomColor: "#797979",
															marginRight: 5,
															paddingLeft: 10,
														}}>
														<Text
															style={{
																color: APP_COLOURS.WHITE,
																fontSize: 15,
															}}>
															{d.title}
														</Text>
													</View>
													<View
														style={{
															width: 25,
															height: 25,
															borderRadius: 10,
															borderWidth: 1,
															borderColor: "#FFF",
															marginRight: 3,
															backgroundColor:
																d.app_properties.brandcolour,
														}}
													/>
												</View>
											</TouchableOpacity>
										))}
								</ScrollView>
							</View>
						</View>
					</View>
				)}
			</View>
		);
	}
}
