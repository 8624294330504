import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
import Fade from "react-reveal/Fade";
// import staff_traq from "../../../../assets/images/staff_traq.svg";
import QRCode from "react-qr-code";

export default class BAProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let d = this.props.data;
		return (
			<Fade>
				<View
					style={{
						width: "100%",
						flex: 1,
						padding: 20,
					}}>
					<View
						style={{
							marginBottom: 20,
						}}>
						<Text
							style={{
								fontSize: 25,
								color: APP_COLOURS.WHITE,
								fontWeight: "300",
								fontFamily: "Avenir",
							}}>
							STAFF
							<Text
								style={{
									fontWeight: "900",
								}}>
								TRAQ
							</Text>
						</Text>
					</View>
					<View
						style={{
							flexDirection: "row",
							padding: 5,
							flexWrap: "wrap",
						}}>
						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								marginRight: 20,
							}}>
							<View
								style={{
									backgroundColor: "#FFF",
									borderRadius: 10,
									padding: 6,
								}}>
								<QRCode
									size={80}
									value={`https://traq.app/${d.id}`}
									bgColor={"#FFF"}
									fgColor={APP_COLOURS.BG2}
								/>
							</View>
						</View>
						<View
							style={{
								paddingRight: 40,
								marginRight: 40,
								minWidth: 150,
							}}>
							<Text
								style={{
									fontSize: 14,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "100",
									opacity: 0.7,
								}}>
								Full name
							</Text>
							<Text
								style={{
									fontSize: 17,
									color: APP_COLOURS.WHITE,
									fontWeight: "600",
								}}>
								{d.first_name} {d.last_name}
							</Text>

							<Text
								style={{
									fontSize: 14,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "100",
									marginTop: 10,
									opacity: 0.7,
								}}>
								Staff-ID
							</Text>
							<Text
								style={{
									fontSize: 12,
									color: APP_COLOURS.TEXTCOLOR,
								}}>
								{d.id}
							</Text>
						</View>
					</View>
					<View
						style={{
							padding: 10,
							borderLeftWidth: 0.3,
							borderLeftColor: APP_COLOURS.BG5,
							flexDirection: "row",
						}}>
						<View
							style={{
								borderRightColor: APP_COLOURS.BG5,
								borderRightWidth: 0.3,
								paddingRight: 40,
								marginRight: 40,
								minWidth: 150,
							}}>
							<Text
								style={{
									fontSize: 12,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "100",
									opacity: 0.7,
								}}>
								Retailer
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: APP_COLOURS.WHITE,
									fontWeight: "600",
								}}>
								{typeof d.retailer.title !== "undefined"
									? d.retailer.title
									: ""}
							</Text>
							<Text
								style={{
									fontSize: 12,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "100",
									marginTop: 10,
									opacity: 0.7,
								}}>
								Mall
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: APP_COLOURS.TEXTCOLOR,
								}}>
								{typeof d.mall.title !== "undefined"
									? d.mall.title
									: ""}
							</Text>
						</View>
						<View
							style={{
								borderRightColor: APP_COLOURS.BG5,
								borderRightWidth: 0.3,
								paddingRight: 40,
								marginRight: 40,
								minWidth: 150,
							}}>
							<Text
								style={{
									fontSize: 12,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "100",
									opacity: 0.7,
								}}>
								Position
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: APP_COLOURS.WHITE,
									fontWeight: "600",
								}}>
								{d.position}
							</Text>

							<Text
								style={{
									fontSize: 12,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "100",
									marginTop: 10,
									opacity: 0.7,
								}}>
								Permission
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: APP_COLOURS.TEXTCOLOR,
								}}>
								{d.permission}
							</Text>
						</View>
						<View
							style={{
								borderRightColor: APP_COLOURS.BG5,
								borderRightWidth: 0.3,
								paddingRight: 40,
								marginRight: 40,
								minWidth: 150,
							}}>
							<Text
								style={{
									fontSize: 12,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "100",
									opacity: 0.7,
								}}>
								Brands
							</Text>

							{d.brands.map((m) => (
								<Text
									style={{
										fontSize: 13,
										color: APP_COLOURS.WHITE,
										fontWeight: "600",
									}}>
									- {m.title}
								</Text>
							))}

							<Text
								style={{
									fontSize: 12,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "100",
									marginTop: 10,
									opacity: 0.7,
								}}>
								Manager
							</Text>
							<Text
								style={{
									fontSize: 14,
									color: APP_COLOURS.TEXTCOLOR,
								}}>
								{d.manager}
							</Text>
						</View>
					</View>
				</View>
			</Fade>
		);
	}
}
