import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import { APP_COLOURS } from "../../APP_VARS";
import WalkThrough from "./WalkThrough";
// import Screenshot from "./ScreenShotReport";
import { api_getAllSurveys } from "../../Api";

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: "",
			currentComponent: "",
			selectedSurvey: "",
		};
	}

	componentDidMount() {
		// this.getSurveys();
	}

	// getSurveys = async () => {
	// 	let url = api_getAllSurveys;

	// 	await this.setState({
	// 		loading: true,
	// 		devices: [],
	// 		showDelete: false,
	// 		addNewDevice: false,
	// 		viewDeviceProfile: false,
	// 	});

	// 	let requestOptions = {
	// 		method: "GET",
	// 		headers: {
	// 			Accept: "application/json",
	// 			"Content-Type": "application/json",
	// 			Origin: "",
	// 			dataType: "json",
	// 			Authorization: `Bearer ${this.props.accessToken}`,
	// 		},
	// 		redirect: "follow",
	// 	};

	// 	await fetch(url, requestOptions)
	// 		.then((response) => {
	// 			// console.log('response', response);
	// 			if (response.status === 200) {
	// 				response
	// 					.json()
	// 					.then(async (responseData) => {
	// 						console.log("response", responseData);
	// 						this.setState({
	// 							surveys: responseData,
	// 							loading: false,
	// 						});
	// 					})
	// 					.catch((error) => {
	// 						alert(error);
	// 						this.setState({
	// 							loading: false,
	// 						});
	// 					});
	// 			} else if (response.status == 401) {
	// 				this.props.logOut();
	// 			} else {
	// 				this.setState({ loading: false });
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error);
	// 			this.setState({
	// 				loading: false,
	// 			});
	// 		});
	// };

	render() {
		return (
			<View
				style={{
					flex: 1,
					flexDirection: "row",
					height: this.props.screenHeight,
				}}>
				{/* {this.sideBar()}
				 */}
				<View
					style={{
						width: 250,
						padding: 10,
						marginRight: 10,
						borderRadius: 15,
						backgroundColor: APP_COLOURS.BG,
					}}>
					<View
						style={{
							width: "100%",
							// width: 30,
							// height: 30,
							alignItems: "center",
							justifyContent: "center",
							marginBottom: 50,
						}}>
						<Image
							source={require("../../../assets/images/logo.png")}
							style={{
								width: 100,
								height: 35,
								resizeMode: "contain",
							}}
						/>
					</View>
					<ScrollView>
						<View
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: "auto",
									padding: 7,
									alignItems: "center",
									justifyContent: "center",
									borderRadius: 5,
									minHeight: 45,
									marginBottom: 5,
									flexDirection: "row",
									borderColor: "#C5C5C5",
									// backgroundColor: "rgba(255, 255, 255, 0.01)",
								}}
								onPress={() =>
									this.setState({
										currentPage: "WalkThrough",
										currentComponent: "WalkThrough",
										selectedSurvey: "",
									})
								}>
								<View
									style={{
										flex: 1,
										padding: 3,
										// display: this.state.hover ? "flex" : "none",
										marginLeft: 9,
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 10,
											color: "#FFF",
											fontWeight: "300",
										}}>
										User guide
									</Text>
								</View>
							</TouchableOpacity>
						</View>
						<View
							style={{
								alignItems: "center",
								justifyContent: "center",
								width: "100%",
							}}>
							<TouchableOpacity
								style={{
									width: "100%",
									height: "auto",
									padding: 7,
									alignItems: "center",
									justifyContent: "center",
									borderRadius: 5,
									minHeight: 45,
									marginBottom: 5,
									flexDirection: "row",
									borderWidth: 0.3,
									borderColor: "#C5C5C5",
									backgroundColor: "rgba(255, 255, 255, 0.1)",
								}}
								onPress={() => this.setState({})}>
								<View
									style={{
										flex: 1,
										padding: 3,
										// display: this.state.hover ? "flex" : "none",
										marginLeft: 9,
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 14,
											color: "#FFF",
											fontWeight: "400",
										}}>
										User management
									</Text>

									<View
										style={{
											flexDirection: "row",
											justifyContent: "space-between",
											marginTop: 5,
										}}>
										<View
											style={{
												flexDirection: "row",
												// position: "absolute",
												// top: 3,
												// left: 3,
												marginTop: 5,
											}}>
											<View
												style={{
													width: 10,
													height: 10,
													backgroundColor: "#2EE39C",
													borderRadius: 5,
													marginRight: 5,
												}}
											/>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 9,
													color: "#FFF",
													fontWeight: "400",
												}}>
												Active
											</Text>
										</View>
										<View
											style={{
												flexDirection: "row",
												marginTop: 5,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 9,
													color: "#FFF",
													fontWeight: "400",
												}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 9,
														color: "#FFF",
														fontWeight: "600",
													}}>
													user management
												</Text>
											</Text>
										</View>
									</View>
								</View>
							</TouchableOpacity>
						</View>
					</ScrollView>
				</View>
				<View
					style={{
						flex: 1,
						alignItems: "center",
					}}>
					<View
						style={{
							width: "100%",
							height: this.props.screenHeight,
							// maxWidth: 1000,
						}}>
						{this.state.currentComponent === "WalkThrough" ? (
							<WalkThrough {...this.props} />
						) : null}
						{/* {this.state.currentComponent === "Con" ? (
							<Con
								{...this.props}
								selectedSurvey={this.state.selectedSurvey}
							/>
						) : null} */}
					</View>
				</View>
			</View>
		);
	}

	// sideBar = () => {
	// 	let surveys = this.state.surveys;
	// 	return (

	// 	);
	// };
}
