import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
// import moment from "moment";
// import Fade from "react-reveal/Fade";
import Font from "react-font";
import filterAz from "../../../assets/images/filterAz.png";
import filterNone from "../../../assets/images/filterNone.png";
import filterZa from "../../../assets/images/filterZa.png";
import bin from "./../../../assets/images/bin.png";
import close from "../../../assets/images/close.png";
import plus from "../../../assets/images/plus.svg";
import search from "../../../assets/images/search.png";

import { api_getProduct, api_deleteProduct, api_addProduct } from "../../Api";
import moment from "moment";
import Add from "./Add";

function currencyFormat(num) {
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default class ProductCrud extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -3,
			showDelete: false,
			userGroups: [],

			addNewProduct: false,
			products: [],
			filterByName: "",
			sortUsers: "",
		};
	}

	addNewProduct = () => {
		return (
			<Add
				addProduct={(d) => this.addProduct(d)}
				selectedBrand={this.props.selectedBrand}
				close={() =>
					this.setState({
						addNewProduct: false,
					})
				}
			/>
		);
	};

	addProduct = async (d) => {
		this.setState({
			addingProduct: true,
			errorLogin: false,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify(d),
		};

		await fetch(api_addProduct, requestOptions)
			.then(async (response) => {
				console.log("response =====", response);
				if (response.status === 200) {
					alert("Product added");
					this.getProducts();
				} else {
					alert("error adding device");
					this.setState({
						addingProduct: false,
					});
				}
			})
			.catch((error) => {
				this.setState({
					addingProduct: false,
				});
			});
	};

	getProducts = async () => {
		let url = api_getProduct;

		await this.setState({
			loading: true,
			products: [],
			showDelete: false,
			addNewProduct: false,
			viewProductProfile: false,
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								products: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	deleteProduct = async (id) => {
		let url = api_deleteProduct;

		await this.setState({
			loading: true,
			products: [],
		});

		let requestOptions = {
			method: "DELETE",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify({
				id: id,
			}),
		};

		await fetch(url, requestOptions)
			.then((response) => {
				if (response.status === 200) {
					this.getProducts();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert("Error deleting");
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		let col1 = 200,
			col2 = 100,
			col3 = 50;

		let fs = 12;

		//

		let products = this.state.products
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.title} ${d_.barcode}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})
			.filter((d_) => {
				if (this.props.selectedBrand.id !== "") {
					return d_.brand.id == this.props.selectedBrand.id;
				} else {
					return d_;
				}
			})
			.sort((a, b) => {
				if (this.state.sortUsers === "AtoZ") {
					return b.title > a.title;
				} else {
					return b.title < a.title;
				}
			});

		// .sort((a, b) => b.last_name < a.last_name);

		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",
					flex: 1,
					padding: 8,
					// width: "100%",
					height: this.props.screenHeight,
				}}>
				<View
					style={{
						flex: 1,
						// flexDirection: 'row',
						marginTop: 16,
					}}>
					<View
						style={{
							// width: 220,
							// paddingLeft: 0,
							flex: 1,
							paddingRight: 8,
							flexDirection: "row",
							// alignItems: "center",
						}}>
						<View
							style={{
								flexDirection: "row",
								flex: 1,
								alignItems: "center",
								// justifyContent: "flex-end",
								paddingTop: 5,
								marginBottom: 5,
								height: 50,
							}}>
							<Text
								numberOfLines={2}
								style={{
									flex: 1,
									fontFamily: "Avenir",
									fontSize: 15,
									fontWeight: "800",
									flex: 1,
									flexWrap: "wrap",
									color: APP_COLOURS.WHITE,
									opacity: 0.7,
									marginRight: 10,
								}}>
								Products:
								<Text
									style={{
										fontWeight: "900",
									}}>
									{" "}
									{this.props.selectedBrand.title}
								</Text>
							</Text>

							<TouchableOpacity
								style={{
									// width: 130,
									height: "auto",
									alignItems: "center",

									padding: 7,
									borderRadius: 6,
									marginRight: 10,
									height: "auto",
									backgroundColor: APP_COLOURS.BLUE,
									flexDirection: "row",
								}}
								onPress={() => {
									this.setState({
										addNewProduct: true,
									});
								}}>
								<Image
									source={plus}
									style={{
										width: 11,
										height: 11,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
							<View
								style={{
									width: "auto",
									height: 35,
									alignItems: "center",
									borderRadius: 5,
									padding: 10,
									marginRight: 10,
									// backgroundColor: APP_COLOURS.BLUE,
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "flex-end",
								}}>
								<Text
									numberOfLines={1}
									style={{
										fontFamily: "Avenir",
										fontSize: 15,
										fontWeight: "400",
										flexWrap: "wrap",
										color: APP_COLOURS.WHITE,
										opacity: 0.7,
										marginRight: 10,
									}}>
									products ({products.length})
								</Text>
							</View>
							<TouchableOpacity
								onPressIn={() =>
									this.setState({
										showSearch: !this.state.showSearch,
										filterByName: "",
									})
								}>
								<Image
									source={search}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
										marginRight: 5,
									}}
								/>
							</TouchableOpacity>
							{this.state.showSearch ? (
								<TextInput
									ref={(ref) => {
										this.filterByName = ref;
									}}
									style={{
										width: 200,
										padding: 8,
										fontSize: 14,
										borderRadius: 20,

										color: "#FFF",
										fontWeight:
											this.state.filterByName === "" ? "200" : "500",
										fontFamily: "Avenir",

										backgroundColor: "rgba(255, 255, 255, 0.05)",
										// borderColor: APP_COLOURS.WHITE_TRANSPARENT3,
										// borderWidth: 0.3,
										marginRight: 5,
										paddingLeft: 15,
									}}
									placeholderTextColor={APP_COLOURS.WHITE_TRANSPARENT3}
									autoCapitalize='words'
									autoFocus={true}
									clearButtonMode='while-editing'
									editable={true}
									placeholder={` Type something`}
									onChangeText={(text) => {
										this.setState({
											filterByName: text,
										});
									}}
								/>
							) : null}

							<TouchableOpacity
								onPress={() =>
									this.setState({
										showDelete: !this.state.showDelete,
									})
								}
								style={{
									flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "center",
									display: "none",
									borderRadius: 10,
									margin: 5,
									marginRight: 15,
								}}>
								<View style={{}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 15,
											fontWeight: "400",
											color: APP_COLOURS.BLUE,
										}}>
										Edit
									</Text>
								</View>
							</TouchableOpacity>
							<View
								style={{
									width: 120,
									flexDirection: "row",
									justifyContent: "flex-end",
									marginLeft: 15,
								}}>
								<TouchableOpacity
									onPressIn={() =>
										this.setState({
											sortUsers: "AtoZ",
										})
									}>
									<Image
										source={filterZa}
										style={{
											width: 28,
											height: 20,
											resizeMode: "contain",
											marginRight: 15,
										}}
									/>
								</TouchableOpacity>

								<TouchableOpacity
									onPressIn={() =>
										this.setState({
											sortUsers: "ZtoA",
										})
									}>
									<Image
										source={filterAz}
										style={{
											width: 28,
											height: 20,
											resizeMode: "contain",
											marginRight: 15,
										}}
									/>
								</TouchableOpacity>

								<TouchableOpacity
									onPressIn={() =>
										this.setState({
											sortUsers: "",
										})
									}>
									<Image
										source={filterNone}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
						</View>
					</View>
					<View
						style={{
							// flex: 1,
							paddingRight: 8,
							borderTopColor: "#797979",
							borderTopWidth: 0.3,
						}}>
						<View
							style={{
								// flex: 1,
								height: this.props.screenHeight - 400,
								// padding: 8,
								paddingTop: 8,
							}}>
							<ScrollView>
								{products.map((d, i) => (
									<View
										key={i}
										onMouseEnter={() =>
											this.setState({
												hover: i,
											})
										}
										onMouseLeave={() =>
											this.setState({
												hover: -1,
											})
										}
										style={{
											// flex: 1,
											borderRadius: 5,
											flexDirection: "row",
											borderColor: "#C5C5C5",
											borderWidth:
												this.state.showDelete === d.id ? 1 : 0,
											backgroundColor: "rgba(255, 255, 255, 0.05)",
											marginBottom: 5,
											padding: 10,
											width: "100%",
										}}>
										<View
											style={{
												borderWidth: 2,
												borderColor: "#FFF",
												borderRadius: 10,
												padding: 10,
												backgroundColor: "#FFF",
												marginRight: 15,
											}}>
											<Image
												source={`data:image/png;base64,${d.base64String}`}
												style={{
													height: 30,
													width: 50,
													resizeMode: "contain",
												}}
											/>
										</View>
										<View
											style={{
												flex: 1,
												opacity: this.state.hover === i ? 1 : 0.9,

												height: "auto",
												padding: 3,
												alignItems: "center",

												marginRight: 10,
												height: "auto",
												marginBottom: 2,
												flexDirection: "row",
											}}>
											<View
												style={{
													width: 300,
													marginRight: 10,
												}}>
												<Text
													style={{
														fontWeight: "600",
														fontSize: 14,
														color: APP_COLOURS.WHITE,
													}}>
													{d.title}
												</Text>
												<Text
													style={{
														fontWeight: "300",
														fontSize: 11,
														color: APP_COLOURS.WHITE,
													}}>
													{d.barcode}
												</Text>
											</View>
											<View style={{}}>
												<Text
													style={{
														fontSize: 9,
														color: APP_COLOURS.WHITE,
														opacity: 0.7,
													}}>
													created:
												</Text>
												<Text
													style={{
														fontSize: 12,
														color: APP_COLOURS.WHITE,
													}}>
													{moment(d.date_created).format(
														"MMM DD, YYYY",
													)}
												</Text>
											</View>
											<View
												style={{
													flex: 1,
												}}
											/>
											<Text
												style={{
													width: 250,
													fontWeight: "600",
													fontSize: 15,
													color: APP_COLOURS.WHITE,
													marginRight: 10,
												}}>
												{currencyFormat(parseFloat(d.price))}
											</Text>
										</View>

										<TouchableOpacity
											disable={this.state.hover !== i}
											onPress={() => this.deleteProduct(d.id)}
											style={{
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Image
												source={bin}
												style={{
													width: 22,
													height: 22,
													resizeMode: "contain",
													marginRight: 5,
													opacity: this.state.hover === i ? 1 : 0,
												}}
											/>
										</TouchableOpacity>
									</View>
								))}
								{this.state.loading ? (
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
											minHeight: 50,
										}}>
										<ActivityIndicator color={APP_COLOURS.BLUE} />
									</View>
								) : null}
							</ScrollView>
						</View>
					</View>
				</View>
				{this.state.addNewProduct ? this.addNewProduct() : <View />}
				{this.state.viewProductProfile ? (
					this.productProfileModal(this.state.profileData)
				) : (
					<View />
				)}
			</div>
		);
	}

	async componentDidMount() {
		await this.validateSession();
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let accessToken = await sessionStorage.getItem("accessToken");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		console.log(accessToken);

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			accessToken,
			refresh_token,
		});

		if (
			accessToken !== null &&
			accessToken !== "" &&
			typeof accessToken !== "undefined"
		) {
			this.getProducts();
		} else {
			this.props.history.push("/");
			sessionStorage.clear();
		}
	};

	componentWillUnmount() {}
}
