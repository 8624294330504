import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
import Fade from "react-reveal/Fade";
import rightArrow from "../../../../assets/images/rightArrow.svg";

export default class BADocuments extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Fade>
				<View
					style={{
						margin: 20,
						borderBottomColor: APP_COLOURS.TRANSHALO,
						borderBottomWidth: 0.5,
						paddingBottom: 20,
					}}>
					<Text
						style={{
							fontStyle: "Avenir",
							fontSize: 18,
							fontWeight: "500",
							color: APP_COLOURS.WHITE,
							margin: 20,
						}}>
						Staff Documents
					</Text>
					<View
						style={{
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							padding: 20,
							maxWidth: 300,
						}}>
						<Text
							style={{
								fontStyle: "Avenir",
								fontSize: 14,
								fontWeight: "400",
								color: APP_COLOURS.WHITE,
							}}>
							Documents
						</Text>
						<Image
							source={rightArrow}
							style={{
								width: 20,
								height: 20,
								resizeMode: "contain",
							}}
						/>
					</View>
				</View>
			</Fade>
		);
	}
}
