import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
// import moment from "moment";
// import Fade from "react-reveal/Fade";
import Font from "react-font";
//
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import QRCode from "react-qr-code";
//
// import memberBadge from "../../../assets/images/memberBadge.svg";
import logo from "../../../../assets/images/whiteLogo.png";
import tick from "../../../../assets/images/tick.png";
import bin from "../../../../assets/images/bin.png";
import close from "../../../../assets/images/close.png";
import apple from "../../../../assets/images/apple.png";
import android from "../../../../assets/images/android.png";
import qrCodeImage from "../../../../assets/images/qrCode.png";
import survey from "../traq_survey.png";

import {
	api_getSurveyDevices,
	api_deleteSurveyDevices,
	api_newSurveyDevice,
} from "../../../Api";
import moment from "moment";
import NewDevice from "./addDevice";
import Results from "./results";
import OverviewReport from "./OverviewReport";
import InputArea from "./InputArea";

export default class table extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -3,
			formFields: [
				// {
				// 	id: 1,
				// 	editable: false,
				// 	state: "deviceUniqueID",
				// 	title: "Unique ID",
				// 	autoCapitalize: false,
				// 	validation: "name",
				// },
				// {
				// 	id: 2,
				// 	editable: true,
				// 	state: "username",
				// 	title: "Username",
				// 	autoCapitalize: false,
				// 	validation: "email",
				// },
				{
					id: 2,
					editable: true,
					state: "mall",
					title: "Mall",
					autoCapitalize: true,
					validation: "name",
				},
				{
					id: 3,
					editable: true,
					state: "retailer",
					title: "Retailer",
					autoCapitalize: true,
					validation: "name",
				},
				{
					id: 4,
					editable: true,
					state: "passcode",
					title: "Passcode",
					autoCapitalize: true,
					validation: "none",
				},
			],

			addNewDevice: false,
			devices: [
				// {
				// 	id: "0eab6853-275b-4e3c-ab55-d080a4e0c1e7",
				// 	username: "monique@TRAQair.com",
				// 	first_name: "Monique",
				// 	last_name: "Bethwaite",
				// 	platform: "female",
				// 	dob: "16/04/1989",
				// 	email: {
				// 		address: "wesleycheia@gmail.com",
				// 		verified: true,
				// 	},
				// 	cell: {
				// 		number: "081 250 2054",
				// 		verified: true,
				// 	},
				// Staffhip: {
				// 	type: "Club Staffhip",
				// 	duration: "24 month",
				// 	endDate: "23/12/2024",
				// },
				// },
			],
			filterByName: "",
			showenlargedQRCodeModal: false,

			url: this.props.selectedSurvey.url,

			tab: "Overview",
			tabs: [
				{
					title: "Overview",
				},
				{
					title: "Devices",
				},
				{
					title: "Data",
				},
			],
			deviceID: "",
		};
	}

	snapShot = () => {
		let title = `TRAQ_App_${this.props.selectedSurvey.title}_${this.state.deviceID}.png`;
		html2canvas(document.getElementById("capture")).then((canvas) => {
			canvas.toBlob(function (blob) {
				saveAs(blob, title);
			});
		});
	};

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	async componentDidMount() {
		this.getDevices();
	}

	componentWillUnmount() {}

	getDevices = async () => {
		let url = api_getSurveyDevices;

		await this.setState({
			loading: true,
			devices: [],
			showDelete: false,
			addNewDevice: false,
			viewDeviceProfile: false,
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								devices: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	deleteDevice = async (id) => {
		let url = api_deleteSurveyDevices;

		await this.setState({
			loading: true,
			devices: [],
		});

		let requestOptions = {
			method: "DELETE",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify({
				id: id,
			}),
		};

		await fetch(url, requestOptions)
			.then((response) => {
				if (response.status === 200) {
					this.getDevices();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert("Error deleting");
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	addNewDevice = () => {
		return (
			<NewDevice
				addDevice={(d) => this.addDevice(d)}
				close={() =>
					this.setState({
						addNewDevice: false,
					})
				}
			/>
		);
	};

	addDevice = async (d) => {
		this.setState({
			addingDevice: true,
			errorLogin: false,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify({
				...d,
				surveyID: this.props.selectedSurvey.id,
			}),
		};

		await fetch(api_newSurveyDevice, requestOptions)
			.then(async (response) => {
				console.log("response =====", response);
				if (response.status === 200) {
					alert("Device added");
					this.getDevices();
				} else {
					alert("error adding device");
					this.setState({
						addingDevice: false,
					});
				}
			})
			.catch((error) => {
				this.setState({
					addingDevice: false,
				});
			});
	};

	render() {
		let col1 = 200,
			col2 = 100,
			col3 = 50;

		let fs = 12;

		let style = {
			backgroundColor: "#FFF",

			borderRadius: 11,
			padding: 8,
			marginRight: 5,
			marginBottom: 5,
			shadowColor: "#000",
			shadowOffset: { width: 0, height: 8 },
			shadowOpacity: 0.44,
			shadowRadius: 9.32,
			elevation: 5,
			opacity: 0.9,
		};

		let devices = this.state.devices;
		let totalDevices = devices.length;
		let activeDevices = devices.filter((d) => d.active).length;

		let devicesOnline = devices.reduce(
			(a, c) => a + (moment().diff(c.last_seen, "hours") < 1),
			0,
		);
		devices = devices
			.filter((d_) => {
				let filter = d_;
				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.mall.title} ${d_.retailer.title} ${d_.uuid}`;
					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})
			.sort((a, b) => b.uuid < a.uuid);

		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",
					flex: 1,
					padding: 8,
					// width: "100%",
					height: this.props.screenHeight,
				}}>
				<View
					style={{
						flexDirection: "row",
					}}>
					<TouchableOpacity
						onPress={() =>
							this.setState({
								showenlargedQRCodeModal: true,
							})
						}>
						<View
							style={{
								backgroundColor: "#FFF",
								borderRadius: 8,
								height: 80,
								width: 80,
								padding: 5,
								marginRight: 10,
							}}>
							<QRCode
								size={70}
								value={this.state.url}
								bgColor={"#FFF"}
								fgColor={APP_COLOURS.BG2}
							/>
							{/* <Image
								source={qrCodeImage}
								style={{
									width: 70,
									height: 70,
									resizeMode: "contain",
								}}
							/> */}
						</View>
					</TouchableOpacity>
					<View
						style={{
							flex: 1,
						}}>
						<View
							style={{
								width: "100%",
								justifyContent: "space-between",
								flexDirection: "row",
							}}>
							<View
								style={{
									flexDirection: "row",
									marginTop: 5,
								}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 18,
										color: APP_COLOURS.TEXTCOLOR,
										fontWeight: "700",
									}}>
									{this.props.selectedSurvey.title}
								</Text>
							</View>

							<View
								style={{
									flexDirection: "row",
									marginTop: 5,
								}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 9,
										color: "#FFF",
										fontWeight: "400",
										backgroundColor: APP_COLOURS.RED,
										padding: 4,
										borderRadius: 5,
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											color: "#FFF",
											fontWeight: "600",
										}}>
										{this.props.selectedSurvey.surveyType} survey
									</Text>
								</Text>
							</View>
						</View>
						<TouchableOpacity
							onPress={() => {
								window.open(this.state.url, "_blank");
							}}>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 14,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "400",
								}}>
								{this.state.url}
							</Text>
						</TouchableOpacity>
						<View
							style={{
								flexDirection: "row",
								justifyContent: "space-between",
								marginTop: 5,
							}}>
							<View
								style={{
									flexDirection: "row",
									// position: "absolute",
									// top: 3,
									// left: 3,
									marginTop: 5,
								}}>
								<View
									style={{
										width: 10,
										height: 10,
										backgroundColor: this.props.selectedSurvey.status
											? "#2EE39C"
											: "#FF5F5F",
										borderRadius: 5,
										marginRight: 5,
									}}
								/>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 9,
										color: "#FFF",
										fontWeight: "400",
									}}>
									{this.props.selectedSurvey.status
										? `Active`
										: `Inactive`}
								</Text>
							</View>

							<View
								style={{
									flexDirection: "row",
									// position: "absolute",
									// top: 3,
									// left: 3,
									marginTop: 5,
								}}>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 9,
										color: "#FFF",
										fontWeight: "400",
									}}>
									ID: {this.props.selectedSurvey.id}
								</Text>
							</View>
						</View>
					</View>
				</View>

				<View
					style={{
						flex: 1,
						borderTopWidth: 0.3,
						borderTopColor: "#C5C5C5",
						marginTop: 5,
					}}>
					<View
						style={{
							// flex: 1,
							marginTop: 8,
							marginBottom: 8,
						}}>
						<ScrollView horizontal>
							<View
								style={{
									flexDirection: "row",
								}}>
								{this.state.tabs.map((d, i) => (
									<TouchableOpacity
										key={i}
										style={{
											width: 150,
											height: "auto",
											padding: 7,
											alignItems: "center",
											borderRadius: 8,
											marginRight: 10,
											height: "auto",
											marginBottom: 1,
											backgroundColor:
												this.state.tab == d.title
													? APP_COLOURS.BLUE
													: APP_COLOURS.BG3,
											flexDirection: "row",
											// borderWidth:
											// 	this.state.tab == d.title ? 0.3 : 0,
											// borderColor: "#C5C5C5",
										}}
										onPress={() => {
											this.setState({
												tab: d.title,
											});
										}}>
										{/* <View
											style={{
												width: 5,
												borderRadius: 4,
												height: "100%",
												backgroundColor:
													this.state.tab == d.title
														? APP_COLOURS.BLUE
														: APP_COLOURS.WHITE,
												marginRight: 12,
											}}
										/> */}
										<View
											style={{
												flex: 1,
												flexWrap: "wrap",
												padding: 4,
												borderRadius: 10,
												// justifyContent: 'center',
												// alignItems: 'center',
											}}>
											<Text
												numberOfLines={2}
												style={{
													fontFamily: "Avenir",
													fontSize: 16 + this.state.fontSize,
													// opacity: 0.8,
													flex: 1,
													flexWrap: "wrap",
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												{d.title}
											</Text>
										</View>
									</TouchableOpacity>
								))}
							</View>
						</ScrollView>
					</View>

					{this.state.tab === "Overview" ? (
						<View
							style={{
								// flex: 1,
								paddingRight: 8,
								borderTopColor: "#797979",
								borderTopWidth: 0.3,
							}}>
							<OverviewReport {...this.state} {...this.props} />
						</View>
					) : null}

					{this.state.tab === "Devices" ? (
						<View
							style={{
								// flex: 1,
								paddingRight: 8,
								borderTopColor: "#797979",
								borderTopWidth: 0.3,
							}}>
							<View
								style={{
									// width: 220,
									// paddingLeft: 0,
									flex: 1,
									paddingRight: 8,
									flexDirection: "row",
									// alignItems: "center",
								}}>
								<View
									style={{
										flexDirection: "row",
										flex: 1,
										alignItems: "center",
										// justifyContent: "flex-end",
										paddingTop: 5,
										marginBottom: 5,
									}}>
									<View
										style={{
											flex: 1,
											flexDirection: "row",
										}}>
										<View
											style={{
												flexDirection: "row",
											}}>
											<View
												style={[
													{
														maxHeight: 90,
													},
													style,
												]}>
												<Text
													style={{
														opacity: 0.8,
														fontSize: 9,
														color: APP_COLOURS.BG4,
													}}>
													Active devices
												</Text>
												<Text
													style={{
														fontSize: 25,
														fontWeight: "800",
														color: APP_COLOURS.BG4,
													}}>
													{activeDevices > 0
														? (
																(activeDevices / totalDevices) *
																100
														  ).toFixed(0)
														: "None"}
													<Text
														style={{
															fontSize: 12,
															fontWeight: "700",
															color: APP_COLOURS.BG4,
															opacity: 0.8,
														}}>
														{activeDevices > 0 ? "%" : ""}
													</Text>
												</Text>
											</View>
										</View>
										<View
											style={{
												flexDirection: "row",
											}}>
											<View
												style={[
													{
														maxHeight: 90,
													},
													style,
												]}>
												<Text
													style={{
														opacity: 0.8,
														fontSize: 9,
														color: APP_COLOURS.BG4,
													}}>
													Devices online
												</Text>
												<Text
													style={{
														fontSize: 25,
														fontWeight: "800",
														color: APP_COLOURS.BG4,
													}}>
													{devicesOnline}{" "}
													<Text
														style={{
															fontSize: 12,
															fontWeight: "500",
															color: APP_COLOURS.BG4,
															opacity: 0.8,
														}}>
														/ {totalDevices}
													</Text>
												</Text>
											</View>
										</View>
									</View>
									<TouchableOpacity
										style={{
											// width: 130,
											height: "auto",
											alignItems: "center",
											borderRadius: 10,
											marginRight: 10,
											height: "auto",
											backgroundColor: APP_COLOURS.BLUE,
											flexDirection: "row",
										}}
										onPress={async () => {
											await this.setState({
												addNewDevice: true,
											});
											await this.setState({
												addNewDevice: true,
											});
										}}>
										<View
											style={{
												flex: 1,
												flexWrap: "wrap",
												padding: 12,
												borderRadius: 10,
											}}>
											<Text
												numberOfLines={2}
												style={{
													fontFamily: "Avenir",
													fontSize: 13,
													// opacity: 0.8,
													flex: 1,
													flexWrap: "wrap",
													fontWeight: "600",
													color: APP_COLOURS.WHITE,
												}}>
												+ Device
											</Text>
										</View>
									</TouchableOpacity>
									<TextInput
										ref={(ref) => {
											this.filterByName = ref;
										}}
										style={{
											padding: 8,
											fontSize: 16,
											borderRadius: 9,
											color: APP_COLOURS.WHITE,
											fontWeight: "200",
											fontFamily: "Avenir",
											borderWidth: 0.3,
											borderColor: APP_COLOURS.BG3,
											backgroundColor: APP_COLOURS.BG3,
											marginRight: 5,
											paddingLeft: 15,
										}}
										placeholderTextColor={"#797979"}
										autoCapitalize='words'
										clearButtonMode='while-editing'
										editable={true}
										placeholder={`Search devices `}
										onChangeText={(text) => {
											this.setState({
												filterByName: text,
											});
										}}
									/>
									{/* <TouchableOpacity
								onPress={() =>
									this.setState({
										showDelete: !this.state.showDelete,
									})
								}
								style={{
									flexDirection: "row",
									justifyContent: "flex-start",
									alignItems: "center",

									borderRadius: 10,
									margin: 5,
									marginRight: 15,
								}}>
								<View style={{}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 15,
											fontWeight: "400",
											color: APP_COLOURS.BLUE,
										}}>
										Edit
									</Text>
								</View>
							</TouchableOpacity> */}
								</View>
							</View>
							<View
								style={{
									// flex: 1,
									height: this.props.screenHeight - 400,
									// padding: 8,
									paddingTop: 8,
								}}>
								<ScrollView>
									{devices.map((d, i) => (
										<View
											key={i}
											style={{
												// flex: 1,
												borderRadius: 5,
												flexDirection: "row",
												borderColor: "#C5C5C5",
												borderWidth:
													this.state.showDelete === d.id ? 1 : 0,
												backgroundColor:
													this.state.hover == i
														? APP_COLOURS.BG5
														: APP_COLOURS.BG4,
												marginBottom: 1,
											}}>
											<TouchableOpacity
												onPress={() => {
													this.setState({
														profileData: d,
														deviceID: d.uuid,
														viewDeviceProfile: true,
													});
												}}>
												<View
													key={i}
													onMouseEnter={() =>
														this.setState({
															hover: i,
														})
													}
													onMouseLeave={() =>
														this.setState({
															hover: -1,
														})
													}
													style={{
														// flex: 1,
														opacity:
															this.state.hover === i ? 1 : 0.9,

														height: "auto",
														padding: 4,
														alignItems: "center",
														marginRight: 10,
														height: "auto",
														marginBottom: 2,
														flexDirection: "row",
													}}>
													{/* <View
														style={{
															width: 5,
															borderRadius: 4,
															height: 30,
															backgroundColor:
																this.state.selectedUserID ===
																d.id
																	? APP_COLOURS.BLUE
																	: "transparent",
															marginRight: 12,
														}}
													/> */}
													<View
														style={{
															width: 70,
															marginRight: 10,
														}}>
														<View
															style={
																{
																	// width: col1,
																	// flexDirection: "row",
																	// alignItems: "center",
																}
															}>
															<Text
																style={{
																	fontSize: fs - 5,
																	opacity: 0.7,
																	color: "#FFF",
																}}>
																Unique ID
															</Text>

															<Text
																style={{
																	fontSize: fs - 1,
																	color: "#FFF",
																}}>
																{d.uuid}
															</Text>
														</View>
													</View>

													<View
														style={{
															width: 200,
															marginRight: 10,
														}}>
														<View
															style={
																{
																	// width: col1,
																	// flexDirection: "row",
																	// alignItems: "center",
																}
															}>
															<Text
																style={{
																	fontSize: fs - 5,
																	opacity: 0.7,
																	color: "#FFF",
																}}>
																Location
															</Text>

															<Text
																style={{
																	width: col1,
																	fontSize: fs,
																	color: "#FFF",
																}}>
																<Text
																	style={{
																		fontWeight: "600",
																	}}>
																	{d.mall.title}
																</Text>
																, {d.retailer.title}
															</Text>
														</View>
													</View>

													<View
														style={{
															width: 70,
															marginRight: 10,
														}}>
														<View
															style={
																{
																	// width: col1,
																	// flexDirection: "row",
																	// alignItems: "center",
																}
															}>
															<Text
																style={{
																	fontSize: fs - 5,
																	opacity: 0.7,
																	color: "#FFF",
																}}>
																platform
															</Text>

															<Text
																style={{
																	fontSize: fs - 1,
																	color: "#FFF",
																}}>
																{d.platform}
															</Text>
														</View>
													</View>

													<View
														style={{
															width: 100,
															marginRight: 10,
														}}>
														<View
															style={
																{
																	// width: col1,
																	// flexDirection: "row",
																	// alignItems: "center",
																}
															}>
															<Text
																style={{
																	fontSize: fs - 5,
																	opacity: 0.7,
																	color: "#FFF",
																}}>
																location type
															</Text>

															<Text
																style={{
																	fontSize: fs - 1,
																	color: "#FFF",
																}}>
																{d.location_type}
															</Text>
														</View>
													</View>
													<View
														style={{
															width: col2,
														}}>
														<Text
															style={{
																fontSize: fs - 5,
																opacity: 0.7,
																color: "#FFF",
															}}>
															created at:
														</Text>
														<Text
															style={{
																fontSize: fs - 1,
																color: "#FFF",
															}}>
															{moment(d.date_created).format(
																"MMM DD, YYYY",
															)}
														</Text>
													</View>

													<View
														style={{
															width: 50,
															marginRight: 5,
														}}>
														<View
															style={
																{
																	// width: col1,
																	// flexDirection: "row",
																	// alignItems: "center",
																}
															}>
															<Text
																style={{
																	fontSize: fs - 5,
																	opacity: 0.7,
																	color: "#FFF",
																}}>
																status
															</Text>

															<View
																style={{
																	flexDirection: "row",
																	marginTop: 2,
																}}>
																<Text
																	style={{
																		fontFamily: "Avenir",
																		fontSize: 9,
																		color: "#FFF",
																		fontWeight: "400",
																	}}>
																	{d.active
																		? `Active`
																		: `Inactive`}
																</Text>
															</View>
														</View>
													</View>

													<View
														style={{
															width: 60,
															marginRight: 5,
														}}>
														<View
															style={
																{
																	// width: col1,
																	// flexDirection: "row",
																	// alignItems: "center",
																}
															}>
															<Text
																style={{
																	fontSize: fs - 5,
																	opacity: 0.7,
																	color: "#FFF",
																}}>
																status
															</Text>

															<View
																style={{
																	width: 50,
																	flexDirection: "row",
																	marginTop: 2,
																}}>
																<View
																	style={{
																		width: 8,
																		height: 8,
																		backgroundColor:
																			d.last_seen !== "" &&
																			moment().diff(
																				d.last_seen,
																				"hours",
																			) < 1
																				? "#2EE39C"
																				: "#FF5F5F",
																		borderRadius: 5,
																		marginRight: 5,
																	}}
																/>
																<Text
																	style={{
																		fontFamily: "Avenir",
																		fontSize: 9,
																		color: "#FFF",
																		fontWeight: "400",
																	}}>
																	{d.last_seen !== "" &&
																	moment().diff(
																		d.last_seen,
																		"hours",
																	) < 1
																		? `Online`
																		: `Offline`}
																</Text>
															</View>
														</View>
													</View>

													<View
														style={{
															width: 90,
														}}>
														<Text
															style={{
																fontSize: fs - 5,
																opacity: 0.7,
																color: "#FFF",
															}}>
															last seen:
														</Text>
														<Text
															style={{
																marginTop: 2,
																fontSize: fs - 4,
																color: "#FFF",
															}}>
															{d.last_seen !== ""
																? moment(d.last_seen).fromNow()
																: "No device set up"}
														</Text>
													</View>
												</View>
											</TouchableOpacity>

											{this.state.showDelete === d.id ? (
												<TouchableOpacity
													onPress={() => this.deleteDevice(d.id)}
													style={{
														justifyContent: "center",
														alignItems: "center",
													}}>
													<Image
														source={bin}
														style={{
															width: 22,
															height: 22,
															resizeMode: "contain",
															marginRight: 5,
														}}
													/>
												</TouchableOpacity>
											) : null}
										</View>
									))}
									{this.state.loading ? (
										<View
											style={{
												flex: 1,
												justifyContent: "center",
												alignItems: "center",
												minHeight: 50,
											}}>
											<ActivityIndicator color={APP_COLOURS.BLUE} />
										</View>
									) : null}
								</ScrollView>
							</View>
						</View>
					) : null}

					{this.state.tab === "Data" ? (
						<View
							style={{
								// flex: 1,
								paddingRight: 8,
								borderTopColor: "#797979",
								borderTopWidth: 0.3,
							}}>
							<View
								style={{
									// flex: 1,
									height: this.props.screenHeight - 220,
									paddingTop: 8,
								}}>
								<Results {...this.props} />
							</View>
						</View>
					) : null}
				</View>
				{this.state.addNewDevice ? this.addNewDevice() : <View />}
				{this.state.viewDeviceProfile ? (
					this.DeviceProfileModal(this.state.profileData)
				) : (
					<View />
				)}

				{this.state.showenlargedQRCodeModal ? (
					this.enlargedQRCodeModal()
				) : (
					<View />
				)}
			</div>
		);
	}

	DeviceProfileModal = (d) => {
		// let imgSize = 1000;
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						// justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							opacity: 0,
							zIndex: -1,
							position: "absolute",
						}}>
						<div
							id='capture'
							style={{
								width: 500,
								height: 800,

								justifyContent: "center",
								alignItems: "center",
							}}>
							<View
								style={{
									width: 500,
									height: 800,
									backgroundColor: APP_COLOURS.BG2,
									padding: 15,
									// height: "auto",
									paddingTop: 20,
									// justifyContent: "center",
									// alignItems: "center",
								}}>
								<View
									style={{
										// justifyContent: "center",
										// alignItems: "center",
										marginTop: 10,
									}}>
									<Image
										source={survey}
										style={{
											width: 120,
											height: 50,
											resizeMode: "contain",
										}}
									/>
								</View>
								<View
									style={{
										width: "100%",
										// justifyContent: "flex-end",
										justifyContent: "space-between",
										alignItems: "flex-end",
										flexDirection: "row",
										// marginBottom: 40,
										padding: 10,
									}}>
									<View
										style={
											{
												// justifyContent: "center",
												// alignItems: "center",
											}
										}>
										<View
											style={{
												flexDirection: "row",
												// marginTop: 5,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 20,
													color: APP_COLOURS.TEXTCOLOR,
													fontWeight: "700",
												}}>
												CREME OF NATURE
											</Text>
										</View>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 14,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "400",
											}}>
											{this.state.url}
										</Text>

										<View
											style={{
												flexDirection: "row",
												marginTop: 5,
												// justifyContent: "center",
												// alignItems: "center",
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 9,
													color: "#FFF",
													fontWeight: "400",
													backgroundColor: APP_COLOURS.RED,
													padding: 4,
													borderRadius: 5,
												}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 9,
														color: "#FFF",
														fontWeight: "600",
													}}>
													{this.props.selectedSurvey.surveyType}{" "}
													survey
												</Text>
											</Text>
										</View>
									</View>
									{d.platform === "ANDROID" ? (
										<Image
											source={android}
											style={{
												width: 150,
												height: 90,
												resizeMode: "contain",
											}}
										/>
									) : (
										<Image
											source={apple}
											style={{
												width: 150,
												height: 90,
												resizeMode: "contain",
											}}
										/>
									)}
								</View>

								<InputArea
									title={"Device unique ID"}
									value={d.uuid}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<InputArea
									title={"Passcode"}
									value={d.passcode}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 10,
										fontFamily: "Avenir Next",
										textAlign: "center",
										fontWeight: "500",
										color: APP_COLOURS.RED,
									}}>
									Passcode only valid for one authentication
								</Text>

								<InputArea
									title={"Mall"}
									value={d.mall.title}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<InputArea
									title={"Retailer"}
									value={d.retailer.title}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										marginTop: 5,
									}}>
									<View
										style={{
											backgroundColor: "#FFF",
											borderRadius: 12,
											height: 200,
											width: 200,
											padding: 10,
										}}>
										<QRCode
											size={180}
											value={this.state.url}
											bgColor={"#FFF"}
											fgColor={APP_COLOURS.BG2}
										/>
									</View>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 10,
											fontFamily: "Avenir Next",
											textAlign: "center",
											fontWeight: "700",
											color: APP_COLOURS.RED,
										}}>
										Scan to activate device
									</Text>
								</View>

								<View
									style={{
										width: "100%",
										// position: "absolute",
										// bottom: 10,
										// left: 0,
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										// paddingRight: 30,
										// paddingLeft: 30,
									}}>
									<Text
										style={{
											width: 110,
											fontSize: 16,
											color: "#FFF",
										}}>
										<Font family='Poppins' weight={800}>
											tetrice
										</Font>
									</Text>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<Image
											source={require("../google_play.png")}
											style={{
												width: 100,
												height: 25,
												resizeMode: "contain",
												marginRight: 10,
											}}
										/>

										<Image
											source={require("../app_store.png")}
											style={{
												width: 100,
												height: 25,
												resizeMode: "contain",
											}}
										/>
									</View>
									<Text
										style={{
											width: 110,
											fontSize: 13,
											color: "#FFF",
										}}>
										<Font family='Poppins' weight={300}>
											info@traq.app
										</Font>
									</Text>
								</View>
							</View>
						</div>
					</View>

					<ScrollView>
						<View
							style={{
								height: 90,
							}}
						/>
						<View
							style={{
								width: 500,
								// maxWidth: 500,
								// height: "80%",
								// backgroundColor: "#FFF",
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 20,
								// justifyContent: "center",
								// alignItems: "center",
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: 10,
									left: 10,
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											viewDeviceProfile: false,
										})
									}>
									<Image
										source={close}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									width: "100%",
									padding: 20,
									height: "auto",
									paddingTop: 25,
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 19,
										fontFamily: "Avenir Next",
										textAlign: "center",
										fontWeight: "700",
										marginBottom: 20,
										color: "#FFF",
									}}>
									Device profile
								</Text>

								<View
									style={{
										width: "100%",
										// justifyContent: "flex-end",
										justifyContent: "space-between",
										alignItems: "flex-end",
										flexDirection: "row",
										// marginBottom: 40,
										padding: 20,
									}}>
									{d.platform === "ANDROID" ? (
										<Image
											source={android}
											style={{
												width: 150,
												height: 90,
												resizeMode: "contain",
											}}
										/>
									) : (
										<Image
											source={apple}
											style={{
												width: 150,
												height: 90,
												resizeMode: "contain",
											}}
										/>
									)}

									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
										}}>
										<View
											style={{
												backgroundColor: "#FFF",
												borderRadius: 12,
												height: 100,
												width: 100,
												padding: 10,
											}}>
											<QRCode
												size={80}
												value={this.state.url}
												bgColor={"#FFF"}
												fgColor={APP_COLOURS.BG2}
											/>
										</View>
										<Text
											allowFontScaling={false}
											style={{
												fontSize: 9,
												fontFamily: "Avenir Next",
												textAlign: "center",
												fontWeight: "700",
												color: "#FFF",
											}}>
											Scan to activate device
										</Text>
									</View>
								</View>

								<InputArea
									title={"Device unique ID"}
									value={d.uuid}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<InputArea
									title={"Passcode"}
									value={d.passcode}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<InputArea
									title={"Mall"}
									value={d.mall.title}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<InputArea
									title={"Retailer"}
									value={d.retailer.title}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<InputArea
									title={"Date created"}
									value={moment(d.date_created).format("MMM DD, YYYY")}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<TouchableOpacity
									onPress={() => this.deleteDevice(d.id)}
									style={{
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Image
										source={bin}
										style={{
											width: 22,
											height: 22,
											resizeMode: "contain",
											marginRight: 5,
										}}
									/>
								</TouchableOpacity>

								<TouchableOpacity
									style={{
										// position: "absolute",
										marginTop: 20,
										zIndex: 999,
										bottom: 0,
										justifyContent: "center",
										alignItems: "center",
									}}
									onPress={() => {
										this.snapShot();
									}}>
									<View
										style={{
											backgroundColor: APP_COLOURS.RED,
											borderRadius: 10,
											padding: 10,
											width: 200,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												color: "#FFF",
												fontFamily: "Avenir",
												fontWeight: "600",
											}}>
											Download & send
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	enlargedQRCodeModal = () => {
		console.log("enlarged");
		// return (
		// 	<Modal animationType='fade' transparent={true} visible={true}>

		// 	</Modal>
		// );

		return (
			<View
				style={{
					position: "absolute",
					top: 0,
					left: 0,

					width: "100%",
					height: this.props.screenHeight,
					// justifyContent: "center",
					alignItems: "center",
					backgroundColor: "rgba(0, 0, 0, 0.8)",
					paddingTop: "20%",
					borderRadius: 10,
				}}>
				<View
					style={{
						width: 500,
						height: 550,
						// height: "80%",
						// backgroundColor: "#FFF",
						backgroundColor: APP_COLOURS.BG2,
						borderRadius: 20,
						// justifyContent: "center",
						// alignItems: "center",
					}}>
					<View
						style={{
							position: "absolute",
							zIndex: 99,
							top: 10,
							left: 10,
						}}>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									showenlargedQRCodeModal: false,
								})
							}>
							<Image
								source={close}
								style={{
									width: 20,
									height: 20,
									resizeMode: "contain",
								}}
							/>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flex: 1,
							padding: 20,

							paddingTop: 25,
						}}>
						<TouchableOpacity
							onPress={() => {
								window.open(this.state.url, "_blank");
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 19,
									fontFamily: "Avenir Next",
									textAlign: "center",
									fontWeight: "700",
									marginBottom: 20,
									color: "#FFF",
								}}>
								{this.state.url}
							</Text>
						</TouchableOpacity>

						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								marginTop: 10,
							}}>
							<View
								style={{
									backgroundColor: "#FFF",
									borderRadius: 12,
									height: 300,
									width: 300,
									padding: 10,
								}}>
								<QRCode
									size={280}
									value={this.state.url}
									bgColor={"#FFF"}
									fgColor={APP_COLOURS.BG2}
								/>
							</View>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16,
									fontFamily: "Avenir Next",
									textAlign: "center",
									fontWeight: "700",
									color: APP_COLOURS.RED,
									marginTop: 10,
								}}>
								SCAN ME
							</Text>
						</View>

						<TouchableOpacity
							style={{
								// position: "absolute",
								marginTop: 20,
								zIndex: 999,
								bottom: 0,
								justifyContent: "center",
								alignItems: "center",
							}}
							onPress={() => {
								this.snapShot2();
							}}>
							<View
								style={{
									backgroundColor: APP_COLOURS.RED,
									borderRadius: 10,
									padding: 10,
									width: 200,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										color: "#FFF",
										fontFamily: "Avenir",
										fontWeight: "600",
									}}>
									Download QR code
								</Text>
							</View>
						</TouchableOpacity>
					</View>
					<View
						style={{
							width: "100%",
							padding: 10,
							// position: "absolute",
							// bottom: 10,
							// left: 0,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							// paddingRight: 30,
							// paddingLeft: 30,
						}}>
						<Text
							style={{
								width: 110,
								fontSize: 16,
								color: "#FFF",
							}}>
							<Font family='Poppins' weight={800}>
								tetrice
							</Font>
						</Text>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<Image
								source={require("../google_play.png")}
								style={{
									width: 100,
									height: 25,
									resizeMode: "contain",
									marginRight: 10,
								}}
							/>

							<Image
								source={require("../app_store.png")}
								style={{
									width: 100,
									height: 25,
									resizeMode: "contain",
								}}
							/>
						</View>
						<Text
							style={{
								width: 110,
								fontSize: 13,
								color: "#FFF",
								textAlign: "right",
							}}>
							<Font family='Poppins' weight={300}>
								info@traq.app
							</Font>
						</Text>
					</View>
				</View>
			</View>
		);
	};
}
