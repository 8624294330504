import React, { Component } from "react";
import { Dimensions } from "react-native";

export const APP_COLOURS = {
	PRIMARY: "",
	SECONDARY: "#748969",
	BACKGROUND: "#F2F3F6",

	TEXTCOLOR: "#FFF",
	PLACEHOLDER: "#797979",
	TEXTINPUT: "#0720D5",
	DESELECTEDCOLOR: "#EEF2FC",
	OFFWHITE: "#FBFBFC",

	WHITE: "#FFF",
	GREEN: "#07D55D",
	ORANGE: "#FFD76E",
	RED: "#FF5F5F",
	BLUE: "#393C42",

	TRANSHALO: "rgba(255, 255, 255, 0.05)",
	BLACK: "#000",
	BG: "#191D22",
	BG2: "#191D22",
	BG3: "#252B33",
	BG4: "#333E4B",
	BG5: "#191D22",
	BG6: "#191D22",
};

export const screenWidth = Dimensions.get("window").width;
export const screenHeight = Dimensions.get("window").height;

export const AVATARS = [
	{
		pp: require("../assets/images/Avatars/1.png"),
		avatar: 0,
	},
	{
		pp: require("../assets/images/Avatars/2.png"),
		avatar: 1,
	},
	{
		pp: require("../assets/images/Avatars/3.png"),
		avatar: 2,
	},
	{
		pp: require("../assets/images/Avatars/4.png"),
		avatar: 4,
	},
	{
		pp: require("../assets/images/Avatars/5.png"),
		avatar: 5,
	},
	{
		pp: require("../assets/images/Avatars/4.png"),
		avatar: 6,
	},
	{
		pp: require("../assets/images/Avatars/4.png"),
		avatar: 7,
	},
	{
		pp: require("../assets/images/Avatars/4.png"),
		avatar: 8,
	},
];

export const DEMOGRAPHICS = [
	{
		pp: require("../assets/images/demographics/other.png"),
		avatar: "other",
	},
	{
		pp: require("../assets/images/demographics/asian.png"),
		avatar: "asian",
	},
	{
		pp: require("../assets/images/demographics/caucasian.png"),
		avatar: "caucasian",
	},
	{
		pp: require("../assets/images/demographics/ethnic.png"),
		avatar: "ethnic",
	},
	{
		pp: require("../assets/images/demographics/indian.png"),
		avatar: "indian",
	},
];
