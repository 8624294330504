import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import { api_getBrand } from "../../Api";

export default class BrandTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: true,
			brand: {
				id: "",
				title: "All",
			},
			brands: [],
			selectedBrands: [],
		};
	}

	render() {
		return (
			<View>
				<View
					// onMouseEnter={() => this.setState({ hover: true })}
					// onMouseLeave={() => this.setState({ hover: false })}
					style={{
						flexDirection: "row",
						marginTop: 10,
						alignItems: "center",
					}}>
					<TouchableOpacity
						onPress={async () => {
							await this.setState({
								brand: {
									id: "",
									title: "All",
								},
							});
							this.props.selectedSubPage({
								id: "",
								title: "All",
							});
						}}>
						<View
							style={{
								// backgroundColor: APP_COLOURS.WHITE,
								// this.state.brand.id === d.id
								// 	? APP_COLOURS.BLUE
								// 	: APP_COLOURS.BLACK,
								borderWidth: 1,
								borderColor:
									this.state.brand.id === ""
										? APP_COLOURS.WHITE
										: APP_COLOURS.PLACEHOLDER,
								marginRight: 5,
								borderRadius: 10,
								// padding: 10,
								justifyContent: "center",
								alignItems: "center",
								// minWidth: 150,
								flexDirection: "row",
								// paddingTop: this.state.hover ? 50 : 10,
								marginLeft: 2,
								padding: 20,
								opacity: this.state.brand.id === "" ? 1 : 0.5,
								// top: 9,
							}}>
							<Text
								style={{
									fontSize: 14,
									fontWeight: "900",
									// this.state.brand === d ? "900" : "300",
									color: "#FFF",
									fontFamily: "Avenir",
								}}>
								All
							</Text>
						</View>
					</TouchableOpacity>
					{this.state.brands.map((d, i) => (
						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									brand: d,
								});
								this.props.selectedSubPage(d);
							}}>
							{/* <View
								style={{
									height: "80%",
									borderRadius: 5,
									width: 3,
									marginTop: this.state.hover ? "5%" : 5,
									backgroundColor:
										this.state.brand === d
											? APP_COLOURS.BLUE
											: "transparent",
									position: "absolute",
									left: 3,
								}}
							/> */}
							<View
								style={{
									backgroundColor: APP_COLOURS.WHITE,
									// this.state.brand.id === d.id
									// 	? APP_COLOURS.BLUE
									// 	: APP_COLOURS.BLACK,
									borderWidth: 1,
									borderColor:
										this.state.brand.id === d.id
											? APP_COLOURS.WHITE
											: APP_COLOURS.PLACEHOLDER,
									marginRight: 5,
									borderRadius: 10,
									// padding: 10,
									// justifyContent: "center",
									alignItems: "center",
									// minWidth: 150,
									flexDirection: "row",
									// paddingTop: this.state.hover ? 50 : 10,
									marginLeft: 2,
									padding: 10,
									opacity: this.state.brand.id === d.id ? 1 : 0.5,
									// top: 9,
								}}>
								<Image
									source={`data:image/png;base64,${d.base64String}`}
									style={{
										width: 100,
										height: 40,
										resizeMode: "contain",
									}}
								/>

								{/* <Text
									style={{
										fontSize: 14,
										fontWeight: "900",
										// this.state.brand === d ? "900" : "300",
										color: "#FFF",
										marginRight: 15,
										fontFamily: "Avenir",
										position: "absolute",
										left: 10,
										bottom: 10,
										zIndex: 99,
									}}>
									{d.title}
								</Text> */}
							</View>
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	}

	async componentDidMount() {
		await this.getData();
		await this.props.selectedSubPage(this.state.brand);
	}

	getData = async () => {
		let url = api_getBrand;
		let accessToken = await sessionStorage.getItem("accessToken");

		await this.setState({
			loading: true,
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log("brands", responseData);
							await this.setState({
								brands: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};
}
