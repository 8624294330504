import React, { Component } from "react";
import { View, Text, Image } from "react-native";
import { APP_COLOURS, DEMOGRAPHICS } from "../../APP_VARS";

import Fade from "react-reveal/Fade";
import TotalSalesLineChart from "./Charts/TotalSalesLineChart";

function randomRange(n, min, max) {
	var values = [],
		i = max;
	while (i >= min) values.push(i--);
	var results = [];
	var maxIndex = max;
	for (i = 1; i <= n; i++) {
		maxIndex--;
		var index = Math.floor(maxIndex * Math.random());
		results.push(values[index]);
		values[index] = values[maxIndex];
	}
	return results.filter((d) => typeof d !== "undefined");
}

function newRandomNumber(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function thousandSep(num) {
	num = num.toString().replace(/[^0-9]/g, "");
	num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	return num;
}

export default class TotalSales extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: "",
			dataHistory: randomRange(12, 1000000, 20000000),

			total: 0,
		};
	}

	render() {
		let style = {
			// backgroundColor: APP_COLOURS.BG3,
			borderRadius: 11,
			padding: 8,
			marginRight: 15,
			marginBottom: 5,
			shadowColor: "#000",
			shadowOffset: { width: 0, height: 8 },
			shadowOpacity: 0.44,
			shadowRadius: 9.32,
			elevation: 5,
			opacity: 0.9,
		};

		let data = this.state.data;

		return (
			<Fade>
				<View
					onMouseEnter={() =>
						this.setState({
							hover: true,
						})
					}
					onMouseLeave={() => this.setState({ hover: false })}
					style={[
						style,
						{
							flexDirection: "row",
							marginTop: 20,
							paddingLeft: 15,
							marginBottom: 20,
							justifyContent: "space-between",
							// backgroundColor: APP_COLOURS.BG3,
							padding: 20,
						},
					]}>
					<View
						style={{
							height: 50,
							width: 4,
							borderRadius: 2,
							backgroundColor: "rgba(255, 156, 0, 1)",
							position: "absolute",
							left: 0,
							top: 20,
						}}
					/>

					<View
						style={{
							width: 200,
						}}>
						<Text
							style={{
								fontSize: 12,
								fontWeight: "300",
								color: "#D8D8FF",
							}}>
							Total Sales
						</Text>
						<Text
							style={{
								fontSize: 25,
								fontWeight: "700",
								color: "#FFF",
							}}>
							R{" "}
							{thousandSep(
								this.state.dataHistory[
									this.state.dataHistory.length - 1
								],
							)}
						</Text>
						<Text
							style={{
								fontFamily: "Avenir",
								fontSize: 10,
								fontWeight: "100",
								color: APP_COLOURS.WHITE,
								opacity: this.state.hover ? 0.8 : 0,
								marginTop: 10,
							}}>
							Total Sales are calculated on total amount of sales
							captured by staff.
						</Text>
					</View>

					<View
						style={{
							position: "absolute",
							right: 10,
							top: 10,
							padding: 6,
							backgroundColor: APP_COLOURS.BG4,
							borderRadius: 6,
						}}>
						<Text
							style={{
								fontSize: 15,
								fontWeight: "700",
								color:
									(this.state.dataHistory[
										this.state.dataHistory.length - 1
									] -
										this.state.dataHistory[
											this.state.dataHistory.length - 2
										]) /
										this.state.dataHistory[
											this.state.dataHistory.length - 2
										] >
									0
										? APP_COLOURS.GREEN
										: APP_COLOURS.RED,
							}}>
							{(
								((this.state.dataHistory[
									this.state.dataHistory.length - 1
								] -
									this.state.dataHistory[
										this.state.dataHistory.length - 2
									]) /
									this.state.dataHistory[
										this.state.dataHistory.length - 2
									]) *
								100
							).toFixed(0)}{" "}
							%
						</Text>
					</View>

					<View
						style={{
							flex: 1,
							justifyContent: "space-between",
						}}>
						<View
							style={{
								// width: 200,
								height: 150,
								padding: 5,
							}}>
							<TotalSalesLineChart
								label={``}
								data={this.state.dataHistory}
								// average={80}
								max={100}
							/>
						</View>
					</View>
				</View>
			</Fade>
		);
	}
}
