import moment from "moment";
import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	TextInput,
} from "react-native";
import * as XLSX from "xlsx";
import { PieChart } from "react-minimal-pie-chart";
import { APP_COLOURS } from "../../APP_VARS";

class ExcelToJson extends React.Component {
	constructor(props) {
		super(props);
		// this.handleClick = this.handleClick.bind(this);
		this.state = {
			file: "",
			csv: [],
			ccc: [],
			ethnicity: "",
			groups: [],
			selectedBrand: "",
			data: [
				{
					id: "47",
					userId: "128",
					cus_id: "128_5cc16ef3078ef",
					receipt: "rec_128_5cc16f35c0237",
					prid: "1416",
					prod_name: "ARMANI CODE A-LIST EDT 75ml",
					sub_brand: "",
					category: "ARMANI",
					sssc: "sale",
					qty: "1",
					discount: "0.00",
					price: "1370.00",
					total: "1370.00",
					day: "25",
					month: "Apr",
					year: "2019",
					brand: "DESIGNER FRAGRANCES ",
					retailer: "Truworths",
					mall: "Sandton",
					gender: "Male",
					age_range: "Below18",
					ethnicity: "Ethnic",
					skin_type: "Dehydrated",
					skin_concern: "Dry",
					ts: "10:26:29",
					ts_ref: "190425",
					date_added: "2019-10-16 08:44:44",
				},
				{
					id: "48",
					userId: "126",
					cus_id: "126_5cc170ceee982",
					receipt: "rec_126_5cc1715bc5be9",
					prid: "1460",
					prod_name: "EMPORIO ARMANI STRONGER WITH YOU EDT 100ml",
					sub_brand: "",
					category: "ARMANI",
					sssc: "sale",
					qty: "1",
					discount: "0.00",
					price: "1320.00",
					total: "1320.00",
					day: "25",
					month: "Apr",
					year: "2019",
					brand: "DESIGNER FRAGRANCES ",
					retailer: "Edgars",
					mall: "Sandton",
					gender: "Female",
					age_range: "36to40",
					ethnicity: "Indian",
					skin_type: "Sensitive",
					skin_concern: "combination",
					ts: "10:35:39",
					ts_ref: "190425",
					date_added: "2019-10-16 08:44:44",
				},
				{
					id: "49",
					userId: "11",
					cus_id: "11_5cc19f8ae49bd",
					receipt: "rec_11_5cc1a0820eadf",
					prid: "176",
					prod_name: "TEINT IDOLE ULTRA WEAR FOUNDATION SPF15 10 PRALINE",
					sub_brand: "",
					category: "FOUNDATIONS",
					sssc: "recommend",
					qty: "1",
					discount: "0.00",
					price: "550.00",
					total: "550.00",
					day: "25",
					month: "Apr",
					year: "2019",
					brand: "LANCOME",
					retailer: "Edgars",
					mall: "East Gate",
					gender: "Female",
					age_range: "51to55",
					ethnicity: "Ethnic",
					skin_type: "Aging",
					skin_concern: "combination",
					ts: "13:56:50",
					ts_ref: "190425",
					date_added: "2019-10-16 08:44:44",
				},
				{
					id: "50",
					userId: "49",
					cus_id: "49_5cc1d8a2b35c6",
					receipt: "rec_49_5cc1d912efce3",
					prid: "198",
					prod_name: "TEINT IDOLE ULTRA WEAR FOUNDATION SPF15 09 COOKIE",
					sub_brand: "",
					category: "FOUNDATIONS",
					sssc: "sale",
					qty: "1",
					discount: "0.00",
					price: "550.00",
					total: "550.00",
					day: "25",
					month: "Apr",
					year: "2019",
					brand: "LANCOME",
					retailer: "",
					mall: "",
					gender: "Female",
					age_range: "25to30",
					ethnicity: "Ethnic",
					skin_type: "Skin Texture",
					skin_concern: "combination",
					ts: "17:58:10",
					ts_ref: "190425",
					date_added: "2019-10-16 08:44:44",
				},
				{
					id: "51",
					userId: "49",
					cus_id: "49_5cc1d8a2b35c6",
					receipt: "rec_49_5cc1d912efce3",
					prid: "198",
					prod_name: "TEINT IDOLE ULTRA WEAR FOUNDATION SPF15 09 COOKIE",
					sub_brand: "",
					category: "FOUNDATIONS",
					sssc: "service",
					qty: "1",
					discount: "0.00",
					price: "550.00",
					total: "550.00",
					day: "25",
					month: "Apr",
					year: "2019",
					brand: "LANCOME",
					retailer: "",
					mall: "",
					gender: "Female",
					age_range: "25to30",
					ethnicity: "Ethnic",
					skin_type: "Skin Texture",
					skin_concern: "combination",
					ts: "17:58:10",
					ts_ref: "190425",
					date_added: "2019-10-16 08:44:44",
				},
				{
					id: "52",
					userId: "49",
					cus_id: "49_5cc1d8a2b35c6",
					receipt: "rec_49_5cc1d912efce3",
					prid: "198",
					prod_name: "TEINT IDOLE ULTRA WEAR FOUNDATION SPF15 09 COOKIE",
					sub_brand: "",
					category: "FOUNDATIONS",
					sssc: "sale",
					qty: "0",
					discount: "0.00",
					price: "550.00",
					total: "0.00",
					day: "25",
					month: "Apr",
					year: "2019",
					brand: "LANCOME",
					retailer: "",
					mall: "",
					gender: "Female",
					age_range: "25to30",
					ethnicity: "Ethnic",
					skin_type: "Skin Texture",
					skin_concern: "combination",
					ts: "17:58:10",
					ts_ref: "190425",
					date_added: "2019-10-16 08:44:44",
				},
				{
					id: "53",
					userId: "24",
					cus_id: "24_5cc2c347c1267",
					receipt: "rec_24_5cc2c4398fbb1",
					prid: "196",
					prod_name: "TEINT IDOLE ULTRA WEAR FOUNDATION SPF15 07 SABLE",
					sub_brand: "",
					category: "FOUNDATIONS",
					sssc: "sale",
					qty: "1",
					discount: "0.00",
					price: "550.00",
					total: "550.00",
					day: "26",
					month: "Apr",
					year: "2019",
					brand: "LANCOME",
					retailer: "Woolworths",
					mall: "East Gate",
					gender: "Female",
					age_range: "Below18",
					ethnicity: "Ethnic",
					skin_type: "Pigmentation",
					skin_concern: "combination",
					ts: "10:41:29",
					ts_ref: "190426",
					date_added: "2019-10-16 08:44:44",
				},
				{
					id: "54",
					userId: "24",
					cus_id: "24_5cc2c347c1267",
					receipt: "rec_24_5cc2c4398fbb1",
					prid: "348",
					prod_name: "TIU CAMOUFLAGE CONCEALERS  555",
					sub_brand: "",
					category: "CONCEALERS",
					sssc: "sale",
					qty: "1",
					discount: "0.00",
					price: "450.00",
					total: "450.00",
					day: "26",
					month: "Apr",
					year: "2019",
					brand: "LANCOME",
					retailer: "Woolworths",
					mall: "East Gate",
					gender: "Female",
					age_range: "Below18",
					ethnicity: "Ethnic",
					skin_type: "Pigmentation",
					skin_concern: "combination",
					ts: "10:41:29",
					ts_ref: "190426",
					date_added: "2019-10-16 08:44:44",
				},
			],
			genderObj: [],
			genders: [],
			colours: ["#14296C", "#6FD1F6", "#737DA9"],
			mainFilterField: "brand",
		};
	}

	componentDidMount() {
		this.processGenders();
	}

	processGenders = async () => {
		const data = this.state.data;
		const mainFilter = this.state.mainFilterField;
		const totalLength = data.length;
		const unique = await [...new Set(data.map((item) => item[mainFilter]))];
		// await console.log(unique);

		let genderObj = [];
		let ind = 0;
		for (const f_ of unique) {
			const genderData = await data.filter((d) => d[mainFilter] === f_);
			const genderRange = await this.filterField(
				genderData,
				f_,
				"gender",
				totalLength,
			);
			const ageRanges = await this.filterField(
				genderData,
				f_,
				"age_range",
				totalLength,
			);
			const brand = await this.filterField(
				genderData,
				f_,
				"brand",
				totalLength,
			);
			const ethnicity = await this.filterField(
				genderData,
				f_,
				"ethnicity",
				totalLength,
			);
			const perProduc = await this.filterField(
				genderData,
				f_,
				"prod_name",
				totalLength,
			);
			const sssc = await this.filterField(
				genderData,
				f_,
				"sssc",
				totalLength,
			);

			const skin_type = await this.filterField(
				genderData,
				f_,
				"skin_type",
				totalLength,
			);
			const skin_concern = await this.filterField(
				genderData,
				f_,
				"skin_concern",
				totalLength,
			);
			const retailer = await this.filterField(
				genderData,
				f_,
				"retailer",
				totalLength,
			);
			const mall = await this.filterField(
				genderData,
				f_,
				"mall",
				totalLength,
			);
			const sub_brand = await this.filterField(
				genderData,
				f_,
				"sub_brand",
				totalLength,
			);
			const category = await this.filterField(
				genderData,
				f_,
				"category",
				totalLength,
			);

			await console.log("brand", brand);
			let count = genderData.length;
			let obj = {
				data: genderData,
				title: f_,
				value: count,
				percent: count / totalLength,
				color: this.state.colours[ind],
				ageRanges: ageRanges,
				brands: brand,
				gender: genderRange,
				ethnicity,
				perProduc,
				sssc,
				skin_type,
				skin_concern,
				retailer,
				mall,
				sub_brand,
				category,
			};

			const push = await genderObj.push(obj);
			ind = ind + 1;
		}

		await this.setState({
			genderObj: genderObj,
			genders: unique,
		});
		console.log(genderObj);
		this.brandsPerGender(genderObj);
	};

	brandsPerGender = async (d) => {};

	// ageRanges = async (data, gender, filterField, totalLength) => {
	// 	let fieldObj = [];
	// 	// const data = data_
	// 	// 	.filter((d) => d.gender === gender)
	// 	// 	.map((dd) => dd.age_range);
	// 	const unique = await [...new Set(data.map((item) => item.age_range))];

	// 	for (const field of unique) {
	// 		const fData = await data.filter((d) => d[filterField] === field);

	// 		// console.log(field, fData);
	// 		let count = fData.length;
	// 		let obj = {
	// 			data: fData,
	// 			title: field,
	// 			count: count,
	// 			percent: count / totalLength,
	// 			percInverted: 1 - count / totalLength,
	// 		};

	// 		const push = await fieldObj.push(obj);
	// 	}
	// 	return fieldObj;
	// };

	filterField = async (data, gender, filterField, totalLength) => {
		let fieldObj = [];
		const unique = await [...new Set(data.map((item) => item[filterField]))];

		for (const field of unique) {
			const fData = await data.filter((d) => d[filterField] === field);
			let count = fData.length;
			let obj = {
				data: fData,
				title: field,
				count: count,
				percent: count / totalLength,
				percInverted: 1 - count / totalLength,
			};

			const push = await fieldObj.push(obj);
		}
		return fieldObj;
	};

	filePathset(e) {
		e.stopPropagation();
		e.preventDefault();
		var file = e.target.files[0];
		console.log(file);
		this.setState({ file });

		// console.log(this.state.file);
		this.readFile(file);
	}

	readFile(f) {
		var name = f.name;
		const reader = new FileReader();
		reader.onload = (evt) => {
			// evt = on_file_select event
			/* Parse data */
			const bstr = evt.target.result;
			const wb = XLSX.read(bstr, { type: "binary" });
			/* Get first worksheet */
			const wsname = wb.SheetNames[0];
			const ws = wb.Sheets[wsname];
			/* Convert array of arrays */
			const data = XLSX.utils.sheet_to_csv(ws);
			// const data = XLSX.utils.sheet_to_json(ws);
			/* Update state */
			// console.log(data); // shows that excel data is read
			console.log(this.convertToJson(data)); // shows data in json format
		};
		reader.readAsBinaryString(f);
	}

	async convertToJson(csv) {
		let assessment_uuid = this.props.assessment_uuid;
		var lines = await csv.split("\n");

		var result = [];

		let testLength = this.props.testLength;
		let question_number = testLength + 1;

		var ethnicity = [];
		var skin_concerns = [];
		var skin_type = [];
		var gender = [];
		var wdyhau = [];

		for (const i of lines) {
			let c = i.split(",");
			// console.log(c[8]);
			if (c[0] !== "" && c[17] === this.state.selectedBrand) {
				let obj = {
					id: c[1],
					fname: c[2],
					lname: c[3],
					bd: `${c[4]} ${c[5]}`,
					gender: c[6],
					age_range: c[7],
					ethnicity: c[8],
					skin_concerns: c[9],
					skin_type: c[10],
					opt_in: c[11],
					email: c[12],
					cell: c[13],
					contact_method: c[14],
					wdyhau: c[15],
					region: c[16],
					brand: c[17],
					mall: c[18],
					retailer: c[19],
					date_added: c[24],
				};

				await ethnicity.push(c[8]);
				await skin_concerns.push(c[9]);
				await skin_type.push(c[10]);
				await gender.push(c[6]);
				await wdyhau.push(c[15]);

				await question_number++;
				await result.push(obj);
			}
		}

		function createGroup(field, array) {
			const ethnicityGroups = [...new Set(array)];
			let ethGro = [];
			ethnicityGroups.forEach(async (field) => {
				let newItem = array.filter(function (value) {
					return value === field;
				}).length;
				await ethGro.push({
					[field]: newItem,
				});
			});

			return ethGro;
			// console.log(ethnicityGroups, ethGro);
		}

		const ethnicityGroup = createGroup("ethnicity", ethnicity);
		// console.log(ethnicityGroup);
		const skinConcernGroup = createGroup("skin_concerns", skin_concerns);
		// console.log(skinConcernGroup);
		const skinTypeGroup = createGroup("skin_type", skin_type);
		// console.log(skinConcernGroup);
		const genderGroup = createGroup("gender", gender);
		// console.log(skinConcernGroup);
		const wdyhauGroup = createGroup("wdyhau", wdyhau);
		// console.log(skinConcernGroup);

		let groups = [
			{
				name: "skin concerns",
				data: skinConcernGroup,
			},
			{
				name: "skin type",
				data: skinTypeGroup,
			},
			{
				name: "ethnicity",
				data: ethnicityGroup,
			},
			{
				name: "gender",
				data: genderGroup,
			},
			{
				name: "where did you hear about us",
				data: wdyhauGroup,
			},
		];

		await this.setState({
			csv: result,
			groups: groups,
		});

		//
	}

	exportExcel = () => {
		const array = this.state.csv;

		let today = moment().format("DD MMM YYYY");
		var str = this.state.selectedBrand;
		var name = str.split(" ").join("_");
		let date = today.split(" ").join("_");
		let document_name = "cris_" + name + "_" + date + ".xlsx";
		var ws = XLSX.utils.json_to_sheet(array);
		var wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Prova");
		const wbout = XLSX.write(wb, { type: "binary", bookType: "xlsx" });
		const exportExcel = XLSX.writeFile(wb, document_name);
	};

	render() {
		return (
			<View
				style={{
					height: this.props.screenHeight,
					paddingBottom: 60,
				}}>
				<ScrollView>
					<View
						style={{
							width: "100%",
							padding: 10,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontSize: 18,
							}}>
							{this.state.mainFilterField} pivots
						</Text>
						<Text
							style={{
								fontSize: 12,
								opacity: 0.6,
							}}>
							Sales filter per gender
						</Text>

						{this.state.csv.length > 0 ? (
							<TouchableOpacity
								onPress={async () => this.exportExcel(this.state.csv)}>
								<View
									style={{
										width: "100%",
										backgroundColor: "green",
										padding: 9,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 10,
										marginTop: 10,
									}}>
									<Text
										style={{
											color: "white",
											fontSize: 15,
										}}>
										create filtered csv
									</Text>
								</View>
							</TouchableOpacity>
						) : null}
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Genders
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj
									.sort((a, b) => a.value < b.value)
									.map((d, i) => (
										<View
											style={{
												justifyContent: "center",
												padding: 5,
											}}>
											<View
												style={{
													flexDirection: "row",
													alignItems: "center",
												}}>
												<View
													style={{
														width: 14,
														height: 14,
														borderRadius: 4,
														backgroundColor: d.color,
														marginRight: 5,
													}}
												/>
												<Text
													style={{
														fontWeight: "600",
														width: 200,
													}}>
													{d.title}:
												</Text>
												<Text
													style={{
														fontWeight: "300",
														width: 40,
													}}>
													{d.value}
												</Text>
												<Text
													style={{
														fontWeight: "300",
														width: 60,
													}}>
													{d.percent * 100} %
												</Text>
											</View>
										</View>
									))}
							</View>

							<View
								style={{
									height: 50,
									width: 50,
								}}>
								<PieChart data={this.state.genderObj} />
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Genders
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.gender
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Age ranges
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.ageRanges
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Ethnicity
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.ethnicity
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Brands
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.brands
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Products
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.perProduc
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Sales, samples, services & other
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.sssc
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Skin type
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.skin_type
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Skin concern
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.skin_concern
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Retailer
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.retailer
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Retailer
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.mall
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Sub brand
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.sub_brand
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							padding: 15,
							borderBottomWidth: 0.5,
							backgroundColor: "#FFF",
							borderRadius: 12,
							marginBottom: 10,
						}}>
						<Text
							style={{
								fontWeight: "600",
								marginBottom: 10,
							}}>
							Product category
						</Text>
						<View
							style={{
								borderWidth: 0.5,
								borderRadius: 5,
								padding: 10,
								flexDirection: "row",
							}}>
							<View style={{}}>
								{this.state.genderObj.map((d, i) => (
									<View
										style={{
											justifyContent: "center",
											padding: 5,
										}}>
										<View style={{}}>
											<Text
												style={{
													fontWeight: "600",
													width: 200,
												}}>
												{d.title}:
											</Text>
											{d.category
												.sort((a, b) => a.count < b.count)
												.map((dd, ii) => (
													<View
														key={ii}
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																fontWeight: "300",
																width: 400,
																marginLeft: 9,
																fontSize: 11,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																}}>
																{dd.count}
															</Text>{" "}
															- {dd.title}
														</Text>
														<View>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: "#0468FC",
																	position: "absolute",
																	opacity: 0.3,
																}}
															/>
															<View
																style={{
																	width: 16 * dd.count,
																	height: 16,
																	borderRadius: 4,
																	backgroundColor: `rgba(4, 104, 255, ${dd.percent})`,
																}}
															/>
														</View>
														<Text
															style={{
																fontWeight: "300",
																marginLeft: 9,
															}}>
															<Text
																style={{
																	fontWeight: "700",
																	fontSize: 12,
																}}>
																{dd.percent * 100}%
															</Text>
														</Text>
													</View>
												))}
										</View>
									</View>
								))}
							</View>
						</View>
					</View>
				</ScrollView>
			</View>
		);
	}
}

export default ExcelToJson;
