import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ActivityIndicator,
	ScrollView,
	Modal,
} from "react-native";
import Font from "react-font";
import Fade from "react-reveal/Fade";
import moment from "moment";
import logo from "../assets/images/tetrice_logo.png";
import wfh from "../assets/images/workfromhome.png";
import newProduct from "../assets/images/newProduct.png";
import staff from "../assets/images/staff.png";
import generalQuery from "../assets/images/generalQuery.png";
import deviceQuery from "../assets/images/deviceQuery.png";
import sideMenu from "../assets/images/sideMenu.svg";
import { api_userqueries, api_admin_managerqueryaddchat } from "../Api";
import { APP_COLOURS } from "../APP_VARS";
import * as animationData from "../assets/lottie/lf30_editor_gqidrmge.json";

// import NewQuery from "./newQuery";
import CurrentQuery from "./CurrentQuery";
import NewQueryModal from "./NewQueryModal";

// const this.props.screenWidth = Dimensions.get("window").width;
// const this.props.screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			secondsCountdown: 10,
			page: "main",
			// page: "newQuery",
			ticketRef: "",
			emailValid: false,
			checkingRVP: false,
			notInvited: false,
			rsvpHasBeenSubmitted: false,

			screenWidth: null,
			screenHeight: null,
			tabs: "New Query",
			queryFilter: "",
			queryTypeSelected: "",
			showQueries: true,
			queries: [
				// {
				// 	title: 'New product',
				// 	status: 'New message',
				// 	date_created: '2021 04 16',
				// 	resolved_date: '',
				// 	id: '123',
				// },
				// {
				// 	title: 'New BA',
				// 	status: 'New message',
				// 	date_created: '2020 09 13',
				// 	resolved_date: '',
				// 	id: '1234',
				// },
				// {
				// 	title: 'General Query',
				// 	status: 'New message',
				// 	date_created: '2021 04 16',
				// 	resolved_date: '',
				// 	id: '123',
				// },
				// {
				// 	title: 'New BA',
				// 	status: 'Resolved',
				// 	date_created: '2020 09 13',
				// 	resolved_date: '',
				// 	id: '123',
				// },
				// {
				// 	title: 'New product',
				// 	status: 'Resolved',
				// 	date_created: '2021 04 16',
				// 	resolved_date: '',
				// 	id: '123',
				// },
				// {
				// 	title: 'New BA',
				// 	status: 'Resolved',
				// 	date_created: '2020 09 13',
				// 	resolved_date: '',
				// 	id: '123',
				// },
				// {
				// 	title: 'New product',
				// 	status: 'Resolved',
				// 	date_created: '2021 04 16',
				// 	resolved_date: '',
				// 	id: '123',
				// },
			],
			userid: "",
			full_name: "",
			queryTypes: [
				// {
				// 	title: "General dashboard query",
				// 	image: newProduct,
				// 	category: "general",
				// 	formElements: [
				// 		{
				// 			title: "Product name",
				// 			state: "productName",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Barcode",
				// 			state: "barcode",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Main Brand",
				// 			state: "mainbrand",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Sub Brand",
				// 			state: "subbrand",
				// 			required: false,
				// 		},
				// 		{
				// 			title: "Price",
				// 			state: "price",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Special instructions ",
				// 			state: "description",
				// 			required: false,
				// 		},
				// 	],
				// },
				// {
				// 	title: "Product update",
				// 	image: newProduct,
				// 	category: "product",
				// 	formElements: [
				// 		{
				// 			title: "Current product name",
				// 			state: "productName",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Current barcode",
				// 			state: "barcode",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Please describe the changes in as much details as possible ",
				// 			state: "description",
				// 			required: true,
				// 		},
				// 	],
				// },
				// {
				// 	title: "New staff profile",
				// 	image: staff,
				// 	category: "staff",
				// 	formElements: [
				// 		{
				// 			title: "Employee ID",
				// 			state: "employeeID",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Employee Full Name",
				// 			state: "full_name",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Position",
				// 			state: "position_",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Brand",
				// 			state: "brand",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Mall",
				// 			state: "mall",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Retailer",
				// 			state: "retailer",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Area manager name",
				// 			state: "areamanager",
				// 			required: true,
				// 		},
				// 	],
				// },
				{
					title: "Staff query - TRAQ",
					image: staff,
					category: "staff",
					formElements: [
						{
							title: "STAFF ID / employee ID",
							state: "id_passport",
							required: true,
						},

						{
							title: "Please describe the query in as much details as possible ",
							state: "description",
							required: true,
						},
					],
				},
				{
					title: "App query - TRAQ",
					image: deviceQuery,
					category: "staff",
					formElements: [
						{
							title: "Please describe the query in as much details as possible ",
							state: "description",
							required: true,
						},
					],
				},
				{
					title: "Account query - TRAQ",
					image: newProduct,
					category: "staff",
					formElements: [
						{
							title: "Please describe the query in as much details as possible ",
							state: "description",
							required: true,
						},
					],
				},
				// {
				// 	title: "Admin Portal - SAMA",
				// 	image: staff,
				// 	category: "staff",
				// 	formElements: [
				// 		// {
				// 		// 	title: "Employee ID",
				// 		// 	state: "employeeID",
				// 		// 	required: true,
				// 		// },
				// 		// {
				// 		// 	title: "Employee Full Name",
				// 		// 	state: "full_name",
				// 		// 	required: true,
				// 		// },
				// 		{
				// 			title: "Please describe the query in as much details as possible ",
				// 			state: "description",
				// 			required: true,
				// 		},
				// 	],
				// },
				// {
				// 	title: "Productivity sheet changes",
				// 	image: generalQuery,
				// 	category: "productivity sheet",
				// 	formElements: [
				// 		{
				// 			title: "Employee ID",
				// 			state: "employeeID",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Employee Full Name",
				// 			state: "full_name",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Retailer month",
				// 			state: "retailerMonth",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Please describe the changes in as much details as possible ",
				// 			state: "description",
				// 			required: true,
				// 		},
				// 	],
				// },
				// {
				// 	title: "Productivity sheet query",
				// 	image: generalQuery,
				// 	category: "productivity sheet",
				// 	formElements: [
				// 		{
				// 			title: "Employee ID",
				// 			state: "employeeID",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Employee Full Name",
				// 			state: "full_name",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Retailer month",
				// 			state: "retailerMonth",
				// 			required: true,
				// 		},
				// 		{
				// 			title: "Please describe the changes in as much details as possible ",
				// 			state: "description",
				// 			required: true,
				// 		},
				// 	],
				// },
				// {
				// 	title: "Access Control Issue - TRAQ",
				// 	image: generalQuery,
				// 	category: "general",
				// 	formElements: [
				// 		{
				// 			title: "Please describe your query in as much details as possible ",
				// 			state: "description",
				// 			required: true,
				// 		},
				// 	],
				// },
				{
					title: "General query - TRAQ",
					image: generalQuery,
					category: "general",
					formElements: [
						{
							title: "Please describe your query in as much details as possible ",
							state: "description",
							required: true,
						},
					],
				},
				// {
				// 	title: "Device: registration",
				// 	image: deviceQuery,
				// 	category: "device",
				// 	formElements: [
				// 		{
				// 			title: "Please note, devices can only be registered within the app. ",
				// 			state: "note",
				// 			required: true,
				// 		},
				// 	],
				// },
				// {
				// 	title: "Device: Connectivity",
				// 	image: deviceQuery,
				// 	category: "device",
				// 	formElements: [
				// 		{
				// 			title: "Please describe your query in as much details as possible ",
				// 			state: "description",
				// 			required: true,
				// 		},
				// 	],
				// },
			],
			queryCategories: [
				{
					category: "product",
				},
				{
					category: "staff",
				},
				{
					category: "device",
				},
				{
					category: "productivity sheet",
				},
				{
					category: "general",
				},
			],

			currentQuery: "",
		};

		this.currentQueryChild = React.createRef();
		this._timeout = 500;
	}

	async componentDidMount() {
		await this.setState({
			full_name: `${this.props.user_data.first_name} ${this.props.user_data.last_name}`,
			userid: this.props.user_data.username,
		});

		await console.log(this.props.user_data);
		await this.getAllQueries(this.props.user_data.username);
	}

	newTicket = () => {
		this.setState({
			page: "newQuery",
		});
		console.log("new ticket");
	};

	nextButton = async () => {
		this.setState({
			checkingRVP: true,
		});
		await sessionStorage.setItem("authed", this.state.weddingCode);
		this.props.history.push("/cristechsupport/");
		// setTimeout(() => this.checkRSVP(), 3000);
	};

	logOut = async () => {
		this.props.history.push("/cristechsupport/");
		sessionStorage.clear();
	};

	getAllQueries = async (userid) => {
		let obj = {
			userid: userid,
		};

		// return;

		console.log("getAllQueries");

		this.setState({
			loading: true,
		});

		await fetch(api_userqueries, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
			},
			body: JSON.stringify(obj),
			redirect: "follow",
		})
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log(
								"=============================",
								responseData,
							);

							await this.setState({
								queries: responseData,
								loading: false,
							});

							if (this.state.currentQuery !== "") {
								let data = responseData.filter(
									(d) => d.id === this.state.currentQuery.id,
								);

								await this.setState({
									currentQuery: data[0],
								});
							}
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else {
					this.setState({
						errorLogin: true,
						loading: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});

		return;
	};

	sendMessage = async (docID, chatData) => {
		await this.setState({
			loading: true,
			sendingMessage: true,
		});
		await fetch(api_admin_managerqueryaddchat, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
			},
			body: JSON.stringify({
				jwt: "1234567",
				docID: docID,
				chatData: chatData,
			}),
		})
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							this.setState({
								currentQuery: responseData,
								sendingMessage: false,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else {
					this.setState({
						errorLogin: true,
						loading: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
			});

		clearTimeout(this._timeout);
		this._timeout = setTimeout(async () => {
			await this.currentQueryChild.current.triggerTimer();
		}, 500);
	};

	// componentDidUpdate(prevProps, prevState) {
	// 	if (prevState.secondsCountdown == 1) {
	// 		console.log("fetch messages");

	// 		this.getAllQueries(this.props.user_data.username);
	// 	}
	// }

	// componentWillUnmount() {
	// 	clearInterval(this.interval);
	// }

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let fontColor = "#525878";
		let style = {
			backgroundColor: APP_COLOURS.SECONDARY,
			borderRadius: 15,
			padding: 15,
			marginRight: 10,
			marginBottom: 10,
			shadowColor: "#D8D8D8",
			shadowOffset: { width: 0, height: 8 },
			shadowOpacity: 0.44,
			shadowRadius: 9.32,
			elevation: 10,
			opacity: 0.9,
		};

		return (
			<div
				style={{
					height: this.props.screenHeight,
					width: this.props.screenWidth,
					// backgroundColor: APP_COLOURS.BACKGROUND,
					overflow: "hidden",
					borderRadius: 18,
					backgroundColor: "#D1DEE7",
				}}>
				<Image
					source={wfh}
					style={{
						resizeMode: "contain",
						width: 150,
						height: 220,
						resizeMode: "contain",
						position: "absolute",
						bottom: 0,
						right: 20,
						// opacity: 0.8,
					}}
				/>

				<View
					style={{
						alignItems:
							this.props.screenWidth > 600 ? "center" : "flex-start",
						// padding: 1,
						width: "100%",
						marginLeft: "auto",
						marginRight: "auto",
						height: this.props.screenHeight,
					}}>
					<View
						style={[
							style,
							{
								flexDirection: "row",
								justifyContent: "space-between",
								width: "100%",
								marginBottom: 0,
								height: 90,
								alignItems: "center",
								backgroundColor: "#141D31",
								borderRadius: 0,
							},
						]}>
						<View
							style={{
								flexDirection: "row",
								width: 160,
								// marginLeft: 10,
							}}>
							<Image
								source={logo}
								style={{
									width: 35,
									height: 35,
									resizeMode: "contain",
								}}
							/>
							<View
								style={{
									// width: "100%",
									marginLeft: 7,
									justifyContent: "flex-start",
									alignItems: "flex-start",
									top: 4,
								}}>
								<Text
									style={{
										color: APP_COLOURS.SECONDARY,
										fontSize: 15,
										opacity: 0.9,
									}}>
									<Font weight={900} family='Poppins'>
										tetrice
									</Font>
								</Text>

								<Text
									style={{
										color: APP_COLOURS.SECONDARY,
										fontSize: 7,
										top: 20,
										position: "absolute",
										opacity: 0.9,
									}}>
									<Font weight={500} family='Montserrat'>
										tech support
									</Font>
								</Text>
							</View>
						</View>

						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "space-between",
								flexDirection: "row",
							}}>
							{/* <View
								style={{
									padding: 5,
									flex: 1,
								}}>
								<Text
									style={{
										color: APP_COLOURS.FONT_PRIMAR,
										fontSize: 12,
									}}>
									<Font weight={300} family='Poppins'>
										{this.props.user_data.first_name}{" "}
										{this.props.user_data.last_name}
									</Font>
								</Text>
								<Text
									style={{
										color: APP_COLOURS.FONT_PRIMAR,
										fontSize: 12,
									}}>
									<Font weight={300} family='Poppins'>
										{this.props.user_data.username}
									</Font>
								</Text>
							</View> */}

							<View
								style={{
									padding: 5,
									flex: 1,
									opacity: 0.9,
								}}>
								<Text
									style={{
										color: APP_COLOURS.SECONDARY,
										fontSize: 10,
									}}>
									<Font weight={500} family='Poppins'>
										Tech support operating hours:
									</Font>
								</Text>
								<Text
									style={{
										color: APP_COLOURS.SECONDARY,
										fontSize: 10,
									}}>
									<Font weight={300} family='Poppins'>
										Monday - Friday
									</Font>
								</Text>
								<Text
									style={{
										color: APP_COLOURS.SECONDARY,
										fontSize: 10,
									}}>
									<Font weight={300} family='Poppins'>
										07:00 - 20:00
									</Font>
								</Text>
							</View>
							<View
								style={{
									padding: 5,
									flex: 1,
									opacity: 0.9,
								}}>
								<Text
									style={{
										color: APP_COLOURS.SECONDARY,
										fontSize: 10,
									}}>
									<Font weight={500} family='Poppins'>
										After hours / Emergancy tech support:
									</Font>
								</Text>
								<Text
									style={{
										color: APP_COLOURS.SECONDARY,
										fontSize: 10,
									}}>
									<Font weight={300} family='Poppins'>
										call: 081 250 2054
									</Font>
								</Text>
								<Text
									style={{
										color: APP_COLOURS.SECONDARY,
										fontSize: 10,
									}}>
									<Font weight={300} family='Poppins'>
										email: support@tetrice.co.za
									</Font>
								</Text>
							</View>
						</View>
					</View>

					<View
						style={{
							// flex: 1,
							width: "100%",
							flexDirection: "row",
							height: this.props.screenHeight - 80,
							padding: 10,
						}}>
						{/* {this.state.showQueries ? ( */}
						<View
							style={[
								style,
								{
									// flex: 1,
									width: "100%",
									maxWidth: 270,
									padding: 5,
									position: "absolute",
									left: this.state.showQueries ? 0 : -270,
									top: 0,
									flexDirection: "row",
									zIndex: 999,
									opacity: 1,
									borderTopLeftRadius: 0,
								},
							]}>
							<View
								style={{
									width: "100%",
									marginRight: 5,
								}}>
								<TouchableOpacity
									onPress={() => {
										this.setState({
											page: "queryTypes",
											showQueries: false,
										});
									}}>
									<View
										style={{
											padding: 10,
											justifyContent: "center",
											alignItems: "center",
											backgroundColor: APP_COLOURS.BLUE,
											marginBottom: 15,
											borderRadius: 13,
											// opacity: 0.9,
										}}>
										<Text
											style={{
												color: APP_COLOURS.SECONDARY,
												fontSize: 10,
											}}>
											<Font weight={600} family='Montserrat'>
												+ New query
											</Font>
										</Text>
									</View>
								</TouchableOpacity>
								<View
									style={{
										width: "100%",
									}}>
									<View
										style={{
											height: 20,
											paddingTop: 10,
											marginBottom: 10,
											// borderTopWidth: 0.5,

											flexDirection: "row",
											alignItems: "center",
											justifyContent: "space-between",
										}}>
										<Text
											style={{
												color: "#797979",
												fontSize: 8,
												fontWeight: "300",
											}}>
											<Font weight={600} family='Montserrat'>
												Pending queries
											</Font>
										</Text>

										{this.state.loading ? (
											<ActivityIndicator
												color={APP_COLOURS.PRIMARY}
											/>
										) : null}
									</View>

									{this.state.queries
										.sort(
											(a, b) =>
												new Date(b.date_created) >
												new Date(a.date_created),
										)
										.filter((d_) => d_.status !== "Resolved")
										.map((d, i) => (
											<TouchableOpacity
												key={i}
												onPress={async () => {
													await this.setState({
														page: "",
														currentQuery: d,
													});

													await this.setState({
														page: "currentQuery",
														currentQuery: d,
														showQueries: false,
													});
												}}
												style={{
													width: "100%",
													height: "auto",
													marginBottom: 5,
													borderTopWidth: 0.5,
													borderTopColor: "#E8E8E8",
													// borderRadius: 10,
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "space-between",
													padding: 7,
												}}>
												<View
													style={{
														flex: 1,
													}}>
													<Text
														style={{
															color: APP_COLOURS.FONT_PRIMAR,
															fontSize: 9,
														}}>
														<Font
															weight={600}
															family='Montserrat'>
															{d.queryCategory}
														</Font>
													</Text>
													<View
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																color: "#797979",
																fontSize: 7,
																fontWeight: "300",
																marginRight: 15,
															}}>
															<Font
																weight={400}
																family='Montserrat'>
																{moment(
																	new Date(d.date_created),
																).format("MMM DD, YYYY")}
															</Font>
														</Text>
														<Text
															style={{
																color: "#797979",
																fontSize: 7,
																fontWeight: "300",
																fontStyle: "italic",
															}}>
															<Font
																weight={400}
																family='Montserrat'>
																{moment(
																	new Date(d.date_created),
																).format("HH:mm")}
															</Font>
														</Text>
													</View>
												</View>

												<View
													style={{
														width: "auto",
														// alignItems:
														// 	'center',
														flexDirection: "row",
														justifyContent: "flex-end",
													}}>
													{d.newMessageForClient ? (
														<View
															style={{
																// width: 20,
																alignItems: "center",
																flexDirection: "row",
															}}>
															<View
																style={{
																	backgroundColor: "red",
																	width: 18,
																	height: 18,
																	borderRadius: 10,
																	justifyContent: "center",
																	alignItems: "center",
																	padding: 3,
																	marginRight: 8,
																}}>
																<Text
																	adjustsFontSizeToFit
																	style={{
																		color: "#FFF",
																		fontSize: 7,

																		fontWeight: "700",
																	}}>
																	1
																</Text>
															</View>
															<Text
																adjustsFontSizeToFit
																style={{
																	color: APP_COLOURS.PRIMARY,
																	fontSize: 7,

																	fontWeight: "700",
																}}>
																New message
															</Text>
														</View>
													) : (
														<View
															style={{
																width: 20,
																alignItems: "center",
															}}>
															<View
																style={{
																	backgroundColor:
																		d.status == "Resolved"
																			? "#00FF74"
																			: d.status == "Pending"
																			? "red"
																			: "#FFA400",
																	width: 14,
																	height: 14,
																	borderRadius: 7,
																	justifyContent: "center",
																	alignItems: "center",
																	padding: 3,
																	marginRight: 8,
																}}>
																<Text
																	adjustsFontSizeToFit
																	style={{
																		color: "#FFF",
																		fontSize: 10,

																		fontWeight: "700",
																	}}></Text>
															</View>
														</View>
													)}
												</View>
											</TouchableOpacity>
										))}

									<View
										style={{
											height: 20,
											paddingTop: 10,
											marginBottom: 10,
											// borderTopWidth: 0.5,
											marginTop: 20,
										}}>
										<Text
											style={{
												color: "#797979",
												fontSize: 8,
												fontWeight: "300",
											}}>
											<Font weight={600} family='Montserrat'>
												Resolved queries
											</Font>
										</Text>
									</View>

									{this.state.queries
										.sort(
											(a, b) =>
												new Date(b.date_created) >
												new Date(a.date_created),
										)
										.filter((d_) => d_.status == "Resolved")
										.map((d, i) => (
											<TouchableOpacity
												key={i}
												onPress={async () => {
													await this.setState({
														page: "",
														currentQuery: d,
													});

													await this.setState({
														page: "currentQuery",
														currentQuery: d,
														showQueries: false,
													});
												}}
												style={{
													width: "100%",
													height: "auto",
													marginBottom: 5,
													borderTopWidth: 0.5,
													borderTopColor: "#E8E8E8",

													// borderRadius: 10,
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "space-between",
													padding: 7,
												}}>
												<View
													style={{
														flex: 1,
													}}>
													<Text
														style={{
															color: APP_COLOURS.FONT_PRIMAR,
															fontSize: 9,
														}}>
														<Font
															weight={600}
															family='Montserrat'>
															{d.queryCategory}
														</Font>
													</Text>
													<View
														style={{
															flexDirection: "row",
														}}>
														<Text
															style={{
																color: "#797979",
																fontSize: 7,
																fontWeight: "300",
																marginRight: 15,
															}}>
															<Font
																weight={400}
																family='Montserrat'>
																{moment(
																	new Date(d.date_created),
																).format("MMM DD, YYYY")}
															</Font>
														</Text>
														<Text
															style={{
																color: "#797979",
																fontSize: 7,
																fontWeight: "300",
																fontStyle: "italic",
															}}>
															<Font
																weight={400}
																family='Montserrat'>
																{moment(
																	new Date(d.date_created),
																).format("HH:mm")}
															</Font>
														</Text>
													</View>
												</View>

												<View
													style={{
														width: 70,
														alignItems: "flex-end",
														justifyContent: "flex-end",
														flexDirection: "row",
													}}>
													<View
														style={{
															justifyContent: "flex-end",
															alignItems: "flex-end",
														}}>
														<Text
															style={{
																color: APP_COLOURS.FONT_PRIMAR,
																fontSize: 7,
																textAlign: "right",
															}}>
															<Font
																weight={600}
																family='Montserrat'>
																{d.status}
															</Font>
														</Text>
													</View>
													{/* <View
														style={{
															backgroundColor: "#00FF74",
															width: 14,
															height: 14,
															borderRadius: 7,
															justifyContent: "center",
															alignItems: "center",
															padding: 3,
															marginLeft: 8,
														}}>
														<Text
															adjustsFontSizeToFit
															style={{
																color: "#FFF",
																fontSize: 7,
															}}></Text>
													</View> */}
												</View>
											</TouchableOpacity>
										))}
								</View>
							</View>
							<TouchableOpacity
								onPress={() =>
									this.setState({
										showQueries: !this.state.showQueries,
									})
								}>
								<View
									style={{
										height: this.props.screenHeight - 100,
										justifyContent: "center",
										alignItems: "center",
										width: 24,
									}}>
									<Image
										source={sideMenu}
										style={{
											width: 24,
											height: 265,
											resizeMode: "contain",
										}}
									/>
								</View>
							</TouchableOpacity>
						</View>

						{this.state.showQueries ? (
							<View
								style={{
									width: this.props.screenWidth,
									height: this.props.screenHeight,
									zIndex: 998,
									position: "absolute",
									top: 0,
								}}>
								<Fade>
									<TouchableOpacity
										onPress={() =>
											this.setState({
												showQueries: false,
											})
										}>
										<View
											style={{
												// flex: 1,
												justifyContent: "center",
												alignItems: "center",
												backgroundColor: "rgba(52, 52, 52, 0.3)",
												width: this.props.screenWidth,
												height: this.props.screenHeight,
											}}></View>
									</TouchableOpacity>
								</Fade>
							</View>
						) : (
							<View />
						)}

						<View
							style={{
								flex: 1,
								paddingLeft: 35,
								zIndex: 997,
							}}>
							{this.state.page === "queryTypes" ? (
								<ScrollView>
									<View
										style={{
											paddingTop: 10,
											marginBottom: 25,
											borderTopWidth: 0.5,
											padding: 10,
										}}>
										<Text
											style={{
												color: APP_COLOURS.PRIMARY,
												fontSize: 12,
												fontWeight: "300",
											}}>
											<Font weight={600} family='Montserrat'>
												In order to open a new ticket, please select
												a possible category of query.
											</Font>
										</Text>
									</View>
									{this.state.queryTypes
										.filter((d_) => {
											if (this.state.queryFilter == "") {
												return d_;
											} else {
												return (
													d_.category == this.state.queryFilter
												);
											}
										})
										.map((d, i) => (
											<TouchableOpacity
												key={i}
												onPress={() =>
													this.setState({
														queryTypeSelected: d,
														page: "newQuery",
														formElements: d.formElements,
													})
												}
												style={{
													width: "100%",
													height: "auto",
													marginBottom: 10,
													borderRadius: 15,
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "space-between",
													padding: 8,
													backgroundColor: "#2F2F42",
												}}>
												<View
													style={{
														marginRight: 10,
														backgroundColor: "#FFF",
														borderRadius: 12,
														padding: 5,
													}}>
													<Image
														source={d.image}
														style={{
															width: 25,
															height: 25,
															resizeMode: "contain",
														}}
													/>
												</View>

												<View
													style={{
														flex: 1,
													}}>
													<Text
														style={{
															color: APP_COLOURS.SECONDARY,
															fontSize: 13,
														}}>
														<Font
															weight={600}
															family='Montserrat'>
															{d.title}
														</Font>
													</Text>
												</View>
											</TouchableOpacity>
										))}
								</ScrollView>
							) : null}

							<View
								style={{
									width: "100%",
									// justifyContent: "center",
									// alignItems: "center",
								}}>
								{this.state.page === "newQuery" ? (
									<NewQueryModal
										redirectBack={() => {
											this.getAllQueries(
												this.props.user_data.username,
											);
											this.setState({
												page: "main",
												showQueries: true,
											});
										}}
										quertyType={this.state.queryTypeSelected}
										userid={this.state.userid}
										full_name={this.state.full_name}
										formElements={this.state.formElements}
										currentQuery={this.state.currentQuery}
										screenWidth={this.props.screenWidth}
										screenHeight={this.props.screenHeight}
									/>
								) : (
									<View />
								)}
								{this.state.page === "currentQuery" ? (
									<CurrentQuery
										ref={this.currentQueryChild}
										redirectBack={() => {
											this.getAllQueries(
												this.props.user_data.username,
											);
											this.setState({
												page: "main",
												showQueries: true,
											});
										}}
										userid={this.state.userid}
										full_name={this.state.full_name}
										sendMessage={this.sendMessage.bind(this)}
										sendingMessage={this.state.sendingMessage}
										currentQuery={this.state.currentQuery}
										screenWidth={this.props.screenWidth}
										screenHeight={this.props.screenHeight}
										getChatData={async () => {
											clearTimeout(this._timeout);
											this._timeout = setTimeout(async () => {
												await this.getAllQueries(
													this.props.user_data.username,
												);

												await this.currentQueryChild.current.triggerTimer();
											}, 500);
										}}
									/>
								) : (
									<View />
								)}
							</View>
						</View>
					</View>
				</View>
			</div>
		);
	}
}

export default index;
