import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";

import LineChart from "./chart/index";
import female from "./../../../assets/images/female.png";
import traqQ from "../../../assets/images/logo512.png";
import geolocation from "../../../assets/images/geolocation.png";
import schedule from "../../../assets/images/schedule.png";
import skills from "../../../assets/images/skills.png";
import sales from "../../../assets/images/sales.png";
import tetrice_ds from "../../../assets/images/tetrice_dataservices.svg";
import Fade from "react-reveal/Fade";
import { APP_COLOURS, AVATARS } from "../../APP_VARS";
import BATargets from "./Profile/BATargets";
import BADocuments from "./Profile/BADocuments";
import BAProfile from "./Profile/BAProfile";
import BAKnowledgeBase from "./Profile/BAKnowlegdeBase";
import BACustomerRatings from "./Profile/BACustomerRatings";
import BAManagerRatings from "./Profile/BAManagerRatings";
import BAAttendance from "./Profile/BAAttendance";
import BASales from "./Profile/BASales";
import BAAttendanceTable from "./Profile/BAAttendanceTable";

export default class StaffProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: "Profile",
			salesReports: [
				{
					id: "1",
					title: "Jan 2022",
					start_date: "",
					end_date: "",
					sales_total: "12000.00",
					targets: [
						{
							id: "1",
							title: "ARMANI target",
							target_amount: 1000,
							qualifier_per: "0.9",
						},
					],
					sales: [
						{
							id: "47",
							user_id: "128",
							cus_id: "128_5cc16ef3078ef",

							product_id: "1416",
							product_name: "ARMANI CODE A-LIST EDT 75ml",
							product_brand: "DESIGNER FRAGRANCES ",
							product_sub_brand: "ARMANI",
							product_category: ["SKINCARE"],
							product_price: "1500.00",

							interaction_type: "sale",

							sale_receipt: "rec_128_5cc16f35c0237",
							sale_qty: "2",
							sale_discount: "0.00",
							sale_total: "3000.00",
							sale_location: {
								mall: "Sandton",
								retailer: "EDGARS",
							},
							customer_gender: "Male",
							customer_age_range: "Below18",
							customer_ethnicity: "Ethnic",
							customer_skin_type: "Dehydrated",
							customer_skin_concern: "Dry",
							date_added: "2019-10-16 08:44:44",
						},
					],
				},
				{
					id: "1",
					title: "Feb 2022",
					start_date: "",
					end_date: "",
					sales_total: "10000.00",
					targets: [
						{
							id: "1",
							title: "ARMANI target",
							target_amount: 1000,
							qualifier_per: "0.9",
						},
					],
					sales: [
						{
							id: "47",
							user_id: "128",
							cus_id: "128_5cc16ef3078ef",

							product_id: "1416",
							product_name: "ARMANI CODE A-LIST EDT 75ml",
							product_brand: "DESIGNER FRAGRANCES ",
							product_sub_brand: "ARMANI",
							product_category: ["SKINCARE"],
							product_price: "1500.00",

							interaction_type: "sale",

							sale_receipt: "rec_128_5cc16f35c0237",
							sale_qty: "2",
							sale_discount: "0.00",
							sale_total: "3000.00",
							sale_location: {
								mall: "Sandton",
								retailer: "EDGARS",
							},
							customer_gender: "Male",
							customer_age_range: "Below18",
							customer_ethnicity: "Ethnic",
							customer_skin_type: "Dehydrated",
							customer_skin_concern: "Dry",
							date_added: "2019-10-16 08:44:44",
						},
					],
				},
				{
					id: "1",
					title: "Mar 2022",
					start_date: "",
					end_date: "",
					sales_total: "15000.00",
					targets: [
						{
							id: "1",
							title: "ARMANI target",
							target_amount: 1000,
							qualifier_per: "0.9",
						},
					],
					sales: [
						{
							id: "47",
							user_id: "128",
							cus_id: "128_5cc16ef3078ef",

							product_id: "1416",
							product_name: "ARMANI CODE A-LIST EDT 75ml",
							product_brand: "DESIGNER FRAGRANCES ",
							product_sub_brand: "ARMANI",
							product_category: ["SKINCARE"],
							product_price: "1500.00",

							interaction_type: "sale",

							sale_receipt: "rec_128_5cc16f35c0237",
							sale_qty: "2",
							sale_discount: "0.00",
							sale_total: "3000.00",
							sale_location: {
								mall: "Sandton",
								retailer: "EDGARS",
							},
							customer_gender: "Male",
							customer_age_range: "Below18",
							customer_ethnicity: "Ethnic",
							customer_skin_type: "Dehydrated",
							customer_skin_concern: "Dry",
							date_added: "2019-10-16 08:44:44",
						},
					],
				},
			],
			tabs: [
				"Profile",
				"Sales",
				"Attendance",
				"Schedule",
				"Skills",
				"Geolocation",
			],
		};
	}

	componentDidMount() {
		setTimeout(
			() =>
				this.setState({
					pageLoaded: true,
				}),
			1000,
		);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.userData !== this.props.userData) {
			this.setState({
				pageLoaded: false,
			});
			setTimeout(
				() =>
					this.setState({
						pageLoaded: true,
					}),
				1000,
			);
		}
	}

	render() {
		let d = this.props.userData;
		if (!this.state.pageLoaded) {
			return this.loadingComponent();
		} else
			return (
				<View
					style={{
						flex: 1,
					}}>
					<View
						style={{
							height: "auto",
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "flex-end",
							marginBottom: 5,
							// marginTop: 20,
						}}>
						<ScrollView horizontal showsHorizontalScrollIndicator={false}>
							{/* <TouchableOpacity
								onPress={async () => {
									await this.setState({
										tab: "Profile",
									});
								}}>
								<View style={{ flexDirection: "row" }}>
									<Image
										// source={female}
										source={AVATARS[d.avatar].pp}
										style={{
											width: 90,
											height: 90,
											borderRadius: 25,
											resizeMode: "contain",
											backgroundColor: "#FFF",
											borderWidth: 0.3,
											borderColor: "#C5C5C5",
											// shadowColor: "#797979",
											// shadowOffset: {
											// 	width: 0,
											// 	height: 8,
											// },
											// shadowOpacity: 0.44,
											// shadowRadius: 10.32,
											// elevation: 10,
											marginRight: 5,
										}}
									/>

									<View
										style={{
											paddingTop: 5,
										}}>
										<Text
											style={{
												width: 180,
												fontSize: 17,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "600",
											}}>
											{d.first_name} {d.last_name}
										</Text>

										<Text
											style={{
												width: 200,
												fontSize: 9,
												fontWeight: "300",
												color: APP_COLOURS.TEXTCOLOR,
												opacity: 0.6,
												marginTop: 6,
											}}>
											{d.permission}:
										</Text>
										<Text
											style={{
												width: 200,
												fontSize: 12,
												fontWeight: "300",
												color: APP_COLOURS.TEXTCOLOR,
												opacity: 0.9,
											}}>
											{d.position}
										</Text>
									</View>
								</View>
							</TouchableOpacity> */}

							{this.state.tabs.map((d, i) => (
								<TouchableOpacity
									onPress={async () => {
										await this.setState({
											tab: d,
										});
									}}>
									<View
										style={{
											height: "80%",
											borderRadius: 5,
											width: 3,
											marginTop: "5%",
											backgroundColor:
												this.state.tab === d
													? APP_COLOURS.BLUE
													: "transparent",
											position: "absolute",
											left: 3,
										}}
									/>
									<View
										style={{
											backgroundColor:
												this.state.tab === d
													? APP_COLOURS.BLUE
													: APP_COLOURS.BLACK,
											borderWidth: 2,
											borderColor:
												this.state.tab === d
													? APP_COLOURS.BLUE
													: APP_COLOURS.PLACEHOLDER,
											marginRight: 5,
											borderRadius: 10,
											padding: 10,
											// justifyContent: "center",
											alignItems: "center",
											minWidth: 150,
											flexDirection: "row",
											paddingTop: 50,
											marginLeft: 10,
										}}>
										{/* <Image
											source={traqQ}
											style={{
												width: 18,
												height: 18,
												resizeMode: "contain",
												marginRight: 5,
												opacity: this.state.tab === d ? 1 : 0,
											}}
										/> */}

										<Text
											style={{
												fontSize: 14,
												fontWeight: "900",
												// this.state.tab === d ? "900" : "300",
												color: "#FFF",
												marginRight: 15,
												fontFamily: "Avenir",
											}}>
											{d}
										</Text>
									</View>
								</TouchableOpacity>
							))}
						</ScrollView>
					</View>
					<ScrollView
						contentContainerStyle={{
							flex: 1,
						}}>
						<View style={{}}>
							{this.state.tab === "Sales2" ? (
								<View style={{ marginTop: 20 }}>
									<View
										style={
											{
												// height: 150,
												// backgroundColor: "#FFF",
											}
										}>
										<LineChart
											// data={this.state.salesReports.map((d) =>
											// 	parseFloat(d.sales_total),
											// )}
											data={[
												1, 2, 4, 5, 3, 4, 5, 2, 3, 5, 6, 7, 5, 3, 2,
												4,
											]}
										/>
									</View>

									<Text
										style={{
											fontSize: 9,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 2,
											opacity: 0.6,
											width: "100%",
											borderBottomColor: "#797979",
											borderBottomWidth: 0.5,
											paddingBottom: 10,
											marginTop: 5,
										}}>
										Sales reports
									</Text>

									{this.state.salesReports.map((rep, i) => (
										<View
											key={i}
											style={{
												width: "100%",
												borderBottomColor: "#797979",
												borderBottomWidth: 0.5,
												paddingBottom: 5,
												paddingTop: 5,
												flexDirection: "row",
												alignItems: "center",
											}}>
											<Text
												style={{
													width: 200,
													fontSize: 14,
													fontWeight: "300",
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												{rep.title}
											</Text>

											<View
												style={{
													width: 200,
												}}>
												<Text
													style={{
														fontSize: 12,
														color: APP_COLOURS.TEXTCOLOR,
													}}>
													{rep.start_date}
												</Text>
												<Text
													style={{
														fontSize: 12,
														color: APP_COLOURS.TEXTCOLOR,
													}}>
													{rep.end_date}
												</Text>
											</View>

											<View>
												<TouchableOpacity
													style={{
														// width: 130,
														height: "auto",
														alignItems: "center",
														borderRadius: 10,
														marginRight: 10,
														height: "auto",
														backgroundColor: APP_COLOURS.BLUE,
														flexDirection: "row",
													}}
													onPress={() => {
														this.setState({
															viewReport: true,
														});
													}}>
													<View
														style={{
															flex: 1,
															flexWrap: "wrap",
															padding: 12,
															borderRadius: 10,
														}}>
														<Text
															numberOfLines={2}
															style={{
																fontFamily: "Avenir",
																fontSize: 13,
																// opacity: 0.8,
																flex: 1,
																flexWrap: "wrap",
																color: APP_COLOURS.WHITE,
															}}>
															View
														</Text>
													</View>
												</TouchableOpacity>
											</View>
										</View>
									))}
								</View>
							) : null}
							{this.state.tab === "Profile" ? (
								<View
									style={{
										flex: 1,
										width: "100%",
										maxWidth: 1400,
										alignSelf: "center",
									}}>
									<BAProfile data={d} />
									<BAKnowledgeBase data={d} />
									<BASales data={d} />
									<BACustomerRatings data={d} />
									<BAManagerRatings data={d} />
									<BAAttendance data={d} />
									{/* <BATargets /> */}
									{/* <BADocuments /> */}
									<View
										style={{
											alignSelf: "flex-end",
											// position: "absolute",
											// right: 0,
											// bottom: 0,
											marginTop: 50,
										}}>
										<Image
											source={tetrice_ds}
											style={{
												width: 190,
												height: 70,
												resizeMode: "contain",
											}}
										/>
									</View>
								</View>
							) : null}
							{this.state.tab === "Sales" ? (
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										// backgroundColor: APP_COLOURS.WHITE,
										// borderRadius: 10,
										paddingTop: 50,
									}}>
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
											maxWidth: 400,
											marginBottom: 20,
										}}>
										<Text
											style={{
												fontStyle: "Avenir",
												fontSize: 28,
												fontWeight: "300",
												color: APP_COLOURS.WHITE,
											}}>
											there has been no{" "}
											<Text
												style={{
													fontWeight: "700",
													color: "#FF5B72",
												}}>
												Sales
											</Text>{" "}
											recorded for this staff member
										</Text>
									</View>
									<Image
										source={sales}
										style={{
											width: 400,
											height: 400,
											resizeMode: "contain",
										}}
									/>
								</View>
							) : null}
							{this.state.tab === "Geolocation" ? (
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										paddingTop: 50,
									}}>
									<View
										style={{
											maxWidth: 400,
											marginBottom: 20,
										}}>
										<Text
											style={{
												fontStyle: "Avenir",
												fontSize: 28,
												fontWeight: "300",
												color: APP_COLOURS.WHITE,
											}}>
											there is no{" "}
											<Text
												style={{
													fontWeight: "700",
													color: "#528DFF",
												}}>
												Geo location
											</Text>{" "}
											information avaliable at this time
										</Text>
									</View>
									<Image
										source={geolocation}
										style={{
											width: 400,
											height: 400,
											resizeMode: "contain",
										}}
									/>
								</View>
							) : null}
							{this.state.tab === "Attendance" ? (
								<View
									style={{
										paddingTop: 50,
									}}>
									<BAAttendance data={d} />
									<BAAttendanceTable user_id={d.id} />
									{/* <View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										// backgroundColor: "red",
									}}>
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
											maxWidth: 400,
											marginBottom: 20,
										}}>
										<Text
											style={{
												fontStyle: "Avenir",
												fontSize: 28,
												fontWeight: "300",
												color: APP_COLOURS.WHITE,
											}}>
											there is no{" "}
											<Text
												style={{
													fontWeight: "700",
													color: "#528DFF",
												}}>
												Attendance
											</Text>{" "}
											logged for this staff member
										</Text>
									</View>
									<Image
										source={schedule}
										style={{
											width: 400,
											height: 400,
											resizeMode: "contain",
										}}
									/>
								</View> */}
								</View>
							) : null}
							{this.state.tab === "Schedule" ? (
								<View
									style={{
										// flex: 1,
										// justifyContent: "center",
										// alignItems: "center",
										// backgroundColor: APP_COLOURS.WHITE,
										// borderRadius: 10,
										paddingTop: 50,
									}}>
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
										}}>
										<View
											style={{
												justifyContent: "center",
												alignItems: "center",
												maxWidth: 400,
												marginBottom: 20,
											}}>
											<Text
												style={{
													fontStyle: "Avenir",
													fontSize: 28,
													fontWeight: "300",
													color: APP_COLOURS.WHITE,
												}}>
												there is no{" "}
												<Text
													style={{
														fontWeight: "700",
														color: "#528DFF",
													}}>
													Schedule
												</Text>{" "}
												set up for this staff member
											</Text>
										</View>
										<Image
											source={schedule}
											style={{
												width: 400,
												height: 400,
												resizeMode: "contain",
											}}
										/>
									</View>
								</View>
							) : null}
							{this.state.tab === "Skills" ? (
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										// backgroundColor: APP_COLOURS.WHITE,
										// borderRadius: 10,
										paddingTop: 50,
									}}>
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
											maxWidth: 400,
											marginBottom: 20,
										}}>
										<Text
											style={{
												fontStyle: "Avenir",
												fontSize: 28,
												fontWeight: "300",
												color: APP_COLOURS.WHITE,
											}}>
											there is no{" "}
											<Text
												style={{
													fontWeight: "700",
													color: "#528DFF",
												}}>
												Skills / eLearning
											</Text>{" "}
											set up for this staff member
										</Text>
									</View>
									<Image
										source={skills}
										style={{
											width: 400,
											height: 400,
											resizeMode: "contain",
										}}
									/>
								</View>
							) : null}
						</View>
					</ScrollView>
				</View>
			);
	}

	loadingComponent = () => {
		return (
			<View
				style={{
					flex: 1,
					zIndex: 999,
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					<Fade>
						<Image
							source={require("../../../assets/images/logo.png")}
							style={{
								width: 200,
								height: 200,
								resizeMode: "contain",
							}}
						/>
					</Fade>
				</View>
			</View>
		);
	};
}
