import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
// import traq_logo from "./traq_app_logo.svg";
// import bg2 from "./bg2.svg";
// import bg from "./surveyBG.svg";
// import tetrice_ds from "./tetrice_dataservices.svg";

export default class WalkThrough extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}
	render() {
		let contain = this.props.screenWidth - 400;
		let maxContain = contain > 520 ? 520 : contain;
		let ar = 600 / 708;
		return (
			<View
				style={{
					flex: 1,
					paddingLeft: 10,
					borderRadius: 5,
					overflow: "hidden",
				}}>
				{/* <View
					style={{
						height: 600,
					}}>
					<View
						style={{
							width: "100%",
							height: 400,
							right: 0,
							top: 0,
							position: "absolute",
						}}>
						<Image
							source={bg}
							style={{
								width: maxContain * ar,
								height: maxContain,
								resizeMode: "contain",
								position: "absolute",
								left: -10,
								top: 0,
							}}
						/>
					</View>
					<View
						style={{
							flex: 1,
						}}>
						<View
							style={
								{
									// position: "absolute",
									// top: "5%",
									// left: "2%",
								}
							}>
							<Image
								source={traq_logo}
								style={{
									width: 190,
									height: 70,
									resizeMode: "contain",
								}}
							/>
						</View>
					</View>
				</View>

				<View
					style={{
						maxWidth: 500,
					}}>
					<Text
						style={{
							color: "#FFF",
							fontFamily: "Avenir",
							fontSize: 25,
							fontWeight: "800",
						}}>
						User guide
					</Text>

					<Text
						style={{
							color: "#FFF",
						}}>
						Here you will see a list of surveys created.{" "}
					</Text>
					<Text
						style={{
							color: "#FFF",
						}}>
						Each survey has their own dahboard where you can manage the
						survey and generate reports from the results collected from
						the survey
					</Text>
				</View>

				<View
					style={{
						alignSelf: "flex-end",
					}}>
					<Image
						source={tetrice_ds}
						style={{
							width: 190,
							height: 70,
							resizeMode: "contain",
						}}
					/>
				</View> */}
			</View>
		);
	}
}
