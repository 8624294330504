import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity, Image } from "react-native";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import { APP_COLOURS } from "../../APP_VARS";

export default class TopTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabs: this.props.tabs,
			tab: this.props.tabs[0],
			hover: true,
		};
	}

	componentDidMount() {
		this.props.selectedSubPage(this.state.tab);
	}

	render() {
		return (
			<View>
				<View
					// onMouseEnter={() => this.setState({ hover: true })}
					// onMouseLeave={() => this.setState({ hover: false })}
					style={{
						flexDirection: "row",
					}}>
					{this.state.tabs.map((d, i) => (
						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									tab: d,
								});
								this.props.selectedSubPage(d);
							}}>
							<View
								style={{
									height: "80%",
									borderRadius: 5,
									width: 3,
									marginTop: this.state.hover ? "5%" : 5,
									backgroundColor:
										this.state.tab === d
											? APP_COLOURS.BLUE
											: "transparent",
									position: "absolute",
									left: 3,
								}}
							/>
							<View
								style={{
									backgroundColor:
										this.state.tab === d
											? APP_COLOURS.BLUE
											: APP_COLOURS.BLACK,
									borderWidth: 2,
									borderColor:
										this.state.tab === d
											? APP_COLOURS.BLUE
											: APP_COLOURS.PLACEHOLDER,
									marginRight: 5,
									borderRadius: 10,
									padding: 10,
									// justifyContent: "center",
									alignItems: "center",
									minWidth: 150,
									flexDirection: "row",
									paddingTop: this.state.hover ? 50 : 10,
									marginLeft: 10,
								}}>
								{/* <Image
											source={traqQ}
											style={{
												width: 18,
												height: 18,
												resizeMode: "contain",
												marginRight: 5,
												opacity: this.state.tab === d ? 1 : 0,
											}}
										/> */}

								<Text
									style={{
										fontSize: 14,
										fontWeight: "900",
										// this.state.tab === d ? "900" : "300",
										color: "#FFF",
										marginRight: 15,
										fontFamily: "Avenir",
									}}>
									{d}
								</Text>
							</View>
						</TouchableOpacity>
					))}
				</View>
			</View>
		);
	}
}
