import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
import Fade from "react-reveal/Fade";
import LineChart from "./PerformanceChart/index";
import perc from "../../../../assets/images/knowledgebase.png";
import bracketLeft from "../../../../assets/images/bracketLeft.svg";
import moment from "moment";

function randomRange(n, min, max) {
	var values = [],
		i = max;
	while (i >= min) values.push(i--);
	var results = [];
	var maxIndex = max;
	for (i = 1; i <= n; i++) {
		maxIndex--;
		var index = Math.floor(maxIndex * Math.random());
		results.push(values[index]);
		values[index] = values[maxIndex];
	}
	return results.filter((d) => typeof d !== "undefined");
}

function newRandomNumber(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function currencyFormat(num) {
	num = parseFloat(num);
	return "R " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export default class BASales extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: "",
			data: [
				{
					title: "Total sales",
					percentage: 0.8,
					percentageStr: `${currencyFormat(
						newRandomNumber(10000, 300000),
					)}`,
					score: newRandomNumber(5, 10),
				},
				{
					title: "Average Rand Sale",
					percentage: 0.65,
					percentageStr: `${currencyFormat(newRandomNumber(30, 5000))}`,
					score: newRandomNumber(6, 10),
				},
				{
					title: "Items per transaction",
					percentage: 0.88,
					percentageStr: `${newRandomNumber(1, 14)}`,
					score: newRandomNumber(4, 10),
				},
				{
					title: "Total units sold",
					percentage: 0.88,
					percentageStr: `${newRandomNumber(20, 500)}`,
					score: newRandomNumber(5, 10),
				},
			],
		};
	}

	render() {
		let barWidth = 200;
		let overallScore = 0;
		return (
			<Fade>
				<View
					style={{
						margin: 20,
						// borderBottomColor: APP_COLOURS.TRANSHALO,
						// borderBottomWidth: 0.5,
						paddingBottom: 20,
						marginTop: 40,
					}}>
					<View
						style={{
							flexDirection: "row",
						}}>
						<View
							style={{
								flex: 1,
							}}>
							<View
								onMouseEnter={() =>
									this.setState({
										hover: "",
									})
								}
								style={{
									width: "100%",
									justifyContent: "space-between",
									flexDirection: "row",
									paddingBottom: 10,
									borderBottomColor: APP_COLOURS.TRANSHALO,
									borderBottomWidth: 0.5,
									alignItems: "flex-end",
									marginBottom: 10,
								}}>
								<View
									style={{
										position: "absolute",
										left: -12,
										top: -15,
									}}>
									<Text
										style={{
											fontSize: 13,
											color: APP_COLOURS.WHITE,
											fontWeight: "300",
											fontFamily: "Avenir",
										}}>
										SALES
										<Text
											style={{
												fontWeight: "900",
											}}>
											TRAQ
										</Text>
									</Text>
								</View>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 25,
										fontWeight: "100",
										color: APP_COLOURS.WHITE,
										marginBottom: 10,
									}}>
									Sales performance
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<View>
									{this.state.data.map((dd) => {
										overallScore =
											overallScore + parseFloat(dd.score);
										return (
											<View
												onMouseEnter={() =>
													this.setState({
														hover: dd.title,
														dataHistory: dd.dataHistory,
													})
												}
												style={{
													flexDirection: "row",
													marginBottom: 10,
													alignItems: "center",
													backgroundColor:
														this.state.hover === dd.title
															? APP_COLOURS.TRANSHALO
															: "transparent",
												}}>
												<View
													style={{
														width: 200,
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 16,
															fontWeight: "200",
															color: APP_COLOURS.WHITE,
														}}>
														{dd.title}
													</Text>
												</View>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 20,
														fontWeight: "700",
														color: APP_COLOURS.WHITE,
														marginLeft: 5,
													}}>
													{dd.percentageStr}
												</Text>
											</View>
										);
									})}
								</View>
							</View>
							<View
								onMouseEnter={() =>
									this.setState({
										hover: "",
									})
								}
								style={{
									width: "100%",
									flexDirection: "row",
								}}>
								<View
									style={{
										marginRight: 15,
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 20,
											fontWeight: "100",
											color: APP_COLOURS.WHITE,
											opacity: 0.6,
										}}>
										Overall score
									</Text>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 50,
											fontWeight: "500",
											color: APP_COLOURS.WHITE,
										}}>
										{overallScore}{" "}
										<Text
											style={{
												fontSize: 25,
												fontWeight: "100",
											}}>
											/ {this.state.data.length * 10}
										</Text>
									</Text>
								</View>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 10,
										fontWeight: "100",
										color: APP_COLOURS.WHITE,
										opacity: 0.8,
										maxWidth: 150,
									}}>
									Sales metrics tracked on submission of sales and
									cross referenced to targets set by managers
								</Text>
							</View>
						</View>

						<View
							style={{
								flex: 1,
							}}>
							<View
								style={{
									width: "100%",
									justifyContent: "space-between",
									flexDirection: "row",
									paddingBottom: 10,
									borderBottomColor: APP_COLOURS.TRANSHALO,
									borderBottomWidth: 0.5,
									alignItems: "flex-end",
									marginBottom: 10,
								}}>
								<View
									style={{
										position: "absolute",
										left: -12,
										top: -15,
									}}>
									<Text
										style={{
											fontSize: 13,
											color: APP_COLOURS.WHITE,
											fontWeight: "300",
											fontFamily: "Avenir",
										}}>
										SALES
										<Text
											style={{
												fontWeight: "900",
											}}>
											TRAQ
										</Text>
									</Text>
								</View>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 25,
										fontWeight: "100",
										color: APP_COLOURS.WHITE,
										marginBottom: 10,
									}}>
									Sales performance history
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<View
									style={{
										// height: 300,
										width: 450,
									}}>
									<LineChart
										// data={this.state.salesReports.map((d) =>
										// 	parseFloat(d.sales_total),
										// )}
										// data={[5, 8.8, 8.3, 5, 6, 7.8, 5.5, 9, 2, 8]}
										label={``}
										data={randomRange(12, 15, 40)}
										average={25}
										max={40}
									/>
								</View>
							</View>
						</View>
					</View>

					<View
						style={{
							alignItems: "flex-end",
							marginTop: 30,
						}}>
						<Text
							style={{
								fontStyle: "italic",
								fontFamily: "Avenir",
								fontSize: 10,
								fontWeight: "100",
								color: APP_COLOURS.WHITE,
							}}>
							Sales performance report
						</Text>
						<Text
							style={{
								fontStyle: "italic",
								fontFamily: "Avenir",
								fontSize: 10,
								fontWeight: "100",
								color: APP_COLOURS.WHITE,
							}}>
							{moment().format("MMMM, YYYY")}
						</Text>
					</View>
				</View>
			</Fade>
		);
	}
}
