import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	TextInput,
	Modal,
	Image,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { APP_COLOURS } from "../../../APP_VARS";
import tick from "../../../../assets/images/tick.png";
import { api_getSurveySubmissions } from "../../../Api";
import { PieChart } from "react-minimal-pie-chart";
import LineChart from "../chart/index";
import SearchBar from "../SearchBar/index";

function isOdd(num) {
	return num % 2;
}

export default class OverviewReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			colours: ["#14296C", "#6FD1F6", "#737DA9", "#A9A9A9"],
			mainFieldRemove: ["0", ""],
			data: [
				// {
				// 	id: "2XEaVwc99lt4FycmD7Hw",
				// 	qrscanned: false,
				// 	endTime: "2022-10-26T22:22:55.783Z",
				// 	organisationID: "revlon981723423",
				// 	userAnswers: [],
				// 	completedSurvey: false,
				// 	deviceInfo: {
				// 		platform: "APPLE iOS",
				// 		location_type: "Fixed location",
				// 		id: "KdgKepjwqkJN2vXox0UL",
				// 		mall: "Sandton",
				// 		retailer: "Edgars",
				// 	},
				// 	autoID: "2XEaVwc99lt4FycmD7Hw",
				// 	customerLink: false,
				// 	surveyID: "XK9NR6G1XLLEshwcdGSl",
				// 	startTime: "2022-10-26T22:22:50.748Z",
				// 	uuid: "396BE1D",
				// },
				// {
				// 	id: "DuEgcCCHkPLV0wAeQHSK",
				// 	deviceInfo: {
				// 		location_type: "Fixed location",
				// 		id: "KdgKepjwqkJN2vXox0UL",
				// 		retailer: "Edgars",
				// 		platform: "APPLE iOS",
				// 		mall: "Sandton",
				// 	},
				// 	organisationID: "revlon981723423",
				// 	completedSurvey: true,
				// 	qrscanned: false,
				// 	startTime: "2022-10-26T23:02:10.898Z",
				// 	surveyID: "XK9NR6G1XLLEshwcdGSl",
				// 	endTime: "2022-10-26T23:02:16.256Z",
				// 	userAnswers: [
				// 		{
				// 			mark: "relaxed",
				// 			answer: "My hair is relaxed",
				// 			question: "Select one of the following:",
				// 			question_number: 1,
				// 		},
				// 		{
				// 			mark: "TypeB",
				// 			question: "Tell us your hair type:",
				// 			question_number: 2,
				// 			answer: "B",
				// 		},
				// 		{
				// 			answer: "Shine",
				// 			question: "My hair needs?",
				// 			mark: "Shine",
				// 			question_number: 3,
				// 		},
				// 	],
				// 	customerLink: false,
				// 	uuid: "396BE1D",
				// 	autoID: "DuEgcCCHkPLV0wAeQHSK",
				// },
				// {
				// 	id: "Y7G213QjLhdeqiAZK8gx",
				// 	surveyID: "XK9NR6G1XLLEshwcdGSl",
				// 	uuid: "396BE1D",
				// 	completedSurvey: true,
				// 	endTime: "2022-10-26T23:17:40.071Z",
				// 	autoID: "Y7G213QjLhdeqiAZK8gx",
				// 	qrscanned: false,
				// 	userAnswers: [
				// 		{
				// 			question_number: 1,
				// 			question: "Select one of the following:",
				// 			mark: "relaxed",
				// 			answer: "My hair is relaxed",
				// 		},
				// 		{
				// 			answer: "A",
				// 			question_number: 2,
				// 			mark: "TypeA",
				// 			question: "Tell us your hair type:",
				// 		},
				// 		{
				// 			answer: "Shine",
				// 			question: "My hair needs?",
				// 			question_number: 3,
				// 			mark: "Shine",
				// 		},
				// 	],
				// 	organisationID: "revlon981723423",
				// 	deviceInfo: {
				// 		platform: "APPLE iOS",
				// 		location_type: "Fixed location",
				// 		mall: "Sandton",
				// 		id: "KdgKepjwqkJN2vXox0UL",
				// 		retailer: "Edgars",
				// 	},
				// 	customerLink: false,
				// 	startTime: "2022-10-26T23:17:36.031Z",
				// },
			],
			mainFilterField: "answer",
			completedPerc: 0,
			groups: [],
			lineChart: {
				labels: [],
				data: [],
			},
			malls: [],
			retailers: [],
			retailersFilter: "",
			mallsFilter: "",
			noResultsFound: false,
		};
	}

	componentDidMount() {}

	runCalculations = async (data) => {
		// return;
		//  Search Bar before filters so that it doesnnt filter thesearchbar
		// let malls = await this.filterUnique(data, "mall");
		// let retailers = await this.filterUnique(data, "retailer");

		let malls = await this.filterUniqueMall(data);
		let retailers = await this.filterUniqueRetailer(data);
		//
		// if one filter only
		if (this.state.mallsFilter !== "") {
			data = await data.filter(
				(d) => d.deviceInfo.mall.title === this.state.mallsFilter,
			);
		}
		// if one filter only
		if (this.state.retailersFilter !== "") {
			data = await data.filter(
				(d) => d.deviceInfo.retailer.title === this.state.retailersFilter,
			);
		}
		// if both filters
		if (this.state.retailersFilter !== "" && this.state.mallsFilter !== "") {
			data = await data.filter(
				(d) =>
					d.deviceInfo.retailer.title === this.state.retailersFilter &&
					d.deviceInfo.mall.title === this.state.mallsFilter,
			);
		}
		//
		//
		let totalCount = data.length;
		if (totalCount < 1) {
			await this.setState({
				noResultsFound: true,
			});
			return;
		} else {
			await this.setState({
				noResultsFound: false,
			});
		}
		let completedCount = await this.calcCompletedSurveys(
			"completedSurvey",
			data,
		);
		let completedPerc = await this.calcPercFromTotal("completedSurvey", data);
		let customerLinkPerc = await this.calcPercFromTotal("customerLink", data);
		let uniquQuestions_ = await this.uniquQuestionNumbers(data);
		//
		// Line CHart
		let averageDailyLabels = await this.averageDailySurveyDays(data);
		let averageDailyCount = await this.averageDailySurveyCount(
			data,
			averageDailyLabels,
		);
		//

		// console.log(retailers);
		// console.log(malls);
		// return;
		//
		//
		//
		let createGroups = [];
		for (const q of uniquQuestions_) {
			let return_ = await this.createGroupedData(q.num, data);
			let obj = {
				title: `Question ${q.num}: ${q.question}`,
				number: q.num,
				data: return_,
			};
			await createGroups.push(obj);
		}

		//
		// SET STATES
		//
		await this.setState({
			totalCount: totalCount,
			completedCount: completedCount,
			completedPerc: completedPerc,
			customerLinkPerc: customerLinkPerc,
			uniquQuestionNumbers: uniquQuestions_,
			groups: createGroups,
			lineChart: {
				labels: averageDailyLabels,
				data: averageDailyCount,
			},
			malls: malls,
			retailers: retailers,
		});
	};

	averageDailySurveyDays = async (data) => {
		let dates = await data.map((item) =>
			moment(item.endTime).format("YYYY/MM/DD"),
		);
		// const unique = await [
		// 	...new Set(data.map((item) => item.question_number)),
		// ];
		// console.log(dates);
		var maxDate = dates.reduce(function (a, b) {
			return a > b ? a : b;
		});
		var minDate = dates.reduce(function (a, b) {
			return a < b ? a : b;
		});

		let arryDays = [
			...Array(moment(maxDate).diff(minDate, "days") + 1).keys(),
		];
		let arryDates = [];
		for (const d of arryDays) {
			let obj = moment(minDate).add(d, "days").format("YYYY/MM/DD");
			await arryDates.push(obj);
		}
		// console.log(arryDates);
		return arryDates;
	};

	averageDailySurveyCount = async (data, days) => {
		let dates = await data.map((item) =>
			moment(item.endTime).format("YYYY/MM/DD"),
		);
		// console.log(days);
		let arryDates = [];
		for (const d of days) {
			let obj = await dates.filter((dd) =>
				moment(dd).isSame(moment(d, "YYYY/MM/DD")),
			);
			await arryDates.push(obj.length);
		}
		console.log(arryDates);
		return arryDates;
	};

	calcCompletedSurveys = (field, data) => {
		let completedSurvey = data.filter((d) => d[field]).length;
		return completedSurvey;
	};

	calcPercFromTotal = (field, data) => {
		let total = data.length;
		let filtered_ = data.filter((d) => d[field]).length;
		let result = (filtered_ / total) * 100;
		return result.toFixed(0);
	};

	uniquQuestionNumbers = async (data) => {
		let userAnswers = [];
		let uniqueQuestions = [];
		for (const i of data) {
			await i.userAnswers.map((d) => userAnswers.push(d));
		}

		const unique = await [
			...new Set(userAnswers.map((item) => item.question_number)),
		];

		for (const q of unique) {
			let question_ = userAnswers.filter((d) => d.question_number === q);
			let obj = {
				question: question_[0].question,
				num: q,
			};
			await uniqueQuestions.push(obj);
		}

		console.log(uniqueQuestions);

		return uniqueQuestions;
	};

	createGroupedData = async (question_number, data) => {
		let userAnswers = [];
		for (const i of data) {
			await i.userAnswers
				.filter((d_) => d_.question_number === question_number)
				.map((d) => userAnswers.push(d.answer));
		}

		function createGroup(field, array) {
			let grandTotal = array.length;
			const unique = [...new Set(array)];
			let total = unique.length;
			let arrGroup = [];

			for (const field of unique) {
				let count = array.filter(function (value) {
					return value === field;
				}).length;
				arrGroup.push({
					name: field,
					count: count,
					grandTotal: grandTotal,
					percentage: count / grandTotal,
					percInverted: 1 - count / total,
				});
			}

			return arrGroup;
		}

		const answer = createGroup("answer", userAnswers);

		let groups = [
			{
				name: `Question: ${question_number} answers`,
				data: answer,
			},
		];

		console.log(groups);

		return groups;
	};

	filterUnique = async (data, filter) => {
		let arr = [];
		for (const d of data) {
			let obj = d.deviceInfo[filter];
			await arr.push(obj);
		}
		const unique = await [...new Set(arr.map((item) => item))];
		return unique;
	};

	filterUniqueMall = async (data) => {
		let arr = [];
		for (const d of data) {
			let obj = d.deviceInfo.mall.title;
			await arr.push(obj);
		}
		const unique = await [...new Set(arr.map((item) => item))];
		return unique;
	};

	filterUniqueRetailer = async (data) => {
		let arr = [];
		for (const d of data) {
			let obj = d.deviceInfo.retailer.title;
			await arr.push(obj);
		}
		const unique = await [...new Set(arr.map((item) => item))];
		return unique;
	};

	render() {
		let color = "#FFF";
		let style = {
			// backgroundColor: "#FFF",
			backgroundColor: APP_COLOURS.BG3,
			borderRadius: 13,
			padding: 20,
			marginRight: 5,
			marginBottom: 5,
			shadowColor: "#000",
			shadowOffset: { width: 0, height: 8 },
			shadowOpacity: 0.44,
			shadowRadius: 9.32,
			elevation: 5,
			// opacity: 0.9,
		};
		return (
			<View
				style={{
					height: this.props.screenHeight - 220,
					// paddingBottom: 60,
				}}>
				{this.state.data.length > 0 ? null : (
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<TouchableOpacity
							style={{
								// position: "absolute",
								marginTop: 20,
								zIndex: 999,
								bottom: 0,
								justifyContent: "center",
								alignItems: "center",
							}}
							disabled={this.state.loading}
							onPress={() => {
								this.getSubmissions();
							}}>
							<View
								style={{
									backgroundColor: APP_COLOURS.RED,
									borderRadius: 10,
									padding: 10,
									width: 200,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										color: color,
										fontFamily: "Avenir",
										fontWeight: "600",
									}}>
									{this.state.loading ? (
										<ActivityIndicator color={"#FFF"} />
									) : (
										"Calculate results"
									)}
								</Text>
							</View>
						</TouchableOpacity>
					</View>
				)}

				<ScrollView
					showsVerticalScrollIndicator={false}
					style={{
						flex: 1,
						display: this.state.data.length > 0 ? "flex" : "none",
					}}>
					<View
						style={{
							// flex: 1,
							width: "100%",
							flexDirection: "row",
							flexWrap: "wrap-reverse",
							marginTop: 30,
							// maxHeight: 100,
							alignItems: "center",
							// justifyContent: "space-between",
							justifyContent: "flex-start",
						}}>
						<View
							style={{
								flexDirection: "row",
							}}>
							<View
								style={[
									{
										// maxHeight: 90,
									},
									style,
								]}>
								<Text
									style={{
										opacity: 0.8,
										fontSize: 13,
										color: color,
									}}>
									Total surveys
								</Text>
								<Text
									style={{
										fontSize: 29,
										fontWeight: "800",
										color: color,
									}}>
									{this.state.totalCount}
								</Text>
							</View>
						</View>
						<View
							style={{
								flexDirection: "row",
							}}>
							<View
								style={[
									{
										// maxHeight: 90,
									},
									style,
								]}>
								<Text
									style={{
										opacity: 0.8,
										fontSize: 13,
										color: color,
									}}>
									Completed
								</Text>
								<Text
									style={{
										fontSize: 29,
										fontWeight: "800",
										color: color,
									}}>
									{this.state.completedCount}
									<Text
										style={{
											fontSize: 14,
											fontWeight: "700",
											color: color,
											opacity: 0.8,
										}}>
										{" "}
										/{this.state.totalCount}
									</Text>
								</Text>
							</View>
						</View>
						<View
							style={{
								flexDirection: "row",
							}}>
							<View
								style={[
									{
										// maxHeight: 90,
									},
									style,
								]}>
								<Text
									style={{
										opacity: 0.8,
										fontSize: 13,
										color: color,
									}}>
									Abandoned
								</Text>
								<Text
									style={{
										fontSize: 29,
										fontWeight: "800",
										color: color,
									}}>
									{this.state.totalCount - this.state.completedCount}
									<Text
										style={{
											fontSize: 14,
											fontWeight: "700",
											color: color,
											opacity: 0.8,
										}}>
										{" "}
										/{this.state.totalCount}
									</Text>
								</Text>
							</View>
						</View>
						<View
							style={{
								flexDirection: "row",
							}}>
							<View
								style={[
									{
										// maxHeight: 90,
									},
									style,
								]}>
								<Text
									style={{
										opacity: 0.8,
										fontSize: 13,
										color: color,
									}}>
									Linked customer
								</Text>
								<Text
									style={{
										fontSize: 29,
										fontWeight:
											this.state.customerLinkPerc > 0
												? "800"
												: "300",
										fontStyle:
											this.state.customerLinkPerc > 0
												? "normal"
												: "italic",
										color: color,
									}}>
									{this.state.customerLinkPerc > 0
										? this.state.customerLinkPerc
										: "None"}
									<Text
										style={{
											fontSize: 12,
											fontWeight: "700",
											color: color,
											opacity: 0.8,
										}}>
										{this.state.customerLinkPerc > 0 ? "%" : ""}
									</Text>
								</Text>
							</View>
						</View>
						<View
							style={{
								flexDirection: "row",
							}}>
							<View
								style={[
									{
										// maxHeight: 90,
									},
									style,
								]}>
								<Text
									style={{
										opacity: 0.8,
										fontSize: 13,
										color: color,
									}}>
									Completion rate
								</Text>
								<Text
									style={{
										fontSize: 29,
										fontWeight: "800",
										color: color,
									}}>
									{this.state.completedPerc}
									<Text
										style={{
											fontSize: 12,
											fontWeight: "700",
											color: color,
											opacity: 0.8,
										}}>
										{this.state.completedPerc > 0 ? "%" : ""}
									</Text>
								</Text>
							</View>
						</View>
						<View
							style={{
								flexDirection: "row",
							}}>
							<View
								style={[
									{
										// maxHeight: 90,
									},
									style,
								]}>
								<Text
									style={{
										opacity: 0.8,
										fontSize: 13,
										color: color,
									}}>
									Abandon rate
								</Text>
								<Text
									style={{
										fontSize: 29,
										fontWeight: "800",
										color: color,
									}}>
									{this.state.completedPerc > 0
										? (100 - this.state.completedPerc).toFixed(0)
										: ""}
									<Text
										style={{
											fontSize: 12,
											fontWeight: "700",
											color: color,
											opacity: 0.8,
										}}>
										{this.state.completedPerc > 0 ? "%" : ""}
									</Text>
								</Text>
							</View>
						</View>
						<View
							style={{
								// flex: 1,
								flexDirection: "row",
								flexWrap: "wrap",
								// alignItems: "center",
								justifyContent: "flex-end",
								padding: 10,
								// marginTop: 50,
							}}>
							<Text
								style={{
									fontSize: 19,
									fontWeight: "800",
									color: color,
									marginRight: 60,
									opacity:
										this.state.mallsFilter !== "" ||
										this.state.retailersFilter
											? 1
											: 0.05,
								}}>
								Filtered report
							</Text>
							<View
								style={{
									flexDirection: "row",
									flexWrap: "wrap",
									alignItems: "center",
								}}>
								<SearchBar
									{...this.props}
									placeHolder={`Filter mall `}
									data={this.state.malls}
									loading={this.state.loading}
									selectedData={async (d) => {
										console.log(d);
										await this.setState({
											mallsFilter: d,
										});
										await this.runCalculations(this.state.data);
									}}
								/>
								<SearchBar
									{...this.props}
									placeHolder={`Filter retailer `}
									data={this.state.retailers}
									loading={this.state.loading}
									selectedData={async (d) => {
										console.log(d);
										await this.setState({
											retailersFilter: d,
										});
										await this.runCalculations(this.state.data);
										// await this.filterCustomers(d);
									}}
								/>
							</View>

							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										retailersFilter: "",
										mallsFilter: "",
									});
									await this.runCalculations(this.state.data);
								}}>
								<Text
									style={{
										fontSize: 12,
										fontWeight: "300",
										color: color,
										marginLeft: 10,
										opacity:
											this.state.mallsFilter !== "" ||
											this.state.retailersFilter
												? 1
												: 0,
									}}>
									Clear all filters
								</Text>
							</TouchableOpacity>
						</View>
					</View>

					{this.state.noResultsFound ? (
						<View
							style={{
								flex: 1,
								// justifyContent: "center",
								alignItems: "center",
								paddingTop: 100,
							}}>
							<View
								style={[
									{
										backgroundColor: APP_COLOURS.BG4,
										borderRadius: 10,
										padding: 10,
										justifyContent: "center",
										alignItems: "center",
									},
									style,
								]}>
								<Text
									style={{
										color: color,
										fontFamily: "Avenir",
										fontWeight: "600",
									}}>
									No results found
								</Text>
							</View>
						</View>
					) : (
						<View
							style={{
								flex: 1,
							}}>
							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									flexWrap: "wrap",
								}}>
								<View
									style={[
										{
											flex: 1,
											minWidth: 600,
										},
										style,
									]}>
									{this.state.groups.map((group, i) => (
										<View
											key={i}
											style={{
												marginBottom: 50,
											}}>
											{/* {console.log(group)} */}
											<View
												style={{
													borderBottomWidth: 0.5,
													borderBottomColor: "#E8E8E8",
													paddingBottom: 3,
													marginBottom: 10,
												}}>
												<Text
													style={{
														fontSize: 12,
														fontWeight: "500",
														color: color,
													}}>
													{group.title}
												</Text>
											</View>
											{group.data.map((d, ii) => (
												<View
													key={ii}
													style={{
														marginBottom: 10,
													}}>
													<View style={{}}>
														{d.data.map((dd, iii) => (
															<View
																key={iii}
																style={{
																	flexDirection: "row",
																	alignItems: "center",
																	marginBottom: 3,
																}}>
																<Text
																	style={{
																		fontSize: 12,
																		width: 200,
																		color: color,
																	}}>
																	{dd.name}
																</Text>
																<Text
																	style={{
																		fontSize: 12,
																		fontWeight: "500",
																		color: color,
																		width: 20,
																	}}>
																	{dd.count}{" "}
																</Text>
																{/* <Text
														style={{
															fontSize: 12,
															fontWeight: "500",
														}}>
														of {dd.grandTotal}
													</Text> */}

																<View
																	style={{
																		width: 200,
																		backgroundColor:
																			"#444654",
																		borderRadius: 3,
																	}}>
																	<View
																		style={{
																			width:
																				200 * dd.percentage,
																			backgroundColor:
																				"#29D9C4",
																			borderRadius: 3,
																			height: 15,
																		}}></View>
																</View>

																<Text
																	style={{
																		fontSize: 12,
																		fontWeight: "500",
																		color: color,
																	}}>
																	{" "}
																	-{" "}
																	{(
																		dd.percentage * 100
																	).toFixed(0)}{" "}
																	%
																</Text>
															</View>
														))}
													</View>
												</View>
											))}
										</View>
									))}
								</View>

								<View
									style={[
										{
											flex: 1,
											minWidth: 600,
										},
										style,
									]}>
									<Text
										style={{
											opacity: 0.8,
											fontSize: 13,
											color: color,
										}}>
										Total surveys per day
									</Text>
									<LineChart
										labels={this.state.lineChart.labels}
										data={this.state.lineChart.data}
										title={"Surveys"}
									/>
								</View>
							</View>

							<View
								style={{
									// flex: 1,
									backgroundColor: "#FFF",
									borderRadius: 12,
									display: "none",
									// height: 100,
									// width: 90,
									alignItems: "center",
									justifyContent: "flex-end",
									padding: 10,
								}}>
								<Text
									style={{
										fontSize: 10,
										fontWeight: "400",
									}}>
									Most popular precription
								</Text>

								<Text
									style={{
										fontSize: 10,
										fontWeight: "400",
									}}>
									answer sstats
								</Text>
								<Text
									style={{
										fontSize: 10,
										fontWeight: "400",
									}}>
									abandon rate
								</Text>
								<Text
									style={{
										fontSize: 10,
										fontWeight: "400",
									}}>
									average timeto completion
								</Text>
								<Text
									style={{
										fontSize: 10,
										fontWeight: "400",
									}}>
									time of day heat map
								</Text>
								<Text
									style={{
										fontSize: 10,
										fontWeight: "400",
									}}>
									most popular area / mall / retailer
								</Text>
							</View>
						</View>
					)}
					<View
						style={{
							height: 60,
						}}
					/>
				</ScrollView>
			</View>
		);
	}

	getSubmissions = async () => {
		let url = api_getSurveySubmissions;

		await this.setState({
			loading: true,
			devices: [],
			showDelete: false,
			addNewDevice: false,
			viewDeviceProfile: false,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify({
				surveyID: this.props.selectedSurvey.id,
			}),
		};

		console.log(url, requestOptions);

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log("response", responseData);
							await this.setState({
								data: responseData,
								loading: false,
							});
							await this.runCalculations(responseData);
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};
}
