import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
	Dimensions,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import Font from "react-font";
import moment from "moment";
import close from "../../../assets/images/close.png";
import tick from "./../../../assets/images/tick.png";
import ImageUploader from "./ImageUploader";
import DevicePreviewer from "./DevicePreviewer";
import { SketchPicker, TwitterPicker } from "react-color";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

function uuidv4() {
	return "xxxx-xxxxx-xxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export default class NewDevice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uuid: uuidv4(),
			date_created: moment().format("MMM DD, YYYY HH:mm:ss"),
			title: "",
			description: "",
			app_properties: {
				brandcolour: "#000000",
				logo: "",
				fontcolour: "#FFF",

				backgroundcolour: "#EDF0F0",
				mainFontColor: APP_COLOURS.BG2,
				cardColor: "#FFF",
				cardFont: APP_COLOURS.BG2,
				shadowColor: APP_COLOURS.BG6,
			},
		};
	}

	render() {
		let form_fields = [
			{
				id: 2,
				editable: true,
				state: "title",
				title: "title",
				autoCapitalize: true,
				validation: "name",
			},
		];
		let blur = {
			filter: "blur(8px)",
			"-webkit-filter": "blur(8px)",
			WebkitFilter: "blur(8px)",
		};
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View>
					<View
						style={{
							// position: "absolute",\
							zIndex: 90,
							width: screenWidth,
							backgroundColor: "#2B3440",
							height: screenHeight,
						}}
					/>
					<View
						style={{
							position: "absolute",
							zIndex: 999,
							width: screenWidth,
							justifyContent: "center",
							alignItems: "center",
							height: screenHeight,
							flexDirection: "row",
						}}>
						<View
							style={{
								height: 650,
								width: 320,
							}}>
							<DevicePreviewer
								bookContent={[]}
								linkerActive={true}
								selectedLine={""}
								app_properties={this.state.app_properties}
								fontSize={14}
								closePreview={() =>
									this.setState({
										openBookLinker: false,
									})
								}
							/>
						</View>
						<View
							style={{
								width: 50,
							}}
						/>
						<View
							style={{
								height: screenHeight,
							}}>
							<ScrollView showsVerticalScrollIndicator={false}>
								<View
									style={{
										width: "100%",
										maxWidth: 500,
										minWidth: 450,
										height: "auto",

										backgroundColor: "white",
										borderRadius: 20,
										justifyContent: "center",
										alignItems: "center",
										marginTop: 90,
										marginBottom: 120,
									}}>
									<View
										style={{
											position: "absolute",
											zIndex: 99,
											top: 10,
											left: 10,
										}}>
										<TouchableOpacity
											onPress={() => this.props.close()}>
											<Image
												source={close}
												style={{
													width: 20,
													height: 20,
													resizeMode: "contain",
												}}
											/>
										</TouchableOpacity>
									</View>
									<View
										style={{
											width: "100%",
											padding: 20,
											height: "auto",
										}}>
										<Text
											allowFontScaling={false}
											style={{
												fontSize: 17 + this.state.fontSize,
												fontFamily: "Avenir Next",
												textAlign: "center",
												fontWeight: "600",
												marginBottom: 20,
											}}>
											Add styled app
										</Text>

										<View
											style={{
												width: "auto",

												flexDirection: "row",
												alignItems: "center",
												margin: 10,
												marginBottom: 16,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													color: APP_COLOURS.PLACEHOLDER,
													fontWeight: "500",
													marginBottom: 4,
													position: "absolute",
													top: -12,
													left: 9,
													backgroundColor: "#FFF",
													padding: 4,
													borderRadius: 8,
												}}>
												App title
											</Text>
											<TextInput
												style={{
													width: "100%",
													borderWidth: 1,
													borderColor: "#000",
													padding: 12,
													paddingLeft: 30,
													fontSize: 16,
													borderRadius: 8,
													backgroundColor: APP_COLOURS.WHITE,
													color: "#000",
													fontWeight: "200",
													fontFamily: "Avenir",
												}}
												placeholderTextColor={"#000"}
												autoCapitalize='words'
												clearButtonMode='while-editing'
												autoCorrect={false}
												placeholder={"App title"}
												value={this.state.title}
												onChangeText={async (text) => {
													await this.setState({
														title: capitalizeName(text),
													});
												}}
											/>
										</View>

										<View
											style={{
												width: "auto",

												flexDirection: "row",
												alignItems: "center",
												margin: 10,
												marginBottom: 16,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													color: APP_COLOURS.PLACEHOLDER,
													fontWeight: "500",
													marginBottom: 4,
													position: "absolute",
													top: -12,
													left: 9,
													backgroundColor: "#FFF",
													padding: 4,
													borderRadius: 8,
												}}>
												Description
											</Text>
											<TextInput
												style={{
													width: "100%",
													borderWidth: 1,
													borderColor: "#000",
													padding: 12,
													paddingLeft: 30,
													fontSize: 16,
													borderRadius: 8,
													backgroundColor: APP_COLOURS.WHITE,
													color: "#000",
													fontWeight: "200",
													fontFamily: "Avenir",
												}}
												placeholderTextColor={"#000"}
												autoCapitalize='words'
												clearButtonMode='while-editing'
												autoCorrect={false}
												placeholder={"description"}
												value={this.state.description}
												onChangeText={async (text) => {
													await this.setState({
														description: text,
													});
												}}
											/>
										</View>

										<View
											style={{
												width: "auto",
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "center",
												margin: 10,
												marginBottom: 16,

												borderWidth: 1,
												borderColor: "#000",
												padding: 12,
												paddingLeft: 30,
												fontSize: 16,
												borderRadius: 8,
												backgroundColor: APP_COLOURS.WHITE,
												color: "#000",
												fontWeight: "200",
												fontFamily: "Avenir",
											}}>
											<Text
												style={{
													opacity: this.state.unEditable ? 0.4 : 1,
													fontFamily: "Avenir",
													fontSize: 12,
													color: APP_COLOURS.PLACEHOLDER,
													fontWeight: "500",
													marginBottom: 4,
													position: "absolute",
													top: -12,
													left: 9,
													backgroundColor: "#FFF",
													padding: 4,
													borderRadius: 8,
												}}>
												Brand logo image
											</Text>
											<ImageUploader
												base64String={(base64String) => {
													// console.log(base64String);
													this.setState({
														app_properties: {
															...this.state.app_properties,
															logo: base64String,
														},
													});
												}}
											/>
											<Image
												source={`data:image/png;base64,${this.state.app_properties.logo}`}
												style={{
													height: 200,
													width: 300,
													resizeMode: "contain",
													position: "absolute",
												}}
											/>
										</View>

										<View
											style={{
												width: "auto",

												flexDirection: "row",
												alignItems: "center",
												margin: 10,
												marginBottom: 16,
												padding: 10,
												borderRadius: 12,
												backgroundColor:
													this.state.app_properties.brandcolour,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													color: APP_COLOURS.PLACEHOLDER,
													fontWeight: "500",
													marginBottom: 4,
													position: "absolute",
													top: -25,
													left: 9,
													backgroundColor: "#FFF",
													padding: 4,
													borderRadius: 8,
												}}>
												Brand colour
											</Text>
											<SketchPicker
												disableAlpha={true}
												color={
													this.state.app_properties.brandcolour
												}
												onChangeComplete={(color) => {
													this.setState({
														app_properties: {
															...this.state.app_properties,
															brandcolour: color.hex,
														},
													});
												}}
											/>
										</View>

										<View
											style={{
												width: "auto",

												flexDirection: "row",
												alignItems: "center",
												margin: 10,
												marginBottom: 16,
												padding: 10,
												borderRadius: 12,
												backgroundColor:
													this.state.app_properties.fontcolour,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													color: APP_COLOURS.PLACEHOLDER,
													fontWeight: "500",
													marginBottom: 4,
													position: "absolute",
													top: -25,
													left: 9,
													backgroundColor: "#FFF",
													padding: 4,
													borderRadius: 8,
												}}>
												Header Font colour
											</Text>
											<TwitterPicker
												colors={[
													"#FFFFFF",
													"#E8E8E8",
													"#797979",

													APP_COLOURS.BG2,
													"#EB144C",
													"#EEF810",
													APP_COLOURS.BLUE,
												]}
												color={this.state.app_properties.fontcolour}
												onChangeComplete={(color) => {
													this.setState({
														app_properties: {
															...this.state.app_properties,
															fontcolour: color.hex,
														},
													});
												}}
											/>
										</View>

										<View
											style={{
												width: "auto",

												flexDirection: "row",
												alignItems: "center",
												margin: 10,
												marginBottom: 16,
												padding: 10,
												borderRadius: 12,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													color: APP_COLOURS.PLACEHOLDER,
													fontWeight: "500",
													marginBottom: 4,
													position: "absolute",
													top: -25,
													left: 9,
													backgroundColor: "#FFF",
													padding: 4,
													borderRadius: 8,
												}}>
												App templates
											</Text>
											<View
												style={
													{
														// flexDirection: "row",
													}
												}>
												<TouchableOpacity
													onPress={() => {
														this.setState({
															app_properties: {
																...this.state.app_properties,
																backgroundcolour: "#EDF0F0",
																mainFontColor: APP_COLOURS.BG2,
																cardColor: "#FFF",
																cardFont: APP_COLOURS.BG2,
																shadowColor: APP_COLOURS.BG6,
															},
														});
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 14,
															fontWeight: "500",
														}}>
														Light mode
													</Text>
												</TouchableOpacity>

												<TouchableOpacity
													onPress={() => {
														this.setState({
															app_properties: {
																...this.state.app_properties,
																backgroundcolour:
																	APP_COLOURS.BG2,
																mainFontColor: "#EDF0F0",
																cardColor: APP_COLOURS.BG4,
																cardFont: "#EDF0F0",
																shadowColor: APP_COLOURS.BG,
															},
														});
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 14,
															fontWeight: "500",
														}}>
														Dark mode
													</Text>
												</TouchableOpacity>

												<TouchableOpacity
													onPress={() => {
														this.setState({
															app_properties: {
																...this.state.app_properties,
																backgroundcolour:
																	APP_COLOURS.BG2,
																mainFontColor: "#EDF0F0",
																cardColor: "#8556F5",
																cardFont: "#FFFFFF",
																shadowColor: APP_COLOURS.BG,
															},
														});
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 14,
															fontWeight: "500",
														}}>
														Purple
													</Text>
												</TouchableOpacity>

												<TouchableOpacity
													onPress={() => {
														this.setState({
															app_properties: {
																...this.state.app_properties,
																backgroundcolour:
																	APP_COLOURS.BG2,
																mainFontColor: "#EDF0F0",
																cardColor: "#0068FF",
																cardFont: "#FFFFFF",
																shadowColor: APP_COLOURS.BG,
															},
														});
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 14,
															fontWeight: "500",
														}}>
														Solid Blue
													</Text>
												</TouchableOpacity>

												<TouchableOpacity
													onPress={() => {
														this.setState({
															app_properties: {
																...this.state.app_properties,
																backgroundcolour: "#14121B",
																mainFontColor: "#8C8EA1",
																cardColor: "#262836",
																cardFont: "#93E9F8",
																shadowColor: "#000000",
															},
														});
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 14,
															fontWeight: "500",
														}}>
														Futuristic
													</Text>
												</TouchableOpacity>

												<TouchableOpacity
													onPress={() => {
														this.setState({
															app_properties: {
																...this.state.app_properties,
																backgroundcolour:
																	APP_COLOURS.BG2,
																mainFontColor: "#EDF0F0",
																cardColor: "#D0E8FF",
																cardFont: APP_COLOURS.BG2,
																shadowColor: APP_COLOURS.BG,
															},
														});
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 14,
															fontWeight: "500",
														}}>
														Soft Blue
													</Text>
												</TouchableOpacity>

												<TouchableOpacity
													onPress={() => {
														this.setState({
															app_properties: {
																...this.state.app_properties,
																backgroundcolour:
																	APP_COLOURS.BG2,
																mainFontColor: "#EDF0F0",
																cardColor:
																	this.state.app_properties
																		.brandcolour,
																cardFont:
																	this.state.app_properties
																		.fontcolour,
																shadowColor: APP_COLOURS.BG,
															},
														});
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 14,
															fontWeight: "500",
														}}>
														Brand color cards
													</Text>
												</TouchableOpacity>

												<TouchableOpacity
													onPress={() => {
														this.setState({
															app_properties: {
																...this.state.app_properties,
																backgroundcolour:
																	this.state.app_properties
																		.brandcolour,
																mainFontColor:
																	this.state.app_properties
																		.fontcolour,
																cardColor: "#FFF",
																cardFont: APP_COLOURS.BG,
																shadowColor: APP_COLOURS.BG,
															},
														});
													}}>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 14,
															fontWeight: "500",
														}}>
														Brand color background
													</Text>
												</TouchableOpacity>
											</View>
										</View>

										{/* <View
											style={{
												width: "auto",

												flexDirection: "row",
												alignItems: "center",
												margin: 10,
												marginBottom: 16,
												padding: 10,
												borderRadius: 12,
												backgroundColor:
													this.state.app_properties
														.backgroundcolour,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													color: APP_COLOURS.PLACEHOLDER,
													fontWeight: "500",
													marginBottom: 4,
													position: "absolute",
													top: -25,
													left: 9,
													backgroundColor: "#FFF",
													padding: 4,
													borderRadius: 8,
												}}>
												Background Color
											</Text>
											<SketchPicker
												disableAlpha={true}
												color={
													this.state.app_properties
														.backgroundcolour
												}
												onChangeComplete={(color) => {
													this.setState({
														app_properties: {
															...this.state.app_properties,
															backgroundcolour: color.hex,
														},
													});
												}}
											/>
										</View> */}

										{/* <View
											style={{
												width: "auto",

												flexDirection: "row",
												alignItems: "center",
												margin: 10,
												marginBottom: 16,
												padding: 10,
												borderRadius: 12,
												backgroundColor:
													this.state.app_properties.mainFontColor,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													color: APP_COLOURS.PLACEHOLDER,
													fontWeight: "500",
													marginBottom: 4,
													position: "absolute",
													top: -25,
													left: 9,
													backgroundColor: "#FFF",
													padding: 4,
													borderRadius: 8,
												}}>
												Main font colour
											</Text>
											<TwitterPicker
												colors={[
													"#FFFFFF",
													"#E8E8E8",
													"#797979",

													APP_COLOURS.BG2,
													"#EB144C",
													"#EEF810",
													APP_COLOURS.BLUE,
												]}
												color={
													this.state.app_properties.mainFontColor
												}
												onChangeComplete={(color) => {
													this.setState({
														app_properties: {
															...this.state.app_properties,
															mainFontColor: color.hex,
														},
													});
												}}
											/>
										</View> */}
									</View>

									<View
										style={{
											flex: 1,
											padding: 16,
											borderRadius: 15,
											backgroundColor: "#E8E8E8",
											alignItems: "center",
											justifyContent: "center",
											display: "none",
										}}>
										<Text
											style={{
												color: APP_COLOURS.BG,
												fontSize: 11,
												opacity: 0.6,
											}}>
											<Font weight={700} family='Montserrat'>
												Unique ID
											</Font>
										</Text>
										<Text
											style={{
												color: APP_COLOURS.BG,
												fontSize: 15,
												margin: 5,
											}}>
											<Font weight={700} family='Montserrat'>
												{this.state.uuid}
											</Font>
										</Text>
									</View>

									<TouchableOpacity
										disabled={this.state.addingAppStyle}
										style={{
											width: this.state.addingAppStyle
												? "auto"
												: "100%",
											padding: 10,
											display:
												this.state.passcode !== ""
													? "flex"
													: "none",
										}}
										onPress={async () => {
											await this.setState({
												addingAppStyle: true,
											});
											let obj = {
												uuid: this.state.uuid,
												date_created: this.state.date_created,
												title: this.state.title,
												description: this.state.description,
												app_properties: this.state.app_properties,
											};
											await this.props.addAppProperties(obj);
										}}>
										<View
											style={{
												backgroundColor: APP_COLOURS.BLUE,
												width: this.state.addingAppStyle
													? "auto"
													: "100%",
												padding: 13,
												height: 45,
												borderRadius: 25,
												justifyContent: "center",
												alignItems: "center",
											}}>
											{!this.state.addingAppStyle ? (
												<Text
													style={{
														alignItems: "center",
														justifyContent: "center",
														color: "#FFF",
														fontSize: 15,
													}}>
													<Font weight={700} family='Montserrat'>
														Done
													</Font>
												</Text>
											) : (
												<ActivityIndicator color={"#FFF"} />
											)}
										</View>
									</TouchableOpacity>
								</View>
							</ScrollView>
						</View>
					</View>
				</View>
			</Modal>
		);
	}
}
