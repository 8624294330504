import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import moment from "moment";
import Font from "react-font";
import logoWhite from "../../../assets/images/whiteLogo.png";
import tick from "./../../../assets/images/tick.png";
import close from "../../../assets/images/close.png";
import { api_newStaff } from "../../Api";
// import Memberships from "./Memberships";
// import BankingDetails from "./BankingDetails";
import CellNumber from "./CellNumber";
import EmailInput from "./EmailInput";
import IDNumberInput from "./IDNumberInput";
import FullName from "./FullName";
import DateOfBirth from "./DateOfBirth";
import GeneralField from "./GeneralField";
import RSAIDInput from "./RSAIDInput";

import Policy from "./Policy";
// import OptIn from "./OptIn";
import Termsandconditions from "./Termsandconditions";
import EmployeeIDField from "./EMPLOYEENUMBER";
import RetailerSearchBar from "../CrudRetailer/RetailerSearchBar";
import MallsSearchBar from "../CrudMalls/MallsSearchBar/MallsSearchBar";
import BRANDMULTISELECT from "../CrudBrand/BrandsMultiSelect/BRANDMULTISELECT";
import ManagerSearchBar from "../ManagerSearchBar";

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function passcodeFuc(n) {
	var add = 1,
		max = 12 - add; // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

	if (n > max) {
		return passcodeFuc(max) + passcodeFuc(n - max);
	}
	max = Math.pow(10, n + add);
	var min = max / 10; // Math.pow(10, n) basically
	var number = Math.floor(Math.random() * (max - min + 1)) + min;

	return ("" + number).substring(add);
}

export default class NewStaffForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedMembership: "",
			avatar: "0",
			first_name: "",
			last_name: "",
			email: "",
			cell: "",
			gender: "",
			position: "",
			permission: "Staff",
			id_passport: "",
			dob: "",
			address: "",
			next_of_kin_name: "",
			next_of_kin_cell: "",
			bankingDetails: "",
			loading: false,
			brands: [],
			debtorIdType: "",
		};
		this._timeout = 500;
	}

	newStaffFunc = async () => {
		let accessToken = await sessionStorage.getItem("accessToken");
		let ud = await JSON.parse(sessionStorage.getItem("user_data"));
		let now = moment().format("MMM DD, YYYY HH:mm:ss");
		// let referrer = {
		// 	first_name: ud.first_name,
		// 	last_name: ud.last_name,
		// 	username: ud.username,
		// 	portal: "admin",
		// 	id: ud.id,
		// 	signup_date: now,
		// };

		this.setState({
			loading: true,
			errorLogin: false,
		});

		let passcode = passcodeFuc(5);

		let {
			avatar,
			first_name,
			last_name,
			email,
			cell,
			gender,
			dob,
			id_passport,
			position,
			permission,
			mall,
			retailer,
			brands,
		} = this.state;

		let obj = {
			first_name,
			last_name,
			email,
			cell,
			gender,
			dob,
			id_passport,
			avatar,
			position,
			permission,
			passcode,
			mall,
			retailer,
			brands,
			status: "Active",
			last_seen: "",
		};

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify(obj),
		};

		// console.log(api_newStaff, obj);
		// return;

		await fetch(api_newStaff, requestOptions)
			.then(async (response) => {
				console.log("response =====", response);
				if (response.status === 200) {
					this.props.closeModal();
				} else if (response.status === 400) {
					this.setState({
						loading: false,
						signupStep: "step2",
						maxAttemptsExceeded: true,
					});
				} else if (response.status === 409) {
					this.setState({
						loading: false,
						signupStep: "step2",
						userExistsModal: true,
					});
				} else if (response.status === 422) {
					this.setState({
						errorViewAll: true,
						loading: false,
						signupStep: "step1",
						signupError: true,
					});
				} else {
					// this.setState({});
					// alert("OTP error");
					// console.log("OTP ERROR");
					this.setState({
						loading: false,
						signupStep: "step1",
						otpError: true,
					});
				}
			})
			.catch((error) => {
				this.setState({ loading: false });
			});
	};

	render() {
		let remaining = 0;
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: "rgba(52, 52, 52, 0.6)",
					position: "absolute",
					top: 0,
					width: "100%",
					height: "100%",
				}}>
				<ScrollView
					contentContainerStyle={{
						padding: 20,
					}}>
					<View
						style={{
							width: "100%",
							// backgroundColor: "#FFF",
							backgroundColor: "#F7F7F7",
							borderRadius: 20,
							padding: 20,
							maxWidth: 500,
							marginRight: "auto",
							marginLeft: "auto",
						}}>
						<View
							style={{
								position: "absolute",
								zIndex: 99,
								top: 10,
								left: 10,
							}}>
							<TouchableOpacity
								onPress={() => this.props.closeModal(true)}>
								<Image
									source={close}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							</TouchableOpacity>
						</View>

						<View
							style={{
								// width: "100%",
								flexDirection: "row",
								padding: 3,
								alignItems: "center",
								marginBottom: 10,
							}}>
							{/* <View
								style={{
									height: 150,
									width: 120,
									borderRadius: 10,
									borderWidth: 3,
									borderColor: "#FFF",
									backgroundColor: APP_COLOURS.BLACK,
									marginRight: 20,
									alignItems: "center",
									justifyContent: "center",
									top: 0,
									// left: 45,
								}}>
								<Image
									source={logoWhite}
									style={{
										width: 80,
										height: 80,
										resizeMode: "contain",
									}}
								/>
							</View> */}

							<Text
								style={{
									color: APP_COLOURS.BLACK,
									fontSize: 15,
								}}>
								<Font weight={700} family='Montserrat'>
									NEW STAFF MEMBER FORM
								</Font>
							</Text>
						</View>

						<View
							style={{
								paddingLeft: 13,
							}}>
							<View
								style={{
									flex: 1,
									// flexWrap: "wrap",
									marginBottom: 10,
								}}>
								{/* <Text
									style={{
										color: APP_COLOURS.BLACK,
										fontSize: 13,
										marginBottom: 15,
									}}>
									<Font weight={700} family='Montserrat'>
										Member details
									</Font>
								</Text> */}

								<View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												paddingLeft: 10,
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Permission
												</Font>
											</Text>
										</View>

										<View
											style={{
												width: "100%",
												flexDirection: "row",
												marginBottom: 10,
											}}>
											<TouchableOpacity
												style={{
													flex: 1,
													marginLeft: 5,
												}}
												onPress={async () => {
													this.setState({
														permission: "Manager",
													});
												}}>
												<View
													style={{
														backgroundColor:
															this.state.permission === "Manager"
																? APP_COLOURS.BLACK
																: "#FFF",
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLACK,
														flex: 1,
														padding: 12,
														borderRadius: 12,
														justifyContent: "center",
														alignItems: "center",
													}}>
													<Text
														style={{
															alignItems: "center",
															justifyContent: "center",
															color:
																this.state.permission ===
																"Manager"
																	? "#FFF"
																	: APP_COLOURS.BLACK,
															fontSize: 15,
														}}>
														<Font
															weight={
																this.state.permission ===
																"Manager"
																	? 700
																	: 500
															}
															family='Montserrat'>
															Manager
														</Font>
													</Text>
												</View>
											</TouchableOpacity>
											<TouchableOpacity
												style={{
													flex: 1,
													marginLeft: 5,
												}}
												onPress={async () => {
													this.setState({
														permission: "Staff",
													});
												}}>
												<View
													style={{
														backgroundColor:
															this.state.permission === "Staff"
																? APP_COLOURS.BLACK
																: "#FFF",
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLACK,
														flex: 1,
														padding: 12,
														borderRadius: 12,
														justifyContent: "center",
														alignItems: "center",
													}}>
													<Text
														style={{
															alignItems: "center",
															justifyContent: "center",
															textAlign: "center",
															color:
																this.state.permission ===
																"Staff"
																	? "#FFF"
																	: APP_COLOURS.BLACK,
															fontSize: 15,
														}}>
														<Font
															weight={
																this.state.permission ===
																"Staff"
																	? 700
																	: 500
															}
															family='Montserrat'>
															Staff
														</Font>
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									</div>
								</View>

								<View
									style={{
										height: 2,
										borderBottomWidth: 0.5,
										borderBottomColor: "#797979",
										width: "100%",
										marginBottom: 20,
									}}
								/>

								<View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={
												{
													// justifyContent: "center",
												}
											}>
											<View
												style={{
													flexDirection: "row",
													marginTop: 5,
													// padding: 3,
													alignItems: "center",
													justifyContent: "center",
													// paddingLeft: 16,
													width: "100%",
												}}>
												<Text
													style={{
														color: APP_COLOURS.BLACK,
														fontSize: 12,
													}}>
													<Font weight={500} family='Montserrat'>
														Full Name
													</Font>
												</Text>
											</View>
										</View>

										<FullName
											first_name={(d) =>
												this.setState({ first_name: d })
											}
											last_name={(d) =>
												this.setState({ last_name: d })
											}
										/>
									</div>
								</View>

								<View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												paddingLeft: 10,
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													ID type
												</Font>
											</Text>
										</View>

										<View
											style={{
												width: "100%",
												flexDirection: "row",
												marginBottom: 10,
											}}>
											<TouchableOpacity
												style={{
													flex: 1,
												}}
												onPress={async () => {
													this.setState({
														debtorIdType: "2",
														genderSet: true,
														setDOB: "",
														gender: "",

														id_passport: "",
														dob: "",
													});
												}}>
												<View
													style={{
														backgroundColor:
															this.state.debtorIdType === "2"
																? APP_COLOURS.BLACK
																: "#FFF",
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLACK,
														// height: "auto",
														flex: 1,
														padding: 12,
														borderRadius: 12,
														justifyContent: "center",
														alignItems: "center",
													}}>
													<Text
														style={{
															alignItems: "center",
															justifyContent: "center",
															color:
																this.state.debtorIdType === "2"
																	? "#FFF"
																	: APP_COLOURS.BLACK,
															fontSize: 15,
														}}>
														<Font
															weight={
																this.state.debtorIdType === "2"
																	? 700
																	: 500
															}
															family='Montserrat'>
															RSA ID
														</Font>
													</Text>
												</View>
											</TouchableOpacity>
											<TouchableOpacity
												style={{
													flex: 1,
													marginLeft: 5,
												}}
												onPress={async () => {
													this.setState({
														debtorIdType: "1",
														genderSet: false,
														setDOB: "",
														gender: "",

														id_passport: "",
														dob: "",
													});
												}}>
												<View
													style={{
														backgroundColor:
															this.state.debtorIdType === "1"
																? APP_COLOURS.BLACK
																: "#FFF",
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLACK,
														flex: 1,
														padding: 12,
														borderRadius: 12,
														justifyContent: "center",
														alignItems: "center",
													}}>
													<Text
														style={{
															alignItems: "center",
															justifyContent: "center",
															color:
																this.state.debtorIdType === "1"
																	? "#FFF"
																	: APP_COLOURS.BLACK,
															fontSize: 15,
														}}>
														<Font
															weight={
																this.state.debtorIdType === "1"
																	? 700
																	: 500
															}
															family='Montserrat'>
															Passport
														</Font>
													</Text>
												</View>
											</TouchableOpacity>
											<TouchableOpacity
												style={{
													flex: 1,
													marginLeft: 5,
												}}
												onPress={async () => {
													this.setState({
														debtorIdType: "3",
														genderSet: false,
														setDOB: "",
														gender: "",
														id_passport: "",
														dob: "",
													});
												}}>
												<View
													style={{
														backgroundColor:
															this.state.debtorIdType === "3"
																? APP_COLOURS.BLACK
																: "#FFF",
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLACK,
														flex: 1,
														padding: 12,
														borderRadius: 12,
														justifyContent: "center",
														alignItems: "center",
													}}>
													<Text
														style={{
															alignItems: "center",
															justifyContent: "center",
															textAlign: "center",
															color:
																this.state.debtorIdType === "3"
																	? "#FFF"
																	: APP_COLOURS.BLACK,
															fontSize: 15,
														}}>
														<Font
															weight={
																this.state.debtorIdType === "3"
																	? 700
																	: 500
															}
															family='Montserrat'>
															Temporary Residence
														</Font>
													</Text>
												</View>
											</TouchableOpacity>
										</View>

										<View
											style={{
												display:
													this.state.debtorIdType === ""
														? "none"
														: "flex",
											}}>
											{this.state.debtorIdType === "2" ? (
												<RSAIDInput
													accessToken={this.props.accessToken}
													title={
														this.state.debtorIdType === "1"
															? "Passport number"
															: this.state.debtorIdType === "2"
															? "ID Number"
															: "Temporary Residence ID number"
													}
													defaultValue={this.state.id_passport}
													setParentState={(
														d,
														dateOfBirth,
														gender,
													) => {
														this.setState({
															id_passport: d,
															dob: dateOfBirth,
															setDOB: dateOfBirth,
															gender: gender,
															genderSet: true,
														});
													}}
												/>
											) : (
												<IDNumberInput
													title={
														this.state.debtorIdType === "1"
															? "Passport number"
															: this.state.debtorIdType === "2"
															? "ID Number"
															: "Temporary Residence ID number"
													}
													setParentState={(d) =>
														this.setState({
															id_passport: d,
														})
													}
												/>
											)}
										</View>
									</div>
								</View>

								{/* <View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Employee ID
												</Font>
											</Text>
										</View>

										<EmployeeIDField
											accessToken={this.props.accessToken}
											title='Employee ID'
											defaultValue={this.state.employee_id}
											setParentState={(d) => {
												this.setState({
													employee_id: d,
												});
											}}
										/>
									</div>
								</View> */}

								<View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												paddingLeft: 10,
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Date of birth
												</Font>
											</Text>
										</View>

										<DateOfBirth
											setDOB={this.state.setDOB}
											setParentState={(d) => {
												this.setState({
													dob: d,
												});
											}}
										/>
									</div>
								</View>

								<View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											padding: 3,
											// maxWidth: 380,
											marginBottom: 10,
										}}>
										<View
											style={
												{
													// justifyContent: "center",
												}
											}>
											<View
												style={{
													flexDirection: "row",
													marginTop: 5,
													paddingLeft: 10,
													width: "100%",
												}}>
												<Text
													style={{
														color: APP_COLOURS.BLACK,
														fontSize: 12,
													}}>
													<Font weight={500} family='Montserrat'>
														Gender
													</Font>
												</Text>
											</View>
										</View>
										<View
											style={{
												width: "100%",
												flexDirection: "row",
											}}>
											<TouchableOpacity
												style={{
													flex: 1,
													marginRight: 10,
												}}
												disabled={this.state.genderSet}
												onPress={async () => {
													this.setState({
														gender: "male",
													});
												}}>
												<View
													style={{
														backgroundColor:
															this.state.gender === "male"
																? APP_COLOURS.BLACK
																: "#FFF",
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLACK,
														height: "auto",
														padding: 12,
														borderRadius: 12,
														justifyContent: "center",
														alignItems: "center",
													}}>
													<Text
														style={{
															alignItems: "center",
															justifyContent: "center",
															color:
																this.state.gender === "male"
																	? "#FFF"
																	: APP_COLOURS.BLACK,
															fontSize: 15,
														}}>
														<Font
															weight={
																this.state.gender === "male"
																	? 700
																	: 500
															}
															family='Montserrat'>
															Male
														</Font>
													</Text>
												</View>
											</TouchableOpacity>
											<TouchableOpacity
												style={{
													flex: 1,
												}}
												disabled={this.state.genderSet}
												onPress={async () => {
													this.setState({
														gender: "female",
													});
												}}>
												<View
													style={{
														backgroundColor:
															this.state.gender === "female"
																? APP_COLOURS.BLACK
																: "#FFF",
														borderWidth: 0.3,
														borderColor: APP_COLOURS.BLACK,
														height: "auto",
														padding: 12,
														borderRadius: 12,
														justifyContent: "center",
														alignItems: "center",
													}}>
													<Text
														style={{
															alignItems: "center",
															justifyContent: "center",
															color:
																this.state.gender === "female"
																	? "#FFF"
																	: APP_COLOURS.BLACK,
															fontSize: 15,
														}}>
														<Font
															weight={
																this.state.gender === "female"
																	? 700
																	: 500
															}
															family='Montserrat'>
															Female
														</Font>
													</Text>
												</View>
											</TouchableOpacity>
										</View>
									</div>
								</View>

								<View
									style={{
										justifyContent: "center",
										// maxWidth: 280,
										marginBottom: 10,
										padding: 3,
									}}>
									<View
										style={
											{
												// justifyContent: "center",
											}
										}>
										<View
											style={{
												flexDirection: "row",
												marginTop: 5,
												// padding: 3,
												paddingLeft: 10,
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Email
												</Font>
											</Text>
										</View>
									</View>
									<EmailInput
										textAlign={"center"}
										setParentState={(d) =>
											this.setState({
												email: d,
											})
										}
									/>
								</View>
								<View
									style={{
										justifyContent: "center",
										// maxWidth: 280,
										marginBottom: 10,
										padding: 3,
									}}>
									<View
										style={
											{
												// justifyContent: "center",
											}
										}>
										<View
											style={{
												flexDirection: "row",
												marginTop: 5,
												paddingLeft: 10,
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Cell number
												</Font>
											</Text>
										</View>
									</View>
									<CellNumber
										title={"Cell number"}
										textAlign={"center"}
										setParentState={(d) =>
											this.setState({
												cell: d,
											})
										}
									/>
								</View>

								{/* <View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												// alignItems: "center",
												// justifyContent: "center",
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 15,
												}}>
												<Font weight={500} family='Montserrat'>
													App credentials
												</Font>
											</Text>
										</View>

										<View
											style={{
												flex: 1,
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "row",
												marginBottom: 5,
											}}>
											<Text
												style={{
													flex: 1,
													color: APP_COLOURS.BLACK,
													fontSize: 12,
													padding: 5,
												}}>
												<Font weight={500} family='Montserrat'>
													Username
												</Font>
											</Text>
											<GeneralField
												title={"username"}
												textAlign={"left"}
												setParentState={(d) =>
													this.setState({
														username: d,
													})
												}
											/>
										</View>

										<View
											style={{
												flex: 1,
												justifyContent: "center",
												alignItems: "center",
												flexDirection: "row",
												marginBottom: 5,
											}}>
											<Text
												style={{
													flex: 1,
													color: APP_COLOURS.BLACK,
													fontSize: 12,
													padding: 5,
												}}>
												<Font weight={500} family='Montserrat'>
													Passcode
												</Font>
											</Text>
											<View
												style={{
													flex: 2,
												}}>
												<GeneralField
													title={"Passcode"}
													textAlign={"left"}
													setParentState={(d) =>
														this.setState({
															passcode: d,
														})
													}
												/>
											</View>
										</View>
									</div>
								</View> */}

								<View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												paddingLeft: 10,
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Employee ID
												</Font>
											</Text>
										</View>

										<GeneralField
											title={"Employee ID"}
											textAlign={"center"}
											setParentState={(d) =>
												this.setState({
													employee_id: d,
												})
											}
										/>
									</div>
								</View>

								<View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												// alignItems: "center",
												// justifyContent: "center",
												paddingLeft: 10,
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Position
												</Font>
											</Text>
										</View>

										<GeneralField
											title={"Position"}
											textAlign={"center"}
											setParentState={(d) => {
												console.log("position", d);
												this.setState({
													position: d,
												});
											}}
										/>
									</div>
								</View>

								<View
									style={{
										display:
											this.state.permission === "Manager"
												? "none"
												: "flex",
									}}>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Manager
												</Font>
											</Text>
										</View>

										<ManagerSearchBar
											{...this.props}
											selectedData={async (d) => {
												console.log(d);
												await this.setState({
													manager: d,
												});
											}}
										/>

										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Mall
												</Font>
											</Text>
										</View>

										<MallsSearchBar
											{...this.props}
											selectedData={async (d) => {
												console.log(d);
												await this.setState({
													mall: d,
												});
											}}
										/>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Retailer
												</Font>
											</Text>
										</View>
										<RetailerSearchBar
											selectedData={async (d) => {
												console.log(d);
												await this.setState({
													retailer: d,
												});
											}}
										/>
									</div>
								</View>

								<View
									style={{
										display:
											this.state.permission === "Manager"
												? "none"
												: "flex",
									}}>
									<BRANDMULTISELECT
										selected={(dd) =>
											this.setState({
												brands: dd,
											})
										}
									/>
								</View>

								{/* <View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Company name
												</Font>
											</Text>
										</View>

										<GeneralField
											title={"company_name"}
											textAlign={"center"}
											setParentState={(d) =>
												this.setState({
													company_name: d,
												})
											}
										/>
									</div>
								</View> */}

								{/* <View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Work address
												</Font>
											</Text>
										</View>

										<GeneralField
											title={"Work address"}
											textAlign={"center"}
											setParentState={(d) =>
												this.setState({
													work_address: d,
												})
											}
										/>
									</div>
								</View> */}

								{/* <View>
									<div
										style={{
											flex: 1,
											alignItems: "center",
											justifyContent: "center",
											marginBottom: 10,
											padding: 3,
										}}>
										<View
											style={{
												flexDirection: "row",
												marginBottom: 5,
												alignItems: "center",
												justifyContent: "center",
												width: "100%",
											}}>
											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													Work contact number
												</Font>
											</Text>
										</View>

										<CellNumber
											title={"Work contact number"}
											textAlign={"center"}
											setParentState={(d) =>
												this.setState({
													work_contact: d,
												})
											}
										/>
									</div>
								</View> */}
							</View>

							<View
								style={{
									// flex: 1,
									marginTop: 50,
								}}>
								<div
									style={{
										flex: 1,
										// alignItems: "center",
										// justifyContent: "center",
										marginBottom: 50,
										padding: 3,
									}}>
									<Termsandconditions
										screenWidth={this.props.screenWidth}
										screenHeight={this.props.screenHeight}
										setParentState={(d) =>
											this.setState({
												tandcs: d,
											})
										}
									/>

									{/* <OptIn
										screenWidth={this.props.screenWidth}
										screenHeight={this.props.screenHeight}
										setParentState={(d) =>
											this.setState({
												opt_in: d,
											})
										}
									/> */}
									<View
										style={{
											height: 10,
										}}
									/>
									<Policy
										screenWidth={this.props.screenWidth}
										screenHeight={this.props.screenHeight}
										setParentState={(d) =>
											this.setState({
												policy: d,
											})
										}
									/>
								</div>
							</View>

							<Text
								style={{
									color: APP_COLOURS.BLACK,
									fontSize: 12,
									marginBottom: 10,
									marginTop: 20,
								}}>
								<Font weight={500} family='Montserrat'>
									Required
								</Font>
							</Text>

							<View
								style={{
									marginBottom: 10,
								}}>
								{[
									{
										title: "First name",
										state: "first_name",
									},
									{
										title: "Last name",
										state: "last_name",
									},
									{
										title: "email",
										state: "email",
									},
									{
										title: "Cell number",
										state: "cell",
									},
									{
										title: "Gender",
										state: "gender",
									},

									{
										title: "ID / Passport",
										state: "id_passport",
									},
									{
										title: "Date  of birth",
										state: "dob",
									},

									{
										title: "Permission level",
										state: "permission",
									},
									{
										title: "Position",
										state: "position",
									},
									{
										title: "Mall",
										state: "mall",
									},
									{
										title: "Retailer",
										state: "retailer",
									},
									{
										title: "User policy",
										state: "tandcs",
									},
									{
										title: "Privacy policy",
										state: "policy",
									},
								].map((d, i) => {
									let rem = this.state[d.state] !== "" ? 0 : 1;

									remaining = remaining + rem;

									return (
										<View
											key={i}
											style={{
												flexDirection: "row",
												alignItems: "center",
												marginBottom: 2,
												paddingLeft: 10,
											}}>
											{this.state[d.state] !== "" &&
											typeof this.state[d.state] !== "undefined" ? (
												<Image
													source={tick}
													style={{
														width: 16,
														height: 16,
														resizeMode: "contain",
														marginRight: 10,
													}}
												/>
											) : (
												<View
													style={{
														width: 16,
														marginRight: 10,
													}}>
													<Text> - </Text>
												</View>
											)}

											<Text
												style={{
													color: APP_COLOURS.BLACK,
													fontSize: 12,
												}}>
												<Font weight={500} family='Montserrat'>
													{d.title}
												</Font>
											</Text>
										</View>
									);
								})}
							</View>
						</View>

						{/* {this.state.last_nameValid && this.state.first_nameValid ? ( */}
						<TouchableOpacity
							style={{
								width: "100%",
								marginTop: 30,
							}}
							disabled={this.state.loading}
							onPress={async () => {
								this.newStaffFunc();
							}}>
							<View
								style={{
									backgroundColor: APP_COLOURS.BLACK,
									width: "100%",
									height: 45,
									borderRadius: 25,
									justifyContent: "center",
									alignItems: "center",
								}}>
								{!this.state.loading ? (
									<Text
										style={{
											alignItems: "center",
											justifyContent: "center",
											color: "#FFF",
											fontSize: 15,
										}}>
										<Font weight={700} family='Montserrat'>
											Save & Next
										</Font>
									</Text>
								) : (
									<ActivityIndicator color={"#FFF"} />
								)}
							</View>
						</TouchableOpacity>
						{/* ) : (
							<View />
						)} */}
					</View>
				</ScrollView>
			</View>
		);
	}
}
