import React, { Component } from "react";
import { View, Text } from "react-native";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: false,
			position: "top",
		},
		title: {
			display: false,
			text: "Chart.js Line Chart",
		},
	},
};

const labels = [
	// "Jan 22",
	// "Feb 22",
	// "Mar 22",
	// "Apr 22",
	"May 22",
	"Jun 22",
	"Jul 22",
	"Aug 22",
	"Sep 22",
	"Oct 22",
	"Nov 22",
	"Dec 22",
];

export default class LineChart extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Line
				style={{
					backgroundColor: "transparent",
				}}
				redraw={false}
				options={options}
				data={this.dataSet(this.props.data)}
			/>
		);
	}

	dataSet = (d) => {
		const data = {
			labels,
			datasets: [
				{
					fill: true,
					label: this.props.label,
					data: d, // [1, 2, 3, 4, 4, 5, 6, 3, 4, 5, 6, 3, 3, 4, 4, 5, 6, 9],
					borderColor: "rgba(0, 255, 249, 1)",
					backgroundColor: "rgba(0, 255, 249, 0.2)",
					lineTension: 0.4,
					pointRadius: 0,
					pointHoverRadius: 5,
					pointHitRadius: 50,
				},
				{
					fill: true,
					label: "Required average",
					data: Array(12).fill(this.props.average),
					borderColor: "rgba(255, 255, 255, 0.1)",
					backgroundColor: "rgba(255, 255, 255, 0.1)",
					lineTension: 0.4,
				},
				{
					fill: false,
					label: "Max average",
					data: [this.props.max],
					borderColor: "rgba(255, 255, 255, 0.1)",
					// backgroundColor: "rgba(256, 100, 96, 0.1)",
					lineTension: 0.4,
				},
				// {
				// 	fill: true,
				// 	label: "Required average",
				// 	data: [8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8],
				// 	borderColor: "rgba(256, 100, 96, 1)",
				// 	backgroundColor: "rgba(256, 100, 96, 0.1)",
				// },
				// {
				// 	fill: false,
				// 	label: "Lowest average",
				// 	data: [0],
				// 	borderColor: "rgba(255, 255, 255, 0.1)",
				// 	// backgroundColor: "rgba(256, 100, 96, 0.1)",
				// },
			],
		};
		return data;
	};
}
