import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
import Fade from "react-reveal/Fade";
import LineChart from "./PerformanceChart/index";
import perc from "../../../../assets/images/knowledgebase.png";
import bracketLeft from "../../../../assets/images/bracketLeft.svg";
import moment from "moment";

function randomRange(n, min, max) {
	var values = [],
		i = max;
	while (i >= min) values.push(i--);
	var results = [];
	var maxIndex = max;
	for (i = 1; i <= n; i++) {
		maxIndex--;
		var index = Math.floor(maxIndex * Math.random());
		results.push(values[index]);
		values[index] = values[maxIndex];
	}
	return results.filter((d) => typeof d !== "undefined");
}

function newRandomNumber(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default class BAAttendance extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: "",
			data: [
				{
					title: "On-time attendance",
					percentage: 0.99,
					percentageStr: `${newRandomNumber(40, 100)}%`,
					score: newRandomNumber(0, 10),
				},
				{
					title: "Sick days",
					percentage: 0.12,
					percentageStr: `${newRandomNumber(0, 10)}`,
					score: newRandomNumber(0, 10),
				},
				{
					title: "Average hours worked",
					percentage: 0.99,
					percentageStr: `${newRandomNumber(0, 12)} hrs`,
					score: newRandomNumber(0, 10),
				},
				{
					title: "Average lunch break",
					percentage: 0.99,
					percentageStr: `${newRandomNumber(0, 60)} min`,
					score: newRandomNumber(0, 10),
				},
				{
					title: "After hours worked",
					percentage: 0.5,
					percentageStr: `${newRandomNumber(1, 50)} hrs`,
					score: newRandomNumber(0, 10),
				},
				{
					title: "After hours percentage",
					percentage: 0.5,
					percentageStr: `${newRandomNumber(1, 10)}%`,
					score: newRandomNumber(0, 10),
				},
				{
					title: "None attendance",
					percentage: 0.99,
					percentageStr: `${newRandomNumber(0, 5)}%`,
					score: newRandomNumber(0, 3),
				},
			],
		};
	}

	render() {
		let barWidth = 200;
		let overallScore = 0;
		return (
			<Fade>
				<View
					style={{
						margin: 20,
						// borderBottomColor: APP_COLOURS.TRANSHALO,
						// borderBottomWidth: 0.5,
						paddingBottom: 20,
						marginTop: 40,
					}}>
					<View
						style={{
							flexDirection: "row",
						}}>
						<View
							style={{
								flex: 1,
							}}>
							<View
								onMouseEnter={() =>
									this.setState({
										hover: "",
									})
								}
								style={{
									width: "100%",
									justifyContent: "space-between",
									flexDirection: "row",
									paddingBottom: 10,
									borderBottomColor: APP_COLOURS.TRANSHALO,
									borderBottomWidth: 0.5,
									alignItems: "flex-end",
									marginBottom: 10,
								}}>
								<View
									style={{
										position: "absolute",
										left: -12,
										top: -15,
									}}>
									<Text
										style={{
											fontSize: 13,
											color: APP_COLOURS.WHITE,
											fontWeight: "300",
											fontFamily: "Avenir",
										}}>
										STAFF
										<Text
											style={{
												fontWeight: "900",
											}}>
											TRAQ
										</Text>
									</Text>
								</View>
								<Text
									style={{
										fontFamily: "Avenir",
										fontSize: 25,
										fontWeight: "100",
										color: APP_COLOURS.WHITE,
										marginBottom: 10,
									}}>
									Attendance
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<View>
										{this.state.data.map((dd) => {
											overallScore =
												overallScore + parseFloat(dd.score);
											return (
												<View
													onMouseEnter={() =>
														this.setState({
															hover: dd.title,
														})
													}
													style={{
														flexDirection: "row",
														marginBottom: 10,
														alignItems: "center",
														// padding: 5,
														paddingLeft: 4,
														paddingRight: 10,
														borderRadius: 5,
														backgroundColor:
															this.state.hover === dd.title
																? APP_COLOURS.TRANSHALO
																: "transparent",
													}}>
													<View
														style={{
															width: 200,
														}}>
														<Text
															style={{
																fontFamily: "Avenir",
																fontSize: 16,
																fontWeight: "200",
																color: APP_COLOURS.WHITE,
															}}>
															{dd.title}
														</Text>
													</View>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 20,
															fontWeight: "700",
															color: APP_COLOURS.WHITE,
															marginLeft: 5,
														}}>
														{dd.percentageStr}
													</Text>
												</View>
											);
										})}
									</View>
								</View>

								<View
									onMouseEnter={() =>
										this.setState({
											hover: "",
										})
									}
									style={{
										flexDirection: "row",
										alignItems: "center",
									}}>
									<View
										style={{
											justifyContent: "center",
											alignItems: "center",
											marginRight: 20,
											marginLeft: 30,
										}}>
										<Image
											source={bracketLeft}
											style={{
												width: 50,
												height: 250,
												resizeMode: "contain",
											}}
										/>
									</View>
									<View
										onMouseEnter={() =>
											this.setState({
												hover: "",
											})
										}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 20,
												fontWeight: "100",
												color: APP_COLOURS.WHITE,
												opacity: 0.6,
											}}>
											Overall score
										</Text>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 50,
												fontWeight: "500",
												color: APP_COLOURS.WHITE,
											}}>
											{overallScore}{" "}
											<Text
												style={{
													fontSize: 25,
													fontWeight: "100",
												}}>
												/ {this.state.data.length * 10}
											</Text>
										</Text>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 10,
												fontWeight: "100",
												color: APP_COLOURS.WHITE,
												opacity: 0.8,
												maxWidth: 150,
											}}>
											Time and attendance tracking is performed
											during usage and none useage of the app and
											cross referenced with the staff members
											schedule
										</Text>
									</View>
								</View>
							</View>
						</View>

						<View
							style={{
								flex: 1,
							}}></View>
					</View>

					<View
						style={{
							alignItems: "flex-end",
							marginTop: 30,
						}}>
						<Text
							style={{
								fontStyle: "italic",
								fontFamily: "Avenir",
								fontSize: 10,
								fontWeight: "100",
								color: APP_COLOURS.WHITE,
							}}>
							Attendance report
						</Text>
						<Text
							style={{
								fontStyle: "italic",
								fontFamily: "Avenir",
								fontSize: 10,
								fontWeight: "100",
								color: APP_COLOURS.WHITE,
							}}>
							{moment().format("MMMM, YYYY")}
						</Text>
					</View>
				</View>
			</Fade>
		);
	}
}
