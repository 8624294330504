import React from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	TextInput,
	TouchableOpacity,
	Image,
	ActivityIndicator,
} from "react-native";
import moment from "moment";
import { api_ } from "../../Api";
import { APP_COLOURS } from "../../APP_VARS";
import ExportToExcel from "../ExportToExcel";
import TotalSales from "./TotalSales";
import SalesByMallRetailer from "./SalesByMallRetailer";

function isOdd(num) {
	return num % 2;
}

class Dashboard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],

			filterByName: "",
			hover: "",
		};
	}

	async componentDidMount() {
		await this.getData();
	}

	getData = async () => {
		let url = api_ + "/audit/dashboard/submissions";
		await this.setState({
			loading: true,
			devices: [],
			showDelete: false,
			addNewDevice: false,
			viewDeviceProfile: false,
		});
		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${sessionStorage.getItem(
					"accessToken",
				)}`,
			},
			redirect: "follow",
		};
		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								data: responseData,
								loading: false,
							});

							this.createAnalyticsState(responseData);
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		const { data } = this.state;

		const keys = data.length > 0 ? Object.keys(data[0]) : [];

		const data_ = data
			.filter((d_) => {
				let filter = d_;

				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.first_name} ${d_.surname}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})
			.sort(
				(a, b) =>
					moment(b.createdAt).unix() < moment(a.createdAt).unix(),
			);

		return (
			<View
				style={[
					styles.container,
					{
						// flexDirection: "row",
					},
				]}>
				{/* <View
					style={{
						flex: 1,
						height: window.innerHeight / 2 - 200,
						borderWidth: 0.5,
						borderRadius: 10,
					}}>
					<View
						style={{
							flex: 1,
							flexDirection: "row",
						}}>
						<TotalSales />
						<SalesByMallRetailer />
					</View>
				</View> */}
				<View
					style={{
						flex: 1,
						height: window.innerHeight / 2 - 200,
						width: "100%",
						borderRadius: 10,
						padding: 10,
						backgroundColor: APP_COLOURS.BG3,
						flexWrap: "wrap",
						margin: 10,
					}}>
					<View
						style={{
							flexDirection: "row",
							paddingBottom: 15,
							borderBottomWidth: 0.5,
						}}>
						{data_.length > 0 ? (
							<ExportToExcel
								excel_doc_name={`traq_admin_safala_report_export-${moment().format(
									"DD-MM-YYYY-HH-MM-SS",
								)}`}
								data={data_}
							/>
						) : null}

						<TextInput
							ref={(ref) => {
								this.filterByName = ref;
							}}
							style={{
								padding: 8,
								fontSize: 16,
								borderRadius: 9,
								color: APP_COLOURS.WHITE,
								fontWeight: "200",
								fontFamily: "Avenir",
								borderWidth: 0.3,
								borderColor: APP_COLOURS.BG3,
								backgroundColor: APP_COLOURS.BG3,
								marginRight: 5,
								paddingLeft: 15,
							}}
							placeholderTextColor={"#797979"}
							autoCapitalize='words'
							clearButtonMode='while-editing'
							editable={true}
							placeholder={`Search `}
							onChangeText={(text) => {
								this.setState({
									filterByName: text,
								});
							}}
						/>

						<View
							style={{
								// flexDirection: "row",
								paddingLeft: 20,
							}}>
							<Text
								style={{
									fontSize: 24,
									fontWeight: "800",
									color: "#FFF",
								}}>
								{data_.length}
							</Text>
							<Text
								style={{
									fontSize: 10,
									flexDirection: "row",
									color: "#FFF",
								}}>
								Total submissions
								{this.state.loading ? (
									<ActivityIndicator
										color={APP_COLOURS.ORANGE}
									/>
								) : null}
							</Text>
						</View>
					</View>

					<View
						style={{
							width: "100%",
							paddingTop: 10,
						}}>
						<ScrollView>
							<ScrollView horizontal>
								<View
									style={{
										flex: 1,
										paddingBottom: 15,
									}}>
									<View
										style={{
											flexDirection: "row",
										}}>
										{data_.length > 0
											? Object.keys(data_[0]).map(
													(dd) => (
														<View
															style={{
																borderRightWidth: 0.5,
																borderRightColor:
																	"#797979",
																// paddingRight: 10,
															}}>
															<Text
																style={{
																	fontSize: 13,
																	color: "#FFF",
																	marginLeft: 3,
																	fontWeight:
																		"500",
																}}>
																{dd}
															</Text>
															{/* .slice(0, 20) */}
															{data_.map(
																(d, i) => (
																	<View
																		style={{
																			backgroundColor:
																				this
																					.state
																					.hover ===
																				i
																					? "#797979"
																					: isOdd(
																							i,
																					  )
																					? APP_COLOURS.BG3
																					: APP_COLOURS.BG4,
																			paddingRight: 10,
																			paddingLeft: 3,
																		}}>
																		<Text
																			ellipsizeMode='tail'
																			style={{
																				fontSize: 13,
																				color: "#FFF",
																				// paddingTop: 3,
																				// paddingBottom: 3,
																			}}>
																			{typeof d[
																				dd
																			] ===
																				"object" &&
																			d[
																				dd
																			] !==
																				null
																				? Object.keys(
																						d[
																							dd
																						],
																				  )
																						.map(
																							(
																								key,
																							) =>
																								`${key}: ${d[dd][key]}`,
																						)
																						.join(
																							", ",
																						)
																				: d[
																						dd
																				  ]}
																		</Text>
																	</View>
																),
															)}
														</View>
													),
											  )
											: null}
									</View>
								</View>
							</ScrollView>
						</ScrollView>
					</View>

					{/* <View style={{ flex: 1 }}>
						<ScrollView>
							{data_.length > 0
								? Object.keys(data_[0]).map((dd) => (
										<View
											style={{
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												// paddingRight: 10,
												width: "100%",
											}}>
											<View
												style={{
													width: "100%",
													padding: 15,
													borderBottomWidth: 0.5,
													backgroundColor: "#FFF",
													borderRadius: 12,
													marginBottom: 10,
												}}>
												<Text
													style={{
														fontWeight: "600",
														marginBottom: 10,
													}}>
													{dd}
												</Text>
												<View
													style={{
														borderWidth: 0.5,
														borderRadius: 5,
														padding: 10,
														flexDirection: "row",
													}}>
													<View style={{}}>
														{data_.map((d, i) => (
															<View
																style={{
																	justifyContent:
																		"center",
																	padding: 5,
																}}>
																<View
																	style={{
																		flexDirection:
																			"row",
																		alignItems:
																			"center",
																	}}>
																	<View
																		style={{
																			width: 14,
																			height: 14,
																			borderRadius: 4,
																			backgroundColor:
																				d.color,
																			marginRight: 5,
																		}}
																	/>
																	<Text
																		style={{
																			fontWeight:
																				"600",
																			width: 200,
																		}}>
																		vv
																	</Text>
																	<Text
																		style={{
																			fontWeight:
																				"300",
																			// width: 40,
																		}}>
																		{typeof d[
																			dd
																		] ===
																			"object" &&
																		d[
																			dd
																		] !==
																			null
																			? Object.keys(
																					d[
																						dd
																					],
																			  )
																					.map(
																						(
																							key,
																						) =>
																							`${key}: ${d[dd][key]}`,
																					)
																					.join(
																						", ",
																					)
																			: d[
																					dd
																			  ]}
																	</Text>
																	<Text
																		style={{
																			fontWeight:
																				"300",
																			// width: 60,
																		}}>
																		{d.percent *
																			100}{" "}
																		%
																	</Text>
																</View>
															</View>
														))}
													</View>
												</View>
											</View>
										</View>
								  ))
								: null}
						</ScrollView>
					</View> */}
				</View>
			</View>
		);
	}

	createAnalyticsState = (data) => {
		// Define a helper function to compare dates
		const compareDates = (date1, date2, isStartDate) => {
			if (!date1) return date2;
			const d1 = new Date(date1);
			const d2 = new Date(date2);
			return isStartDate ? (d1 < d2 ? d1 : d2) : d1 > d2 ? d1 : d2;
		};

		// Create an empty object to store our analytics
		const analytics = {};

		// Iterate over each item in the data array
		data.forEach((item) => {
			const allocation = item.allocation;

			// If this allocation isn't in the analytics object yet, add it
			if (!analytics[allocation]) {
				analytics[allocation] = {
					count: 1,
					earliestStartDate: item.dateCreated,
					latestEndDate: item.dateCreated,
				};
			} else {
				// Otherwise, increment the count and update the dates
				analytics[allocation].count++;
				analytics[allocation].earliestStartDate = compareDates(
					analytics[allocation].dateCreated,
					item.dateCreated,
					true,
				);
				analytics[allocation].latestEndDate = compareDates(
					analytics[allocation].dateCreated,
					item.dateCreated,
					false,
				);
			}
		});

		// Convert the analytics object to an array
		const analyticsArray = Object.keys(analytics).map((key) => ({
			allocation: key,
			count: analytics[key].count,
			earliestStartDate: analytics[key].earliestStartDate,
			latestEndDate: analytics[key].latestEndDate,
		}));
		console.log("analyticsArray", analyticsArray);

		this.setState({
			analyticsArray: analyticsArray,
		});
	};
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		padding: 16,
	},

	row: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 10,
	},
	header: {
		fontWeight: "bold",
		fontSize: 16,
	},
	cell: {
		fontSize: 16,
	},
});

export default Dashboard;
