import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import Font from "react-font";
import moment from "moment";
import close from "../../../assets/images/close.png";
import tick from "./../../../assets/images/tick.png";
import ImageUploader from "./ImageUploader";

function uuidv4() {
	return "xxxx-xxxxx-xxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

export default class NewDevice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uuid: uuidv4(),
			date_created: moment().format("MMM DD, YYYY HH:mm:ss"),
			title: "",
			base64String: "",
		};
	}

	render() {
		let form_fields = [
			{
				id: 2,
				editable: true,
				state: "title",
				title: "title",
				autoCapitalize: true,
				validation: "name",
			},
		];
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView>
						<View
							style={{
								width: "100%",
								maxWidth: 500,
								minWidth: 450,
								height: "auto",
								backgroundColor: "white",
								borderRadius: 20,
								justifyContent: "center",
								alignItems: "center",
								marginTop: 30,
								marginBottom: 30,
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: 10,
									left: 10,
								}}>
								<TouchableOpacity onPress={() => this.props.close()}>
									<Image
										source={close}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									width: "100%",
									padding: 20,
									height: "auto",
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 17 + this.state.fontSize,
										fontFamily: "Avenir Next",
										textAlign: "center",
										fontWeight: "600",
										marginBottom: 20,
									}}>
									Add retailer
								</Text>

								<View
									style={{
										width: "auto",

										flexDirection: "row",
										alignItems: "center",
										margin: 10,
										marginBottom: 16,
									}}>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 12,
											color: APP_COLOURS.PLACEHOLDER,
											fontWeight: "500",
											marginBottom: 4,
											position: "absolute",
											top: -12,
											left: 9,
											backgroundColor: "#FFF",
											padding: 4,
											borderRadius: 8,
										}}>
										Retailer name
									</Text>
									<TextInput
										style={{
											width: "100%",
											borderWidth: 1,
											borderColor: "#000",
											padding: 12,
											paddingLeft: 30,
											fontSize: 16,
											borderRadius: 8,
											backgroundColor: APP_COLOURS.WHITE,
											color: "#000",
											fontWeight: "200",
											fontFamily: "Avenir",
										}}
										placeholderTextColor={"#000"}
										autoCapitalize='words'
										clearButtonMode='while-editing'
										autoCorrect={false}
										placeholder={"Retailer name"}
										value={this.state.title}
										onChangeText={async (text) => {
											text = capitalizeName(text);
											await this.setState({
												title: text,
											});
										}}
									/>
								</View>

								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
										margin: 10,
										marginBottom: 16,

										width: "auto",
										borderWidth: 1,
										borderColor: "#000",
										padding: 12,
										paddingLeft: 30,
										fontSize: 16,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										color: "#000",
										fontWeight: "200",
										fontFamily: "Avenir",
									}}>
									<Text
										style={{
											opacity: this.state.unEditable ? 0.4 : 1,
											fontFamily: "Avenir",
											fontSize: 12,
											color: APP_COLOURS.PLACEHOLDER,
											fontWeight: "500",
											marginBottom: 4,
											position: "absolute",
											top: -12,
											left: 9,
											backgroundColor: "#FFF",
											padding: 4,
											borderRadius: 8,
										}}>
										Retailer logo image
									</Text>
									<ImageUploader
										base64String={(base64String) => {
											console.log(base64String);
											this.setState({
												base64String: base64String,
											});
										}}
									/>
									<Image
										source={`data:image/png;base64,${this.state.base64String}`}
										style={{
											height: 200,
											width: 300,
											resizeMode: "contain",
											position: "absolute",
										}}
									/>
								</View>
							</View>

							<View
								style={{
									flex: 1,
									padding: 16,
									borderRadius: 15,
									backgroundColor: "#E8E8E8",
									alignItems: "center",
									justifyContent: "center",
									display: "none",
								}}>
								<Text
									style={{
										color: APP_COLOURS.BG,
										fontSize: 11,
										opacity: 0.6,
									}}>
									<Font weight={700} family='Montserrat'>
										Unique ID
									</Font>
								</Text>
								<Text
									style={{
										color: APP_COLOURS.BG,
										fontSize: 15,
										margin: 5,
									}}>
									<Font weight={700} family='Montserrat'>
										{this.state.uuid}
									</Font>
								</Text>
							</View>

							<TouchableOpacity
								style={{
									width: this.state.addingRetailer ? "auto" : "100%",
									padding: 10,
									display:
										this.state.passcode !== "" ? "flex" : "none",
								}}
								onPress={async () => {
									await this.props.addRetailer(this.state);
								}}>
								<View
									style={{
										backgroundColor: APP_COLOURS.BLUE,
										width: this.state.addingRetailer
											? "auto"
											: "100%",
										padding: 13,
										height: 45,
										borderRadius: 25,
										justifyContent: "center",
										alignItems: "center",
									}}>
									{!this.state.addingRetailer ? (
										<Text
											style={{
												alignItems: "center",
												justifyContent: "center",
												color: "#FFF",
												fontSize: 15,
											}}>
											<Font weight={700} family='Montserrat'>
												Add retailer
											</Font>
										</Text>
									) : (
										<ActivityIndicator color={"#FFF"} />
									)}
								</View>
							</TouchableOpacity>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	}
}
