import React, { Component } from "react";
import { View, Text } from "react-native";

export default class ProductOverview extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",
					flex: 1,
					padding: 8,
					// width: "100%",
					height: this.props.screenHeight,
				}}>
				<View
					style={{
						flex: 1,
						marginTop: 16,
					}}></View>
				<View>
					<Text> Overview </Text>
				</View>
			</div>
		);
	}
}
