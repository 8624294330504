import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
// import moment from "moment";
// import Fade from "react-reveal/Fade";
import Font from "react-font";
import logo from "../../../assets/images/whiteLogo.png";
import tick from "./../../../assets/images/tick.png";
import bin from "./../../../assets/images/bin.png";
import close from "../../../assets/images/close.png";

import apple from "../../../assets/images/apple.png";
import android from "../../../assets/images/android.png";

import {
	api_addAppProperties,
	api_deleteAppProperties,
	api_getAppProperties,
} from "../../Api";
import moment from "moment";
import Add from "./Add";
import DevicePreviewer from "./DevicePreviewer";

export default class AppPropertiesCrud extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -3,
			showDelete: false,
			userGroups: [],

			addNewAppProperties: false,
			selectedUserGroup: 1,
			selecteedUserGroupName: "Staff",
			apps: [
				// {
				// 	title: "TRAQ Default App",
				// 	app_properties: {
				// 		backgroundcolour: "#171D25",
				// 		brandcolour: "#000000",
				// 		fontcolour: "#FFF",
				// 	},
				// },
			],
			filterByName: "",
		};
	}

	addNewAppProperties = () => {
		return (
			<Add
				addAppProperties={(d) => this.addAppProperties(d)}
				close={() =>
					this.setState({
						addNewAppProperties: false,
					})
				}
			/>
		);
	};

	addAppProperties = async (d) => {
		this.setState({
			addingAppProperties: true,
			errorLogin: false,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify(d),
		};

		await fetch(api_addAppProperties, requestOptions)
			.then(async (response) => {
				console.log("response =====", response);
				if (response.status === 200) {
					// alert("AppPropertiesadded");
					this.getAppProperties();
					this.setState({
						addNewAppProperties: false,
						addingAppProperties: false,
					});
				} else {
					alert("error adding device");
					this.setState({
						addingAppProperties: false,
					});
				}
			})
			.catch((error) => {
				this.setState({
					addingAppProperties: false,
				});
			});
	};

	getAppProperties = async () => {
		let url = api_getAppProperties;

		await this.setState({
			loading: true,
			AppProperties: [],
			showDelete: false,
			addNewAppPropertie: false,
			viewAppPropertieProfile: false,
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								apps: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	deleteAppProperties = async (id) => {
		let url = api_deleteAppProperties;

		await this.setState({
			loading: true,
			AppProperties: [],
		});

		let requestOptions = {
			method: "DELETE",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify({
				id: id,
			}),
		};

		await fetch(url, requestOptions)
			.then((response) => {
				if (response.status === 200) {
					this.getAppProperties();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert("Error deleting");
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	AppPropertieProfileModal = (d) => {
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						// justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<ScrollView>
						<View
							style={{
								height: 90,
							}}
						/>
						<View
							style={{
								width: "100%",
								maxWidth: 500,
								// height: "80%",
								backgroundColor: "#FFF",
								borderRadius: 20,
								// justifyContent: "center",
								// alignItems: "center",
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: 10,
									left: 10,
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											viewAppPropertieProfiles: false,
										})
									}>
									<Image
										source={close}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									width: "100%",
									padding: 20,
									height: "auto",
								}}>
								<View
									style={{
										justifyContent: "center",
										alignItems: "center",
										padding: 20,
									}}>
									<View
										style={{
											width: 110,
											height: 110,
											alignItems: "center",
											justifyContent: "center",
											borderRadius: 55,
											borderWidth: 1,
											borderColor: "#797979",
											backgroundColor: "#797979",
											shadowColor: "#000",
											shadowOffset: {
												width: 0,
												height: 2,
											},
											shadowOpacity: 0.25,
											shadowRadius: 7.84,
											elevation: 12,
										}}>
										<Image
											source={logo}
											style={{
												width: 70,
												height: 70,
												resizeMode: "contain",
											}}
										/>
									</View>
								</View>

								<Text
									allowFontScaling={false}
									style={{
										fontSize: 20,
										fontFamily: "Avenir Next",
										textAlign: "center",
										fontWeight: "600",
										marginBottom: 20,
									}}>
									{d.first_name} {d.last_name}
								</Text>

								<View
									style={{
										marginTop: 15,
									}}>
									<View
										style={{
											flex: 1,
											paddingLeft: 15,
										}}>
										<Text
											adjustsFontSizeToFit
											style={{
												color: "#000",
												fontSize: 10,
											}}>
											<Font weight={600} family='Montserrat'>
												Contacts
											</Font>
										</Text>
									</View>
									<View
										style={{
											padding: 15,
											paddingRight: 50,
										}}>
										<View
											style={{
												width: "auto",
												marginBottom: 16,
											}}>
											<View
												style={{
													// width: col1,
													flexDirection: "row",
													alignItems: "center",
													marginBottom: 5,
												}}>
												<Text
													style={{
														fontSize: 10,
														marginRight: 3,
														opacity: 0.6,
													}}>
													email:
												</Text>
												{d.userActive ? (
													<Image
														source={tick}
														style={{
															width: 12,
															height: 12,
															// borderRadius: 25,
															resizeMode: "contain",
															marginRight: 5,
														}}
													/>
												) : (
													<View />
												)}
											</View>

											<Text
												style={{
													width: 200,
													fontSize: 15,
													marginBottom: 10,
												}}>
												{d.username}
											</Text>

											<Text
												style={{
													fontSize: 10,
													marginBottom: 5,
													opacity: 0.6,
												}}>
												created at:
											</Text>
											<Text
												style={{
													fontSize: 13,
												}}>
												{moment(d.date_created).format(
													"MMM DD, YYYY",
												)}
											</Text>
										</View>
									</View>
								</View>

								<TouchableOpacity
									onPress={() => this.deleteAppProperties(d.id)}
									style={{
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Image
										source={bin}
										style={{
											width: 22,
											height: 22,
											resizeMode: "contain",
											marginRight: 5,
										}}
									/>
								</TouchableOpacity>
							</View>
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	render() {
		let col1 = 200,
			col2 = 100,
			col3 = 50;

		let fs = 12;

		// .filter((d_) => d_.user_type == this.state.selectedUserGroup)

		let apps = this.state.apps.filter((d_) => {
			let filter = d_;

			if (
				typeof this.state.filterByName !== "undefined" &&
				this.state.filterByName !== ""
			) {
				let text_ = this.state.filterByName;
				var outString = text_.replace(
					/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
					"",
				);

				let join_names = `${d_.title} ${d_.description}`;

				return join_names.toLowerCase().includes(outString.toLowerCase());
			}

			return filter;
		});

		// .sort((a, b) => b.last_name < a.last_name);

		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",
					flex: 1,
					padding: 8,
					// width: "100%",
					height: this.props.screenHeight,
				}}>
				<View
					style={{
						flex: 1,
						// flexDirection: 'row',
						marginTop: 16,
					}}>
					<View
						style={{
							// width: 220,
							// paddingLeft: 0,
							flex: 1,
							paddingRight: 8,
							flexDirection: "row",
							// alignItems: "center",
						}}>
						<View
							style={{
								flexDirection: "row",
								flex: 1,
								alignItems: "center",
								// justifyContent: "flex-end",
								// paddingTop: 5,
								marginBottom: 5,
							}}>
							<TouchableOpacity
								style={{
									// width: 130,
									height: "auto",
									alignItems: "center",
									borderRadius: 10,
									marginRight: 10,
									height: "auto",
									backgroundColor: APP_COLOURS.BLUE,
									flexDirection: "row",
								}}
								onPress={async () => {
									await this.setState({
										addNewAppProperties: true,
									});
									await this.setState({
										addNewAppProperties: true,
									});
								}}>
								<View
									style={{
										flex: 1,
										flexWrap: "wrap",
										padding: 12,
										borderRadius: 10,
									}}>
									<Text
										numberOfLines={2}
										style={{
											fontFamily: "Avenir",
											fontSize: 13,
											// opacity: 0.8,
											flex: 1,
											flexWrap: "wrap",
											fontWeight: "600",
											color: APP_COLOURS.WHITE,
										}}>
										+ Styled App
									</Text>
								</View>
							</TouchableOpacity>
							<TextInput
								ref={(ref) => {
									this.filterByName = ref;
								}}
								style={{
									padding: 8,
									fontSize: 16,
									borderRadius: 9,
									color: APP_COLOURS.WHITE,
									fontWeight: "200",
									fontFamily: "Avenir",
									borderWidth: 0.3,
									borderColor: APP_COLOURS.BG3,
									backgroundColor: APP_COLOURS.BG3,
									marginRight: 5,
									paddingLeft: 15,
								}}
								placeholderTextColor={"#797979"}
								autoCapitalize='words'
								clearButtonMode='while-editing'
								editable={true}
								placeholder={`Search Apps `}
								onChangeText={(text) => {
									this.setState({
										filterByName: text,
									});
								}}
							/>
						</View>
					</View>
					<View
						style={{
							// flex: 1,
							paddingRight: 8,
							borderTopColor: "#797979",
							borderTopWidth: 0.3,
						}}>
						<View
							style={{
								// flex: 1,
								height: this.props.screenHeight - 200,
								// padding: 8,
								paddingTop: 8,
							}}>
							<ScrollView>
								{apps.map((d, i) => (
									<View
										key={i}
										onMouseEnter={() =>
											this.setState({
												hover: i,
											})
										}
										onMouseLeave={() =>
											this.setState({
												hover: -1,
											})
										}
										style={{
											// flex: 1,
											borderRadius: 5,
											flexDirection: "row",
											borderColor: "#C5C5C5",
											// borderWidth:
											// 	this.state.showDelete === d.id ? 1 : 0,
											// backgroundColor: "rgba(255, 255, 255, 0.05)",
											marginBottom: 20,
											padding: 10,
											width: "100%",
										}}>
										{/* <View
											style={{
												borderWidth: 2,
												borderColor: "#FFF",
												borderRadius: 10,
												padding: 10,
												backgroundColor: "#FFF",
												marginRight: 15,
											}}>
											<Image
												source={`data:image/png;base64,${d.base64String}`}
												style={{
													height: 60,
													width: 100,
													resizeMode: "contain",
												}}
											/>
										</View> */}

										<View
											style={{
												height: 650,
												width: 320,
											}}>
											<DevicePreviewer
												bookContent={[]}
												linkerActive={true}
												selectedLine={""}
												app_properties={d.app_properties}
												fontSize={14}
												closePreview={() =>
													this.setState({
														openBookLinker: false,
													})
												}
											/>
										</View>

										<View
											style={{
												flex: 1,
												opacity: this.state.hover === i ? 1 : 0.9,

												height: "auto",
												padding: 20,
												marginRight: 10,
												height: "auto",
												marginBottom: 2,
												// flexDirection: "row",
											}}>
											<Text
												style={{
													// width: col1,
													fontWeight: "600",
													fontSize: 15,
													color: APP_COLOURS.WHITE,
												}}>
												{d.title}
											</Text>

											<View style={{}}>
												<View
													style={
														{
															// width: col1,
															// flexDirection: "row",
															// alignItems: "center",
														}
													}>
													<Text
														style={{
															fontSize: 9,
															color: APP_COLOURS.WHITE,
															opacity: 0.7,
														}}>
														description
													</Text>

													<Text
														style={{
															fontSize: 12,
															color: APP_COLOURS.WHITE,
														}}>
														{d.description}
													</Text>
												</View>
											</View>
											<View
												style={{
													marginTop: 20,
												}}>
												<View
													style={
														{
															// width: col1,
															// flexDirection: "row",
															// alignItems: "center",
														}
													}>
													<Text
														style={{
															fontSize: 15,
															color: APP_COLOURS.WHITE,
															// opacity: 0.7,
															fontWeight: "600",
														}}>
														Background color
													</Text>

													<View
														style={{
															width: 60,
															height: 50,
															borderRadius: 10,
															borderWidth: 1,
															borderColor: "#FFF",
															backgroundColor:
																d.app_properties
																	.backgroundcolour,
														}}></View>
												</View>
											</View>
											<View
												style={{
													marginTop: 20,
												}}>
												<View
													style={
														{
															// width: col1,
															// flexDirection: "row",
															// alignItems: "center",
														}
													}>
													<Text
														style={{
															fontSize: 15,
															color: APP_COLOURS.WHITE,
															// opacity: 0.7,
															fontWeight: "600",
														}}>
														Brand color
													</Text>

													<View
														style={{
															width: 60,
															height: 50,
															borderRadius: 10,
															borderWidth: 1,
															borderColor: "#FFF",
															backgroundColor:
																d.app_properties.brandcolour,
														}}></View>
												</View>
											</View>
											<View
												style={{
													marginTop: 20,
												}}>
												<View
													style={
														{
															// width: col1,
															// flexDirection: "row",
															// alignItems: "center",
														}
													}>
													<Text
														style={{
															fontSize: 15,
															color: APP_COLOURS.WHITE,
															// opacity: 0.7,
															fontWeight: "600",
														}}>
														Font color
													</Text>

													<View
														style={{
															width: 60,
															height: 50,
															borderRadius: 10,
															borderWidth: 1,
															borderColor: "#FFF",
															backgroundColor:
																d.app_properties.fontcolour,
														}}></View>
												</View>
											</View>
											<View
												style={{
													marginTop: 20,
												}}>
												<Text
													style={{
														fontSize: 15,
														color: APP_COLOURS.WHITE,
														// opacity: 0.7,
														fontWeight: "600",
													}}>
													Brand logo
												</Text>
												<Image
													source={`data:image/png;base64,${d.app_properties.logo}`}
													style={{
														height: 60,
														width: 60,
														resizeMode: "contain",
													}}
												/>
											</View>
											<View
												style={{
													marginTop: 50,
												}}>
												<Text
													style={{
														fontSize: 9,
														color: APP_COLOURS.WHITE,
														opacity: 0.7,
													}}>
													created at:
												</Text>
												<Text
													style={{
														fontSize: 12,
														color: APP_COLOURS.WHITE,
													}}>
													{moment(d.date_created).format(
														"MMM DD, YYYY",
													)}
												</Text>
											</View>

											{/* <View
												style={{
													flexDirection: "row",
												}}>
												<Image
													source={android}
													style={{
														width: 50,
														height: 50,
														resizeMode: "contain",
														marginRight: 20,
													}}
												/>
												<Image
													source={apple}
													style={{
														width: 40,
														height: 50,
														resizeMode: "contain",
													}}
												/>
											</View> */}

											<View
												style={{
													flex: 1,
												}}
											/>

											<TouchableOpacity
												disable={this.state.hover !== i}
												onPress={() =>
													this.deleteAppProperties(d.id)
												}
												style={
													{
														// justifyContent: "center",
														// alignItems: "center",
													}
												}>
												<Image
													source={bin}
													style={{
														width: 22,
														height: 22,
														resizeMode: "contain",
														marginRight: 5,
														opacity:
															this.state.hover === i ? 1 : 0,
													}}
												/>
											</TouchableOpacity>
										</View>
									</View>
								))}
								{this.state.loading ? (
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
											minHeight: 50,
										}}>
										<ActivityIndicator color={APP_COLOURS.BLUE} />
									</View>
								) : null}
							</ScrollView>
						</View>
					</View>
				</View>
				{this.state.addNewAppProperties ? (
					this.addNewAppProperties()
				) : (
					<View />
				)}
				{/* {this.state.viewAppPropertieProfile ? (
					this.AppPropertieProfileModal(this.state.profileData)
				) : (
					<View />
				)} */}
			</div>
		);
	}

	async componentDidMount() {
		await this.validateSession();
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let accessToken = await sessionStorage.getItem("accessToken");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		console.log(accessToken);

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			accessToken,
			refresh_token,
		});

		if (
			accessToken !== null &&
			accessToken !== "" &&
			typeof accessToken !== "undefined"
		) {
			this.getAppProperties();
		} else {
			this.props.history.push("/");
			sessionStorage.clear();
		}
	};

	componentWillUnmount() {}
}
