import React, { Component } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import Home from "./Authed/Home";
import Login from "./Unauthed/Login";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
			console.log("Polly fill for smooth scroll in safari");
		} else {
			// production code
		}
	}

	render() {
		return (
			<Router>
				<Routes>
					<Route path='/login' element={<Login />} />
					<Route path='/Home' element={<Home />} />

					<Route path='*' element={<Navigate to='/login' />} />
				</Routes>
			</Router>
		);
	}
}

export default index;
