import React, { Component } from "react";
import { View, Text, Image } from "react-native";
import { APP_COLOURS, DEMOGRAPHICS } from "../../APP_VARS";

import Fade from "react-reveal/Fade";
import VisitsLineChart from "./Charts/Visits";

function randomRange(n, min, max) {
	var values = [],
		i = max;
	while (i >= min) values.push(i--);
	var results = [];
	var maxIndex = max;
	for (i = 1; i <= n; i++) {
		maxIndex--;
		var index = Math.floor(maxIndex * Math.random());
		results.push(values[index]);
		values[index] = values[maxIndex];
	}
	return results.filter((d) => typeof d !== "undefined");
}

function newRandomNumber(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default class CustomerConversions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: "",
			dataHistory: randomRange(12, 50, 100),
			overallHistory: randomRange(12, 50, 100),

			data: [
				{
					title: "caucasian",
					icon: "",
					visits: 4000,
					dataHistory: randomRange(12, 50, 100),
					percentage: newRandomNumber(0, 100) / 100,
				},
				{
					title: "ethnic",
					icon: "",
					visits: 10000,
					dataHistory: randomRange(12, 50, 100),
					percentage: newRandomNumber(0, 100) / 100,
				},
				{
					title: "asian",
					icon: "",
					visits: 7000,
					dataHistory: randomRange(12, 50, 100),
					percentage: newRandomNumber(0, 100) / 100,
				},
				{
					title: "indian",
					icon: "",
					visits: 4000,
					dataHistory: randomRange(12, 50, 100),
					percentage: newRandomNumber(0, 100) / 100,
				},
				{
					title: "other",
					icon: "",
					visits: 2000,
					dataHistory: randomRange(12, 50, 100),
					percentage: newRandomNumber(0, 100) / 100,
				},
			],
		};
	}

	render() {
		let style = {
			backgroundColor: APP_COLOURS.BG3,
			borderRadius: 11,
			padding: 8,
			marginRight: 5,
			marginBottom: 5,
			shadowColor: "#000",
			shadowOffset: { width: 0, height: 8 },
			shadowOpacity: 0.44,
			shadowRadius: 9.32,
			elevation: 5,
			opacity: 0.9,
		};

		let data = this.state.data;

		let totalVisis = data.reduce((a, c) => a + parseFloat(c.visits), 0);
		return (
			<Fade>
				<View
					style={{
						flexDirection: "row",
						marginTop: 60,
						paddingLeft: 15,
						marginBottom: 60,
						justifyContent: "space-between",
					}}>
					<View
						style={{
							flex: 1,
						}}>
						<View
							onMouseEnter={() =>
								this.setState({
									hover: "",
									dataHistory: this.state.dataHistory,
								})
							}
							style={{
								width: "100%",
								justifyContent: "space-between",
								flexDirection: "row",
								paddingBottom: 10,
								borderBottomColor: APP_COLOURS.TRANSHALO,
								borderBottomWidth: 0.5,
								alignItems: "flex-end",
								marginBottom: 10,
							}}>
							<View
								style={{
									position: "absolute",
									left: -12,
									top: -15,
								}}>
								<Text
									style={{
										fontSize: 13,
										color: APP_COLOURS.WHITE,
										fontWeight: "300",
										fontFamily: "Avenir",
									}}>
									CUSTOMER
									<Text
										style={{
											fontWeight: "900",
										}}>
										TRAQ
									</Text>
								</Text>
							</View>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 25,
									fontWeight: "100",
									color: APP_COLOURS.WHITE,
									marginBottom: 10,
								}}>
								Customer conversions
							</Text>
						</View>
						<View style={[style, {}]}>
							<View
								style={{
									flex: 1,
								}}>
								<View
									onMouseEnter={() =>
										this.setState({
											hover: "",
											dataHistory: this.state.dataHistory,
										})
									}
									style={{
										flexDirection: "row",
										marginBottom: 10,
									}}>
									<View
										style={{
											flex: 1,
											padding: 5,
											// backgroundColor: APP_COLOURS.BG5,
											borderRadius: 4,
											marginRight: 3,
										}}>
										<Text
											style={{
												color: "#FFF",
												fontSize: 16,
												fontWeight: "600",
											}}>
											Conversions
										</Text>
									</View>
								</View>

								{data
									.sort((a, b) => a.visits < b.visits)
									.map((d, i) => {
										let avatar = DEMOGRAPHICS.filter(
											(av) => av.avatar === d.title,
										);

										return (
											<View
												key={i}
												onMouseEnter={() =>
													this.setState({
														hover: d.title,
														dataHistory: d.dataHistory,
													})
												}
												style={{
													flex: 1,
													// padding: 5,
													borderRadius: 4,
													marginRight: 3,
													flexDirection: "row",
													alignItems: "center",
													marginBottom: 10,
													backgroundColor:
														this.state.hover === d.title
															? APP_COLOURS.BG4
															: APP_COLOURS.BG3,
												}}>
												<View
													style={{
														width: 120,
														flexDirection: "row",
														alignItems: "center",
													}}>
													<View
														style={{
															padding: 2,
															borderRadius: 6,
															backgroundColor: "#FFF",
															marginRight: 5,
														}}>
														<Image
															source={
																avatar.length > 0
																	? avatar[0].pp
																	: require("../../../assets/images/dots/dot_task.svg")
															}
															style={{
																width: 27,
																height: 27,
																resizeMode: "contain",
															}}
														/>
													</View>
													<Text
														style={{
															color: "#FFF",
															fontWeight:
																this.state.hover === d.title
																	? "300"
																	: "200",
														}}>
														{d.title}
													</Text>
												</View>

												<View
													style={{
														width: 120,
														flexDirection: "row",
														alignItems: "center",
													}}>
													<Text
														style={{
															color: "#FFF",
															fontWeight:
																this.state.hover === d.title
																	? "600"
																	: "200",
														}}>
														{d.visits}
													</Text>
												</View>

												<View
													style={{
														width: 200,
														backgroundColor: "#444654",
														borderRadius: 3,
														marginLeft: 10,
													}}>
													<View
														style={{
															width:
																200 * (d.visits / totalVisis),
															backgroundColor:
																"rgba(0, 255, 249, 1)",
															borderRadius: 3,
															height: 15,
															opacity:
																this.state.hover === d.title
																	? 1
																	: 0.7,
														}}></View>
												</View>
											</View>
										);
									})}
							</View>
						</View>
					</View>
					<View
						style={{
							flex: 1,
							justifyContent: "space-between",
						}}>
						<View
							style={{
								width: "100%",
								justifyContent: "space-between",
								flexDirection: "row",
								paddingBottom: 10,
								borderBottomColor: APP_COLOURS.TRANSHALO,
								borderBottomWidth: 0.5,
								alignItems: "flex-end",
								marginBottom: 10,
							}}>
							<View
								style={{
									position: "absolute",
									left: -12,
									top: -15,
								}}>
								<Text
									style={{
										fontSize: 13,
										color: APP_COLOURS.WHITE,
										fontWeight: "300",
										fontFamily: "Avenir",
									}}>
									STAFF
									<Text
										style={{
											fontWeight: "900",
										}}>
										TRAQ
									</Text>
								</Text>
							</View>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 25,
									fontWeight: "100",
									color: APP_COLOURS.WHITE,
									marginBottom: 10,
								}}>
								{this.state.hover !== ""
									? this.state.hover
									: "Customer interactions"}{" "}
								history
							</Text>
						</View>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
							}}>
							<View
								style={{
									width: 450,
								}}>
								<VisitsLineChart
									label={`${this.state.hover}`}
									data={this.state.dataHistory}
									// average={80}
									max={100}
								/>
							</View>
						</View>
					</View>
				</View>
			</Fade>
		);
	}
}
