import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import TabTabs from "../BasicTabs";
import MallsSearchBar from "../CrudMalls/MallsSearchBar/MallsSearchBar";
import RetailerSearchBar from "../CrudRetailer/RetailerSearchBar";
import CustomerConversions from "./CustomerConversions";
import CustomerVisits from "./CustomerVisits";
import Results from "./results";
import TotalCustomers from "./TotalCustomers";
import TotalInteractions from "./TotalInteractions";

export default class CustomerSegmentation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: "",

			retailersFilter: "",
			mallsFilter: "",
			retailer: "",
			mall: "",

			filterCategory: "",
		};
	}

	render() {
		let style = {
			backgroundColor: APP_COLOURS.BG3,
			borderRadius: 11,
			padding: 8,
			marginRight: 5,
			marginBottom: 5,
			shadowColor: "#000",
			shadowOffset: { width: 0, height: 8 },
			shadowOpacity: 0.44,
			shadowRadius: 9.32,
			elevation: 5,
			opacity: 0.9,
		};
		return (
			<View
				style={{
					padding: 8,
					width: "100%",
					height: this.props.screenHeight - 230,
					marginTop: 15,
				}}>
				<ScrollView showsVerticalScrollIndicator={false}>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
							flexWrap: "wrap",
							alignItems: "center",
							justifyContent: "flex-end",
							padding: 10,
							// marginTop: 50,
						}}>
						<Text
							style={{
								fontSize: 19,
								fontWeight: "800",
								color: "#FFF",
								marginRight: 60,
								opacity:
									this.state.mallsFilter !== "" ||
									this.state.retailersFilter
										? 1
										: 0.05,
							}}>
							Filtered report
						</Text>
						<View
							style={{
								flexDirection: "row",

								alignItems: "center",
								flex: 1,
								justifyContent: "flex-end",
							}}>
							<TouchableOpacity
								onPress={async () => {
									await this.setState({
										retailersFilter: "",
										mallsFilter: "",
										retailer: "",
										mall: "",
									});
								}}>
								<Text
									style={{
										fontSize: 12,
										fontWeight: "300",
										color: "#FFF",
										marginRight: 10,
										opacity:
											this.state.mallsFilter !== "" ||
											this.state.retailersFilter !== ""
												? 1
												: 0,
									}}>
									Clear all filters
								</Text>
							</TouchableOpacity>
							<MallsSearchBar
								{...this.props}
								selected={this.state.mall}
								selectedData={async (d) => {
									console.log(d);
									await this.setState({
										mall: d,
										mallsFilter: d.title,
									});
								}}
							/>
							<View
								style={{
									width: 15,
								}}
							/>

							<RetailerSearchBar
								{...this.props}
								selected={this.state.retailer}
								selectedData={async (d) => {
									console.log(d);
									await this.setState({
										retailer: d,
										retailersFilter: d.title,
									});
								}}
							/>
						</View>
					</View>

					{this.state.mallsFilter !== "" ||
					this.state.retailersFilter !== "" ? (
						<View>
							<Text
								style={{
									fontSize:
										this.state.retailersFilter === "" ? 13 : 19,
									fontWeight:
										this.state.retailersFilter === "" ? "200" : "800",
									color: "#FFF",
								}}>
								{this.state.retailersFilter === ""
									? "(All retailers)"
									: this.state.retailersFilter}
							</Text>
							<Text
								style={{
									fontSize: this.state.mallsFilter === "" ? 13 : 19,
									fontWeight:
										this.state.mallsFilter === "" ? "200" : "500",
									color: "#FFF",
								}}>
								{this.state.mallsFilter === ""
									? "(All malls)"
									: this.state.mallsFilter}
							</Text>
						</View>
					) : null}

					{/* <View
						style={{
							width: "100%",
							flexDirection: "row",
						}}>
						<ScrollView showsHorizontalScrollIndicator={false} horizontal>
							<TotalCustomers />
							<TotalInteractions />
							<View
								style={{
									width: 100,
								}}
							/>
						</ScrollView>
					</View> */}

					<Text
						style={{
							fontSize: 19,
							fontWeight: "800",
							color: "#FFF",
							marginRight: 60,
						}}>
						Filter category
					</Text>
					<View
						style={{
							flexDirection: "row",
						}}>
						<View
							style={{
								flex: 1,
							}}>
							<ScrollView
								horizontal
								showsHorizontalScrollIndicator={false}>
								<TabTabs
									tabs={[
										"Demographics",
										"Gender",
										"Skin type",
										"Hair type",
										"Hair color",
									]}
									selectedSubPage={(d) =>
										this.setState({
											filterCategory: d,
										})
									}
								/>
							</ScrollView>
						</View>
					</View>

					{this.state.filterCategory === "Demographics" ? (
						<View>
							<CustomerVisits data={[]} />

							{/* <CustomerConversions data={[]} /> */}
						</View>
					) : null}

					{/* <View style={[style, {}]}>
						<View
							style={{
								flex: 1,
								marginTop: 15,
							}}>
							<View
								style={{
									flexDirection: "row",
								}}>
								<View
									style={{
										flex: 1,
										padding: 5,
										backgroundColor: APP_COLOURS.BG5,
										borderRadius: 4,
										marginRight: 3,
									}}>
									<Text
										style={{
											color: "#FFF",
										}}>
										Visits
									</Text>
								</View>
								<View
									style={{
										flex: 1,
										padding: 5,
										backgroundColor: APP_COLOURS.BG5,
										borderRadius: 4,
										marginRight: 3,
									}}>
									<Text
										style={{
											color: "#FFF",
										}}>
										Conversions
									</Text>
								</View>
								<View
									style={{
										flex: 1,
										padding: 5,
										backgroundColor: APP_COLOURS.BG5,
										borderRadius: 4,
										marginRight: 3,
									}}>
									<Text
										style={{
											color: "#FFF",
										}}>
										Average basket
									</Text>
								</View>
								<View
									style={{
										flex: 1,
										padding: 5,
										backgroundColor: APP_COLOURS.BG5,
										borderRadius: 4,
										marginRight: 3,
									}}>
									<Text
										style={{
											color: "#FFF",
										}}>
										Key demographcs
									</Text>
								</View>
							</View>

							{[].map((d, i) => (
								<View
									onMouseEnter={() =>
										this.setState({
											hover: d.title,
										})
									}
									style={{
										flexDirection: "row",
									}}>
									<View
										style={{
											flex: 1,
											padding: 5,
											borderRadius: 4,
											marginRight: 3,
											flexDirection: "row",
											alignItems: "center",
										}}>
										<Text
											style={{
												color: "#FFF",
												fontWeight:
													this.state.hover === d.title
														? "300"
														: "200",
											}}>
											Visits
										</Text>
										<View
											style={{
												width: 200,
												backgroundColor: "#444654",
												borderRadius: 3,
											}}>
											<View
												style={{
													width: 200 * 0.6,
													backgroundColor: "#29D9C4",
													borderRadius: 3,
													height: 15,
													opacity:
														this.state.hover === d.title
															? 1
															: 0.7,
												}}></View>
										</View>
									</View>
								</View>
							))}
						</View>
					</View> */}

					{/* <Text
						style={{
							fontSize: 19,
							fontWeight: "800",
							color: "#FFF",
							marginRight: 60,
						}}>
						Client segmentation report
					</Text> */}
				</ScrollView>
			</View>
		);
	}
}
