import React, { Component } from "react";
import Index from "./support/index";

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return <Index {...this.props} />;
	}
}

export default index;
