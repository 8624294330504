import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
import Font from "react-font";
import moment from "moment";
import tick from "../../../../assets/images/tick.png";
import abandoned from "../../../../assets/images/abandoned.png";
import { api_getSurveySubmissions } from "../../../Api";
import ExportToExcel from "../../ExportToExcel";

export default class Results extends Component {
	constructor(props) {
		super(props);
		this.state = {
			results: [],
		};
	}

	componentDidMount() {
		this.getSubmissions();
	}

	render() {
		let col1 = 200,
			col2 = 100,
			col3 = 50;
		let fs = 10;
		return (
			<View
				style={{
					flex: 1,
					backgroundColor: "#FFF",
					borderRadius: 12,
				}}>
				<View
					style={{
						width: "100%",
						padding: 3,
						borderBottomWidth: 0.5,
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<Text
						style={{
							fontSize: 14,
						}}>
						Total results:{" "}
						<Text
							style={{
								fontSize: 14,
								fontWeight: "700",
							}}>
							{this.state.results.length}
						</Text>
					</Text>

					{this.state.results.length > 0 ? (
						<ExportToExcel
							excel_doc_name={`con_report_${moment().format(
								"DD-MMM-YYYY",
							)}`}
							data={this.state.results}
						/>
					) : null}
				</View>
				<View
					style={{
						// flex: 1,
						height: this.props.screenHeight - 280,
						padding: 3,
						// paddingTop: 8,
					}}>
					<ScrollView>
						{this.state.results
							.sort(
								(a, b) =>
									moment(a.endTime).unix() < moment(b.endTime).unix(),
							)
							.map((d, i) => (
								<View
									key={i}
									style={{
										// flex: 1,
										borderRadius: 5,
										flexDirection: "row",
										borderColor: "#C5C5C5",
										backgroundColor:
											this.state.hover == i
												? "#C5C5C5"
												: "transparent",
										marginBottom: 1,
										borderBottomWidth: 0.3,
									}}>
									<TouchableOpacity
										onPress={() => {
											this.setState({});
										}}>
										<View
											key={i}
											onMouseEnter={() =>
												this.setState({
													hover: i,
												})
											}
											onMouseLeave={() =>
												this.setState({
													hover: -1,
												})
											}
											style={{
												// flex: 1,
												opacity: this.state.hover === i ? 1 : 0.9,

												height: "auto",
												padding: 4,
												alignItems: "center",

												marginRight: 10,
												height: "auto",
												marginBottom: 2,

												flexDirection: "row",
											}}>
											<Text
												style={{
													width: 120,
													fontSize: fs,
												}}>
												<Text
													style={{
														fontWeight: "400",
													}}>
													{d.deviceInfo.retailer.title},{" "}
												</Text>
												<Text
													style={{
														fontWeight: "600",
													}}>
													{d.deviceInfo.mall.title}
												</Text>
											</Text>

											<Text
												style={{
													width: 60,
													fontSize: fs,
												}}>
												<Text
													style={{
														fontWeight: "600",
													}}>
													{d.uuid}
												</Text>
											</Text>

											<View
												style={{
													width: col2,
												}}>
												<Text
													style={{
														fontSize: fs - 4,
														marginRight: 3,
													}}>
													created at:
												</Text>
												<Text
													style={{
														fontSize: fs - 1,
													}}>
													{moment(d.endTime).format(
														"MMM DD, YYYY",
													)}
												</Text>
											</View>

											<View
												style={{
													width: 80,
													height: 50,
													padding: 3,
													marginRight: 5,
													justifyContent: "center",
													alignItems: "center",
													flexDirection: "row",
												}}>
												<Image
													source={
														d.completedSurvey ? tick : abandoned
													}
													style={{
														width: fs + 2,
														height: fs + 2,
														resizeMode: "contain",
														marginRight: 5,
													}}
												/>
												<Text
													style={{
														fontSize: fs - 1,
													}}>
													{d.completedSurvey
														? "Completed"
														: "Abandoned"}
												</Text>
											</View>
											<View
												style={{
													width: 80,
													height: 50,
													padding: 3,
													marginRight: 5,
													justifyContent: "center",
													alignItems: "center",
													flexDirection: "row",
												}}>
												<Image
													source={d.qrscanned ? tick : abandoned}
													style={{
														width: fs + 2,
														height: fs + 2,
														resizeMode: "contain",
														marginRight: 5,
													}}
												/>
												<Text
													style={{
														fontSize: fs - 1,
													}}>
													{d.qrscanned
														? "QR scanned"
														: "Not scanned"}
												</Text>
											</View>
											<View
												style={{
													width: 80,
													height: 50,
													padding: 3,
													marginRight: 5,
													justifyContent: "center",
													alignItems: "center",
													flexDirection: "row",
												}}>
												<Image
													source={
														d.customerLink ? tick : abandoned
													}
													style={{
														width: fs + 2,
														height: fs + 2,
														resizeMode: "contain",
														marginRight: 5,
													}}
												/>
												<Text
													style={{
														fontSize: fs - 1,
													}}>
													{d.customerLink
														? "Customer"
														: "No customer"}
												</Text>
											</View>
											<View
												style={{
													width: 80,
													height: 50,
													padding: 3,
													marginRight: 5,
												}}>
												<Text
													style={{
														fontSize: fs - 4,
														marginRight: 3,
													}}>
													Interaction duration:
												</Text>
												<Text
													style={{
														fontSize: fs - 1,
													}}>
													{moment(d.endTime).diff(
														moment(d.startTime),
														"seconds",
													)}{" "}
													seconds
												</Text>
											</View>
											<View
												style={{
													width: 1,
													height: 20,
													backgroundColor: APP_COLOURS.BG4,
													marginRight: 10,
												}}
											/>
											<View
												style={{
													flex: 1,
													flexDirection: "row",
												}}>
												{d.userAnswers.map((ans, ind) => (
													<View
														style={{
															width: 80,
															height: 50,
															padding: 3,
															borderRadius: 8,
															borderWidth: 0.5,
															borderColor: APP_COLOURS.BG3,
															marginRight: 5,
														}}>
														<Text
															style={{
																fontSize: fs - 4,
																marginRight: 3,
															}}>
															Question: {ans.question_number}
														</Text>
														<Text
															style={{
																fontSize: fs - 1,
															}}>
															{ans.answer}
														</Text>
													</View>
												))}
											</View>
										</View>
									</TouchableOpacity>
								</View>
							))}
						{this.state.loading ? (
							<View
								style={{
									flex: 1,
									justifyContent: "center",
									alignItems: "center",
									minHeight: 50,
								}}>
								<ActivityIndicator color={APP_COLOURS.BLUE} />
							</View>
						) : null}
					</ScrollView>
				</View>
			</View>
		);
	}

	getSubmissions = async () => {
		let url = api_getSurveySubmissions;

		await this.setState({
			loading: true,
			devices: [],
			showDelete: false,
			addNewDevice: false,
			viewDeviceProfile: false,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify({
				surveyID: this.props.selectedSurvey.id,
			}),
		};

		console.log(url, requestOptions);

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								results: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};
}
