import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS, AVATARS } from "../../APP_VARS";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Font from "react-font";

import SearchBar from "./SearchBar";

import traqQ from "../../../assets/images/logo512.png";
import search from "../../../assets/images/search.png";
import filterAz from "../../../assets/images/filterAz.png";
import filterNone from "../../../assets/images/filterNone.png";
import filterZa from "../../../assets/images/filterZa.png";

import timer from "../../../assets/images/timer.png";
import location from "../../../assets/images/location.png";
import charts from "../../../assets/images/charts.png";
import prize from "../../../assets/images/prize.png";
import plus from "../../../assets/images/plus.svg";

// import female from "./../../../assets/images/female.png";
// import logo from "../../../assets/images/whiteLogo.png";
// import logoWhite from "../../../assets/images/whiteLogo.png";
// import tick from "./../../../assets/images/tick.png";
// import bin from "./../../../assets/images/bin.png";
// import close from "../../../assets/images/close.png";
import { api_getStaff, api_deleteStaff } from "../../Api";
// import Signup from "../Signup/signup";
import NewStaffForm from "./NEWSTAFFFORM";
import UsersTable from "./UsersTable";
import StaffProfile from "./StaffProfile";
import MallsSearchBar from "../CrudMalls/MallsSearchBar/MallsSearchBar";
import RetailerSearchBar from "../CrudRetailer/RetailerSearchBar";

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

function randomRange(n, min, max) {
	var values = [],
		i = max;
	while (i >= min) values.push(i--);
	var results = [];
	var maxIndex = max;
	for (i = 1; i <= n; i++) {
		maxIndex--;
		var index = Math.floor(maxIndex * Math.random());
		results.push(values[index]);
		values[index] = values[maxIndex];
	}
	return results.filter((d) => typeof d !== "undefined");
}

function newRandomNumber(min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

function thousandSep(num) {
	num = num.toString().replace(/[^0-9]/g, "");
	num = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

	return num;
}

export default class LiveView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -3,
			showDelete: false,
			userGroups: [],
			addNewStaff: false,
			selectedUserGroup: 1,
			selecteedUserGroupName: "Staffs",
			users: [],

			selectedProfile: "",
			permissionType: "",

			filterByName: "",
			startRec: 0,
			endRec: 5,
			pageNumber: 1,
			selectedUserID: "",
			usersPerPage: 10,
			filterByPermission: "",
			sortUsers: "AtoZ",

			deleteModal: "",
		};

		this._timeout = 500;
	}

	async componentDidMount() {
		await this.validateSession();
		// setTimeout(() => {	}, 1000);
		await this.processUsers();

		this.calTableHeight();
	}

	processUsers = async () => {
		let newArr = [];
		let users = this.state.users;
		// for (const d of users) {
		// 	let fn = d.uidUsers.split(" ");
		// 	d.first_name = fn[0];
		// 	d.last_name = fn[1];
		// 	// d.id = d.idUsers;
		// 	let obj = {
		// 		...d,
		// 	};
		// 	// console.log(d);
		// 	const push_ = await newArr.push(obj);
		// }

		let sort_ = await users.sort((a, b) => a.last_name > b.last_name);

		await this.setState({
			users: sort_,
		});
	};

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let accessToken = await sessionStorage.getItem("accessToken");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			accessToken,
			refresh_token,
		});

		if (accessToken !== null && accessToken !== "") {
			await this.getStaffFunc();
		} else {
			this.props.logOut();
			sessionStorage.clear();
		}
	};

	getStaffFunc = async () => {
		let url = api_getStaff;

		await this.setState({
			loading: true,
			users: [],
			showDelete: false,
			viewstaffProfile: false,
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								users: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		let fs = 10;
		let col1 = 50;
		let users = [];

		let usersLength = 0;
		if (Array.isArray(this.state.users)) {
			users = this.state.users
				.filter((d_) => {
					let filter = d_;

					if (
						typeof this.state.filterByName !== "undefined" &&
						this.state.filterByName !== ""
					) {
						let text_ = this.state.filterByName;
						var outString = text_.replace(
							/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
							"",
						);

						let join_names = `${d_.last_name} ${d_.first_name}`;

						return join_names
							.toLowerCase()
							.includes(outString.toLowerCase());
					}

					return filter;
				})
				.filter((d_) => {
					let filter = d_;

					if (
						typeof this.state.filterByPermission !== "undefined" &&
						this.state.filterByPermission !== ""
					) {
						let text_ = this.state.filterByPermission;
						var outString = text_.replace(
							/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
							"",
						);

						let join_names = `${d_.permission}`;

						return join_names
							.toLowerCase()
							.includes(outString.toLowerCase());
					}

					return filter;
				})
				.filter((d_) => {
					let filter = d_;

					if (
						typeof this.state.filterByMall !== "undefined" &&
						this.state.filterByMall !== "" &&
						typeof d_.mall !== "undefined"
					) {
						let text_ = this.state.filterByMall;
						var outString = text_.replace(
							/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
							"",
						);

						let join_names = `${d_.mall.title}`;

						return join_names
							.toLowerCase()
							.includes(outString.toLowerCase());
					}

					return filter;
				})
				.filter((d_) => {
					let filter = d_;

					if (
						typeof this.state.filterByRetailer !== "undefined" &&
						this.state.filterByRetailer !== "" &&
						typeof d_.retailer !== "undefined"
					) {
						let text_ = this.state.filterByRetailer;
						var outString = text_.replace(
							/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
							"",
						);

						let join_names = `${d_.retailer.title}`;

						return join_names
							.toLowerCase()
							.includes(outString.toLowerCase());
					}

					return filter;
				})
				.sort((a, b) => {
					if (this.state.sortUsers === "AtoZ") {
						return b.last_name < a.last_name;
					} else {
						return b.last_name > a.last_name;
					}
				});
			usersLength = users.length > 0 ? users.length : 0;
		}

		return (
			<View
				style={{
					flex: 1,
					height: this.props.screenHeight,
					padding: 10,
				}}>
				<View
					style={{
						flexDirection: "row",
					}}>
					{[
						{
							title: "Online",
							value: 1,
							color: APP_COLOURS.GREEN,
						},
						{
							title: "On lunch",
							value: 1,
							color: "rgba(255, 156, 0, 1)",
						},
						{
							title: "Offline",
							value: this.state.users.length,
							color: APP_COLOURS.RED,
						},
					].map((dd, ii) => (
						<View
							style={[
								{
									width: 90,
									flexDirection: "row",
									marginRight: 10,
									paddingLeft: 15,
									marginBottom: 5,
									justifyContent: "space-between",
									backgroundColor: APP_COLOURS.BG3,
									padding: 10,
									borderRadius: 12,
								},
							]}>
							<View
								style={{
									height: 40,
									width: 4,
									borderRadius: 2,
									backgroundColor: dd.color,
									position: "absolute",
									left: 8,
									top: 8,
								}}
							/>

							<View
								style={{
									paddingLeft: 4,
								}}>
								<Text
									style={{
										fontSize: 10,
										fontWeight: "200",
										color: "#D8D8FF",
									}}>
									{dd.title}
								</Text>
								<Text
									style={{
										fontSize: 20,
										fontWeight: "700",
										color: "#FFF",
									}}>
									{dd.value}
								</Text>
							</View>
						</View>
					))}

					<View
						style={{
							flex: 1,
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
						}}>
						{/* <MallsSearchBar
							{...this.props}
							selectedData={async (d) => {
								console.log(d);
								await this.setState({
									filterByMall: d.title,
								});
							}}
						/>

						<RetailerSearchBar
							selectedData={async (d) => {
								console.log(d);
								await this.setState({
									filterByRetailer: d.title,
								});
							}}
						/> */}

						{usersLength > 0
							? this.filterFields([
									...new Set(
										this.state.users.map((d) => d.permission),
									),
							  ])
							: null}

						<TouchableOpacity
							onPressIn={() =>
								this.setState({
									showSearch: !this.state.showSearch,
									filterByName: "",
								})
							}>
							<Image
								source={search}
								style={{
									width: 20,
									height: 20,
									resizeMode: "contain",
									marginRight: 5,
								}}
							/>
						</TouchableOpacity>
						{this.state.showSearch ? (
							<TextInput
								ref={(ref) => {
									this.filterByName = ref;
								}}
								style={{
									padding: 8,
									fontSize: 14,
									borderRadius: 20,

									color: "#FFF",
									fontWeight:
										this.state.filterByName === "" ? "200" : "500",
									fontFamily: "Avenir",

									backgroundColor: "rgba(255, 255, 255, 0.05)",
									// borderColor: APP_COLOURS.WHITE_TRANSPARENT3,
									// borderWidth: 0.3,
									marginRight: 5,
									paddingLeft: 15,
								}}
								placeholderTextColor={APP_COLOURS.WHITE_TRANSPARENT3}
								autoCapitalize='words'
								autoFocus={true}
								clearButtonMode='while-editing'
								editable={true}
								placeholder={`Type something`}
								onChangeText={(text) => {
									this.setState({
										filterByName: text,
									});
								}}
							/>
						) : null}

						<TouchableOpacity
							onPressIn={() =>
								this.setState({
									sortUsers: "AtoZ",
								})
							}>
							<Image
								source={filterZa}
								style={{
									width: 28,
									height: 20,
									resizeMode: "contain",
									marginRight: 15,
								}}
							/>
						</TouchableOpacity>

						<TouchableOpacity
							onPressIn={() =>
								this.setState({
									sortUsers: "ZtoA",
								})
							}>
							<Image
								source={filterAz}
								style={{
									width: 28,
									height: 20,
									resizeMode: "contain",
									marginRight: 15,
								}}
							/>
						</TouchableOpacity>

						<TouchableOpacity
							onPressIn={() =>
								this.setState({
									sortUsers: "",
								})
							}>
							<Image
								source={filterNone}
								style={{
									width: 20,
									height: 20,
									resizeMode: "contain",
								}}
							/>
						</TouchableOpacity>
					</View>
				</View>
				<View
					style={{
						width: "100%",
						backgroundColor: APP_COLOURS.BG,
						// backgroundColor: "#171D25",
						borderRadius: 12,
						padding: 10,
						// flex: 1,
						marginRight: 10,
					}}>
					<View
						style={{
							flex: 1,
							width: "100%",
							paddingRight: 8,
							borderTopColor: "#797979",
							borderTopWidth: 0.3,
						}}>
						<View
							style={{
								height: this.props.screenHeight - 300,
								paddingTop: 8,
							}}>
							<ScrollView>
								{users
									.slice(this.state.startRec, this.state.endRec)
									.map((d, i) => (
										<View
											key={i}
											style={{
												width: "100%",
												flexDirection: "row",
												borderBottomWidth: 0.5,
												borderBottomColor: APP_COLOURS.BG4,
											}}>
											<TouchableOpacity>
												<View
													key={i}
													// onMouseEnter={() =>
													// 	this.setState({
													// 		hover: i,
													// 	})
													// }
													// onMouseLeave={() =>
													// 	this.setState({
													// 		hover: -1,
													// 	})
													// }
													style={{
														width: "100%",
														opacity:
															this.state.hover === i ? 1 : 0.9,
														flexDirection: "row",
														alignItems: "center",
														borderRadius: 3,
														marginRight: 10,
														marginBottom: 2,
														paddingRight: 10,

														// backgroundColor:
														// 	this.state.hover == i
														// 		? APP_COLOURS.WHITE
														// 		: APP_COLOURS.OFFWHITE,
														borderColor: "#C5C5C5",
													}}>
													<Image
														// source={female}
														source={AVATARS[d.avatar].pp}
														style={{
															width: 45,
															height: 45,
															// borderRadius: 15,
															resizeMode: "contain",
															marginRight: 0,
														}}
													/>
													<View
														style={{
															width: 250,
															padding: 10,
														}}>
														<Text
															style={{
																fontSize: 17,
																color: APP_COLOURS.WHITE,
																fontWeight: "700",
															}}>
															{d.first_name}, {d.last_name}
														</Text>
														{/* <View
															style={{
																flexDirection: "row",
																alignItems: "center",
																marginTop: 3,
															}}>
															<Text
																style={{
																	fontSize: 10,
																	fontWeight: "100",
																	color: APP_COLOURS.TEXTCOLOR,
																	marginRight: 5,
																}}>
																{d.permission}
															</Text>
															<Text
																style={{
																	fontSize: 10,
																	color: APP_COLOURS.TEXTCOLOR,
																	fontWeight: "600",
																}}>
																{d.position}
															</Text>
														</View> */}
														<View
															style={{
																flexDirection: "row",
																alignItems: "center",
																marginTop: 3,
															}}>
															<View
																style={{
																	width: 8,
																	borderRadius: 4,
																	height: 8,
																	backgroundColor:
																		moment().diff(
																			moment(d.last_seen),
																			"minutes",
																		) > 59
																			? APP_COLOURS.RED
																			: d.status ===
																			  "On counter"
																			? APP_COLOURS.GREEN
																			: d.status ===
																			  "On lunch"
																			? "rgba(255, 156, 0, 1)"
																			: APP_COLOURS.RED,
																	marginRight: 4,
																}}
															/>
															<Text
																style={{
																	fontSize: 10,
																	color: APP_COLOURS.WHITE,
																	fontWeight: "200",
																	opacity: 0.7,
																}}>
																{moment().diff(
																	moment(d.last_seen),
																	"minutes",
																) > 59
																	? "Offline"
																	: d.status}{" "}
															</Text>
														</View>
													</View>

													<View
														style={{
															width: 110,
														}}>
														<Text
															style={{
																fontSize: 8,
																color: APP_COLOURS.WHITE,
																fontWeight: "100",
																opacity: 0.8,
															}}>
															Last seen
														</Text>
														<Text
															style={{
																fontSize: 10,
																color: APP_COLOURS.WHITE,
																fontWeight: "600",
																fontStyle:
																	d.last_seen !== ""
																		? "normal"
																		: "italic",
															}}>
															{d.last_seen !== ""
																? moment(d.last_seen).fromNow()
																: "unavaliable"}
														</Text>
													</View>

													<View
														style={{
															flexDirection: "row",
															alignItems: "center",
														}}>
														<View
															style={{
																height: "100%",
																width: 0.5,
																backgroundColor:
																	APP_COLOURS.WHITE,
																marginRight: 10,
															}}
														/>

														<View
															style={{
																width: 80,
															}}>
															<Text
																style={{
																	fontSize: 11,
																	fontWeight: "100",
																	color: APP_COLOURS.TEXTCOLOR,
																}}>
																Start time
															</Text>
															<Text
																style={{
																	fontSize: 15,
																	color: APP_COLOURS.TEXTCOLOR,
																	fontWeight: "600",
																}}>
																08:00
															</Text>
														</View>

														<View
															style={{
																width: 110,
															}}>
															<Text
																style={{
																	fontSize: 11,
																	fontWeight: "100",
																	color: APP_COLOURS.TEXTCOLOR,
																}}>
																Todays Sales
															</Text>
															<Text
																style={{
																	fontSize: 15,
																	color: APP_COLOURS.TEXTCOLOR,
																	fontWeight: "600",
																}}>
																R{" "}
																{thousandSep(
																	newRandomNumber(1000, 25000),
																)}
															</Text>
														</View>

														<View
															style={{
																width: 90,
															}}>
															<Text
																style={{
																	fontSize: 11,
																	fontWeight: "100",
																	color: APP_COLOURS.TEXTCOLOR,
																}}>
																Units sold
															</Text>
															<Text
																style={{
																	fontSize: 15,
																	color: APP_COLOURS.TEXTCOLOR,
																	fontWeight: "600",
																}}>
																{newRandomNumber(1, 20)}
															</Text>
														</View>

														<View
															style={{
																width: 110,
															}}>
															<Text
																style={{
																	fontSize: 11,
																	fontWeight: "100",
																	color: APP_COLOURS.TEXTCOLOR,
																}}>
																Average rand sale
															</Text>
															<Text
																style={{
																	fontSize: 15,
																	color: APP_COLOURS.TEXTCOLOR,
																	fontWeight: "600",
																}}>
																R{" "}
																{thousandSep(
																	newRandomNumber(1, 2500),
																)}
															</Text>
														</View>

														<View
															style={{
																width: 110,
															}}>
															<Text
																style={{
																	fontSize: 11,
																	fontWeight: "100",
																	color: APP_COLOURS.TEXTCOLOR,
																}}>
																Average rand sale
															</Text>
															<Text
																style={{
																	fontSize: 15,
																	color: APP_COLOURS.TEXTCOLOR,
																	fontWeight: "600",
																}}>
																R{" "}
																{thousandSep(
																	newRandomNumber(1, 2500),
																)}
															</Text>
														</View>

														<View
															style={{
																width: 110,
															}}>
															<Text
																style={{
																	fontSize: 11,
																	fontWeight: "100",
																	color: APP_COLOURS.TEXTCOLOR,
																}}>
																Percentage to target
															</Text>
															<Text
																style={{
																	fontSize: 15,
																	color: APP_COLOURS.TEXTCOLOR,
																	fontWeight: "900",
																}}>
																{newRandomNumber(1, 100)}%
															</Text>
														</View>

														{/* <Image
															source={timer}
															style={{
																width: 20,
																height: 25,
																resizeMode: "contain",
																marginRight: 5,
																opacity: 0.3,
															}}
														/>
														<Image
															source={location}
															style={{
																width: 20,
																height: 25,
																resizeMode: "contain",
																marginRight: 5,
																opacity: 0.3,
															}}
														/>
														<Image
															source={prize}
															style={{
																width: 25,
																height: 20,
																resizeMode: "contain",
																marginRight: 5,
																opacity: 0.3,
															}}
														/>
														<Image
															source={charts}
															style={{
																width: 25,
																height: 25,
																resizeMode: "contain",
																marginRight: 5,
																opacity: 0.3,
															}}
														/> */}
													</View>
												</View>
											</TouchableOpacity>
										</View>
									))}
								{this.props.loading ? (
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
											minHeight: 50,
										}}>
										<ActivityIndicator color={APP_COLOURS.BLUE} />
									</View>
								) : null}
							</ScrollView>
							{usersLength > 0 ? this.pagination(usersLength) : null}
							{this.state.deleteModal !== ""
								? this.deleteModalView()
								: null}
						</View>
					</View>
				</View>
			</View>
		);
	}

	filterFields = (unique) => {
		// const unique = [...new Set(users.map((d) => d.permission))];
		return (
			<View
				style={{
					marginRight: 8,
				}}>
				<SearchBar
					{...this.props}
					placeHolder={`Filter by permission`}
					data={unique}
					loading={this.state.loading}
					selectedData={async (d) => {
						console.log(d);
						await this.setState({
							filterByPermission: d,
						});
						await this.calTableHeight();
					}}
				/>
			</View>
		);
	};

	calTableHeight = async () => {
		await console.log(this.props.screenHeight);
		let records = (this.props.screenHeight - 50) / 51;
		let totalRec = parseInt(records);

		this.setState({
			startRec: totalRec * (this.state.pageNumber - 1),
			endRec: totalRec * this.state.pageNumber,
			usersPerPage: totalRec,
		});
	};

	pagination = (usersLength) => {
		let userCountPerPage =
			parseInt(usersLength / this.state.usersPerPage) + 1;

		let pages = new Array(userCountPerPage > 0 ? userCountPerPage : 1).fill();

		return (
			<View
				style={{
					height: 20,

					flexDirection: "row",
					alignItems: "center",
					justifyContent: "flex-end",
				}}>
				<ScrollView horizontal showsHorizontalScrollIndicator={false}>
					{pages.map((d, i) => (
						<TouchableOpacity
							onPress={async () => {
								await this.setState({
									pageNumber: i + 1,
								});
								await this.calTableHeight();
							}}>
							<View
								style={{
									backgroundColor:
										this.state.pageNumber === i + 1
											? APP_COLOURS.BLUE
											: "transsparent",

									marginRight: 3,
									width: 25,
									height: 25,
									borderRadius: 5,
									padding: 5,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										fontSize: 12,
										fontWeight: "500",
										color: "#FFF",
									}}>
									{i + 1}
								</Text>
							</View>
						</TouchableOpacity>
					))}
				</ScrollView>
			</View>
		);
	};
}
