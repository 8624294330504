import React, { Component } from "react";
import { View, Text } from "react-native";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
);

export const options = {
	responsive: true,
	maintainAspectRatio: false,
	animation: false,
	plugins: {
		legend: {
			display: false,
			position: "top",
			labels: {
				color: "blue", // not 'fontColor:' anymore
				// fontSize: 18  // not 'fontSize:' anymore
				font: {
					size: 18, // 'size' now within object 'font {}'
				},
			},
		},
		title: {
			display: false,
			text: "Chart.js Line Chart",
		},
	},
	// scales: {
	// 	xAxes: [
	// 		{
	// 			gridLines: {
	// 				color: "rgba(0, 0, 0, 0)",
	// 			},
	// 		},
	// 	],
	// 	yAxes: [
	// 		{
	// 			gridLines: {
	// 				color: "rgba(0, 0, 0, 0)",
	// 			},
	// 		},
	// 	],
	// },
	scales: {
		x: {
			grid: {
				display: false,
			},
			ticks: {
				display: false,
				color: "green",
				font: {
					size: 18,
				},
				// stepSize: 1,
				// beginAtZero: true,
			},
		},
		y: {
			grid: {
				display: false,
				color: "green",
			},
			ticks: {
				display: false,
				color: "green",
				font: {
					size: 18,
				},
				// stepSize: 1,
				// beginAtZero: true,
			},
		},
	},
};

const labels = [
	"Jan 22",
	"Feb 22",
	"Mar 22",
	"Apr 22",
	"May 22",
	"Jun 22",
	"Jul 22",
	"Aug 22",
	"Sep 22",
	"Oct 22",
	"Nov 22",
	"Dec 22",
];

export default class TotalSalesLineChart extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Line
				style={{
					backgroundColor: "transparent",
				}}
				redraw={false}
				options={options}
				data={this.dataSet(this.props.data)}
			/>
		);
	}

	dataSet = (d) => {
		const data = {
			labels,
			datasets: [
				{
					fill: false,
					label: this.props.label,
					data: d, // [1, 2, 3, 4, 4, 5, 6, 3, 4, 5, 6, 3, 3, 4, 4, 5, 6, 9],
					borderColor: "rgba(255, 156, 0, 1)",
					backgroundColor: "rgba(255, 156, 0, 0.2)",

					lineTension: 0.4,

					pointRadius: 0,
					pointHoverRadius: 5,
					pointHitRadius: 50,
					pointBackgroundColor: "#FFF",
				},
			],
		};
		return data;
	};
}
