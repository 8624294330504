import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { View, Text, Image, TouchableOpacity, TextInput } from "react-native";
import Lottie from "react-lottie";
import moment from "moment";
import Fade from "react-reveal/Fade";

import Font from "react-font";

import { APP_COLOURS } from "../APP_VARS";

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formFields: [
				{
					id: 1,
					editable: false,
					state: "username",
					title: "Email",
					autoCapitalize: false,
				},
				{
					id: 2,
					editable: true,
					state: "first_name",
					title: "First name",
					autoCapitalize: true,
				},
				{
					id: 3,
					editable: true,
					state: "last_name",
					title: "Last name",
					autoCapitalize: true,
				},
			],
			user_data: {
				id: "....",
				username: "...",
				first_name: "... ",
				last_name: "...",
			},
			email: "",
			accessToken: "",
			refresh_token: "",
			screenWidth: 0,
			screenHeight: 0,
			authenticating: true,
			validated: true,
		};
	}

	async componentDidMount() {
		await this.validateSession();
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let accessToken = await sessionStorage.getItem("accessToken");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			accessToken,
			refresh_token,
		});

		if (accessToken !== null && accessToken !== "") {
			setTimeout(() => {
				this.setState({
					loading: false,
					authenticating: false,
				});
			}, 1000);
		} else {
			// this.props.history.push("/");
			sessionStorage.clear();
		}
	};

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	logOut = async () => {
		await this.props.logOut();
	};

	render() {
		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",

					flex: 1,
					// backgroundColor: APP_COLOURS.BACKGROUND,
					padding: 20,
				}}>
				<View
					style={{
						// alignItems: 'center',
						// justifyContent: 'center',
						width: "100%",
						borderBottomWidth: 0.3,
						borderBottomColor: "#C5C5C5",
						marginTop: 5,
					}}>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 25,
							color: APP_COLOURS.WHITE,
							fontWeight: "700",
						}}>
						Profile
					</Text>
				</View>
				<Fade>
					<View
						style={{
							flex: 1,
							marginLeft: "auto",
							marginRight: "auto",
							flexDirection: "row",
							flexWrap: "wrap",
							alignItems: "center",
							justifyContent: "center",
						}}>
						<View
							style={{
								flex: 1,
								maxWidth: 400,
								padding: 20,
								backgroundColor: "#FFF",
								borderRadius: 20,
							}}>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 16,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "500",
									marginBottom: 15,
								}}>
								User details
							</Text>

							{this.state.formFields.map((d, i) => (
								<View
									key={i}
									style={{
										width: "auto",
										marginBottom: 16,
										flexDirection: "row",
										alignItems: "center",
									}}>
									<Text
										style={{
											opacity: this.state.unEditable ? 0.4 : 1,
											fontFamily: "Avenir",
											fontSize: 12,
											color: APP_COLOURS.PLACEHOLDER,
											fontWeight: "500",
											marginBottom: 4,
											position: "absolute",
											top: -12,
											left: 9,
											backgroundColor: "#FFF",
											padding: 4,
											borderRadius: 8,
										}}>
										{d.title}
									</Text>
									<TextInput
										style={{
											width: "100%",
											opacity: this.state.unEditable ? 0.4 : 1,
											borderWidth: 0.5,
											borderColor: "#000",
											padding: 12,
											paddingLeft: 10,
											fontSize: 16,
											borderRadius: 8,
											backgroundColor: APP_COLOURS.WHITE,
											color: "#000",
											fontWeight: "200",
											fontFamily: "Avenir",
										}}
										placeholderTextColor={"#000"}
										autoCapitalize='words'
										clearButtonMode='while-editing'
										autoCompleteType='email'
										editable={d.editable}
										onFocus={() => {
											this.setState({
												unEditable: !d.editable,
											});
										}}
										onBlur={() => {
											this.setState({
												unEditable: false,
											});
										}}
										autoCorrect={false}
										keyboardType='email-address'
										textContentType='emailAddress'
										placeholder={d.title}
										// defaultValue={this.state.user_data.email}
										value={this.state.user_data[d.state]}
										onChangeText={(text) => {
											// let validated = this.validateEmail(text);

											if (d.autoCapitalize) {
												text = capitalizeName(text);
											}

											this.setState((prevState) => ({
												user_data: {
													...prevState.user_data,
													[d.state]: text,
												},
											}));
										}}
									/>

									{this.state.unEditable && !d.editable ? (
										<View
											style={{
												position: "absolute",
												width: "100%",
												height: "100%",
												backgroundColor: `rgba(52, 52, 52, 0.3)`,
												flex: 1,
												justifyContent: "center",
												alignItems: "center",
												borderRadius: 8,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													color: APP_COLOURS.WHITE,
													fontWeight: "700",
												}}>
												Uneditable field
											</Text>
										</View>
									) : null}

									{/* {this.state.emailValid ? (
									<Image
										source={tick}
										style={{
											width: 16,
											height: 16,
											resizeMode: 'contain',
											position: 'absolute',
											left: 20,
											top: 19,
										}}
									/>
								) : null} */}
								</View>
							))}

							{/* <View
								style={{
									width: "auto",
									marginBottom: 5,
									flexDirection: "row",
									alignItems: "center",
								}}>
								<Text
									style={{
										opacity: this.state.unEditable ? 0.4 : 1,
										fontFamily: "Avenir",
										fontSize: 12,
										color: APP_COLOURS.TEXTCOLOR,
										fontWeight: "500",
										marginBottom: 4,
										position: "absolute",
										top: -12,
										left: 9,
										backgroundColor: "#FFF",
										padding: 4,
										borderRadius: 8,
									}}>
									Username
								</Text>
								<TextInput
									style={{
										width: "100%",
										opacity: this.state.unEditable ? 0.4 : 1,
										borderWidth: 0.5,
										borderColor: "#000",
										padding: 12,
										paddingLeft: 10,
										fontSize: 16,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										color: "#000",
										fontWeight: "200",
										fontFamily: "Avenir",
									}}
									placeholderTextColor={"#000"}
									autoCapitalize='none'
									clearButtonMode='while-editing'
									autoCompleteType='email'
									editable={false}
									onFocus={() => {
										this.setState({
											unEditable: true,
										});
									}}
									onBlur={() => {
										this.setState({
											unEditable: false,
										});
									}}
									autoCorrect={false}
									keyboardType='email-address'
									textContentType='emailAddress'
									placeholder={`Email address`}
									// defaultValue={this.state.user_data.email}
									value={this.state.user_data.email}
									onChangeText={(text) => {
										let validated = this.validateEmail(text);

										this.setState((prevState) => ({
											user_data: {
												...prevState.user_data,
												email: text,
											},
										}));
									}}
								/>

								{this.state.unEditable ? (
									<View
										style={{
											position: "absolute",
											width: "100%",
											height: "100%",
											backgroundColor: `rgba(52, 52, 52, 0.3)`,
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
											borderRadius: 8,
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 12,
												color: APP_COLOURS.WHITE,
												fontWeight: "500",
											}}>
											Uneditable field
										</Text>
									</View>
								) : null}
							</View> */}
						</View>
						{/* <View
							style={{
								flex: 1,

								padding: 20,
								minWidth: 300,
							}}>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 16,
									color: APP_COLOURS.TEXTCOLOR,
									fontWeight: "500",
									marginBottom: 15,
								}}>
								Settings
							</Text>
							<View
								style={{
									width: 300,
									height: 60,
									// alignItems: 'center',
									marginTop: 5,
								}}>
								<TextInput
									style={{
										width: "100%",
										height: 50,
										paddingLeft: 10,
										marginBottom: 5,
										marginTop: 0,
										fontSize: 16,
										borderRadius: 8,
										backgroundColor: APP_COLOURS.WHITE,
										// textAlign: 'center',
										color: "#000",
										fontWeight: "200",
										fontFamily: "Avenir",
										// letterSpacing: 0.26,
										// borderWidth: 0.3,
										// borderColor: '#000',
									}}
									placeholderTextColor={"#000"}
									autoCapitalize='none'
									clearButtonMode='while-editing'
									autoCompleteType='email'
									editable={false}
									autoCorrect={false}
									keyboardType='email-address'
									textContentType='emailAddress'
									placeholder={`Email address`}
									// defaultValue={this.state.user_data.email}
									value={this.state.user_data.email}
									onChangeText={(text) => {
										let validated = this.validateEmail(text);

										this.setState((prevState) => ({
											user_data: {
												...prevState.user_data,
												email: text,
											},
										}));
									}}
								/>
							</View>
						</View> */}
					</View>
				</Fade>

				<View
					style={{
						position: "absolute",
						bottom: 20,
						width: "100%",
						justifyContent: "center",
						alignItems: "center",
					}}>
					<Fade>
						<div
							style={{
								height: "100%",
								width: "100%",
								maxWidth: 300,
								alignItems: "center",
								justifyContent: "center",
							}}>
							<TouchableOpacity onPress={() => this.logOut()}>
								<View
									style={{
										backgroundColor: APP_COLOURS.BLUE,
										flex: 1,
										padding: 9,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 10,
									}}>
									<Text
										style={{
											color: APP_COLOURS.WHITE,
										}}>
										Log out
									</Text>
								</View>
							</TouchableOpacity>
						</div>
					</Fade>
				</View>
			</div>
		);
	}
}

export default index;
