import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import moment from "moment";
import Fade from "react-reveal/Fade";
import Font from "react-font";
// import female from "./../../../assets/images/female.png";
// import logo from "../../../assets/images/whiteLogo.png";
// import logoWhite from "../../../assets/images/whiteLogo.png";
// import tick from "./../../../assets/images/tick.png";
// import bin from "./../../../assets/images/bin.png";
// import close from "../../../assets/images/close.png";
import { api_getStaff, api_deleteStaff } from "../../Api";
// import Signup from "../Signup/signup";
import NewStaffForm from "./NEWSTAFFFORM";
import UsersTable from "./UsersTable";
import StaffProfile from "./StaffProfile";

function uuidv4() {
	return "xxxxxxx".replace(/[xy]/g, function (c) {
		let r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -3,
			showDelete: false,
			userGroups: [],
			addNewStaff: false,
			selectedUserGroup: 1,
			selecteedUserGroupName: "Staffs",
			users: [
				// {
				// 	id: uuidv4(),
				// 	avatar: 3,
				// 	first_name: "Wesley",
				// 	last_name: "Cheia",
				// 	retailer: "TRAQ",
				// 	mall: "App",
				// 	email: "wesley@tetrice.co.za",
				// 	last_seen: moment().subtract(10, "minutes"),
				// 	position: "Account manager",
				// 	permission: "Admin",
				// 	status: "offline",
				// 	brand: "",
				// 	manager: "",
				// },
				// {
				// 	id: uuidv4(),
				// 	avatar: 2,
				// 	first_name: "Ashley",
				// 	last_name: "Fernandes",
				// 	retailer: "Head office",
				// 	mall: "Head office",
				// 	email: "ashley.fernandes@revlon.com",
				// 	last_seen: moment().subtract(1, "months"),
				// 	position: "Manager",
				// 	permission: "Admin",
				// 	status: "offline",
				// 	brand: "REVLON",
				// 	manager: "None",
				// },
				// {
				// 	id: uuidv4(),
				// 	avatar: 0,
				// 	first_name: "Mpilo",
				// 	last_name: "Radebe",
				// 	retailer: "Head office",
				// 	mall: "Head office",
				// 	email: "mpilo.radebe@revlon.com",
				// 	last_seen: moment().subtract(1, "months"),
				// 	position: "Manager",
				// 	permission: "Manager",
				// 	status: "offline",
				// 	brand: "REVLON",
				// 	manager: "None",
				// },
				// {
				// 	id: uuidv4(),
				// 	avatar: 0,
				// 	first_name: "Jane",
				// 	last_name: "Doe",
				// 	email: "info@traq.app",
				// 	retailer: "Edgars",
				// 	mall: "Mall of Africa",
				// 	last_seen: moment().subtract(1, "hour"),
				// 	position: "Beauty Advisor",
				// 	permission: "Staff",
				// 	status: "On Counter",
				// 	brand: "REVLON",
				// 	manager: "Jane does's manager",
				// },
				// {
				// 	id: uuidv4(),
				// 	avatar: 4,
				// 	first_name: "John",
				// 	last_name: "Doe",
				// 	email: "info@traq.app",
				// 	retailer: "Woolworths",
				// 	mall: "Sandton",
				// 	last_seen: moment().subtract(4, "hour"),
				// 	position: "Fragrance Advisor",
				// 	permission: "Staff",
				// 	status: "On lunch",
				// 	brand: "REVLON",
				// 	manager: "John Doe's manager",
				// },
			],

			selectedProfile: "",
			permissionType: "",
		};
	}

	async componentDidMount() {
		await this.validateSession();
		// setTimeout(() => {	}, 1000);
		await this.processUsers();
	}

	processUsers = async () => {
		let newArr = [];
		let users = this.state.users;
		// for (const d of users) {
		// 	let fn = d.uidUsers.split(" ");
		// 	d.first_name = fn[0];
		// 	d.last_name = fn[1];
		// 	// d.id = d.idUsers;
		// 	let obj = {
		// 		...d,
		// 	};
		// 	// console.log(d);
		// 	const push_ = await newArr.push(obj);
		// }

		let sort_ = await users.sort((a, b) => a.last_name > b.last_name);

		await this.setState({
			users: sort_,
		});
	};

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let accessToken = await sessionStorage.getItem("accessToken");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			accessToken,
			refresh_token,
		});

		if (accessToken !== null && accessToken !== "") {
			await this.getStaffFunc();
		} else {
			this.props.logOut();
			sessionStorage.clear();
		}
	};

	// componentWillUnmount() {}

	getStaffFunc = async () => {
		let url = api_getStaff;

		await this.setState({
			loading: true,
			users: [],
			showDelete: false,
			viewstaffProfile: false,
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								users: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	deleteStaff = async (id) => {
		let url = api_deleteStaff;

		await this.setState({
			loading: true,
			// users: [],
		});

		let requestOptions = {
			method: "DELETE",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify({
				id: id,
			}),
		};

		await fetch(url, requestOptions)
			.then((response) => {
				if (response.status === 200) {
					this.setState({
						staff: [],
						selectedProfile: "",
					});
					this.getStaffFunc();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert("Error deleting");
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	// validateEmail = (text) => {
	// 	// console.log(text);
	// 	let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	// 	if (reg.test(text) === false) {
	// 		// console.log("Email is Not Correct");
	// 		this.setState({ emailValid: false });
	// 		return false;
	// 	} else {
	// 		this.setState({ emailValid: true });
	// 		// console.log("Email is Correct");
	// 		return true;
	// 	}
	// };

	render() {
		return (
			<View
				style={{
					flex: 1,
					height: this.props.screenHeight,
					flexDirection: "row",
				}}>
				<View
					style={{
						backgroundColor: APP_COLOURS.BG,
						borderRadius: 12,
						padding: 7,
						// flex: 1,
						marginRight: 7,
					}}>
					<UsersTable
						users={this.state.users}
						loading={this.state.loading}
						newStaffProfileModal={() =>
							this.setState({
								newStaffProfileModal: true,
							})
						}
						screenHeight={this.props.screenHeight}
						screenWidth={this.props.screenWidth}
						selectedProfile={(d) => {
							this.setState({
								selectedProfile: d,
								permissionType: d.permission,
							});
							console.log(d);
						}}
						deleteStaff={this.deleteStaff.bind(this)}
					/>
				</View>

				<View
					style={{
						backgroundColor: APP_COLOURS.BG,
						borderRadius: 12,
						padding: 10,
						flex: 1,
					}}>
					{this.state.selectedProfile !== "" ? null : (
						<View
							style={{
								width: "100%",
								minHeight: 400,
								backgroundColor: "rgba(255, 255, 255, 0.05)",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								style={{
									fontSize: 15,
									color: "#FFF",
								}}>
								Select a user
							</Text>
						</View>
					)}

					{this.state.selectedProfile !== "" &&
					this.state.permissionType === "Staff" ? (
						<StaffProfile userData={this.state.selectedProfile} />
					) : null}

					{this.state.selectedProfile !== "" &&
					this.state.permissionType === "Admin" ? (
						<View
							style={{
								width: "100%",
								minHeight: 400,
								backgroundColor: "rgba(255, 255, 255, 0.05)",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								style={{
									fontSize: 15,
									color: "#FFF",
								}}>
								Manager preferences:please contact tech support at
								support@traq.app
							</Text>
						</View>
					) : null}

					{this.state.selectedProfile !== "" &&
					this.state.permissionType === "Admin" ? (
						<View
							style={{
								width: "100%",
								minHeight: 400,
								backgroundColor: "rgba(255, 255, 255, 0.05)",
								justifyContent: "center",
								alignItems: "center",
							}}>
							<Text
								style={{
									fontSize: 15,
									color: "#FFF",
								}}>
								No Admin preferences
							</Text>
						</View>
					) : null}
				</View>

				{this.state.newStaffProfileModal ? (
					<NewStaffForm
						{...this.props}
						accessToken={this.state.accessToken}
						closeModal={() => {
							this.setState({
								newStaffProfileModal: false,
							});
							this.getStaffFunc();
						}}
					/>
				) : (
					<View />
				)}
			</View>
		);
	}
}
