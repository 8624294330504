import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";

import tick from "./../../../assets/images/tick.png";

function capitalizeName(name) {
	return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
}
function isValidName(myval) {
	var validCharactersRegex = /^[a-zA-Z\-]+$/;

	return validCharactersRegex.test(myval.trim());
}

export default class NextOfKinName extends Component {
	constructor(props) {
		super(props);
		this.state = {
			field_value: "",
			validName_: false,
		};
		this._timeout = 500;
	}
	render() {
		return (
			<View
				style={{
					flex: 2,
				}}>
				<TextInput
					style={{
						// flex: 1,
						paddingLeft: this.props.textAlign === "center" ? 14 : 40,
						padding: 14,
						fontSize: 17,
						borderRadius: 15,
						textAlign: this.props.textAlign,
						color: APP_COLOURS.BLACK,
						fontWeight: this.state.validName_ ? "500" : "200",
						fontFamily: "Avenir",
						letterSpacing: 0.26,
						borderWidth: this.state.validName_ ? 0 : 0.3,
						backgroundColor: "#FFF",
						borderColor: APP_COLOURS.BLACK,
					}}
					placeholderTextColor={"#797979"}
					autoCapitalize='sentences'
					clearButtonMode='while-editing'
					autoCorrect={false}
					placeholder={this.props.title}
					value={this.state.field_value}
					onChangeText={(text) => {
						const textA = capitalizeName(text);
						this.props.setParentState(textA);
						// const validName_ = isValidName(textA);
						// let length_ = textA.length > 1 ? true : false;

						this.setState({
							field_value: textA,
							// validName_: validName_ && length_,
						});

						// clearTimeout(this._timeout);
						// this._timeout = setTimeout(() => {
						// 	if (validName_ && length_) {

						// 	} else {
						// 		this.props.setParentState("");
						// 	}
						// }, 500);
					}}
				/>
			</View>
		);
	}
}
