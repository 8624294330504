let e = `https://us-central1-traq-e3acf.cloudfunctions.net/app`;

// if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
// 	e = `http://localhost:5001/traq-e3acf/us-central1/app`;
// }

// let e = `https://traq.app/REST/dashboard_api`;

export const api_login = e + "/dashboard/login";

//
//
// DEVICES
export const api_addDevice = e + "/dashboard/newDevice";
export const api_getDevices = e + "/dashboard/getDevices";
export const api_deleteDevice = e + "/dashboard/deleteDevice";
export const api_updateDeviceUsers = e + "/dashboard/updateDeviceUsers";

//
//
//
// SURVEY
export const api_getSurveyDevices = e + "/dashboard/getSurveyDevices";
export const api_deleteSurveyDevices = e + "/dashboard/deleteSurveyDevice";
export const api_newSurveyDevice = e + "/dashboard/newSurveyDevice";
export const api_getAllSurveys = e + "/dashboard/getAllSurveys";
export const api_getSurveySubmissions = e + "/dashboard/getSurveySubmissions";

//
//
//
export const api_checkidexists = e + "/dashboard/checkidexists";
export const api_checkemployeeidexists = e + "/dashboard/heckemployeeidexists";
//
//
//
// ------------------ STAFF
export const api_newStaff = e + "/dashboard/newStaff";
export const api_getStaff = e + "/dashboard/getStaff";
export const api_updateStaff = e + "/dashboard/updateStaff";
export const api_deleteStaff = e + "/dashboard/deleteStaff";

export const api_getManager = e + "/dashboard/getManager";

// ------------------ Brands
export const api_addBrand = e + "/brands/addBrand";
export const api_getBrand = e + "/brands/getBrands";
export const api_updateBrand = e + "/brands/updateBrand";
export const api_deleteBrand = e + "/brands/deleteBrand";

// ------------------ AppProperties
export const api_addAppProperties = e + "/AppProperties/add";
export const api_getAppProperties = e + "/AppProperties/get";
export const api_updateAppProperties = e + "/AppProperties/update";
export const api_deleteAppProperties = e + "/AppProperties/delete";

// ------------------ Malls
export const api_addMall = e + "/malls/add";
export const api_getMall = e + "/malls/get";
export const api_updateMall = e + "/malls/update";
export const api_deleteMall = e + "/malls/delete";
//
// ------------------ Retailer
export const api_addRetailer = e + "/retailers/add";
export const api_getRetailer = e + "/retailers/get";
export const api_updateRetailer = e + "/retailers/update";
export const api_deleteRetailer = e + "/retailers/delete";
//
// ------------------ Products
export const api_addProduct = e + "/products/add";
export const api_getProduct = e + "/products/get";
export const api_updateProduct = e + "/products/update";
export const api_deleteProduct = e + "/products/delete";
//
// ------------------ Customers
export const api_addCustomers = e + "/customers/add";
export const api_getCustomers = e + "/customers/get";
// export const api_updateCustomers = e + "/sales/update";
// export const api_deleteCustomers = e + "/sales/delete";
//
//
// ------------------ Sales
export const api_addSales = e + "/sales/add";
export const api_getSales = e + "/sales/get";
export const api_updateSales = e + "/sales/update";
export const api_deleteSales = e + "/sales/delete";
//
///

// ------------------ QR COdes

export const api_ = e;

//
//
//
export const api_getStaffAttendance = e + "/dashboard/attendance/";

//
//  UNUSED
// export const api_getallusers = e + "/admin/get_all_users";
export const api_deleteMember = e + "/admin/deleteMember";

export const api_addStaffMember = e + "/admin/addStaffMember";
export const api_deleteStaffMember = e + "/admin/deleteStaff";

export const api_addMembership = e + "/admin/addMembership";
export const api_deleteMembership = e + "/admin/deleteMembership";
export const api_getAllMemberships = e + "/admin/getAllMemberships";

export const api_memberSignup = e + "/admin/memberSignup";
export const api_verifyotp = e + "/auth/verifyotp";
