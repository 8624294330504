import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ActivityIndicator,
	ScrollView,
	Modal,
} from "react-native";
import Font from "react-font";
import sentTicks from "../assets/images/sentMessage.svg";
import send from "../assets/images/send.png";
import logo from "../assets/images/tetrice_logo.png";
import { APP_COLOURS } from "../APP_VARS";
import * as animationData from "../assets/lottie/lf30_editor_gqidrmge.json";

import "./input.css";
import moment from "moment";

function uuid() {
	return "xxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16).toUpperCase();
	});
}

function randomString(len, charSet) {
	charSet = charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
	var randomString = "";
	for (var i = 0; i < len; i++) {
		var randomPoz = Math.floor(Math.random() * charSet.length);
		randomString += charSet.substring(randomPoz, randomPoz + 1);
	}
	return randomString;
}

class CurrentQuery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			chatData: [
				// {
				// 	text: 'Initial chat data',
				// 	sender: '1234',
				// },
			],

			secondsCountdown: 10,
		};

		this.inputRef = React.createRef();
	}

	submitTicket = async () => {
		await clearInterval(this.interval);
		this.setState({
			submitting: true,
		});
		// let ticketNumber = await uuid();
		let ticketNumber = await randomString(5);

		console.log("new ticket");
		if (
			this.state.fullName.length > 0 &&
			this.state.email.length > 0 &&
			this.state.appName.length > 0 &&
			this.state.description.length > 0
		) {
			this.sendQuery(ticketNumber);
		}
		this.triggerTimer();
	};

	sendQuery = (ticketNumber) => {
		let BaseURL = "https://www.tetrice.co.za/techsupport/saveQuery.php";

		fetch(BaseURL, {
			method: "POST",
			body: JSON.stringify({
				fullname: this.state.fullName,
				email: this.state.email,
				appname: this.state.appName,
				description: this.state.description,
				screenshot: "",
				ticketNumber: ticketNumber,
			}),
		})
			.then((response) => {
				console.log("response.status", response.status);
				console.log("response", response);
				if (response.status == 200) {
					this.setState({
						rsvpSuccessful: true,
					});
					let response = sessionStorage.setItem(
						"latest_ticket",
						JSON.stringify(ticketNumber),
					);

					this.setState({
						submitSuccessful: true,
						ticketNumber: ticketNumber,
					});
				} else {
					this.setState({
						submitSuccessful: false,
					});
				}
				this.triggerTimer();
			})

			.catch((error) => {
				console.log(error);
			});
	};

	validateEmail = async (text) => {
		// await console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// await console.log("Email is Not Correct");
			await this.setState({ emailValid: false });
			return false;
		} else {
			await this.setState({ emailValid: true });
			// await console.log("Email is Correct");
			return true;
		}
	};

	splitName = async (fullName) => {
		var name = fullName; // try "Paul", "Paul Steve"
		var firstName = name.split(" ")[0];
		var lastName = name.substring(firstName.length).trim();

		if (lastName === "") {
			firstName = fullName;
		}

		console.log("firstName", firstName);
		console.log("lastName", lastName);

		await this.setState({
			fullName: fullName,
			firstName: firstName,
			lastName: lastName,
		});
	};

	async componentDidMount() {
		await clearInterval(this.interval);
		this.interval = setInterval(
			() =>
				this.setState({
					secondsCountdown: this.state.secondsCountdown - 1,
				}),
			1000,
		);

		console.log("mounted");
	}

	triggerTimer = async () => {
		await clearInterval(this.interval);
		await this.setState({
			secondsCountdown: 10,
		});

		this.interval = await setInterval(
			() =>
				this.setState({
					secondsCountdown: this.state.secondsCountdown - 1,
				}),
			1000,
		);
	};

	async componentDidUpdate(prevProps, prevState) {
		if (prevState.secondsCountdown == 1) {
			await console.log("fetch messages");
			await this.setState({
				secondsCountdown: 0,
			});
			await clearInterval(this.interval);
			await this.props.getChatData();
		}
		console.log(this.state.secondsCountdown);
	}

	async componentWillUnmount() {
		await console.log("unmounted");
		await clearInterval(this.interval);
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		return (
			<View
				style={{
					flex: 1,
					justifyContent: "center",
					alignItems: "center",
				}}>
				<View
					style={{
						width: "100%",
						height: this.props.screenHeight - 120,
						borderRadius: 5,
						borderColor: "#FFF",
					}}>
					<View
						style={{
							flex: 1,
							borderTopColor: "#E8E8E8",
							borderTopWidth: 0.5,
						}}>
						{/* <View
							style={{
								flexDirection: "row",
								opacity: 0.1,
							}}>
							<TouchableOpacity
								style={{
									flexDirection: "row",
									justifyContent: "flex-start",
									padding: 4,
									borderRadius: 20,
									alignItems: "center",
									backgroundColor: "#141D31",
									paddingRight: 20,
									opacity: 0.9,
								}}>
								<Image
									source={require("./../assets/images/male2.png")}
									style={{
										width: 36,
										height: 36,
										borderRadius: 20,
										resizeMode: "contain",
										backgroundColor: "#FFF",
										marginRight: 5,
									}}
								/>
								<View
									style={
										{
											// padding: 5,
											// alignItems: "center",
											// height: 50,
											// alignItems: "flex-start",
										}
									}>
									<Text
										style={{
											fontFamily: "Montserrat",
											fontSize: 12,
											fontWeight: "600",
											color: APP_COLOURS.SECONDARY,
										}}>
										Tyron Brett
									</Text>

									<Text
										style={{
											fontFamily: "Montserrat",
											fontSize: 8,
											opacity: 0.8,
											color: APP_COLOURS.SECONDARY,
										}}>
										Tech support agent
									</Text>
								</View>
							</TouchableOpacity>
						</View> */}
						<ScrollView
							showsVerticalScrollIndicator={false}
							ref={(ref) => {
								this.scrollView = ref;
							}}
							onContentSizeChange={() =>
								this.scrollView.scrollToEnd({
									animated: true,
								})
							}
							style={{
								padding: 10,
								flex: 1,
							}}>
							<View
								style={{
									flex: 1,
									width: "100%",
								}}>
								<ScrollView
									ref={(ref) => {
										this.scrollView = ref;
									}}
									onContentSizeChange={() =>
										this.scrollView.scrollToEnd({
											animated: true,
										})
									}
									style={{
										padding: 10,
										flex: 1,
									}}>
									<View
										style={{
											width: "100%",
											alignItems: "flex-end",
										}}>
										<View
											style={{
												width: "70%",
												backgroundColor: APP_COLOURS.BLUE,
												borderRadius: 12,
												padding: 10,

												marginBottom: 13,
											}}>
											<Text
												style={{
													fontWeight: "600",
													textDecorationLine: "underline",
													color: "#FFF",
												}}>
												{this.props.currentQuery.queryCategory}
											</Text>
											{Object.keys(
												this.props.currentQuery.query,
											).map((d, i) => (
												<Text
													key={i}
													style={{
														color: "#FFF",
														fontWeight: "300",
													}}>
													{d}:{" "}
													<Text
														style={{
															fontWeight: "600",
														}}>
														{this.props.currentQuery.query[d]}
													</Text>
												</Text>
											))}

											<Text
												style={{
													fontWeight: "300",
													fontStyle: "italic",
													color: "#FFF",
													fontSize: 8,
													alignSelf: "flex-end",
													marginTop: 15,
												}}>
												{moment(
													this.props.currentQuery.date_created,
												).format("DD/MM/YY")}
											</Text>
											<Image
												source={sentTicks}
												style={{
													width: 12 * (34 / 25),
													height: 12,
													resizeMode: "contain",
													position: "absolute",
													right: -10,
													bottom: -10,
												}}
											/>
										</View>
									</View>
									{this.props.currentQuery.chatData.map((d, i) => (
										<View
											style={{
												width: "100%",
												alignItems:
													d.sender == this.props.userid
														? "flex-end"
														: "flex-start",
											}}>
											<View
												style={{
													width: "70%",
													backgroundColor:
														d.sender == this.props.userid
															? APP_COLOURS.BLUE
															: APP_COLOURS.SECONDARY,
													borderRadius: 12,
													padding: 10,
													marginBottom: 10,
													shadowColor: "#D8D8D8",
													shadowOffset: { width: 0, height: 8 },
													shadowOpacity: 0.44,
													shadowRadius: 9.32,
													elevation: 10,
													opacity: 0.9,
												}}>
												{d.sender == this.props.userid ? null : (
													<View
														style={{
															flexDirection: "row",
															width: 200,
															marginBottom: 10,
														}}>
														<Image
															source={logo}
															style={{
																width: 20,
																height: 20,
																resizeMode: "contain",
															}}
														/>
														<View
															style={{
																// width: "100%",
																marginLeft: 7,
																justifyContent: "flex-start",
																alignItems: "flex-start",
																top: 2,
															}}>
															<Text
																style={{
																	color: APP_COLOURS.FONT_PRIMAR,
																	fontSize: 10,
																	opacity: 0.9,
																}}>
																<Font
																	weight={900}
																	family='Poppins'>
																	tetrice
																</Font>
															</Text>
														</View>
													</View>
												)}
												<Text
													style={{
														color:
															d.sender == this.props.userid
																? "#FFF"
																: APP_COLOURS.PRIMARY,
													}}>
													{d.text}
												</Text>

												<Text
													style={{
														fontWeight: "300",
														fontStyle: "italic",
														color:
															d.sender === this.props.userid
																? "#FFF"
																: "#797979",
														fontSize: 8,
														alignSelf: "flex-end",
														marginTop: 15,
													}}>
													{moment(d.sent_date).format(
														"HH:mm DD/MM/YY",
													)}
												</Text>
												<Image
													source={sentTicks}
													style={{
														width: 12 * (34 / 25),
														height: 12,
														resizeMode: "contain",
														position: "absolute",
														right: -10,
														bottom: -10,
														display:
															d.sender === this.props.userid
																? "flex"
																: "none",
													}}
												/>
											</View>
										</View>
									))}

									{this.props.sendingMessage ? (
										<View
											style={{
												width: "100%",
												alignItems: "flex-end",
												marginBottom: 15,
											}}>
											<View
												style={{
													width: "70%",
													opacity: 0.8,
													backgroundColor: APP_COLOURS.BLUE,
													borderRadius: 12,
													padding: 10,

													marginBottom: 3,
												}}>
												<Text
													style={{
														color: "#FFF",
													}}>
													{this.state.sentMessageData}
												</Text>
											</View>

											<ActivityIndicator color={"#FFF"} />
										</View>
									) : null}
								</ScrollView>
							</View>

							{this.props.currentQuery.status == "Resolved" ? (
								<View
									style={{
										width: "100%",
										padding: 20,
										alignItems: "center",

										marginTop: 20,
										marginBottom: 90,
									}}>
									<View
										style={{
											width: 70,
											alignItems: "center",
											flexDirection: "row",
										}}>
										<View
											style={{
												backgroundColor: "#00FF74",
												width: 16,
												height: 16,
												borderRadius: 10,
												justifyContent: "center",
												alignItems: "center",
												padding: 3,
												marginRight: 8,
											}}>
											<Text
												adjustsFontSizeToFit
												style={{
													color: "#FFF",
													fontSize: 10,
												}}></Text>
										</View>
										<View>
											<View
												style={{
													justifyContent: "center",
													alignItems: "center",
												}}>
												<Text
													style={{
														color: APP_COLOURS.FONT_PRIMAR,
														fontSize: 10,
													}}>
													<Font weight={600} family='Montserrat'>
														Resolved
													</Font>
												</Text>
											</View>
										</View>
									</View>
									<Text
										style={{
											color: APP_COLOURS.PRIMARY,
											fontSize: 12,
											fontStyle: "italic",
											marginTop: 10,
										}}>
										This ticket has been marked as resolved, in order
										to query this ticket further, you will need to
										open a new ticket and reference this ticket number
										'
										<Text
											style={{
												fontWeight: "500",
											}}>
											{this.props.currentQuery.ticketNumber}
										</Text>
										'
									</Text>
								</View>
							) : null}
							<View
								style={{
									width: "100%",
									height: 90,
								}}
							/>
						</ScrollView>
					</View>

					{this.props.currentQuery.status == "Resolved" ? null : (
						<View
							style={{
								width: "100%",
								height: "auto",
								maxHeight: 100,
								marginRight: "auto",
								marginLeft: "auto",
								alignItems: "center",
								maxWidth: "100%",
								position: "absolute",
								bottom: 0,
								flexDirection: "row",
								borderRadius: 15,
								borderWidth: 0.5,
								borderColor: "#E8E8E8",
								padding: 5,
								// backgroundColor: "rgba(255, 255, 255, 0.8)",
								backgroundColor: "#FFF",
							}}>
							<TextInput
								ref={(ref) => (this.inputRef = ref)}
								style={{
									flex: 1,
									paddingLeft: 10,
									paddingTop: 10,
									marginBottom: 5,
									marginTop: 0,

									fontSize: 12,
									color: APP_COLOURS.PRIMARY,
									minHeight: 50,

									marginRight: 10,
								}}
								multiline
								numberOfLines={1}
								placeholderTextColor={"#797979"}
								clearButtonMode='while-editing'
								autoCorrect={true}
								placeholder={`Type something`}
								// onChangeText={(text) => {
								// 	this.setState({
								// 		textData: text,
								// 	});
								// }}
							/>

							<TouchableOpacity
								onPress={async () => {
									await clearInterval(this.interval);
									await this.setState({
										sentMessageData: this.inputRef.value,
										sendingMessage: true,
									});

									await this.props.sendMessage(
										this.props.currentQuery.id,
										{
											text: this.inputRef.value,
											sender: this.props.userid,
											sent_date: moment().toISOString(),
										},
									);

									this.inputRef.clear();
								}}>
								<View
									style={{
										width: 40,
										height: "auto",
										justifyContent: "center",
										alignItems: "center",
										opacity: 0.9,
									}}>
									<Image
										source={send}
										style={{
											width: 40,
											height: 40,
											resizeMode: "contain",
										}}
									/>
								</View>
							</TouchableOpacity>
						</View>
					)}
				</View>
			</View>
		);
	}
}

export default CurrentQuery;
