import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import Font from "react-font";
import moment from "moment";
import tick from "../../../assets/images/tick.png";
import abandoned from "../../../assets/images/abandoned.png";
import { api_getSales } from "../../Api";
import ExportToExcel from "../ExportToExcel";
import SearchBar from "./SearchBar";

function isOdd(num) {
	return num % 2;
}

export default class Results extends Component {
	constructor(props) {
		super(props);
		this.state = {
			results: [],
			backupArray: [],
			processing: false,

			months: [],
			years: [],
			sssc: [],

			fields: [
				"mall",
				"retailer",
				"month",
				"year",
				"sales_categories",
				"product_categories",
				"product_subbrands",
				"payment_method",
				"customer_ethnicity",
				"customer_gender",
				"customer_skin_type",
				"customer_nationality",
				"customer_age_range",
			],
			subItems: [],
		};
	}

	componentDidMount() {
		this.getSales();
	}

	processFields = async () => {
		await this.setState({
			processing: true,
		});

		let fields = this.state.fields;

		for (const field of fields) {
			await this.getUnique(field);
		}

		await this.setState({
			processing: false,
		});
	};

	getUnique = async (field) => {
		const results = this.state.results;
		const res = await [...new Set(results.map((item) => item[field]))];
		await console.log(field, res);
		this.setState({
			[field]: res.sort((a, b) => a > b),
		});
	};

	render() {
		let sales_ = this.state.results
			.filter((b) => {
				if (this.props.selectedBrand.title !== "All") {
					return b.brands
						.toLowerCase()
						.includes(this.props.selectedBrand.title.toLowerCase());
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_mall !== "undefined" &&
					this.state.filter_mall !== ""
				) {
					return b.mall
						.toLowerCase()
						.includes(this.state.filter_mall.toLowerCase());
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_retailer !== "undefined" &&
					this.state.filter_retailer !== ""
				) {
					return b.retailer
						.toLowerCase()
						.includes(this.state.filter_retailer.toLowerCase());
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_month !== "undefined" &&
					this.state.filter_month !== ""
				) {
					return b.month
						.toLowerCase()
						.includes(this.state.filter_month.toLowerCase());
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_year !== "undefined" &&
					this.state.filter_year !== ""
				) {
					return b.year
						.toLowerCase()
						.includes(this.state.filter_year.toLowerCase());
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_sales_categories !== "undefined" &&
					this.state.filter_sales_categories !== ""
				) {
					return (
						b.sales_categories.toLowerCase() ===
						this.state.filter_sales_categories.toLowerCase()
					);
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_payment_method !== "undefined" &&
					this.state.filter_payment_method !== ""
				) {
					return b.payment_method
						.toLowerCase()
						.includes(this.state.filter_payment_method.toLowerCase());
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_customer_ethnicity !== "undefined" &&
					this.state.filter_customer_ethnicity !== ""
				) {
					return b.customer_ethnicity
						.toLowerCase()
						.includes(this.state.filter_customer_ethnicity.toLowerCase());
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_customer_gender !== "undefined" &&
					this.state.filter_customer_gender !== ""
				) {
					return b.customer_gender
						.toLowerCase()
						.includes(this.state.filter_customer_gender.toLowerCase());
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_customer_skin_type !== "undefined" &&
					this.state.filter_customer_skin_type !== ""
				) {
					return b.customer_skin_type
						.toLowerCase()
						.includes(this.state.filter_customer_skin_type.toLowerCase());
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_customer_customer_nationality !==
						"undefined" &&
					this.state.filter_customer_customer_nationality !== ""
				) {
					return b.customer_nationality
						.toLowerCase()
						.includes(
							this.state.filter_customer_customer_nationality.toLowerCase(),
						);
				} else {
					return b;
				}
			})
			.filter((b) => {
				if (
					typeof this.state.filter_customer_customer_age_range !==
						"undefined" &&
					this.state.filter_customer_ccustomer_age_range !== ""
				) {
					return b.customer_age_range
						.toLowerCase()
						.includes(
							this.state.filter_customer_customer_age_range.toLowerCase(),
						);
				} else {
					return b;
				}
			});
		return (
			<View
				style={{
					flex: 1,
					// backgroundColor: "#FFF",
					borderRadius: 5,
					backgroundColor: APP_COLOURS.BG2,
					paddingTop: 10,
				}}>
				<View
					style={{
						// flex: 1,
						height: this.props.screenHeight - 320,
						padding: 3,
						// paddingTop: 8,
						flexDirection: "row",
					}}>
					<View
						style={
							{
								// height: this.props.screenHeight - 280,
							}
						}>
						{this.state.fields.map((dd) => (
							<SearchBar
								{...this.props}
								data={
									typeof this.state[dd] !== "undefined"
										? this.state[dd]
										: []
								}
								loading={this.state.processing}
								placeHolder={
									typeof this.state[dd] !== "undefined" ? dd : ""
								}
								selectedData={(s) => {
									this.setState({
										[`filter_${dd}`]: s,
									});
								}}
							/>
						))}
					</View>
					<View
						style={{
							flex: 1,
							// height: this.props.screenHeight - 320,
							padding: 10,
							borderRadius: 5,
							backgroundColor: "#FFF",
						}}>
						<View
							style={{
								width: "100%",
								padding: 3,
								borderBottomWidth: 0.5,
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
							}}>
							<Text
								style={{
									fontSize: 14,
									flexDirection: "row",
									color: APP_COLOURS.BG2,
								}}>
								Total results:{" "}
								<Text
									style={{
										fontSize: 14,
										fontWeight: "700",
									}}>
									{sales_.length}{" "}
								</Text>
								{this.state.processing ? (
									<ActivityIndicator color={APP_COLOURS.BLUE} />
								) : null}
							</Text>

							{sales_.length > 0 ? (
								<ExportToExcel
									excel_doc_name={`sales_interactions_${moment().format(
										"DD-MMM-YYYY",
									)}`}
									data={sales_}
								/>
							) : (
								<View
									style={{
										opacity: 0.4,
									}}>
									<ExportToExcel
										excel_doc_name={`sales_interactions_${moment().format(
											"DD-MMM-YYYY",
										)}`}
										data={[]}
									/>
								</View>
							)}
						</View>
						<ScrollView horizontal>
							<View
								style={{
									flex: 1,
									paddingBottom: 15,
								}}>
								{sales_.map((d, i) => (
									<View
										style={{
											borderRightWidth: 0.5,
											borderRightColor: "#797979",
											// paddingRight: 10,
											backgroundColor:
												this.state.hover === i
													? "#797979"
													: isOdd(i)
													? "#E8E8E8"
													: "#FFF",
											flexDirection: "row",
										}}>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												opacity: 0.3,
											}}>
											{d.id}
										</Text>

										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{/* {d.brands.map((dd) => `${dd},`)}
											 */}
											{d.brands}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{d.mall}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{d.retailer}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{d.day}/{d.month}/{d.year}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{d.staff_id}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{d.customer_gender}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{d.customer_nationality}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{d.customer_ethnicity}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{d.customer_age_range}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{d.customer_skin_type}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{/* {d.sales_categories.map(
														(dd) => `${dd},`,
													)} */}
											{d.sales_categories}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											{d.currency} {d.total}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											qty: {d.qty}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											ars: {d.ars}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											aus: {d.aus}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											sales: {d.value_sales} qty: {d.qty_sales}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											samples: {d.value_samples} qty: {d.qty_samples}
										</Text>
										<Text
											ellipsizeMode='tail'
											numberOfLines={1}
											style={{
												width: 90,
												fontSize: 9,
												borderRightWidth: 0.5,
												borderRightColor: "#797979",
												paddingLeft: 2,
											}}>
											services: {d.value_services} qty:{" "}
											{d.qty_services}
										</Text>
									</View>
								))}
								{this.state.loading ? (
									<View
										style={{
											flex: 1,
											justifyContent: "center",
											alignItems: "center",
											padding: 50,
										}}>
										<ActivityIndicator />
									</View>
								) : null}
							</View>
						</ScrollView>
					</View>
				</View>
			</View>
		);
	}

	getSales = async (brand) => {
		let url = api_getSales;

		await this.setState({
			loading: true,
			devices: [],
			showDelete: false,
			addNewDevice: false,
			viewDeviceProfile: false,
		});

		var requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
		};

		console.log(url, requestOptions);

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log("response", responseData);

							// responseData.brands =

							let results = [];
							for (const f of responseData) {
								// JOIN ARRAY FIELDS INTO STRING
								let sales_categories = "";
								sales_categories = f.sales_categories
									.map((d) => d)
									.join(", ");
								f.sales_categories = sales_categories;
								// JOIN ARRAY FIELDS INTO STRING
								let brands = "";
								brands = f.brands.map((d) => d).join(", ");
								f.brands = brands;
								//
								let product_categories = "";
								product_categories = f.product_categories
									.map((d) => d)
									.join(", ");
								f.product_categories = product_categories;
								//
								let product_subbrands = "";
								product_subbrands = f.product_subbrands
									.map((d) => d)
									.join(", ");
								f.product_subbrands = product_subbrands;
								//
								//
								results.push(f);
							}

							await this.setState({
								results: results,
								backupArray: results,
								loading: false,
							});

							await this.processFields();
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	filterData = async () => {
		await this.setState({
			loading: true,
		});

		console.log({ ...this.state });
		// const results = await this.state.results;

		// let filteredData = await results

		// 	.filter((d) => d.month === this.state.month)
		// 	.filter((d) => d.year === this.state.filterYear)
		// 	.filter((d_) => {
		// 		let filter = d_;
		// 		if (
		// 			typeof this.state.filterByName !== "undefined" &&
		// 			this.state.filterByName !== ""
		// 		) {
		// 			let text_ = this.state.filterByName;
		// 			var outString = text_.replace(
		// 				/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
		// 				"",
		// 			);

		// 			let join_names = `${d_}`;

		// 			return join_names
		// 				.toLowerCase()
		// 				.includes(outString.toLowerCase());
		// 		}

		// 		return filter;
		// 	});
		// // .filter((d) => d.sssc === this.state.filterSSSC)

		// console.log(filteredData);
	};
}
