import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
import { api_getBrand } from "../../../Api";
import Font from "react-font";
import tick_red from "../../../../assets/images/tick_red.png";

export default class BRANDMULTISELECT extends Component {
	constructor(props) {
		super(props);
		this.state = {
			brands: [],
			selectedBrands: [],
		};
	}

	render() {
		return (
			<div
				style={{
					flex: 1,
					alignItems: "center",
					justifyContent: "center",
					padding: 10,
					marginBottom: 20,
				}}>
				<View
					style={{
						flexDirection: "row",
						marginBottom: 5,
						width: "100%",
					}}>
					<Text
						style={{
							color: APP_COLOURS.BG,
							fontSize: 12,
						}}>
						<Font weight={500} family='Montserrat'>
							Brands
						</Font>
					</Text>
				</View>
				<View
					style={{
						justifyContent: "center",
					}}>
					{this.state.loading ? (
						<View style={{}}>
							<ActivityIndicator color={APP_COLOURS.BG} />
						</View>
					) : null}
					{this.state.brands.map((d, ii) => {
						let selected = this.state.selectedBrands.filter(
							(d_) => d_.id === d.id,
						);

						return (
							<TouchableOpacity
								onPress={async () => {
									let selectedBrands = await this.state.selectedBrands;
									delete d.base64String;
									let filter_ = await selectedBrands.filter(
										(dd) => d.id !== dd.id,
									);
									if (selected.length === 0) {
										await filter_.push(d);
									}
									await this.setState({
										selectedBrands: filter_,
									});
									await console.log(filter_);
									this.props.selected(filter_);
								}}>
								<View
									style={{
										marginTop: 8,
										flexDirection: "row",
										padding: 5,
									}}>
									{selected.length > 0 ? (
										<Image
											source={require("./checked.png")}
											style={{
												width: 16,
												height: 16,
												resizeMode: "contain",
											}}
										/>
									) : (
										<Image
											source={require("./unchecked.png")}
											style={{
												width: 16,
												height: 16,
												resizeMode: "contain",
											}}
										/>
									)}

									<Text
										style={{
											marginLeft: 10,
											fontSize: 13,
											color: APP_COLOURS.BG,
										}}>
										{d.title}
									</Text>
								</View>
							</TouchableOpacity>
						);
					})}
				</View>
			</div>
		);
	}

	async componentDidMount() {
		await this.getData();
	}

	getData = async () => {
		let url = api_getBrand;
		let accessToken = await sessionStorage.getItem("accessToken");

		await this.setState({
			loading: true,
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log("brands", responseData);
							await this.setState({
								brands: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};
}
