import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import Font from "react-font";
import { APP_COLOURS } from "../../APP_VARS";
import rsacoa from "./../../../assets/images/rsa_coat_of_arms.png";
import moment from "moment";
import { api_checkidexists } from "../../Api";

function notSpecialChars(t) {
	return t.replace(/[^\w\s]/gi, "");
}

function validateRSAIDNumber(num) {
	const sIdNo = num;

	let year = sIdNo.slice(0, 2);
	let month = sIdNo.slice(2, 4);
	let day = sIdNo.slice(4, 6);

	// This is for birthdays that are e.g 1955 shown as 55 and not 2055.
	if (year > moment().format("YY")) {
		year = moment(year, "YY").subtract(100, "years").format("YYYY");
	} else {
		year = moment(year, "YY").format("YYYY");
	}

	let dateOfBirth = moment(`${day}/${month}/${year}`, "DD/MM/YYYY").format(
		"MMM DD, YYYY",
	);

	let gender = "Male";
	let gedrNum = sIdNo.slice(6, 10);

	if (parseInt(gedrNum) >= 0 && parseInt(gedrNum) <= 4999) {
		gender = "Female";
	}

	let saRes = sIdNo.slice(10, 11);
	let saCitizen = saRes === "0" ? "SA Citizen" : "Permanent resident";

	let isEight = sIdNo.slice(11, 12);

	let LuhnDigit = sIdNo.slice(12, 13);

	console.log(gender, saCitizen, dateOfBirth, isEight, LuhnDigit);

	let isValid = luhn_validate(sIdNo);

	return isValid;
}

function numberOnly(num) {
	var cleaned = num.replace(/\D/g, "");
	cleaned.replace(/ /g, "+");
	return cleaned;
}

function luhn_validate(imei) {
	return (
		!/^\d+$/.test(imei) ||
		imei.split("").reduce(function (sum, d, n) {
			return (
				sum +
				parseInt(
					(n + imei.length) % 2 ? d : [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][d],
				)
			);
		}, 0) %
			10 ==
			0
	);
}

export default class RSAIDInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id_passport: "",
			idValid: false,
			clearedState: true,
		};
		this._timeout = 200;
	}

	checkIDRecord = async (id_passport) => {
		this.setState({
			loading: true,
			errorLogin: false,
		});

		let obj = {
			id_passport,
		};

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.props.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify(obj),
		};

		await fetch(api_checkidexists, requestOptions)
			.then(async (response) => {
				console.log("response =====", response);
				if (response.status === 200) {
					this.setState({
						loading: false,
						idValid: true,
					});
				} else {
					this.setState({
						loading: false,
						id_passport: "",
						idValid: false,
					});
					alert("ID Number exists");
					this.idInput.clear();
					this.props.setParentState("", "", "");
					this.setState({
						id_passport: "",
						idValid: false,
						clearedState: true,
					});
				}
			})
			.catch((error) => {
				// this.setState({
				// 	loading: false,
				// 	signupStep: "step1",
				// 	signupError: true,
				// });
			});
	};

	render() {
		// console.log(validateRSAIDNumber("8904165034088"));
		return (
			<View
				style={{
					justifyContent: "center",
					width: "100%",
					backgroundColor: "#222222",
					borderRadius: 15,
					borderWidth: this.state.idValid ? 1 : 0.3,
					borderColor: this.state.idValid ? "#222222" : "#527A65",
					// padding: 5,
					overflow: "hidden",
					height: 50,
				}}>
				<View
					style={{
						flexDirection: "row",
						// backgroundColor: "#FFF",
						// justifyContent: "center",
						// alignItems: "center",
					}}>
					{this.state.idValid ? (
						this.state.loading ? (
							<View
								style={{
									backgroundColor: "#222222",
									width: 110,
									height: 50,
									justifyContent: "center",
									alignItems: "center",
									// position: "absolute",
									left: 0,
									zIndex: 99,

									flexDirection: "row",
								}}>
								<ActivityIndicator color={"#FFF"} />
							</View>
						) : (
							<View
								style={{
									backgroundColor: "#222222",
									width: 110,
									height: 50,
									justifyContent: "center",
									alignItems: "center",
									// position: "absolute",
									left: 0,
									zIndex: 99,
									display: this.state.idValid ? "flex" : "none",
									flexDirection: "row",
								}}>
								<Image
									source={rsacoa}
									style={{
										width: 40,
										height: 40,
										marginRight: 5,
										resizeMode: "contain",
									}}
								/>
								<Text
									style={{
										color: "#527A65",
										marginRight: 5,
									}}>
									ID Valid
								</Text>
							</View>
						)
					) : (
						<View
							style={{
								width: 110,
								height: 50,
								justifyContent: "center",
								alignItems: "center",
								// position: "absolute",
								left: 0,
								zIndex: 99,
								display:
									this.state.id_passport.length === 13
										? "flex"
										: "none",
								flexDirection: "row",
							}}>
							<Text
								style={{
									color: "red",
									marginRight: 5,
								}}>
								ID Invalid
							</Text>
						</View>
					)}
					<View
						style={{
							flex: 1,
							alignItems: "center",
							justifyContent: "center",
							backgroundColor: this.state.idValid ? "#E7F4ED" : "#FFF",
							height: 50,
						}}>
						{this.state.idValid ? (
							<Text
								style={{
									fontWeight: this.state.idValid ? "700" : "300",
									textAlign: "center",
									color: this.state.idValid ? "#22222" : "#007D3B",
									letterSpacing: 0.26,
									fontSize: 17,
								}}>
								{this.state.id_passport}
							</Text>
						) : null}
						<TextInput
							ref={(ref) => {
								this.idInput = ref;
							}}
							style={{
								position: "absolute",
								zIndex: 99,
								height: 50,
								marginTop: 0,
								fontSize: 17,
								fontWeight: this.state.idValid ? "800" : "300",
								textAlign: "center",
								color: this.state.idValid ? "transparent" : "#527A65",
								letterSpacing: 0.26,
							}}
							placeholderTextColor={"#527A65"}
							autoCapitalize='sentences'
							clearButtonMode='while-editing'
							autoCorrect={false}
							maxLength={13}
							placeholder={this.props.title}
							defaultValue={this.props.defaultValue}
							value={this.state.id_passport}
							onChange={(e) => {
								e.preventDefault();
								let t = e.target.value;
								let idValid = false;
								let text = notSpecialChars(t);

								console.log(text);
								text = numberOnly(text);
								console.log(text);

								if (text.length === 13) {
									clearTimeout(this._timeout);
									this._timeout = setTimeout(async () => {
										this.setState({
											id_passport: text,
										});
										// console.log(this.state.id_passport);
										idValid = validateRSAIDNumber(text);

										if (idValid) {
											this.setState({
												idValid: idValid,
												clearedState: false,
											});
											this.checkIDRecord(text);
											let year = text.slice(0, 2);
											let month = text.slice(2, 4);
											let day = text.slice(4, 6);

											console.log(year, moment().format("YY"));

											if (year >= moment().format("YY")) {
												year = moment(`20${year}`, "YYYY")
													.subtract(100, "years")
													.format("YYYY");
											} else {
												year = moment(year, "YY").format("YYYY");
											}

											let dateOfBirth = `${day}/${month}/${year}`;

											let gender = "male";
											let gedrNum = text.slice(6, 10);
											if (
												parseInt(gedrNum) >= 0 &&
												parseInt(gedrNum) <= 4999
											) {
												gender = "female";
											}

											this.props.setParentState(
												text,
												dateOfBirth,
												gender,
											);
										}
									}, 200);
								} else {
									this.props.setParentState("", "", "");
									this.setState({
										id_passport: text,
										idValid: false,
										clearedState: true,
									});
								}
							}}
						/>
					</View>
				</View>
			</View>
		);
	}
}

// import React, { Component } from "react";
// import {
// 	View,
// 	Modal,
// 	Image,
// 	TouchableOpacity,
// 	Text,
// 	TextInput,
// 	ActivityIndicator,
// 	ScrollView,
// } from "react-native";
// import Font from "react-font";
// import { APP_COLOURS } from "../../APP_VARS";
// import tick from "./../../../assets/images/tick.png";
// import moment from "moment";

// function notSpecialChars(t) {
// 	return t.replace(/[^\w\s]/gi, "");
// }

// function validateRSAIDNumber(num) {
// 	const sIdNo = num;

// 	let year = sIdNo.slice(0, 2);
// 	let month = sIdNo.slice(2, 4);
// 	let day = sIdNo.slice(4, 6);

// 	let dateOfBirth = moment(`${day}/${month}/${year}`, "DD/MM/YY").format(
// 		"MMM DD, YYYY",
// 	);

// 	let gender = "Male";
// 	let gedrNum = sIdNo.slice(6, 10);

// 	if (parseInt(gedrNum) >= 0 && parseInt(gedrNum) <= 4999) {
// 		gender = "Female";
// 	}

// 	let saRes = sIdNo.slice(10, 11);
// 	let saCitizen = saRes === "0" ? "SA Citizen" : "Permanent resident";

// 	let isEight = sIdNo.slice(11, 12);

// 	let LuhnDigit = sIdNo.slice(12, 13);

// 	console.log(gender, saCitizen, dateOfBirth, isEight, LuhnDigit);

// 	let isValid = luhn_validate(sIdNo);

// 	return isValid;
// }

// function luhn_validate(imei) {
// 	return (
// 		!/^\d+$/.test(imei) ||
// 		imei.split("").reduce(function (sum, d, n) {
// 			return (
// 				sum +
// 				parseInt(
// 					(n + imei.length) % 2 ? d : [0, 2, 4, 6, 8, 1, 3, 5, 7, 9][d],
// 				)
// 			);
// 		}, 0) %
// 			10 ==
// 			0
// 	);
// }

// export default class RSAIDInput extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			id_passport: "",
// 			idValid: false,
// 			clearedState: true,
// 		};
// 		this._timeout = 200;
// 	}
// 	render() {
// 		// console.log(validateRSAIDNumber("8904165034088"));
// 		return (
// 			<View
// 				style={{
// 					justifyContent: "center",
// 					width: "100%",
// 					backgroundColor: "#222222",
// 					borderRadius: 15,
// 					borderWidth: this.state.idValid ? 1 : 0.3,
// 					borderColor: this.state.idValid ? "#222222" : "#527A65",
// 					// padding: 5,
// 					overflow: "hidden",
// 					height: 50,
// 				}}>
// 				<View
// 					style={{
// 						flexDirection: "row",
// 						// backgroundColor: "#FFF",
// 						// justifyContent: "center",
// 						// alignItems: "center",
// 					}}>
// 					{this.state.idValid ? (
// 						<View
// 							style={{
// 								backgroundColor: "#222222",
// 								width: 110,
// 								height: 50,
// 								justifyContent: "center",
// 								alignItems: "center",
// 								position: "absolute",
// 								left: 0,
// 								zIndex: 99,
// 								display: this.state.idValid ? "flex" : "none",
// 								flexDirection: "row",
// 							}}>
// 							<Image
// 								source={require("./../../../assets/images/rsa_coat_of_arms.png")}
// 								style={{
// 									width: 40,
// 									height: 40,
// 									marginRight: 5,
// 									resizeMode: "contain",
// 								}}
// 							/>
// 							<Text
// 								style={{
// 									color: "#527A65",
// 									marginRight: 5,
// 								}}>
// 								ID Valid
// 							</Text>
// 						</View>
// 					) : (
// 						<View
// 							style={{
// 								width: 110,
// 								height: 50,
// 								justifyContent: "center",
// 								alignItems: "center",
// 								position: "absolute",
// 								left: 0,
// 								zIndex: 99,
// 								display:
// 									this.state.id_passport.length === 13
// 										? "flex"
// 										: "none",
// 								flexDirection: "row",
// 							}}>
// 							<Text
// 								style={{
// 									color: "red",
// 									marginRight: 5,
// 								}}>
// 								ID Invalid
// 							</Text>
// 						</View>
// 					)}
// 					<View
// 						style={{
// 							flex: 1,
// 							alignItems: "center",
// 							justifyContent: "center",
// 							backgroundColor: this.state.idValid ? "#E7F4ED" : "#FFF",
// 							height: 50,
// 						}}>
// 						{this.state.idValid ? (
// 							<Text
// 								style={{
// 									fontWeight: this.state.idValid ? "700" : "300",
// 									textAlign: "center",
// 									color: this.state.idValid ? "#22222" : "#007D3B",
// 									letterSpacing: 0.26,
// 									fontSize: 17,
// 								}}>
// 								{this.state.id_passport}
// 							</Text>
// 						) : null}
// 						<TextInput
// 							style={{
// 								position: "absolute",
// 								zIndex: 99,
// 								height: 50,
// 								marginTop: 0,
// 								fontSize: 17,
// 								fontWeight: this.state.idValid ? "800" : "300",
// 								textAlign: "center",
// 								color: this.state.idValid ? "transparent" : "#527A65",
// 								letterSpacing: 0.26,
// 							}}
// 							placeholderTextColor={"#527A65"}
// 							autoCapitalize='sentences'
// 							clearButtonMode='while-editing'
// 							autoCorrect={false}
// 							maxLength={13}
// 							placeholder={this.props.title}
// 							// value={this.state.id_passport}
// 							// onChangeText={async (t) => {
// 							// 	let text = notSpecialChars(t);
// 							// 	let idValid = false;
// 							// 	if (text.length === 13) {
// 							// 		await this.setState({
// 							// 			id_passport: text,
// 							// 			loading: true,
// 							// 		});
// 							// 		console.log(this.state.id_passport);
// 							// 		idValid = await validateRSAIDNumber(text);

// 							// 		if (idValid) {
// 							// 			clearTimeout(this._timeout);
// 							// 			await this.setState({
// 							// 				idValid: idValid,
// 							// 			});
// 							// 			let year = text.slice(0, 2);
// 							// 			let month = text.slice(2, 4);
// 							// 			let day = text.slice(4, 6);

// 							// 			let dateOfBirth = `${day}/${month}/${year}`;

// 							// 			let gender = "male";
// 							// 			let gedrNum = text.slice(6, 10);
// 							// 			if (
// 							// 				parseInt(gedrNum) >= 0 &&
// 							// 				parseInt(gedrNum) <= 4999
// 							// 			) {
// 							// 				gender = "female";
// 							// 			}

// 							// 			await this.props.setParentState(
// 							// 				text,
// 							// 				dateOfBirth,
// 							// 				gender,
// 							// 			);
// 							// 			this.setState({
// 							// 				loading: false,
// 							// 			});
// 							// 		}
// 							// 	} else {
// 							// 		this.setState({
// 							// 			id_passport: "",
// 							// 			idValid: false,
// 							// 		});
// 							// 		clearTimeout(this._timeout);
// 							// 		this._timeout = setTimeout(() => {
// 							// 			this.props.setParentState(text, "", "");
// 							// 		}, 2000);
// 							// 	}
// 							// }}
// 							onChange={(e) => {
// 								e.preventDefault();
// 								let t = e.target.value;

// 								let text = notSpecialChars(t);
// 								let idValid = false;

// 								if (text.length === 13) {
// 									clearTimeout(this._timeout);
// 									this._timeout = setTimeout(async () => {
// 										this.setState({
// 											id_passport: text,
// 										});
// 										// console.log(this.state.id_passport);
// 										idValid = validateRSAIDNumber(text);

// 										if (idValid) {
// 											this.setState({
// 												idValid: idValid,
// 												clearedState: false,
// 											});
// 											let year = text.slice(0, 2);
// 											let month = text.slice(2, 4);
// 											let day = text.slice(4, 6);

// 											let dateOfBirth = `${day}/${month}/${year}`;

// 											let gender = "male";
// 											let gedrNum = text.slice(6, 10);
// 											if (
// 												parseInt(gedrNum) >= 0 &&
// 												parseInt(gedrNum) <= 4999
// 											) {
// 												gender = "female";
// 											}

// 											this.props.setParentState(
// 												text,
// 												dateOfBirth,
// 												gender,
// 											);
// 										}
// 									}, 200);
// 								} else {
// 									if (!this.state.clearedState) {
// 										this.props.setParentState(text, "", "");
// 										this.setState({
// 											id_passport: "",
// 											idValid: false,
// 											clearedState: true,
// 										});
// 									}
// 								}
// 							}}
// 						/>
// 					</View>
// 				</View>
// 			</View>
// 		);
// 	}
// }
