import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { APP_COLOURS, AVATARS } from "../../APP_VARS";
// import moment from "moment";
// import Fade from "react-reveal/Fade";
import QRCode from "react-qr-code";
import Font from "react-font";
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
// import logo from "../../../assets/images/whiteLogo.png";
// import tick from "./../../../assets/images/tick.png";
import bin from "./../../../assets/images/bin.png";
import close from "../../../assets/images/close.png";

import apple from "../../../assets/images/apple.png";
import android from "../../../assets/images/android.png";
import InputArea from "./InputArea";

import {
	api_getDevices,
	api_deleteDevice,
	api_addDevice,
	api_updateDeviceUsers,
} from "../../Api";
import moment from "moment";
import AddDevice from "./AddDevice";

import TopTabs from "../TopTabs/index";
import StaffSearchBar from "../StaffSearchBar";

export default class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fontSize: -3,
			showDelete: false,

			formFields: [
				// {
				// 	id: 1,
				// 	editable: false,
				// 	state: "deviceUniqueID",
				// 	title: "Unique ID",
				// 	autoCapitalize: false,
				// 	validation: "name",
				// },
				// {
				// 	id: 2,
				// 	editable: true,
				// 	state: "username",
				// 	title: "Username",
				// 	autoCapitalize: false,
				// 	validation: "email",
				// },
				{
					id: 2,
					editable: true,
					state: "mall",
					title: "Mall",
					autoCapitalize: true,
					validation: "name",
				},
				{
					id: 3,
					editable: true,
					state: "retailer",
					title: "Retailer",
					autoCapitalize: true,
					validation: "name",
				},
				{
					id: 4,
					editable: true,
					state: "passcode",
					title: "Passcode",
					autoCapitalize: true,
					validation: "none",
				},
			],

			addNewDevice: false,
			selectedUserGroup: 1,
			selecteedUserGroupName: "Staff",
			devices: [],
			filterByName: "",
			tabs: [
				{
					title: "Overview",
				},
				{
					title: "Devices",
				},
				{
					title: "Data",
				},
			],
			deviceID: "",

			deviceProfileSubPage: "Overview",
		};
	}

	async componentDidMount() {
		await this.validateSession();
	}

	validateSession = async () => {
		let user_data = await sessionStorage.getItem("user_data");
		let email = await sessionStorage.getItem("email");
		let accessToken = await sessionStorage.getItem("accessToken");
		let refresh_token = await sessionStorage.getItem("refresh_token");

		await this.setState({
			user_data: JSON.parse(user_data),
			email,
			accessToken,
			refresh_token,
		});

		if (accessToken !== null && accessToken !== "") {
			await this.getDevices();
		} else {
			this.props.history.push("/");
			sessionStorage.clear();
		}
	};

	componentWillUnmount() {}

	snapShot = () => {
		let title = `TRAQ_App_${this.state.deviceID}.png`;
		html2canvas(document.getElementById("capture")).then((canvas) => {
			canvas.toBlob(function (blob) {
				saveAs(blob, title);
			});
		});
	};

	getDevices = async () => {
		let url = api_getDevices;

		await this.setState({
			loading: true,
			// devices: [],
			showDelete: false,
			addNewDevice: false,
			viewDeviceProfile: false,
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log('response', response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							console.log("response", responseData);
							this.setState({
								devices: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	deleteDevice = async (id) => {
		let url = api_deleteDevice;

		await this.setState({
			loading: true,
		});

		let requestOptions = {
			method: "DELETE",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify({
				id: id,
			}),
		};

		await fetch(url, requestOptions)
			.then((response) => {
				if (response.status === 200) {
					this.getDevices();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert("Error deleting");
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	addDevice = async (d) => {
		this.setState({
			addingDevice: true,
			errorLogin: false,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify(d),
		};

		await fetch(api_addDevice, requestOptions)
			.then(async (response) => {
				console.log("response =====", response);
				if (response.status === 200) {
					// alert("Device added");
					await this.getDevices();
					// await this.setState({
					// 	profileData: d,
					// 	deviceID: d.uuid,
					// 	viewDeviceProfile: true,
					// });
				} else {
					alert("error adding device");
					this.setState({
						addingDevice: false,
					});
				}
			})
			.catch((error) => {
				this.setState({
					addingDevice: false,
				});
			});
	};

	updateDeviceUsers = async (id, users) => {
		let url = api_updateDeviceUsers;

		await this.setState({
			loading: true,
		});

		let requestOptions = {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${this.state.accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify({
				id: id,
				users: users,
			}),
		};

		// console.log(id, users);
		// return;

		await fetch(url, requestOptions)
			.then((response) => {
				if (response.status === 200) {
					this.getDevices();
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					alert("Error adding user");
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		let col1 = 200,
			col2 = 100,
			col3 = 50;

		let fs = 12;

		let style = {
			backgroundColor: "#FFF",
			borderRadius: 11,
			padding: 8,
			marginRight: 5,
			marginBottom: 5,
			shadowColor: "#000",
			shadowOffset: { width: 0, height: 8 },
			shadowOpacity: 0.44,
			shadowRadius: 9.32,
			elevation: 5,
			opacity: 0.9,
		};

		let devices = this.state.devices;
		let totalDevices = devices.length;
		let activeDevices = devices.filter((d) => d.active).length;

		let devicesOnline = devices.reduce(
			(a, c) => a + (moment().diff(c.last_seen, "hours") < 1),
			0,
		);
		let linkedDevices = devices.reduce((a, c) => a + c.isDevice, 0);
		devices = devices
			.filter((d_) => {
				let filter = d_;
				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_.mall.title} ${d_.retailer.title} ${d_.uuid}`;
					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})
			.sort((a, b) => b.uuid < a.uuid);

		return (
			<div
				style={{
					overflow: "-moz-hidden-unscrollable",
					flex: 1,
					padding: 8,
					// width: "100%",
					height: this.props.screenHeight,
				}}>
				<View
					style={{
						width: "100%",
						borderBottomWidth: 0.3,
						borderBottomColor: "#C5C5C5",
						marginTop: 5,
					}}>
					<Text
						style={{
							fontFamily: "Avenir",
							fontSize: 18,
							color: APP_COLOURS.TEXTCOLOR,
							fontWeight: "700",
						}}>
						Authorised Devices
					</Text>
				</View>
				<View
					style={{
						flex: 1,
						// flexDirection: 'row',
						marginTop: 16,
						// paddingBottom: 16,
					}}>
					<View
						style={{
							// width: 220,
							// paddingLeft: 0,
							flex: 1,
							paddingRight: 8,
							flexDirection: "row",
							// alignItems: "center",
						}}>
						<ScrollView horizontal>
							<View
								style={{
									flexDirection: "row",
									marginRight: 50,
								}}>
								<View
									style={{
										backgroundColor: APP_COLOURS.BLUE,
										borderRadius: 15,
										height: 65,
										width: 65,
										// padding: 5,
										marginRight: 6,
										justifyContent: "center",
										alignItems: "center",
										shadowColor: "#000",
										shadowOffset: { width: 0, height: 8 },
										shadowOpacity: 0.44,
										shadowRadius: 9.32,
										elevation: 5,
									}}>
									<Image
										source={require("../../../assets/images/logo512.png")}
										style={{
											width: 50,
											height: 50,
											resizeMode: "contain",
										}}
									/>
								</View>

								<View
									style={{
										flex: 1,
									}}>
									<View
										style={{
											width: "100%",
											// justifyContent: "space-between",
											flexDirection: "row",
										}}>
										<Image
											source={require("../../../assets/images/logo.png")}
											style={{
												width: 70,
												height: 37,
												resizeMode: "contain",
											}}
										/>

										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 15,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "700",
												marginTop: 5,
												marginLeft: 6,
											}}>
											- Staff App
										</Text>
									</View>
									<Text
										style={{
											fontFamily: "Avenir",
											fontSize: 10,
											color: APP_COLOURS.TEXTCOLOR,
											fontWeight: "300",
											marginLeft: 6,
											width: 250,
										}}>
										Once the app has been downloaded to the intended
										device, you will need to register the device here
										in order to use the app
									</Text>
								</View>
							</View>
							<View
								style={{
									flexDirection: "row",
								}}>
								<View
									style={{
										flexDirection: "row",
										marginRight: 50,
									}}>
									<TouchableOpacity
										onPress={() =>
											this.setState({
												showenlargedQRCodeModal: true,
											})
										}>
										<View
											style={{
												// backgroundColor: APP_COLOURS.BLUE,
												borderRadius: 8,
												height: 75,
												width: 75,
												marginRight: 7,
												backgroundColor: "#FFF",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<QRCode
												size={65}
												value={"https://traq.app"}
												bgColor={"#FFF"}
												fgColor={APP_COLOURS.BG2}
											/>
										</View>
									</TouchableOpacity>

									<Image
										source={require("../../../assets/images/apple.png")}
										style={{
											width: 70,
											height: 25,
											resizeMode: "contain",
										}}
									/>
									<View
										style={
											{
												// flex: 1,
											}
										}>
										<View
											style={{
												width: "100%",
												justifyContent: "space-between",
												flexDirection: "row",
											}}>
											<View
												style={{
													flexDirection: "row",
													marginTop: 5,
												}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 18,
														color: APP_COLOURS.TEXTCOLOR,
														fontWeight: "700",
													}}>
													iOS App
												</Text>
											</View>
										</View>
										<TouchableOpacity
											onPress={() => {
												window.open(this.state.url, "_blank");
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 14,
													color: APP_COLOURS.TEXTCOLOR,
													fontWeight: "300",
												}}>
												Apple app store
											</Text>
										</TouchableOpacity>
										{/* <View
									style={{
										flexDirection: "row",
										justifyContent: "space-between",
										marginTop: 5,
									}}>
									<View
										style={{
											flexDirection: "row",
											// position: "absolute",
											// top: 3,
											// left: 3,
											marginTop: 5,
										}}>
										<View
											style={{
												width: 10,
												height: 10,
												backgroundColor: true
													? "#2EE39C"
													: "#FF5F5F",
												borderRadius: 5,
												marginRight: 5,
											}}
										/>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 9,
												color: "#FFF",
												fontWeight: "400",
											}}>
											{true ? `Active` : `Inactive`}
										</Text>
									</View>

									<View
										style={{
											flexDirection: "row",
											marginTop: 5,
										}}>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 9,
												color: "#FFF",
												fontWeight: "400",
											}}>
											ID: 206AuTvffSAPPL32
										</Text>
									</View>
								</View> */}
									</View>
								</View>

								<View
									style={{
										flexDirection: "row",
										marginTop: 5,
									}}>
									<TouchableOpacity
										onPress={() =>
											this.setState({
												showenlargedQRCodeModal: true,
											})
										}>
										<View
											style={{
												borderRadius: 8,
												height: 75,
												width: 75,
												marginRight: 7,
												backgroundColor: "#FFF",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<QRCode
												size={65}
												value={"https://traq.app"}
												bgColor={"#FFF"}
												fgColor={APP_COLOURS.BG2}
											/>
										</View>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() =>
											this.setState({
												showenlargedQRCodeModal: true,
											})
										}>
										<Image
											source={require("../../../assets/images/android.png")}
											style={{
												width: 70,
												height: 25,
												resizeMode: "contain",
											}}
										/>
									</TouchableOpacity>
									<View
										style={
											{
												// flex: 1,
											}
										}>
										<View
											style={{
												width: "100%",
												justifyContent: "space-between",
												flexDirection: "row",
											}}>
											<View
												style={{
													flexDirection: "row",
													marginTop: 5,
												}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 18,
														color: APP_COLOURS.TEXTCOLOR,
														fontWeight: "700",
													}}>
													Android App
												</Text>
											</View>
										</View>
										<TouchableOpacity
											onPress={() => {
												window.open(this.state.url, "_blank");
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 14,
													color: APP_COLOURS.TEXTCOLOR,
													fontWeight: "300",
												}}>
												Google play app store
											</Text>
										</TouchableOpacity>
										{/* <View
										style={{
											flexDirection: "row",
											justifyContent: "space-between",
											marginTop: 5,
										}}>
										<View
											style={{
												flexDirection: "row",
												// position: "absolute",
												// top: 3,
												// left: 3,
												marginTop: 5,
											}}>
											<View
												style={{
													width: 10,
													height: 10,
													backgroundColor: true
														? "#2EE39C"
														: "#FF5F5F",
													borderRadius: 5,
													marginRight: 5,
												}}
											/>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 9,
													color: "#FFF",
													fontWeight: "400",
												}}>
												{true ? `Active` : `Inactive`}
											</Text>
										</View>

										<View
											style={{
												flexDirection: "row",
												marginTop: 5,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 9,
													color: "#FFF",
													fontWeight: "400",
												}}>
												ID:
											</Text>
										</View>
									</View> */}
									</View>
								</View>
							</View>
						</ScrollView>
					</View>
					<View
						style={{
							// flex: 1,
							paddingRight: 8,
							// borderTopColor: "#797979",
							// borderTopWidth: 0.3,
						}}>
						<View
							style={{
								// flex: 1,
								height: this.props.screenHeight - 400,
								// padding: 8,
								paddingTop: 8,
							}}>
							<View
								style={{
									flex: 1,
									borderTopWidth: 0.3,
									borderTopColor: "#C5C5C5",
									marginTop: 5,
								}}>
								{/* <View
									style={{
										// flex: 1,
										marginTop: 8,
										marginBottom: 8,
									}}>
									<ScrollView horizontal>
										<View
											style={{
												flexDirection: "row",
											}}>
											{this.state.tabs.map((d, i) => (
												<TouchableOpacity
													key={i}
													style={{
														width: 150,
														height: "auto",
														padding: 7,
														alignItems: "center",
														borderRadius: 8,
														marginRight: 10,
														height: "auto",
														marginBottom: 1,
														backgroundColor:
															this.state.tab == d.title
																? APP_COLOURS.BLUE
																: APP_COLOURS.BG3,
														flexDirection: "row",
														// borderWidth:
														// 	this.state.tab == d.title ? 0.3 : 0,
														// borderColor: "#C5C5C5",
													}}
													onPress={() => {
														this.setState({
															tab: d.title,
														});
													}}>
													<View
														style={{
															width: 5,
															borderRadius: 4,
															height: "100%",
															backgroundColor:
																this.state.tab == d.title
																	? APP_COLOURS.BLUE
																	: APP_COLOURS.WHITE,
															marginRight: 12,
														}}
													/>
													<View
														style={{
															flex: 1,
															flexWrap: "wrap",
															padding: 4,
															borderRadius: 10,
															// justifyContent: 'center',
															// alignItems: 'center',
														}}>
														<Text
															numberOfLines={2}
															style={{
																fontFamily: "Avenir",
																fontSize:
																	16 + this.state.fontSize,
																// opacity: 0.8,
																flex: 1,
																flexWrap: "wrap",
																color: APP_COLOURS.TEXTCOLOR,
															}}>
															{d.title}
														</Text>
													</View>
												</TouchableOpacity>
											))}
										</View>
									</ScrollView>
								</View> */}

								{/* {this.state.tab === "Overview" ? (
									<View
										style={{
											// flex: 1,
											paddingRight: 8,
											borderTopColor: "#797979",
											borderTopWidth: 0.3,
										}}>
										<OverviewReport {...this.state} {...this.props} />
									</View>
								) : null} */}

								<View
									style={{
										// flex: 1,
										paddingRight: 8,
										borderTopColor: "#797979",
										borderTopWidth: 0.3,
										// maxWidth: 700,
									}}>
									<View
										style={{
											// width: 220,
											// paddingLeft: 0,
											flex: 1,

											paddingRight: 8,
											flexDirection: "row",
										}}>
										<View
											style={{
												flexDirection: "row",
												flex: 1,
												alignItems: "center",
												// justifyContent: "flex-end",
												paddingTop: 5,
												marginBottom: 5,
											}}>
											<View
												style={{
													flex: 1,
													flexDirection: "row",
												}}>
												<View
													style={{
														flexDirection: "row",
													}}>
													<View
														style={[
															{
																maxHeight: 90,
															},
															style,
														]}>
														<Text
															style={{
																opacity: 0.8,
																fontSize: 9,
																color: APP_COLOURS.BG4,
															}}>
															Total devices
														</Text>
														<Text
															style={{
																fontSize: 25,
																fontWeight: "800",
																color: APP_COLOURS.BG4,
															}}>
															{totalDevices}
														</Text>
													</View>
												</View>
												<View
													style={{
														flexDirection: "row",
													}}>
													<View
														style={[
															{
																maxHeight: 90,
															},
															style,
														]}>
														<Text
															style={{
																opacity: 0.8,
																fontSize: 9,
																color: APP_COLOURS.BG4,
															}}>
															Active devices
														</Text>
														<Text
															style={{
																fontSize: 25,
																fontWeight: "800",
																color: APP_COLOURS.BG4,
															}}>
															{activeDevices > 0
																? (
																		(activeDevices /
																			totalDevices) *
																		100
																  ).toFixed(0)
																: "None"}
															<Text
																style={{
																	fontSize: 12,
																	fontWeight: "700",
																	color: APP_COLOURS.BG4,
																	opacity: 0.8,
																}}>
																{activeDevices > 0 ? "%" : ""}
															</Text>
														</Text>
													</View>
												</View>
												<View
													style={{
														flexDirection: "row",
													}}>
													<View
														style={[
															{
																maxHeight: 90,
															},
															style,
														]}>
														<Text
															style={{
																opacity: 0.8,
																fontSize: 9,
																color: APP_COLOURS.BG4,
															}}>
															Devices online
														</Text>
														<Text
															style={{
																fontSize: 25,
																fontWeight: "800",
																color: APP_COLOURS.BG4,
															}}>
															{devicesOnline}{" "}
															<Text
																style={{
																	fontSize: 12,
																	fontWeight: "500",
																	color: APP_COLOURS.BG4,
																	opacity: 0.8,
																}}>
																/ {totalDevices}
															</Text>
														</Text>
													</View>
												</View>
												<View
													style={{
														flexDirection: "row",
													}}>
													<View
														style={[
															{
																maxHeight: 90,
															},
															style,
														]}>
														<Text
															style={{
																opacity: 0.8,
																fontSize: 9,
																color: APP_COLOURS.BG4,
															}}>
															Linked devices
														</Text>
														<Text
															style={{
																fontSize: 25,
																fontWeight: "800",
																color: APP_COLOURS.BG4,
															}}>
															{linkedDevices}{" "}
															<Text
																style={{
																	fontSize: 12,
																	fontWeight: "500",
																	color: APP_COLOURS.BG4,
																	opacity: 0.8,
																}}>
																/ {totalDevices}
															</Text>
														</Text>
													</View>
												</View>
											</View>
											{/* <TouchableOpacity
												onPress={() =>
													this.setState({
														wrapDeviceOrientation: true
													})
												}>
												<Image
													source={require("../../../assets/images/icons/gantt.png")}
													style={{
														width: 30,
														height: 30,
														resizeMode: "contain",
														marginRight: 10
													}}
												/>
											</TouchableOpacity> */}
											<TouchableOpacity
												style={{
													// width: 130,
													height: "auto",
													alignItems: "center",
													borderRadius: 10,
													marginRight: 10,
													height: "auto",
													backgroundColor: APP_COLOURS.BLUE,
													flexDirection: "row",
												}}
												onPress={async () => {
													await this.setState({
														addNewDevice: true,
													});
													await this.setState({
														addNewDevice: true,
													});
												}}>
												<View
													style={{
														flex: 1,
														flexWrap: "wrap",
														padding: 12,
														borderRadius: 10,
													}}>
													<Text
														numberOfLines={2}
														style={{
															fontFamily: "Avenir",
															fontSize: 13,
															// opacity: 0.8,
															flex: 1,
															flexWrap: "wrap",
															fontWeight: "600",
															color: APP_COLOURS.WHITE,
														}}>
														+ Device
													</Text>
												</View>
											</TouchableOpacity>
											<TextInput
												ref={(ref) => {
													this.filterByName = ref;
												}}
												style={{
													padding: 8,
													fontSize: 16,
													borderRadius: 9,
													color: APP_COLOURS.WHITE,
													fontWeight: "200",
													fontFamily: "Avenir",
													borderWidth: 0.3,
													borderColor: APP_COLOURS.BG3,
													backgroundColor: APP_COLOURS.BG3,
													marginRight: 5,
													paddingLeft: 15,
												}}
												placeholderTextColor={"#797979"}
												autoCapitalize='words'
												clearButtonMode='while-editing'
												editable={true}
												placeholder={`Search devices `}
												onChangeText={(text) => {
													this.setState({
														filterByName: text,
													});
												}}
											/>
										</View>
									</View>
									<View
										style={{
											// flex: 1,
											height: this.props.screenHeight - 400,
											// padding: 8,
											paddingTop: 8,
										}}>
										<ScrollView>
											{devices.map((d, i) => (
												<View
													key={i}
													style={{
														// flex: 1,
														borderRadius: 5,
														flexDirection: "row",
														borderColor: "#C5C5C5",
														borderWidth:
															this.state.showDelete === d.id
																? 1
																: 0,
														backgroundColor:
															this.state.hover == i
																? APP_COLOURS.BG5
																: APP_COLOURS.BG4,
														marginBottom: 1,
													}}>
													<TouchableOpacity
														onPress={() => {
															this.setState({
																profileData: d,
																deviceID: d.uuid,
																viewDeviceProfile: true,
															});
														}}>
														<View
															key={i}
															onMouseEnter={() =>
																this.setState({
																	hover: i,
																})
															}
															onMouseLeave={() =>
																this.setState({
																	hover: -1,
																})
															}
															style={{
																// flex: 1,
																opacity:
																	this.state.hover === i
																		? 1
																		: 0.9,

																height: "auto",
																padding: 4,
																alignItems: "center",
																marginRight: 10,
																height: "auto",
																marginBottom: 2,
																flexDirection: "row",
															}}>
															{/* <View
														style={{
															width: 5,
															borderRadius: 4,
															height: 30,
															backgroundColor:
																this.state.selectedUserID ===
																d.id
																	? APP_COLOURS.BLUE
																	: "transparent",
															marginRight: 12,
														}}
													/> */}
															<View
																style={{
																	width: 70,
																	marginRight: 10,
																}}>
																<View
																	style={
																		{
																			// width: col1,
																			// flexDirection: "row",
																			// alignItems: "center",
																		}
																	}>
																	<Text
																		style={{
																			fontSize: fs - 5,
																			opacity: 0.7,
																			color: "#FFF",
																		}}>
																		Unique ID
																	</Text>

																	<Text
																		style={{
																			fontSize: fs - 1,
																			color: "#FFF",
																		}}>
																		{d.uuid}
																	</Text>
																</View>
															</View>

															<View
																style={{
																	width: 150,
																	marginRight: 10,
																}}>
																<View
																	style={
																		{
																			// width: col1,
																			// flexDirection: "row",
																			// alignItems: "center",
																		}
																	}>
																	<Text
																		style={{
																			fontSize: fs - 5,
																			opacity: 0.7,
																			color: "#FFF",
																		}}>
																		Location
																	</Text>

																	<Text
																		style={{
																			fontSize: 12,
																			color: "#FFF",
																			opacity: 0.9,
																		}}>
																		{d.retailer.title},
																	</Text>
																	<Text
																		style={{
																			fontSize: 12,
																			color: "#FFF",
																			fontWeight: "600",
																		}}>
																		{d.mall.title}
																	</Text>
																</View>
															</View>

															<View
																style={{
																	width: 70,
																	marginRight: 10,
																}}>
																<View style={{}}>
																	<Text
																		style={{
																			fontSize: fs - 5,
																			opacity: 0.7,
																			color: "#FFF",
																		}}>
																		platform
																	</Text>
																	<View
																		style={{
																			flexDirection: "row",
																			alignItems: "center",
																		}}>
																		{d.platform ===
																			"ANDROID" ||
																		d.platform === "" ||
																		typeof d.platform ===
																			"undefined" ? (
																			<Image
																				source={android}
																				style={{
																					width: 20,
																					height: 20,
																					resizeMode:
																						"contain",
																				}}
																			/>
																		) : (
																			<Image
																				source={apple}
																				style={{
																					width: 20,
																					height: 20,
																					resizeMode:
																						"contain",
																				}}
																			/>
																		)}

																		<Text
																			style={{
																				fontSize: fs - 3,
																				color: "#FFF",
																			}}>
																			{d.version}
																		</Text>
																	</View>
																</View>
															</View>

															<View
																style={{
																	width: 90,
																	marginRight: 10,
																}}>
																<View
																	style={
																		{
																			// width: col1,
																			// flexDirection: "row",
																			// alignItems: "center",
																		}
																	}>
																	<Text
																		style={{
																			fontSize: fs - 5,
																			opacity: 0.7,
																			color: "#FFF",
																		}}>
																		location type
																	</Text>

																	<Text
																		style={{
																			fontSize: fs - 1,
																			color: "#FFF",
																		}}>
																		{d.location_type}
																	</Text>
																</View>
															</View>

															<View
																style={{
																	width: 90,
																	marginRight: 10,
																}}>
																<View
																	style={
																		{
																			// width: col1,
																			// flexDirection: "row",
																			// alignItems: "center",
																		}
																	}>
																	<Text
																		style={{
																			fontSize: fs - 5,
																			opacity: 0.7,
																			color: "#FFF",
																		}}>
																		App style
																	</Text>

																	<Text
																		style={{
																			fontSize: fs - 1,
																			color: "#FFF",
																		}}>
																		{d.app_properties.title}
																	</Text>
																</View>
															</View>

															<View
																style={{
																	width: 70,
																}}>
																<Text
																	style={{
																		fontSize: fs - 5,
																		opacity: 0.7,
																		color: "#FFF",
																	}}>
																	app users:
																</Text>
																<View
																	style={{
																		flexDirection: "row",
																		alignItems: "center",
																		height: 22,
																	}}>
																	{d.users.map((u, ii) => (
																		<Image
																			source={
																				AVATARS[u.avatar].pp
																			}
																			style={{
																				width: 20,
																				height: 20,
																				borderRadius: 10,
																				resizeMode:
																					"contain",
																				backgroundColor:
																					"#E8E8E8",
																				borderWidth: 1,
																				borderColor: "#FFF",
																				shadowColor: "#000",
																				shadowOffset: {
																					width: 0,
																					height: 8,
																				},
																				shadowOpacity: 0.44,
																				shadowRadius: 10.32,
																				elevation: 16,
																				left: ii * 11,
																				position:
																					"absolute",
																			}}
																		/>
																	))}
																</View>
															</View>

															<View
																style={{
																	width: col2,
																}}>
																<Text
																	style={{
																		fontSize: fs - 5,
																		opacity: 0.7,
																		color: "#FFF",
																	}}>
																	created on:
																</Text>
																<Text
																	style={{
																		fontSize: 10,
																		color: "#FFF",
																	}}>
																	{moment(
																		d.date_created,
																	).format("MMM DD, YYYY")}
																</Text>
															</View>

															<View
																style={{
																	width: 50,
																	marginRight: 10,
																}}>
																<View
																	style={
																		{
																			// width: col1,
																			// flexDirection: "row",
																			// alignItems: "center",
																		}
																	}>
																	<Text
																		style={{
																			fontSize: fs - 5,
																			opacity: 0.7,
																			color: "#FFF",
																		}}>
																		status
																	</Text>

																	<View
																		style={{
																			width: 40,
																			flexDirection: "row",
																			marginTop: 2,
																		}}>
																		<Text
																			style={{
																				fontFamily:
																					"Avenir",
																				fontSize: 10,
																				color: "#FFF",
																				fontWeight: "400",
																			}}>
																			{d.active
																				? `Active`
																				: `Inactive`}
																		</Text>
																	</View>
																</View>
															</View>

															<View
																style={{
																	width: 70,
																	marginRight: 10,
																}}>
																<View
																	style={
																		{
																			// width: col1,
																			// flexDirection: "row",
																			// alignItems: "center",
																		}
																	}>
																	<Text
																		style={{
																			fontSize: fs - 5,
																			opacity: 0.7,
																			color: "#FFF",
																		}}>
																		linked device
																	</Text>

																	<View
																		style={{
																			width: 50,
																			flexDirection: "row",
																			marginTop: 2,
																		}}>
																		<View
																			style={{
																				width: 8,
																				height: 8,
																				backgroundColor:
																					d.isDevice
																						? "#2EE39C"
																						: "#FF5F5F",
																				borderRadius: 5,
																				marginRight: 5,
																			}}
																		/>
																		<Text
																			style={{
																				fontFamily:
																					"Avenir",
																				fontSize: 10,
																				color: "#FFF",
																				fontWeight: "400",
																			}}>
																			{d.isDevice
																				? `true`
																				: `false`}
																		</Text>
																	</View>
																	<Text
																		numberOfLines={1}
																		ellipsizeMode='clip'
																		style={{
																			fontSize: 6,
																			color: "#FFF",
																			fontWeight: "400",
																			maxWidth: 50,
																		}}>
																		{d.device_id}
																	</Text>
																</View>
															</View>

															<View
																style={{
																	width: 70,
																	marginRight: 10,
																}}>
																<View
																	style={
																		{
																			// width: col1,
																			// flexDirection: "row",
																			// alignItems: "center",
																		}
																	}>
																	<Text
																		style={{
																			fontSize: fs - 5,
																			opacity: 0.7,
																			color: "#FFF",
																		}}>
																		status
																	</Text>

																	<View
																		style={{
																			width: 50,
																			flexDirection: "row",
																			marginTop: 2,
																		}}>
																		<View
																			style={{
																				width: 8,
																				height: 8,
																				backgroundColor:
																					d.last_seen !==
																						"" &&
																					moment().diff(
																						d.last_seen,
																						"hours",
																					) < 1
																						? "#2EE39C"
																						: "#FF5F5F",
																				borderRadius: 5,
																				marginRight: 5,
																			}}
																		/>
																		<Text
																			style={{
																				fontFamily:
																					"Avenir",
																				fontSize: 10,
																				color: "#FFF",
																				fontWeight: "400",
																			}}>
																			{d.last_seen !== "" &&
																			moment().diff(
																				d.last_seen,
																				"hours",
																			) < 1
																				? `Online`
																				: `Offline`}
																		</Text>
																	</View>
																</View>
															</View>

															<View
																style={{
																	width: 90,
																}}>
																<Text
																	style={{
																		fontSize: fs - 5,
																		opacity: 0.7,
																		color: "#FFF",
																	}}>
																	last seen:
																</Text>
																<Text
																	style={{
																		marginTop: 2,
																		fontSize: 10,
																		color:
																			d.last_seen !== ""
																				? "#FFF"
																				: APP_COLOURS.ORANGE,
																	}}>
																	{d.last_seen !== ""
																		? moment(
																				d.last_seen,
																		  ).fromNow()
																		: "No device set up"}
																</Text>
															</View>
														</View>
													</TouchableOpacity>

													{this.state.showDelete === d.id ? (
														<TouchableOpacity
															onPress={() => {
																this.deleteDevice(d.id);
															}}
															style={{
																justifyContent: "center",
																alignItems: "center",
															}}>
															<Image
																source={bin}
																style={{
																	width: 22,
																	height: 22,
																	resizeMode: "contain",
																	marginRight: 5,
																}}
															/>
														</TouchableOpacity>
													) : null}
												</View>
											))}
											{this.state.loading ? (
												<View
													style={{
														flex: 1,
														justifyContent: "center",
														alignItems: "center",
														minHeight: 50,
													}}>
													<ActivityIndicator
														color={APP_COLOURS.BLUE}
													/>
												</View>
											) : null}
										</ScrollView>
									</View>
								</View>

								{/* {this.state.tab === "Data" ? (
									<View
										style={{
											// flex: 1,
											paddingRight: 8,
											borderTopColor: "#797979",
											borderTopWidth: 0.3,
										}}>
										<View
											style={{
												// flex: 1,
												height: this.props.screenHeight - 220,
												paddingTop: 8,
											}}>
											<Results {...this.props} />
										</View>
									</View>
								) : null} */}
							</View>
						</View>
					</View>
				</View>
				{this.state.addNewDevice ? this.addNewDevice() : <View />}

				{this.state.viewDeviceProfile ? (
					this.DeviceProfileModal(this.state.profileData)
				) : (
					<View />
				)}
				{this.state.showenlargedQRCodeModal ? (
					this.enlargedQRCodeModal()
				) : (
					<View />
				)}
			</div>
		);
	}

	// DeviceProfileModal2 = (d) => {
	// 	return (
	// 		<Modal animationType='fade' transparent={true} visible={true}>
	// 			<View
	// 				style={{
	// 					flex: 1,
	// 					// justifyContent: "center",
	// 					alignItems: "center",
	// 					backgroundColor: "rgba(52, 52, 52, 0.6)",
	// 				}}>
	// 				<ScrollView>
	// 					<View
	// 						style={{
	// 							height: 90,
	// 						}}
	// 					/>
	// 					<View
	// 						style={{
	// 							width: "100%",
	// 							maxWidth: 500,
	// 							// height: "80%",
	// 							backgroundColor: "#FFF",
	// 							// backgroundColor: APP_COLOURS.BG3,
	// 							borderRadius: 20,
	// 							// justifyContent: "center",
	// 							// alignItems: "center",
	// 						}}>
	// 						<View
	// 							style={{
	// 								position: "absolute",
	// 								zIndex: 99,
	// 								top: 10,
	// 								left: 10,
	// 							}}>
	// 							<TouchableOpacity
	// 								onPress={() =>
	// 									this.setState({
	// 										viewDeviceProfile: false,
	// 									})
	// 								}>
	// 								<Image
	// 									source={close}
	// 									style={{
	// 										width: 20,
	// 										height: 20,
	// 										resizeMode: "contain",
	// 									}}
	// 								/>
	// 							</TouchableOpacity>
	// 						</View>
	// 						<View
	// 							style={{
	// 								width: "100%",
	// 								padding: 20,
	// 								height: "auto",
	// 							}}>
	// 							<View
	// 								style={{
	// 									justifyContent: "center",
	// 									alignItems: "center",
	// 									padding: 20,
	// 								}}>
	// 								<View
	// 									style={{
	// 										width: 110,
	// 										height: 110,
	// 										alignItems: "center",
	// 										justifyContent: "center",
	// 										borderRadius: 55,
	// 										borderWidth: 1,
	// 										borderColor: "#797979",
	// 										backgroundColor: "#797979",
	// 										shadowColor: "#000",
	// 										shadowOffset: {
	// 											width: 0,
	// 											height: 2,
	// 										},
	// 										shadowOpacity: 0.25,
	// 										shadowRadius: 7.84,
	// 										elevation: 12,
	// 									}}>
	// 									<Image
	// 										source={logo}
	// 										style={{
	// 											width: 70,
	// 											height: 70,
	// 											resizeMode: "contain",
	// 										}}
	// 									/>
	// 								</View>
	// 							</View>

	// 							<Text
	// 								allowFontScaling={false}
	// 								style={{
	// 									fontSize: 20,
	// 									fontFamily: "Avenir Next",
	// 									textAlign: "center",
	// 									fontWeight: "600",
	// 									marginBottom: 20,
	// 								}}>
	// 								{d.uuid} {d.mall} {d.retailer}
	// 							</Text>

	// 							<View
	// 								style={{
	// 									marginTop: 15,
	// 								}}>
	// 								<View
	// 									style={{
	// 										flex: 1,
	// 										paddingLeft: 15,
	// 									}}>
	// 									<Text
	// 										adjustsFontSizeToFit
	// 										style={{
	// 											color: "#000",
	// 											fontSize: 10,
	// 										}}>
	// 										<Font weight={600} family='Montserrat'>
	// 											Contacts
	// 										</Font>
	// 									</Text>
	// 								</View>
	// 								<View
	// 									style={{
	// 										padding: 15,
	// 										paddingRight: 50,
	// 									}}>
	// 									<View
	// 										style={{
	// 											width: "auto",
	// 											marginBottom: 16,
	// 										}}>
	// 										<View
	// 											style={{
	// 												// width: col1,
	// 												flexDirection: "row",
	// 												alignItems: "center",
	// 												marginBottom: 5,
	// 											}}>
	// 											<Text
	// 												style={{
	// 													fontSize: 10,
	// 													marginRight: 3,
	// 													opacity: 0.6,
	// 												}}>
	// 												email:
	// 											</Text>
	// 											{d.userActive ? (
	// 												<Image
	// 													source={tick}
	// 													style={{
	// 														width: 12,
	// 														height: 12,
	// 														// borderRadius: 25,
	// 														resizeMode: "contain",
	// 														marginRight: 5,
	// 													}}
	// 												/>
	// 											) : (
	// 												<View />
	// 											)}
	// 										</View>

	// 										<Text
	// 											style={{
	// 												width: 200,
	// 												fontSize: 15,
	// 												marginBottom: 10,
	// 											}}>
	// 											{d.username}
	// 										</Text>

	// 										<Text
	// 											style={{
	// 												fontSize: 10,
	// 												marginBottom: 5,
	// 												opacity: 0.6,
	// 											}}>
	// 											created at:
	// 										</Text>
	// 										<Text
	// 											style={{
	// 												fontSize: 13,
	// 											}}>
	// 											{moment(d.date_created).format(
	// 												"MMM DD, YYYY",
	// 											)}
	// 										</Text>
	// 									</View>

	// 									{/* <View
	// 									style={{
	// 										width: "auto",
	// 										marginBottom: 16,
	// 									}}>
	// 									<View
	// 										style={{
	// 											// width: col1,
	// 											flexDirection: "row",
	// 											alignItems: "center",
	// 											marginBottom: 5,
	// 										}}>
	// 										<Text
	// 											style={{
	// 												fontSize: 10,
	// 												marginRight: 3,
	// 											}}>
	// 											cell:
	// 										</Text>
	// 										{d.cell.verified ? (
	// 											<Image
	// 												source={tick}
	// 												style={{
	// 													width: 12,
	// 													height: 12,
	// 													// borderRadius: 25,
	// 													resizeMode: "contain",
	// 													marginRight: 5,
	// 												}}
	// 											/>
	// 										) : (
	// 											<View />
	// 										)}
	// 									</View>

	// 									<Text
	// 										style={{
	// 											width: 200,
	// 											fontSize: 15,
	// 										}}>
	// 										{d.cell.number}
	// 									</Text>
	// 								</View> */}
	// 								</View>
	// 							</View>

	// 							<TouchableOpacity
	// 								onPress={() => this.deleteDevice(d.id)}
	// 								style={{
	// 									justifyContent: "center",
	// 									alignItems: "center",
	// 								}}>
	// 								<Image
	// 									source={bin}
	// 									style={{
	// 										width: 22,
	// 										height: 22,
	// 										resizeMode: "contain",
	// 										marginRight: 5,
	// 									}}
	// 								/>
	// 							</TouchableOpacity>
	// 						</View>
	// 					</View>
	// 				</ScrollView>
	// 			</View>
	// 		</Modal>
	// 	);
	// };

	DeviceProfileModal = (d) => {
		// let imgSize = 1000;
		return (
			<Modal animationType='fade' transparent={true} visible={true}>
				<View
					style={{
						flex: 1,
						// justifyContent: "center",
						alignItems: "center",
						backgroundColor: "rgba(52, 52, 52, 0.6)",
					}}>
					<View
						style={{
							opacity: 0,
							zIndex: -1,
							position: "absolute",
						}}>
						<div
							id='capture'
							style={{
								width: 500,
								height: 800,

								justifyContent: "center",
								alignItems: "center",
							}}>
							<View
								style={{
									width: 500,
									height: 800,
									backgroundColor: APP_COLOURS.BG2,
									padding: 15,
									// height: "auto",
									paddingTop: 20,
									// justifyContent: "center",
									// alignItems: "center",
								}}>
								<View
									style={{
										// justifyContent: "center",
										// alignItems: "center",
										marginTop: 10,
									}}>
									{/* <Image
										source={survey}
										style={{
											width: 120,
											height: 50,
											resizeMode: "contain",
										}}
									/> */}
								</View>
								<View
									style={{
										width: "100%",
										// justifyContent: "flex-end",
										justifyContent: "space-between",
										alignItems: "flex-end",
										flexDirection: "row",
										// marginBottom: 40,
										padding: 10,
									}}>
									<View
										style={
											{
												// justifyContent: "center",
												// alignItems: "center",
											}
										}>
										<View
											style={{
												flexDirection: "row",
												// marginTop: 5,
											}}>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 20,
													color: APP_COLOURS.TEXTCOLOR,
													fontWeight: "700",
												}}>
												TRAQ - Staff app
											</Text>
										</View>
										<Text
											style={{
												fontFamily: "Avenir",
												fontSize: 14,
												color: APP_COLOURS.TEXTCOLOR,
												fontWeight: "400",
											}}>
											https://traq.app
										</Text>

										<View
											style={{
												width: 300,
												marginRight: 10,
												display: d.isDevice ? "flex" : "none",
											}}>
											<View
												style={
													{
														// width: col1,
														// flexDirection: "row",
														// alignItems: "center",
													}
												}>
												<Text
													style={{
														fontSize: 8,
														opacity: 0.7,
														color: "#FFF",
													}}>
													linked device
												</Text>

												<View
													style={{
														width: 60,
														flexDirection: "row",
														marginTop: 2,
														alignItems: "center",
													}}>
													<View
														style={{
															width: 8,
															height: 8,
															backgroundColor: d.isDevice
																? "#2EE39C"
																: "#FF5F5F",
															borderRadius: 5,
															marginRight: 5,
														}}
													/>
													<Text
														style={{
															fontFamily: "Avenir",
															fontSize: 12,
															color: "#FFF",
															fontWeight: "400",
														}}>
														{d.isDevice ? `true` : `false`}
													</Text>
												</View>
											</View>
											<Text
												style={{
													fontFamily: "Avenir",
													fontSize: 12,
													color: "#FFF",
													fontWeight: "400",
													opacity: 0.5,
												}}>
												{d.device_id}
											</Text>
										</View>
									</View>
									{d.platform === "ANDROID" ? (
										<Image
											source={android}
											style={{
												width: 150,
												height: 90,
												resizeMode: "contain",
											}}
										/>
									) : (
										<Image
											source={apple}
											style={{
												width: 150,
												height: 90,
												resizeMode: "contain",
											}}
										/>
									)}
								</View>

								<InputArea
									title={"Device unique ID"}
									value={d.uuid}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<InputArea
									title={"Passcode"}
									value={d.passcode}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 10,
										fontFamily: "Avenir Next",
										textAlign: "center",
										fontWeight: "500",
										color: APP_COLOURS.RED,
									}}>
									Passcode only valid for one authentication
								</Text>

								<InputArea
									title={"Mall"}
									value={d.mall}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<InputArea
									title={"Retailer"}
									value={d.retailer}
									editable={false}
									color={"#FFF"}
									bgColor={APP_COLOURS.BG2}
								/>

								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
										marginTop: 5,
									}}>
									<View
										style={{
											backgroundColor: "#FFF",
											borderRadius: 12,
											height: 200,
											width: 200,
											padding: 10,
										}}>
										<QRCode
											size={180}
											value={`https://traq.app?deviceregistration=${d.uuid},${d.passcode}`}
											bgColor={"#FFF"}
											fgColor={APP_COLOURS.BG2}
										/>
									</View>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 10,
											fontFamily: "Avenir Next",
											textAlign: "center",
											fontWeight: "700",
											color: APP_COLOURS.RED,
										}}>
										Scan to activate device
									</Text>
								</View>

								<View
									style={{
										width: "100%",
										// position: "absolute",
										// bottom: 10,
										// left: 0,
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										// paddingRight: 30,
										// paddingLeft: 30,
									}}>
									<Text
										style={{
											width: 110,
											fontSize: 16,
											color: "#FFF",
										}}>
										<Font family='Poppins' weight={800}>
											tetrice
										</Font>
									</Text>
									<View
										style={{
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<Image
											source={require("../../../assets/images/google_play.png")}
											style={{
												width: 100,
												height: 25,
												resizeMode: "contain",
												marginRight: 10,
											}}
										/>

										<Image
											source={require("../../../assets/images/app_store.png")}
											style={{
												width: 100,
												height: 25,
												resizeMode: "contain",
											}}
										/>
									</View>
									<Text
										style={{
											width: 110,
											fontSize: 13,
											color: "#FFF",
										}}>
										<Font family='Poppins' weight={300}>
											info@traq.app
										</Font>
									</Text>
								</View>
							</View>
						</div>
					</View>

					<ScrollView>
						<View
							style={{
								height: 20,
							}}
						/>
						<View
							style={{
								width: 500,
								// maxWidth: 500,
								// height: "80%",
								// backgroundColor: "#FFF",
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 20,
								// justifyContent: "center",
								// alignItems: "center",
								paddingTop: 50,
							}}>
							<View
								style={{
									position: "absolute",
									zIndex: 99,
									top: 10,
									left: 10,
								}}>
								<TouchableOpacity
									onPress={() =>
										this.setState({
											viewDeviceProfile: false,
										})
									}>
									<Image
										source={close}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
							</View>
							<View
								style={{
									padding: 10,
								}}>
								<TopTabs
									{...this.state}
									screenHeight={this.state.screenHeight}
									screenWidth={this.state.screenWidth}
									selectedSubPage={(d) =>
										this.setState({
											deviceProfileSubPage: d,
										})
									}
									tabs={["Overview", "Users", "App style"]}
								/>
							</View>

							{this.state.deviceProfileSubPage === "Overview" ? (
								<View
									style={{
										width: "100%",
										padding: 20,
										height: "auto",
										paddingTop: 25,
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 19,
											fontFamily: "Avenir Next",
											textAlign: "center",
											fontWeight: "700",
											marginBottom: 20,
											color: "#FFF",
										}}>
										Device profile
									</Text>

									<View
										style={{
											width: "100%",
											// justifyContent: "flex-end",
											justifyContent: "space-between",
											alignItems: "flex-end",
											flexDirection: "row",
											// marginBottom: 40,
											padding: 20,
										}}>
										<View>
											{/* {d.platform === "ANDROID" ? ( */}
											<Image
												source={android}
												style={{
													width: 150,
													height: 90,
													resizeMode: "contain",
												}}
											/>
											<View
												style={{
													width: 300,
													marginRight: 10,
													display: d.isDevice ? "flex" : "none",
												}}>
												<View
													style={
														{
															// width: col1,
															// flexDirection: "row",
															// alignItems: "center",
														}
													}>
													<Text
														style={{
															fontSize: 8,
															opacity: 0.7,
															color: "#FFF",
														}}>
														linked device
													</Text>

													<View
														style={{
															width: 60,
															flexDirection: "row",
															marginTop: 2,
															alignItems: "center",
														}}>
														<View
															style={{
																width: 8,
																height: 8,
																backgroundColor: d.isDevice
																	? "#2EE39C"
																	: "#FF5F5F",
																borderRadius: 5,
																marginRight: 5,
															}}
														/>
														<Text
															style={{
																fontFamily: "Avenir",
																fontSize: 12,
																color: "#FFF",
																fontWeight: "400",
															}}>
															{d.isDevice ? `true` : `false`}
														</Text>
													</View>
												</View>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 12,
														color: "#FFF",
														fontWeight: "400",
														opacity: 0.5,
													}}>
													{d.device_id}
												</Text>
											</View>
										</View>

										<View
											style={{
												justifyContent: "center",
												alignItems: "center",
											}}>
											<View
												style={{
													backgroundColor: "#FFF",
													borderRadius: 12,
													height: 100,
													width: 100,
													padding: 10,
												}}>
												<QRCode
													size={80}
													value={`https://traq.app?deviceregistration=${d.uuid},${d.passcode}`}
													bgColor={"#FFF"}
													fgColor={APP_COLOURS.BG2}
												/>
											</View>
											<Text
												allowFontScaling={false}
												style={{
													fontSize: 9,
													fontFamily: "Avenir Next",
													textAlign: "center",
													fontWeight: "700",
													color: "#FFF",
												}}>
												Scan to activate device
											</Text>
										</View>
									</View>

									<InputArea
										title={"Device unique ID"}
										value={d.uuid}
										editable={false}
										color={"#FFF"}
										bgColor={APP_COLOURS.BG2}
									/>

									<InputArea
										title={"Passcode"}
										value={d.passcode}
										editable={false}
										color={"#FFF"}
										bgColor={APP_COLOURS.BG2}
									/>

									<InputArea
										title={"Mall"}
										value={d.mall.title}
										editable={false}
										color={"#FFF"}
										bgColor={APP_COLOURS.BG2}
									/>

									<InputArea
										title={"Retailer"}
										value={d.retailer.title}
										editable={false}
										color={"#FFF"}
										bgColor={APP_COLOURS.BG2}
									/>

									<InputArea
										title={"Date created"}
										value={moment(d.date_created).format(
											"MMM DD, YYYY",
										)}
										editable={false}
										color={"#FFF"}
										bgColor={APP_COLOURS.BG2}
									/>

									<TouchableOpacity
										onPress={() => this.deleteDevice(d.id)}
										style={{
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Image
											source={bin}
											style={{
												width: 22,
												height: 22,
												resizeMode: "contain",
												marginRight: 5,
											}}
										/>
									</TouchableOpacity>

									<TouchableOpacity
										style={{
											// position: "absolute",
											marginTop: 20,
											zIndex: 999,
											bottom: 0,
											justifyContent: "center",
											alignItems: "center",
										}}
										onPress={() => {
											this.snapShot();
										}}>
										<View
											style={{
												backgroundColor: APP_COLOURS.RED,
												borderRadius: 10,
												padding: 10,
												width: 200,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#FFF",
													fontFamily: "Avenir",
													fontWeight: "600",
												}}>
												Download & send
											</Text>
										</View>
									</TouchableOpacity>
								</View>
							) : null}

							{this.state.deviceProfileSubPage === "Users" ? (
								<View
									style={{
										width: "100%",
										padding: 20,
										height: "auto",
										paddingTop: 25,
										minHeight: 600,
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 19,
											fontFamily: "Avenir Next",
											textAlign: "center",
											fontWeight: "700",
											marginBottom: 20,
											color: "#FFF",
										}}>
										Users
									</Text>

									<View
										style={{
											marginBottom: 10,
										}}>
										<StaffSearchBar
											{...this.props}
											selectedData={async (newStaff) => {
												console.log(newStaff);
												let editUsers = await [...d.users];
												let filterUser = editUsers.filter(
													(d_) => d_.id !== newStaff.id,
												);
												await filterUser.push(newStaff);
												await this.updateDeviceUsers(
													d.id,
													filterUser,
												);
											}}
										/>
									</View>

									<View>
										{d.users.map((user, i) => (
											<View
												key={i}
												onMouseEnter={() =>
													this.setState({
														userHover: i,
													})
												}
												onMouseLeave={() =>
													this.setState({
														userHover: -1,
													})
												}
												style={{
													width: "100%",
													backgroundColor:
														"rgba(255, 255, 255, 0.05)",
													opacity:
														this.state.userHover === i
															? 1
															: this.state.selectedUserID ===
															  user.id
															? 1
															: 0.7,
													flexDirection: "row",
													alignItems: "center",
													borderRadius: 3,
													marginRight: 10,
													marginBottom: 2,
													paddingRight: 10,

													// backgroundColor:
													// 	this.state.hover == i
													// 		? APP_COLOURS.WHITE
													// 		: APP_COLOURS.OFFWHITE,
													flexDirection: "row",
													borderColor: "#C5C5C5",
												}}>
												<View
													style={{
														width: 5,
														borderRadius: 4,
														height: 30,
														backgroundColor:
															this.state.selectedUserID ===
															user.id
																? APP_COLOURS.BLUE
																: "transparent",
														marginRight: 12,
													}}
												/>
												<Image
													// source={female}
													source={AVATARS[user.avatar].pp}
													style={{
														width: 40,
														height: 40,
														borderRadius: 20,
														resizeMode: "contain",
														backgroundColor: "#797979",
														borderWidth: 2,
														borderColor: "#C5C5C5",
														shadowColor: "#ECF4FF",
														shadowOffset: {
															width: 0,
															height: 8,
														},
														shadowOpacity: 0.44,
														shadowRadius: 10.32,

														elevation: 16,
														marginRight: 5,
													}}
												/>
												<View
													style={{
														flex: 1,
														minWidth: 150,
														padding: 10,
													}}>
													<Text
														style={{
															fontSize: 15,
															color: APP_COLOURS.WHITE,
														}}>
														{user.first_name},{" "}
														<Text
															style={{
																fontWeight: "600",
															}}>
															{user.last_name}
														</Text>
													</Text>
													<View
														style={{
															flexDirection: "row",
															alignItems: "center",
															marginTop: 3,
														}}>
														<View
															style={{
																width: 8,
																height: 8,
																borderRadius: 4,

																backgroundColor:
																	user.status === "On Counter"
																		? APP_COLOURS.GREEN
																		: APP_COLOURS.RED,
																marginRight: 4,
															}}
														/>
														<Text
															style={{
																fontSize: 9,
																color: APP_COLOURS.WHITE,
																fontWeight: "300",
															}}>
															{user.status}
														</Text>
													</View>
												</View>

												<View
													style={{
														marginRight: 10,
													}}>
													<Text
														style={{
															fontSize: 9,
															color: APP_COLOURS.WHITE,
															fontWeight: "300",
															opacity: 0.7,
														}}>
														passcode:
													</Text>
													<Text
														style={{
															fontSize: 12,
															color: APP_COLOURS.WHITE,
															fontWeight: "600",
														}}>
														{user.passcode}
													</Text>
												</View>

												{/* <View
													style={{
														width: 100,
														display:
															this.props.screenWidth < 1553
																? "none"
																: "flex",
													}}>
													<Text
														style={{
															fontSize: fs * 0.6,
															color: APP_COLOURS.WHITE,
														}}>
														Last seen
													</Text>
													<Text
														style={{
															fontSize: fs * 0.7,
															color: APP_COLOURS.WHITE,
															fontWeight: "600",
														}}>
														{moment(user.last_seen).fromNow()}
													</Text>
												</View> */}

												<View
													style={{
														// flex: 1,
														display:
															this.props.screenWidth < 1670
																? "none"
																: "flex",
														flexDirection: "row",
													}}>
													<View
														style={{
															height: "100%",
															width: 0.5,
															backgroundColor: APP_COLOURS.WHITE,
															marginRight: 10,
														}}
													/>
													<View
														style={{
															width: 150,
														}}>
														<Text
															style={{
																fontSize: 12,
																fontWeight: "100",
																color: APP_COLOURS.TEXTCOLOR,
															}}>
															{user.permission}
														</Text>
														<Text
															style={{
																fontSize: 12,
																color: APP_COLOURS.TEXTCOLOR,
																fontWeight: "600",
															}}>
															{user.position}
														</Text>
													</View>
												</View>

												<TouchableOpacity
													disable={this.state.userHover !== i}
													onPress={() => {
														let editUsers = [...d.users];
														let filterUser = editUsers.filter(
															(d_) => d_.id !== user.id,
														);
														this.updateDeviceUsers(
															d.id,
															filterUser,
														);
													}}
													style={{
														justifyContent: "center",
														alignItems: "center",
														opacity:
															this.state.userHover === i ? 1 : 0,
													}}>
													<Image
														source={bin}
														style={{
															width: 22,
															height: 22,
															resizeMode: "contain",
															marginRight: 5,
														}}
													/>
												</TouchableOpacity>
											</View>
										))}
									</View>

									{/* <TouchableOpacity
										style={{
											// position: "absolute",
											marginTop: 20,
											zIndex: 999,
											bottom: 0,
											justifyContent: "center",
											alignItems: "center",
										}}
										onPress={() => {
											// this.snapShot();
										}}>
										<View
											style={{
												backgroundColor: APP_COLOURS.BLUE,
												borderRadius: 15,
												padding: 10,
												width: 200,
												justifyContent: "center",
												alignItems: "center",
											}}>
											<Text
												style={{
													color: "#FFF",
													fontFamily: "Avenir",
													fontWeight: "600",
												}}>
												Add user
											</Text>
										</View>
									</TouchableOpacity> */}
								</View>
							) : null}

							{this.state.deviceProfileSubPage === "App style" ? (
								<View
									style={{
										width: "100%",
										padding: 20,
										height: "auto",
										paddingTop: 25,
										minHeight: 600,
									}}>
									<Text
										allowFontScaling={false}
										style={{
											fontSize: 19,
											fontFamily: "Avenir Next",
											textAlign: "center",
											fontWeight: "700",
											marginBottom: 20,
											color: "#FFF",
										}}>
										App style
									</Text>
									<View
										style={{
											marginTop: 20,
										}}>
										<Text
											style={{
												fontSize: 15,
												color: APP_COLOURS.WHITE,
												// opacity: 0.7,
												fontWeight: "600",
											}}>
											Brand logo
										</Text>
										<Image
											source={`data:image/png;base64,${d.app_properties.logo}`}
											style={{
												height: 60,
												width: 60,
												resizeMode: "contain",
											}}
										/>
									</View>
									<View>
										<View
											style={{
												marginTop: 20,
											}}>
											<View
												style={
													{
														// width: col1,
														// flexDirection: "row",
														// alignItems: "center",
													}
												}>
												<Text
													style={{
														fontSize: 15,
														color: APP_COLOURS.WHITE,
														// opacity: 0.7,
														fontWeight: "600",
													}}>
													Background color
												</Text>

												<View
													style={{
														width: 60,
														height: 50,
														borderRadius: 10,
														borderWidth: 1,
														borderColor: "#FFF",
														backgroundColor:
															d.app_properties.backgroundcolour,
													}}></View>
											</View>
										</View>
										<View
											style={{
												marginTop: 20,
											}}>
											<View
												style={
													{
														// width: col1,
														// flexDirection: "row",
														// alignItems: "center",
													}
												}>
												<Text
													style={{
														fontSize: 15,
														color: APP_COLOURS.WHITE,
														// opacity: 0.7,
														fontWeight: "600",
													}}>
													Brand color
												</Text>

												<View
													style={{
														width: 60,
														height: 50,
														borderRadius: 10,
														borderWidth: 1,
														borderColor: "#FFF",
														backgroundColor:
															d.app_properties.brandcolour,
													}}></View>
											</View>
										</View>
										<View
											style={{
												marginTop: 20,
											}}>
											<View
												style={
													{
														// width: col1,
														// flexDirection: "row",
														// alignItems: "center",
													}
												}>
												<Text
													style={{
														fontSize: 15,
														color: APP_COLOURS.WHITE,
														// opacity: 0.7,
														fontWeight: "600",
													}}>
													Font color
												</Text>

												<View
													style={{
														width: 60,
														height: 50,
														borderRadius: 10,
														borderWidth: 1,
														borderColor: "#FFF",
														backgroundColor:
															d.app_properties.fontcolour,
													}}></View>
											</View>
										</View>
									</View>
								</View>
							) : null}
						</View>
					</ScrollView>
				</View>
			</Modal>
		);
	};

	enlargedQRCodeModal = () => {
		console.log("enlarged");
		// return (
		// 	<Modal animationType='fade' transparent={true} visible={true}>

		// 	</Modal>
		// );

		return (
			<View
				style={{
					position: "absolute",
					top: 0,
					left: 0,

					width: "100%",
					height: this.props.screenHeight,
					// justifyContent: "center",
					alignItems: "center",
					backgroundColor: "rgba(0, 0, 0, 0.8)",
					paddingTop: "20%",
					borderRadius: 10,
				}}>
				<View
					style={{
						width: 500,
						height: 550,
						// height: "80%",
						// backgroundColor: "#FFF",
						backgroundColor: APP_COLOURS.BG2,
						borderRadius: 20,
						// justifyContent: "center",
						// alignItems: "center",
					}}>
					<View
						style={{
							position: "absolute",
							zIndex: 99,
							top: 10,
							left: 10,
						}}>
						<TouchableOpacity
							onPress={() =>
								this.setState({
									showenlargedQRCodeModal: false,
								})
							}>
							<Image
								source={close}
								style={{
									width: 20,
									height: 20,
									resizeMode: "contain",
								}}
							/>
						</TouchableOpacity>
					</View>
					<View
						style={{
							flex: 1,
							padding: 20,

							paddingTop: 25,
						}}>
						<TouchableOpacity
							onPress={() => {
								window.open(this.state.url, "_blank");
							}}>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 19,
									fontFamily: "Avenir Next",
									textAlign: "center",
									fontWeight: "700",
									marginBottom: 20,
									color: "#FFF",
								}}>
								{"https://traq.app"}
							</Text>
						</TouchableOpacity>

						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								marginTop: 10,
							}}>
							<View
								style={{
									backgroundColor: "#FFF",
									borderRadius: 12,
									height: 300,
									width: 300,
									padding: 10,
								}}>
								<QRCode
									size={280}
									value={`https://traq.app`}
									bgColor={"#FFF"}
									fgColor={APP_COLOURS.BG2}
								/>
							</View>
							<Text
								allowFontScaling={false}
								style={{
									fontSize: 16,
									fontFamily: "Avenir Next",
									textAlign: "center",
									fontWeight: "700",
									color: APP_COLOURS.RED,
									marginTop: 10,
								}}>
								SCAN ME
							</Text>
						</View>

						<TouchableOpacity
							style={{
								// position: "absolute",
								marginTop: 20,
								zIndex: 999,
								bottom: 0,
								justifyContent: "center",
								alignItems: "center",
							}}
							onPress={() => {
								this.snapShot2();
							}}>
							<View
								style={{
									backgroundColor: APP_COLOURS.RED,
									borderRadius: 10,
									padding: 10,
									width: 200,
									justifyContent: "center",
									alignItems: "center",
								}}>
								<Text
									style={{
										color: "#FFF",
										fontFamily: "Avenir",
										fontWeight: "600",
									}}>
									Download QR code
								</Text>
							</View>
						</TouchableOpacity>
					</View>
					<View
						style={{
							width: "100%",
							padding: 10,
							// position: "absolute",
							// bottom: 10,
							// left: 0,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							// paddingRight: 30,
							// paddingLeft: 30,
						}}>
						<Text
							style={{
								width: 110,
								fontSize: 16,
								color: "#FFF",
							}}>
							<Font family='Poppins' weight={800}>
								tetrice
							</Font>
						</Text>
						<View
							style={{
								flexDirection: "row",
								alignItems: "center",
								justifyContent: "center",
							}}>
							<Image
								source={require("../../../assets/images/google_play.png")}
								style={{
									width: 100,
									height: 25,
									resizeMode: "contain",
									marginRight: 10,
								}}
							/>

							<Image
								source={require("../../../assets/images/app_store.png")}
								style={{
									width: 100,
									height: 25,
									resizeMode: "contain",
								}}
							/>
						</View>
						<Text
							style={{
								width: 110,
								fontSize: 13,
								color: "#FFF",
								textAlign: "right",
							}}>
							<Font family='Poppins' weight={300}>
								info@traq.app
							</Font>
						</Text>
					</View>
				</View>
			</View>
		);
	};

	addNewDevice = () => {
		return (
			<AddDevice
				screenHeight={this.props.screenHeight}
				screenWidth={this.props.screenWidth}
				addDevice={(d) => this.addDevice(d)}
				close={() =>
					this.setState({
						addNewDevice: false,
					})
				}
			/>
		);
	};
}
