import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	TextInput,
	Image,
	ActivityIndicator,
	Modal,
} from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import { api_getStaff } from "../../Api";

export default class StaffSearchBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterByName: "",
			selectedStaff: "",
			staff: [],
			loading: true,
			showAddItemsForm: false,
			accessToken: "",
		};
	}

	selectEntity = (d) => {
		this.props.selectedEntity(d);
		this.setState({
			selectedEntity: d.title,
			selectedEntityID: d.id,
			showAddItemsForm: false,
		});
	};

	async componentDidMount() {
		await this.getStaffs();
	}

	getStaffs = async () => {
		let url = api_getStaff;
		let accessToken = await sessionStorage.getItem("accessToken");

		await this.setState({
			loading: true,
			staff: [],
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log("staff", responseData);
							await this.setState({
								staff: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		return (
			<View
				style={{
					marginBottom: 10,
				}}>
				<View
					onLayout={(e) => {
						// console.log(e.nativeEvent);
						this.setState({
							left: e.nativeEvent.layout.left,
							top: e.nativeEvent.layout.top,
							width: e.nativeEvent.layout.width,
						});
					}}
					style={{
						width: "100%",
						padding: 8,
						fontSize: 16,
						borderRadius: 9,
						borderWidth: 0.3,
						borderColor: APP_COLOURS.BG3,
						backgroundColor: APP_COLOURS.BG3,
						paddingLeft: 15,
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						// justifyContent: "center",
						zIndex: 10,
					}}>
					<TouchableOpacity
						style={{
							// alignItems: "center",
							// justifyContent: "center",
							flex: 1,
						}}
						onPress={() => {
							this.setState({
								showAddItemsForm: true,
								filterByName: "",
							});
						}}>
						<Text
							style={{
								color:
									this.state.selectedStaff === ""
										? "#797979"
										: APP_COLOURS.WHITE,
								fontWeight: "200",
								fontFamily: "Avenir",
								fontSize: 16,
							}}>
							{this.state.selectedStaff === ""
								? `Select user`
								: `${this.state.selectedStaff.first_name} ${this.state.selectedStaff.last_name}`}
						</Text>
					</TouchableOpacity>
					{this.props.loading ? (
						<ActivityIndicator color={APP_COLOURS.BLUE} />
					) : (
						<Image
							source={require("./logo512.png")}
							style={{
								width: 20,
								height: 20,
								resizeMode: "contain",
							}}
						/>
					)}
				</View>

				<Modal
					transparent={true}
					visible={this.state.showAddItemsForm}
					animationType='fade'
					style={{
						width: this.props.screenWidth,
						height: this.props.screenHeight,
					}}>
					<TouchableOpacity
						activeOpacity={1}
						style={{
							width: this.props.screenWidth,
							height: this.props.screenHeight,
						}}
						onPress={() =>
							this.setState({
								showAddItemsForm: false,
							})
						}>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "rgba(52, 52, 52, 0.6)",
								width: this.props.screenWidth,
								height: this.props.screenHeight,
							}}></View>
					</TouchableOpacity>

					<View
						style={{
							width: this.state.width,
							maxHeight: 500,
							// height: 500,
							backgroundColor: APP_COLOURS.BG2,
							position: "absolute",
							top: this.state.top,
							left: this.state.left,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.25,
							shadowRadius: 7.84,
							elevation: 12,
							borderRadius: 10,
							// padding: 10,
							zIndex: 999,
						}}>
						<View
							ref={(ref) => {
								this.searchBox = ref;
							}}
							onLayout={(e) => {
								// console.log(e.nativeEvent);
								this.setState({
									searchBox: e.nativeEvent.layout.width,
								});
							}}
							style={{
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 12,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
									padding: 8,
									paddingLeft: 15,
									borderWidth: 0.3,
									borderColor: APP_COLOURS.BG3,
									borderRadius: 9,
									backgroundColor: APP_COLOURS.BG3,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 7.84,
									elevation: 12,
								}}>
								<TextInput
									ref={(ref) => {
										this.textInput = ref;
									}}
									style={{
										// padding: 8,
										fontSize: 16,
										borderRadius: 9,
										color: APP_COLOURS.WHITE,
										fontWeight: "200",
										fontFamily: "Avenir",
									}}
									autoFocus
									placeholderTextColor={"#797979"}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									editable={true}
									placeholder={"Select user"}
									value={this.state.filterByName}
									onChangeText={(text) => {
										this.setState({
											filterByName: text,
										});
									}}
								/>
								<TouchableOpacity
									onPress={() => {
										this.setState({
											filterByName: "",
										});
										this.textInput.focus();
									}}>
									<Image
										source={require("./close.png")}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										this.setState((prevState) => ({
											showAddItemsForm: false,
										}));
									}}>
									{this.props.loading ? (
										<ActivityIndicator color={APP_COLOURS.BLUE} />
									) : (
										<Image
											source={require("./logo512.png")}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
											}}
										/>
									)}
								</TouchableOpacity>
							</View>
							<View
								style={{
									maxHeight: 350,
								}}>
								<ScrollView>
									{this.state.staff
										.filter((d_) => {
											let filter = d_;
											if (
												typeof this.state.filterByName !==
													"undefined" &&
												this.state.filterByName !== ""
											) {
												let text_ = this.state.filterByName;
												var outString = text_.replace(
													/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
													"",
												);

												let join_names = `${d_.first_name} ${d_.last_name}`;

												return join_names
													.toLowerCase()
													.includes(outString.toLowerCase());
											}

											return filter;
										})
										.sort((a, b) => b.title < a.title)
										.map((d, i) => (
											<TouchableOpacity
												key={i}
												onPress={() => {
													this.setState(() => ({
														selectedStaff: d,
														showAddItemsForm: false,
														filterByName: d.title,
													}));

													this.props.selectedData(d);
												}}>
												<View
													style={{
														padding: 8,
														borderBottomColor: "#797979",
														marginBottom: 2,
														// flexDirection: "row",
													}}>
													<Text
														style={{
															color: "#FFF",
															fontSize: 15,
														}}>
														{d.first_name} {d.last_name}
													</Text>
												</View>
											</TouchableOpacity>
										))}
								</ScrollView>
							</View>
						</View>
					</View>
				</Modal>
			</View>
		);
	}
}
