import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	TextInput,
	Image,
	ActivityIndicator,
	Modal,
} from "react-native";
import Font from "react-font";
import { APP_COLOURS, screenWidth, screenHeight } from "../../../APP_VARS";
import { api_getMall } from "../../../Api";

export default class MallsSearchBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterByName: "",
			selectedMall: {
				id: "",
				title: "",
			},
			malls: [],
			loading: false,
			showAddItemsForm: false,
			accessToken: "",
			width: 100,
		};
	}

	selectEntity = (d) => {
		this.props.selectedEntity(d);
		this.setState({
			selectedEntity: d.title,
			selectedEntityID: d.id,
			showAddItemsForm: false,
		});
	};

	async componentDidMount() {
		// await this.getMalls();
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.selected !== this.props.selected &&
			this.props.selected === ""
		) {
			this.setState({
				filterByName: "",
				selectedMall: {
					id: "",
					title: "",
				},
			});
		}
	}

	getMalls = async () => {
		let url = api_getMall;
		let accessToken = await sessionStorage.getItem("accessToken");

		await this.setState({
			loading: true,
			// malls: [],
		});

		let requestOptions = {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${accessToken}`,
			},
			redirect: "follow",
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log("malls", responseData);
							await this.setState({
								malls: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};

	render() {
		return (
			<View
				style={{
					marginBottom: 10,
					width: "100%",
				}}>
				{!this.state.showAddItemsForm ? (
					<View
						onLayout={(e) => {
							// console.log(e.nativeEvent);
							this.setState({
								left: e.nativeEvent.layout.left,
								top: e.nativeEvent.layout.top,
								width: e.nativeEvent.layout.width,
							});
						}}
						style={{
							width: "100%",
							padding: 16,
							fontSize: 16,
							borderRadius: 9,
							borderWidth: 0.3,
							borderColor: APP_COLOURS.BG3,
							backgroundColor: APP_COLOURS.BG3,
							paddingLeft: 15,
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							// justifyContent: "center",
							zIndex: 10,
						}}>
						<TouchableOpacity
							style={{
								// alignItems: "center",
								// justifyContent: "center",
								flex: 1,
								minWidth: 200,
							}}
							onPress={async (e) => {
								e.preventDefault();

								await this.setState({
									showAddItemsForm: true,
									filterByName: "",
									top: e.nativeEvent.y - e.nativeEvent.layerY - 17,
								});
								// if (this.state.malls.length === 0) {
								await this.getMalls();
								// }
							}}>
							<Text
								style={{
									color:
										this.state.selectedMall.title === ""
											? "#797979"
											: APP_COLOURS.WHITE,
									fontWeight: "200",
									fontFamily: "Avenir",
									fontSize: 16,
								}}>
								<Font weight={500} family='Montserrat'>
									{this.state.selectedMall.title === ""
										? `Select mall`
										: this.state.selectedMall.title}
								</Font>
							</Text>
						</TouchableOpacity>
						<View
							style={
								{
									// padding: 20,
								}
							}>
							{this.state.loading ? (
								<ActivityIndicator color={APP_COLOURS.WHITE} />
							) : (
								<Image
									source={require("./logo512.png")}
									style={{
										width: 20,
										height: 20,
										resizeMode: "contain",
									}}
								/>
							)}
						</View>
					</View>
				) : (
					<View
						style={{
							// width: screenWidth,
							// height: screenHeight,
							// backgroundColor: "rgba(0, 0, 0, 0.6)",
							// display: this.state.showAddItemsForm ? "flex" : "none",
							width: "100%",
							height: "auto",
						}}>
						<View
							style={{
								width: this.state.width,
								maxHeight: 500,
								// height: 500,
								backgroundColor: APP_COLOURS.BG2,
								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 2,
								},
								shadowOpacity: 0.25,
								shadowRadius: 7.84,
								elevation: 12,
								borderRadius: 10,
								// padding: 10,
								zIndex: 999,
							}}>
							<View
								ref={(ref) => {
									this.searchBox = ref;
								}}
								onLayout={(e) => {
									// console.log(e.nativeEvent);
									this.setState({
										searchBox: e.nativeEvent.layout.width,
									});
								}}
								style={{
									backgroundColor: APP_COLOURS.BG2,
									borderRadius: 12,
								}}>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "space-between",
										padding: 16,
										paddingLeft: 15,
										borderWidth: 0.3,
										borderColor: APP_COLOURS.BG3,
										borderRadius: 9,
										backgroundColor: APP_COLOURS.BG3,
										shadowColor: "#000",
										shadowOffset: {
											width: 0,
											height: 2,
										},
										shadowOpacity: 0.25,
										shadowRadius: 7.84,
										elevation: 12,
									}}>
									<TextInput
										ref={(ref) => {
											this.textInput = ref;
										}}
										style={{
											// padding: 8,
											fontSize: 16,
											borderRadius: 9,
											color: APP_COLOURS.WHITE,
											fontWeight: "200",
											fontFamily: "Avenir",
										}}
										autoFocus
										placeholderTextColor={"#797979"}
										autoComplete='none'
										autoCapitalize='words'
										clearButtonMode='while-editing'
										editable={true}
										placeholder={"Type something"}
										value={this.state.filterByName}
										onChangeText={(text) => {
											this.setState({
												filterByName: text,
											});
										}}
									/>
									<TouchableOpacity
										onPress={() => {
											this.setState({
												filterByName: "",
											});
											this.textInput.focus();
										}}>
										<Image
											source={require("./close.png")}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
											}}
										/>
									</TouchableOpacity>
									<TouchableOpacity
										onPress={() => {
											this.setState((prevState) => ({
												showAddItemsForm: false,
											}));
										}}>
										{this.state.loading ? (
											<ActivityIndicator color={APP_COLOURS.BLUE} />
										) : (
											<Image
												source={require("./logo512.png")}
												style={{
													width: 20,
													height: 20,
													resizeMode: "contain",
												}}
											/>
										)}
									</TouchableOpacity>
								</View>
								<View
									style={{
										maxHeight: 350,
									}}>
									<ScrollView>
										{this.state.malls
											.filter((d_) => {
												let filter = d_;
												if (
													typeof this.state.filterByName !==
														"undefined" &&
													this.state.filterByName !== ""
												) {
													let text_ = this.state.filterByName;
													var outString = text_.replace(
														/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
														"",
													);

													let join_names = `${d_.title}`;

													return join_names
														.toLowerCase()
														.includes(outString.toLowerCase());
												}

												return filter;
											})
											.sort((a, b) => b.title < a.title)
											.map((d, i) => (
												<TouchableOpacity
													key={i}
													onPress={() => {
														this.setState(() => ({
															selectedMall: d,
															showAddItemsForm: false,
															filterByName: d.title,
														}));
														let obj = {
															title: d.title,
															id: d.id,
														};
														this.props.selectedData(obj);
													}}>
													<View
														style={{
															padding: 8,
															borderBottomColor: "#797979",
															marginBottom: 2,
															// flexDirection: "row",
														}}>
														<Text
															style={{
																color: "#FFF",
																fontSize: 15,
															}}>
															{d.title}
														</Text>
													</View>
												</TouchableOpacity>
											))}
									</ScrollView>
								</View>
							</View>
						</View>
					</View>
				)}
			</View>
		);
	}
}
