import React, { Component } from "react";
import { View, Text, TextInput } from "react-native";

export default class InputArea extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					height: "auto",
					width: "100%",
					marginBottom: 10,
					marginTop: 10,
					backgroundColor: "rgba(255, 255, 255, 0.01)",
					borderRadius: 9,
				}}>
				<View
					style={{
						width: "100%",
						position: "absolute",
						borderWidth: 0.3,
						borderColor: this.props.color,
						opacity: 0.3,
						borderRadius: 9,
						height: 46,
					}}
				/>
				<TextInput
					style={{
						width: "100%",
						padding: 13,
						fontSize: 16,
						borderRadius: 9,
						color: this.props.color,
						fontWeight: this.state.taskName !== "" ? "700" : "300",
						fontFamily: "Avenir",

						// backgroundColor: this.props.bgColor,
						opacity: 0.99,
					}}
					placeholderTextColor={"#797979"}
					multiline
					// autoFocus
					autoCapitalize='sentences'
					clearButtonMode='while-editing'
					autoCompleteType='off'
					editable={this.props.editable}
					autoCorrect={false}
					keyboardType='default'
					textContentType='none'
					// placeholder={` Task title `}
					value={this.props.value}
					// onChangeText={(text) => {
					// 	let t = capitalizeName(text);
					// 	this.setState({
					// 		taskName: t,
					// 	});
					// }}
				/>
				<View
					style={{
						borderRadius: 8,
						position: "absolute",
						top: -13,
						left: 5,
						backgroundColor: this.props.bgColor,
						// backgroundColor: "red",
						margin: 5,
					}}>
					<Text
						allowFontScaling={false}
						style={{
							fontSize: 11,
							fontFamily: "Avenir Next",
							// margin: 5,
							paddingLeft: 10,
							paddingRight: 10,
							fontWeight: "400",

							backgroundColor: "rgba(255, 255, 255, 0.01)",
							opacity: 0.99,

							color: this.props.color,
						}}>
						{this.props.title}
					</Text>
				</View>
			</View>
		);
	}
}
