import React, { Component } from "react";
import { View, Text, ScrollView, TouchableOpacity } from "react-native";
import { APP_COLOURS } from "../../APP_VARS";
import TabTabs from "../BasicTabs";
import MallsSearchBar from "../CrudMalls/MallsSearchBar/MallsSearchBar";
import RetailerSearchBar from "../CrudRetailer/RetailerSearchBar";
import CustomerConversions from "./CustomerConversions";
import CustomersByMall from "./CustomersByMall";
import CustomersByMallRetailer from "./CustomersByMallRetailer";
import CustomersByRetailer from "./CustomersByRetailer";
import CustomerVisits from "./CustomerVisits";
import Results from "./results";
import TotalCustomers from "./TotalCustomers";
import TotalInteractions from "./TotalInteractions";
import NewCustomers from "./NewCustomers";

export default class Overview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: "",

			retailersFilter: "",
			mallsFilter: "",
			retailer: "",
			mall: "",

			filterCategory: "",
		};
	}

	render() {
		let style = {
			backgroundColor: APP_COLOURS.BG3,
			borderRadius: 11,
			padding: 8,
			marginRight: 5,
			marginBottom: 5,
			shadowColor: "#000",
			shadowOffset: { width: 0, height: 8 },
			shadowOpacity: 0.44,
			shadowRadius: 9.32,
			elevation: 5,
			opacity: 0.9,
		};
		return (
			<View
				style={{
					padding: 8,
					width: "100%",
					height: this.props.screenHeight - 230,
					marginTop: 15,
				}}>
				<Text
					style={{
						fontSize: 19,
						fontWeight: "800",
						color: "#FFF",
						marginRight: 60,
					}}>
					{this.props.selectedBrandTab.title} customers
				</Text>
				<ScrollView showsVerticalScrollIndicator={false}>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
						}}>
						<ScrollView showsHorizontalScrollIndicator={false} horizontal>
							<TotalCustomers />

							<View
								style={{
									width: 100,
								}}
							/>
						</ScrollView>
					</View>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
						}}>
						<ScrollView showsHorizontalScrollIndicator={false} horizontal>
							<NewCustomers />

							<TotalInteractions />
							<View
								style={{
									width: 100,
								}}
							/>
						</ScrollView>
					</View>
					<View
						style={{
							width: "100%",
							flexDirection: "row",
						}}>
						<CustomersByMall />
						<CustomersByRetailer />
						<CustomersByMallRetailer />
					</View>
				</ScrollView>
			</View>
		);
	}
}
