import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import logo from "./logo.png";
import logo_tetrice from "./logo_tetrice.png";
import nomobile from "./nomobile.png";
import tick from "./tick_red.png";
import traq_logo from "./traqbifull.png";
// import traq_logo from "./traqbi.svg";
import bg2 from "./devices.svg";
import "./index.css";
import { api_login } from "../Api";
import * as animationData from "./lf30_editor_sdru9bf3.json";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			password: "",
			emailInput: "",
			emailValid: false,
			screenWidth: screenWidth,
			screenHeight: screenHeight,

			otpPinSent: false,
		};
	}

	validateEmail = (text) => {
		// console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if (reg.test(text) === false) {
			// console.log("Email is Not Correct");
			this.setState({ emailValid: false });
			return false;
		} else {
			this.setState({ emailValid: true });
			// console.log("Email is Correct");
			return true;
		}
	};

	loginButton = async () => {
		this.setState({
			loading: true,
			otpPinSent: true,
			errorLogin: false,
		});
		await sessionStorage.setItem("email", this.state.emailInput);
		// await sessionStorage.setItem('password', this.state.password);
	};

	loginButton2 = async () => {
		this.setState({
			loading: true,
			// otpPinSent: true,
			errorLogin: false,
		});

		await sessionStorage.setItem("email", this.state.emailInput);
		// await sessionStorage.setItem('password', this.state.password);
		// this.props.history.push("/dashboard/");
		// setTimeout(() => this.checkRSVP(), 3000);
		// this.loginFunc();
		this.loginFuncWithOTP();
	};

	loginFuncWithOTP = async () => {
		let url = api_login;
		let password = this.state.password;
		let email = this.state.emailInput;

		var raw = JSON.stringify({
			passcode: password,
			username: email,
		});

		var requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,
		};

		console.log(url, requestOptions);

		await fetch(url, requestOptions)
			.then((response) => {
				// console.log("response.status", response.status);
				console.log("response =====", response);
				if (response.status == 200) {
					response
						.json()
						.then(async (responseData) => {
							// await console.log(
							// 	"=============================",
							// 	responseData,
							// );
							let user_data = await sessionStorage.setItem(
								"user_data",
								JSON.stringify(responseData),
							);
							let accessToken = await sessionStorage.setItem(
								"accessToken",
								responseData.accessToken,
							);

							await this.props.navigate("/Home");
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
								password: "",
								emailInput: "",
								emailValid: false,
								otpPinSent: false,
							});
						});
				} else {
					// alert("User has not been found");
					this.setState({
						errorLogin: true,
						loading: false,
						password: "",
						emailInput: "",
						emailValid: false,
						otpPinSent: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					errorLogin: true,
					loading: false,
					password: "",
					emailInput: "",
					emailValid: false,
					otpPinSent: false,
				});
			});
	};

	componentDidMount() {
		console.log("componentDidMount");
		let accessToken = sessionStorage.getItem("accessToken");

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			sessionStorage.clear();
			this.setState({
				loading: false,
			});
		}

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let contain =
			this.state.screenWidth * 0.9 > this.state.screenHeight * 0.9
				? this.state.screenHeight * 0.89
				: this.state.screenWidth * 0.9;

		let maxContain =
			contain > this.state.screenWidth * 0.33
				? this.state.screenWidth * 0.33
				: contain;

		let ar = 636 / 545;

		if (this.state.screenWidth < 1000) {
			return (
				<div
					style={{
						// backgroundColor: "#131119",

						flex: 1,
					}}>
					<View
						style={{
							flex: 1,
							alignItems: "center",
							// marginTop:
							height: this.state.screenHeight,
							padding: 10,
							justifyContent: "center",
						}}>
						<View
							style={{
								width: "100%",
								maxWidth: 400,
								alignItems: "center",
								padding: 10,
								borderRadius: 15,
								backgroundColor: "#121419",

								shadowColor: "#000",
								shadowOffset: {
									width: 0,
									height: 8,
								},
								shadowOpacity: 0.44,
								shadowRadius: 10.32,

								elevation: 16,
							}}>
							<Image
								source={logo}
								style={{
									width: 180,
									height: 100,
									resizeMode: "contain",
								}}
							/>

							<View style={{}}>
								<Image
									source={nomobile}
									style={{
										width: 80,
										height: 80,
										resizeMode: "contain",
										marginTop: 15,
										marginBottom: 15,
									}}
								/>
							</View>

							<View
								style={{
									width: 300,
									marginTop: 10,
									marginBottom: 50,
									opacity: 0.7,
								}}>
								<Text
									style={{
										color: "#FFF",
										marginBottom: 15,
										textAlign: "center",
									}}>
									TRAQ Auditing dashboard is not avaliable for
									smaller mobile devices.
								</Text>
								<Text
									style={{
										color: "#FFF",
										marginBottom: 15,
										textAlign: "center",
									}}>
									Try using a tablet or desktop device.
								</Text>
							</View>
						</View>
					</View>
				</div>
			);
		}

		return (
			<div
				style={{
					flex: 1,
					width: this.state.screenWidth,
					height: this.state.screenHeight,
					// backgroundColor: "#1F2229",
					backgroundColor: "#FFF",
					overflow: "hidden",
				}}>
				<View
					style={{
						width: this.state.screenWidth,
						height: this.state.screenHeight,
						alignItems: "center",
						justifyContent: "center",
					}}>
					{/* <View
						style={{
							width: this.state.screenWidth,
							height: this.state.screenHeight,
							right: 0,
							bottom: 0,
							position: "absolute",
						}}>
						<Image
							source={bg2}
							style={{
								width: maxContain * ar,
								height: maxContain,
								resizeMode: "contain",
								position: "absolute",
								left: -10,
								bottom: -10,
							}}
						/>
					</View> */}

					{/* <View
						style={{
							position: "absolute",
							bottom: "10%",
							left: "1%",
						}}>
						<Lottie
							options={defaultOptions}
							height={contain > 600 ? 600 : contain}
							width={contain > 600 ? 600 : contain}
						/>
					</View> */}

					<View
						style={{
							flex: 1,
							flexDirection: "row",
							width: this.state.screenWidth,
							height: this.state.screenHeight,

							alignItems: "center",
							justifyContent: "center",
						}}>
						{/* <View
							style={{
								flex: 1,
							}}>
							<View
								style={{
									position: "absolute",
									top: "5%",
									left: "2%",
								}}>
								<Image
									source={traq_logo}
									style={{
										width: 200,
										height: 100,
										resizeMode: "contain",
									}}
								/>
							</View>
						</View> */}

						<View
							style={{
								flex: 1,
								alignItems: "center",
								justifyContent: "space-between",
								flexDirection: "row",

								// maxWidth: this.state.screenWidth * 0.7,
								paddingBottom: "10%",
							}}>
							<View
								style={{
									flex: 1,
									alignItems: "center",
									justifyContent: "center",
								}}>
								<View
									style={{
										width: 330,
										height: "auto",
										// top: 80,
										// marginTop: "10%",
										alignItems: "center",
										padding: 20,
										borderRadius: 25,
										backgroundColor: "#121419",
										// shadowColor: "#000",
										// shadowOffset: {
										// 	width: 0,
										// 	height: 8,
										// },
										// shadowOpacity: 0.44,
										// shadowRadius: 10.32,
										// elevation: 16,
									}}>
									<Image
										source={traq_logo}
										style={{
											width: 120,
											height: 100,
											resizeMode: "contain",
											margin: 5,
										}}
									/>

									{this.state.errorLogin ? (
										<View
											style={{
												width: 300,
												marginTop: 15,
												padding: 10,
											}}>
											<Text
												style={{
													color: "#FF5F5F",
													fontSize: 12,
												}}>
												We are unable to verify your
												profile, please check your email
												password and try again.
											</Text>
										</View>
									) : null}

									<View
										style={{
											flex: 1,
											width: "100%",
											alignItems: "center",
											// justifyContent: 'center',
										}}>
										<Fade>
											<div
												style={{
													height: "100%",
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<View
													style={{
														width: "100%",
														padding: 10,
														marginRight: "auto",
														marginLeft: "auto",
														justifyContent:
															"center",
														alignItems: "center",
														maxWidth: 350,
														marginTop: 15,
														borderWidth: 0.3,
														borderColor: "#1F2229",
														backgroundColor:
															"#1F2229",
														borderRadius: 12,
														flexDirection: "row",
													}}>
													{this.state.emailValid ? (
														<Image
															source={tick}
															style={{
																width: 20,
																height: 20,
																resizeMode:
																	"contain",
																marginRight: 10,
															}}
														/>
													) : null}
													<TextInput
														style={{
															width: "100%",
															fontSize: 16,
															textAlign: "left",
															color: "#FBFBFC",
															fontWeight: "200",
															fontFamily:
																"Avenir",
															letterSpacing: 0.26,
														}}
														placeholderTextColor={
															"#797979"
														}
														autoCapitalize='none'
														clearButtonMode='while-editing'
														autoCompleteType='username'
														autoCorrect={false}
														autoFocus={true}
														keyboardType='email-address'
														textContentType='emailAddress'
														placeholder={`Email address`}
														value={
															this.state
																.emailInput
														}
														onChangeText={(
															text,
														) => {
															let validated =
																this.validateEmail(
																	text,
																);
															this.setState({
																emailInput:
																	text,
															});
														}}
													/>
												</View>
											</div>
										</Fade>

										<Fade>
											<div
												style={{
													height: "100%",
													width: "100%",
													alignItems: "center",
													justifyContent: "center",
												}}>
												<View
													style={{
														width: "100%",
														padding: 10,
														marginRight: "auto",
														marginLeft: "auto",
														justifyContent:
															"center",
														alignItems: "center",
														maxWidth: 350,
														marginTop: 15,
														borderWidth: 0.3,
														borderColor: "#1F2229",
														backgroundColor:
															"#1F2229",
														borderRadius: 12,
														flexDirection: "row",
													}}>
													{this.state.password
														.length ? (
														<Image
															source={tick}
															style={{
																width: 20,
																height: 20,
																resizeMode:
																	"contain",
																marginRight: 10,
															}}
														/>
													) : null}
													<TextInput
														style={{
															width: "100%",
															fontSize: 16,
															textAlign: "left",
															color: "#FBFBFC",
															fontWeight: "200",
															fontFamily:
																"Avenir",
															letterSpacing: 0.26,
														}}
														placeholderTextColor={
															"#797979"
														}
														autoCapitalize='none'
														clearButtonMode='while-editing'
														autoCompleteType='password'
														secureTextEntry={true}
														textContentType='password'
														autoCorrect={false}
														placeholder={`Password`}
														value={
															this.state.password
														}
														// defaultValue={
														// 	this.state.password
														// }
														onChangeText={(
															text,
														) => {
															this.setState({
																password: text,
															});
														}}
														onSubmitEditing={() =>
															this.loginButton()
														}
													/>
												</View>
											</div>
										</Fade>

										<View
											style={{
												height: "100%",
												width: "100%",
												// alignItems: "center",
												// justifyContent: "center",
												marginTop: 15,
											}}>
											{!this.state.loading ? (
												<TouchableOpacity
													style={{
														width: "100%",
													}}
													disabled={
														this.state.loading
													}
													onPress={() =>
														this.loginButton2()
													}>
													<View
														style={{
															backgroundColor:
																this.state
																	.otpPinSent
																	? "#0F9891"
																	: "#0F9891",
															width: "100%",
															padding: 10,
															justifyContent:
																"center",
															alignItems:
																"center",
															borderRadius: 15,
														}}>
														{this.state.loading ? (
															<ActivityIndicator />
														) : (
															<Text
																style={{
																	color: "#FFF",
																	fontWeight:
																		"600",
																}}>
																Login
															</Text>
														)}
													</View>
												</TouchableOpacity>
											) : (
												<View
													style={{
														backgroundColor:
															"#0F9891",
														padding: 6,
														justifyContent:
															"center",
														alignItems: "center",
														borderRadius: 15,
														width: 30,
														height: 30,
														marginLeft: "auto",
														marginRight: "auto",
													}}>
													<ActivityIndicator color='#FFF' />
												</View>
											)}
										</View>
									</View>
								</View>
							</View>
							<View
								style={{
									flex: 1,
									alignItems: "center",
									justifyContent: "center",
								}}>
								<Image
									source={bg2}
									style={{
										width: maxContain * ar,
										height: this.state.screenHeight * 0.65,
										resizeMode: "contain",
									}}
								/>
							</View>
							{/* <View
								style={{
									paddingLeft: 30,
								}}>
								<Text
									style={{
										fontSize: 26,
										color: "#FFF",
									}}>
									<Font family='Poppins' weight={400}>
										Surveys,
									</Font>
								</Text>
								<Text
									style={{
										fontSize: 26,
										color: "#FFF",
									}}>
									<Font family='Poppins' weight={400}>
									Rese
									</Font>
								</Text>
							</View> */}
						</View>
					</View>
				</View>
				<View
					style={{
						width: this.state.screenWidth,
						position: "absolute",
						bottom: 30,
						left: 0,
						flexDirection: "row",
						justifyContent: "space-between",
						paddingRight: 30,
						paddingLeft: 30,
					}}>
					<Text
						style={{ width: 110, fontSize: 16, color: "#121419" }}>
						<Font family='Poppins' weight={800}>
							tetrice
						</Font>
					</Text>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							justifyContent: "center",
						}}>
						{/* <Image
							source={require("./google_play.png")}
							style={{
								width: 130,
								height: 32,
								resizeMode: "contain",
								marginRight: 10,
							}}
						/>

						<Image
							source={require("./app_store.png")}
							style={{
								width: 130,
								height: 32,
								resizeMode: "contain",
							}}
						/> */}
					</View>
					<Text
						style={{
							width: 110,
							fontSize: 13,
							color: "#FFF",
						}}>
						<Font family='Poppins' weight={300}>
							info@traq.app
						</Font>
					</Text>
				</View>
			</div>
		);
	}
}

export const withNavigation = (Component: Component) => {
	return (props) => <Component {...props} navigate={useNavigate()} />;
};

export default withNavigation(index);
