import React, { Component } from "react";
import {
	View,
	Text,
	Image,
	TouchableOpacity,
	ScrollView,
	Modal,
	Dimensions,
	ActivityIndicator,
} from "react-native";
import Font from "react-font";
import tetriceLogo from "../../../assets/images/traq_app_logo.svg";

const screenWidth = Dimensions.get("window").width;
const screenHeight = Dimensions.get("window").height;

class DevicePreviewer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bookContent: [],
			loading: true,
			selectedLine: this.props.selectedLine,
			fontSize: this.props.fontSize,
		};
	}

	componentDidMount() {
		this.setState({
			bookContent: this.props.bookContent,
		});
		setTimeout(() => {
			this.setState({
				loading: false,
			});
		}, 2000);
	}

	render() {
		return (
			<View
				style={
					{
						// width: "100%",
						// padding: 20,
						// height: "100%",
					}
				}>
				<View
					style={{
						flex: 1,
						marginLeft: 10,
						alignItems: "center",
						padding: 5,
						zIndex: 99,
					}}>
					<View
						style={{
							borderRadius: 30,
							backgroundColor: "#000",
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 8,
							},
							shadowOpacity: 0.46,
							shadowRadius: 11.14,

							elevation: 17,
							// height: "100%",
							height: 650,
							width: 320,
							paddingTop: 30,
							paddingBottom: 30,
							paddingLeft: 5,
							paddingRight: 5,
						}}>
						<Text
							style={{
								position: "absolute",
								textAlign: "center",
								top: 7,
								color: "#FFF",
								// left: 137,
								width: "100%",
								zIndex: 99,
								fontSize: 9,
							}}>
							Preview
						</Text>
						<View
							style={{
								width: "100%",
								textAlign: "center",
								position: "absolute",
								bottom: 5,

								zIndex: 99,
							}}>
							<Text
								style={{
									fontSize: 12,
									color: "#FFF",
								}}>
								<Font
									family='Poppins'
									// style={{ fontSize: 4 }}
									weight={900}>
									tetrice
								</Font>
							</Text>
						</View>

						{this.state.loading ? (
							<View
								style={{
									flex: 1,
									backgroundColor: "#222B45",
									justifyContent: "center",
									alignItems: "center",
									borderRadius: 15,
								}}>
								<View
									style={{
										flex: 1,
										justifyContent: "center",
										alignItems: "center",
									}}>
									<Image
										source={tetriceLogo}
										style={{
											width: 125,
											height: 100,
											resizeMode: "contain",
										}}
									/>
									<ActivityIndicator color='#FFF' />
								</View>
								<View
									style={{
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "center",
										paddingBottom: 15,
									}}>
									<Image
										source={require("../../../assets/images/google_play.png")}
										style={{
											width: 100,
											height: 25,
											resizeMode: "contain",
											marginRight: 10,
										}}
									/>

									<Image
										source={require("../../../assets/images/app_store.png")}
										style={{
											width: 100,
											height: 25,
											resizeMode: "contain",
										}}
									/>
								</View>
							</View>
						) : null}

						<View
							style={{
								backgroundColor:
									this.props.app_properties.backgroundcolour,

								overflow: "hidden",
								flex: 1,
								// padding: 15,
								display: this.state.loading ? "none" : "flex",
								borderRadius: 15,
							}}>
							<View
								style={{
									width: "100%",
									padding: 10,
									backgroundColor:
										this.props.app_properties.brandcolour,
									marginRight: 15,
									// shadowColor: this.props.app_properties.brandcolour,
									// shadowOffset: {
									// 	width: 0,
									// 	height: 8,
									// },
									// shadowOpacity: 0.16,
									// shadowRadius: 11.14,

									flexDirection: "row",
								}}>
								<Image
									source={`data:image/png;base64,${this.props.app_properties.logo}`}
									style={{
										height: 60,
										width: 90,
										resizeMode: "contain",
									}}
								/>

								<View
									style={{
										flex: 1,
										// marginTop: 20,
										textAlign: "left",
										justifyContent: "center",
										alignItems: "flex-end",
										marginRight: 13,
									}}>
									<Text
										style={{
											fontSize: 12,
											color: this.props.app_properties.fontcolour,
											fontWeight: "200",
										}}>
										Retailer
									</Text>
									<Text
										style={{
											fontSize: 12,
											color: this.props.app_properties.fontcolour,
											fontWeight: "600",
										}}>
										Mall
									</Text>
									<Text
										style={{
											color: this.props.app_properties.fontcolour,
											fontSize: 9,
											marginTop: 3,
											fontWeight: "200",
										}}>
										Online
									</Text>
								</View>
							</View>

							<View
								style={{
									padding: 20,
								}}>
								<Text
									style={{
										fontSize: 12,
										color: this.props.app_properties.mainFontColor,
										fontWeight: "500",
										marginBottom: 15,
									}}>
									Main font colour
								</Text>
								<View
									style={{
										// flex: 1,
										// marginTop: 20,
										width: "100%",
										textAlign: "left",
										justifyContent: "center",
										// alignItems: "flex-end",
										marginRight: 13,
										backgroundColor:
											this.props.app_properties.cardColor,
										borderRadius: 12,
										padding: 20,
										shadowColor:
											this.props.app_properties.shadowColor,
										shadowOffset: {
											width: 0,
											height: 8,
										},
										shadowOpacity: 0.06,
										shadowRadius: 7.14,
										elevation: 10,
									}}>
									<Text
										style={{
											fontSize: 18,
											color: this.props.app_properties.cardFont,
											fontWeight: "800",
											marginTop: 3,
										}}>
										Headdings
									</Text>
									<Text
										style={{
											fontSize: 15,
											color: this.props.app_properties.cardFont,
											fontWeight: "500",
											marginTop: 3,
										}}>
										sub headdings
									</Text>

									<Text
										style={{
											color: this.props.app_properties.cardFont,
											fontSize: 10,
											marginTop: 3,
											fontWeight: "200",
										}}>
										paragraphs
									</Text>
								</View>
							</View>
						</View>
					</View>
				</View>
			</View>
		);
	}
}

export default DevicePreviewer;
