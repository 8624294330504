import React, { Component } from "react";
import {
	View,
	Text,
	ScrollView,
	TouchableOpacity,
	TextInput,
	Modal,
	Image,
	ActivityIndicator,
} from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";

function capitalize(str) {
	str = str.replace(/[`~!@#$%^&*()_|+\-=?;:'".<>\{\}\[\]\\\/]/gi, " ");
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export default class SearchBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterByName: "",
			selectedItem: "",
			data: [],
			showAddItemsForm: false,
		};
	}

	componentWillUnmount() {
		this.setState = (state, callback) => {
			return;
		};
	}

	render() {
		let data = this.props.data
			.filter((d_) => {
				let filter = d_;
				if (
					typeof this.state.filterByName !== "undefined" &&
					this.state.filterByName !== ""
				) {
					let text_ = this.state.filterByName;
					var outString = text_.replace(
						/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
						"",
					);

					let join_names = `${d_}`;

					return join_names
						.toLowerCase()
						.includes(outString.toLowerCase());
				}

				return filter;
			})
			.sort((a, b) => b.full_name < a.full_name);
		return (
			<View
				style={{
					marginRight: 10,
					minWidth: 190,
					marginBottom: 10,
				}}>
				<View
					onLayout={(e) => {
						// console.log(e.nativeEvent);
						this.setState({
							left: e.nativeEvent.layout.left,
							top: e.nativeEvent.layout.top,
						});
					}}
					style={{
						width: 250,
						padding: 8,
						fontSize: 16,
						borderRadius: 9,
						borderWidth: 0.3,
						borderColor: APP_COLOURS.BG3,
						backgroundColor: APP_COLOURS.BG3,
						paddingLeft: 15,
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						// justifyContent: "center",
					}}>
					<TouchableOpacity
						style={{
							// alignItems: "center",
							// justifyContent: "center",
							flex: 1,
						}}
						onPress={(e) =>
							this.setState({
								showAddItemsForm: true,
								// filterByName: "",
							})
						}>
						<Text
							style={{
								color:
									this.state.selectedItem === ""
										? "#797979"
										: APP_COLOURS.WHITE,
								fontWeight:
									this.state.selectedItem === "" ? "200" : "600",
								fontFamily: "Avenir",
								fontSize: 14,
							}}>
							{this.state.selectedItem === ""
								? capitalize(this.props.placeHolder)
								: capitalize(this.state.selectedItem)}
						</Text>
					</TouchableOpacity>
					<TouchableOpacity>
						{this.props.loading ? (
							<ActivityIndicator color={APP_COLOURS.BLUE} />
						) : (
							<Image
								source={require("./logo512.png")}
								style={{
									width: 20,
									height: 20,
									resizeMode: "contain",
								}}
							/>
						)}
					</TouchableOpacity>
				</View>

				<Modal
					transparent={true}
					visible={this.state.showAddItemsForm}
					animationType='fade'
					style={{
						width: this.props.screenWidth,
						height: this.props.screenHeight,
					}}>
					<TouchableOpacity
						activeOpacity={1}
						style={{
							width: this.props.screenWidth,
							height: this.props.screenHeight,
						}}
						onPress={() =>
							this.setState({
								showAddItemsForm: false,
							})
						}>
						<View
							style={{
								flex: 1,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "rgba(52, 52, 52, 0.6)",
								width: this.props.screenWidth,
								height: this.props.screenHeight,
							}}></View>
					</TouchableOpacity>

					<View
						style={{
							width: 250,
							maxHeight: 500,
							backgroundColor: APP_COLOURS.BG2,
							position: "absolute",
							top: this.state.top,
							left: this.state.left,
							shadowColor: "#000",
							shadowOffset: {
								width: 0,
								height: 2,
							},
							shadowOpacity: 0.25,
							shadowRadius: 7.84,
							elevation: 12,
							borderRadius: 10,
							// padding: 10,
							zIndex: 999,
						}}>
						<View
							ref={(ref) => {
								this.searchBox = ref;
							}}
							onLayout={(e) => {
								// console.log(e.nativeEvent);
								this.setState({
									searchBox: e.nativeEvent.layout.width,
								});
							}}
							style={{
								backgroundColor: APP_COLOURS.BG2,
								borderRadius: 12,
							}}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									justifyContent: "space-between",
									padding: 8,
									paddingLeft: 15,
									borderWidth: 0.3,
									borderColor: APP_COLOURS.BG3,
									borderRadius: 9,
									backgroundColor: APP_COLOURS.BG3,
									shadowColor: "#000",
									shadowOffset: {
										width: 0,
										height: 2,
									},
									shadowOpacity: 0.25,
									shadowRadius: 7.84,
									elevation: 12,
								}}>
								<TextInput
									ref={(ref) => {
										this.textInput = ref;
									}}
									style={{
										// padding: 8,
										fontSize: 16,
										borderRadius: 9,
										color: APP_COLOURS.WHITE,
										fontWeight: "200",
										fontFamily: "Avenir",
									}}
									autoFocus
									placeholderTextColor={"#797979"}
									autoCapitalize='words'
									clearButtonMode='while-editing'
									editable={true}
									placeholder={this.props.placeHolder}
									value={this.state.filterByName}
									onChangeText={(text) => {
										this.setState({
											filterByName: text,
										});
									}}
								/>
								<TouchableOpacity
									onPress={async () => {
										await this.props.selectedData("");
										await this.setState({
											filterByName: "",
											showAddItemsForm: false,
											selectedItem: "",
										});
										await this.textInput.focus();
									}}>
									<Image
										source={require("./close.png")}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</TouchableOpacity>
								<TouchableOpacity
									onPress={() => {
										this.setState((prevState) => ({
											showAddItemsForm: false,
										}));
									}}>
									{this.props.loading ? (
										<ActivityIndicator color={APP_COLOURS.BLUE} />
									) : (
										<Image
											source={require("./logo512.png")}
											style={{
												width: 20,
												height: 20,
												resizeMode: "contain",
											}}
										/>
									)}
								</TouchableOpacity>
							</View>
							<View
								style={{
									maxHeight: 350,
								}}>
								<ScrollView>
									{data.map((d, i) => (
										<TouchableOpacity
											onPress={() => {
												this.setState(() => ({
													selectedItem: d,
													showAddItemsForm: false,
												}));
												this.props.selectedData(d);
											}}>
											<View
												style={{
													padding: 8,
													borderBottomColor: "#797979",
													marginBottom: 2,
													// flexDirection: "row",
												}}>
												<Text
													style={{
														color: "#FFF",
														fontSize: 15,
													}}>
													{capitalize(d)}
												</Text>
											</View>
										</TouchableOpacity>
									))}
								</ScrollView>
							</View>
						</View>
					</View>
				</Modal>
			</View>
		);
	}
}
