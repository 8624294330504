import React, { Component } from "react";
import { View, Text, Image, TouchableOpacity, ScrollView } from "react-native";
import { APP_COLOURS } from "../../../APP_VARS";
import Fade from "react-reveal/Fade";
import moment from "moment";
import { api_getStaffAttendance } from "../../../Api";
import ExportToExcel from "../../ExportToExcel";
import location from "../../../../assets/images/location.png";

export default class BAAttendanceTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hover: "",
			data: [],
		};
	}

	componentDidMount() {
		this.getUserAttendance(this.props.user_id);
	}

	render() {
		return (
			<View
				style={{
					flex: 1,
				}}>
				<ScrollView>
					<View
						onMouseEnter={() =>
							this.setState({
								hover: "",
							})
						}
						style={{
							width: "100%",
							justifyContent: "space-between",
							flexDirection: "row",
							paddingBottom: 10,
							borderBottomColor: APP_COLOURS.TRANSHALO,
							borderBottomWidth: 0.5,
							alignItems: "flex-end",
							marginBottom: 10,
						}}>
						<View
							style={{
								position: "absolute",
								left: -12,
								top: -15,
							}}>
							<Text
								style={{
									fontSize: 13,
									color: APP_COLOURS.WHITE,
									fontWeight: "300",
									fontFamily: "Avenir",
								}}>
								STAFF
								<Text
									style={{
										fontWeight: "900",
									}}>
									TRAQ
								</Text>
							</Text>
						</View>

						<View
							style={{
								width: "100%",
								flexDirection: "row",
								justifyContent: "space-between",
								paddingRight: 20,
							}}>
							<Text
								style={{
									fontFamily: "Avenir",
									fontSize: 25,
									fontWeight: "100",
									color: APP_COLOURS.WHITE,
									marginBottom: 10,
								}}>
								Attendance
							</Text>
							{this.state.data.length > 0 ? (
								<ExportToExcel
									excel_doc_name={`attendance_${moment().format(
										"DD-MMM-YYYY",
									)}`}
									data={this.state.data}
								/>
							) : (
								<View
									style={{
										opacity: 0.4,
									}}>
									<ExportToExcel
										excel_doc_name={`attendance_${moment().format(
											"DD-MMM-YYYY",
										)}`}
										data={[]}
									/>
								</View>
							)}
						</View>
					</View>

					<View
						style={{
							height: 400,
						}}>
						<ScrollView>
							{this.state.data
								.sort(
									(a, b) =>
										moment(a.last_seen).unix() <
										moment(b.last_seen).unix(),
								)
								.map((d) => {
									return (
										<View
											onMouseEnter={() =>
												this.setState({
													hover: d.id,
												})
											}
											style={{
												flexDirection: "row",
												// marginBottom: 10,
												alignItems: "center",
												padding: 9,

												// borderRadius: 5,
												backgroundColor:
													this.state.hover === d.id
														? APP_COLOURS.TRANSHALO
														: "transparent",
												borderLeftWidth: 3,
												borderLeftColor:
													d.status === "Checkin"
														? APP_COLOURS.GREEN
														: d.status === "Shift ended"
														? APP_COLOURS.RED
														: "transparent",

												marginBottom:
													d.status === "Checkin" ? 50 : 0,
											}}>
											<View
												style={{
													width: 130,
													marginRight: 10,
												}}>
												<Text
													style={{
														fontSize: 13,
														color: "#FFF",
														fontWeight: "300",
													}}>
													{moment(d.last_seen).format(
														"MMM, DD YYYY",
													)}{" "}
													<Text
														style={{
															fontWeight: "600",
														}}>
														{moment(d.last_seen).format("HH:mm")}
													</Text>
												</Text>
											</View>
											<View
												style={{
													width: 110,
													marginRight: 10,
												}}>
												<Text
													style={{
														fontSize: 13,
														color: "#FFF",
													}}>
													{d.status}
												</Text>
											</View>
											<View
												style={{
													flex: 1,
												}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 13,
														fontWeight: "200",
														color: APP_COLOURS.WHITE,
													}}>
													{d.retailer}, {d.mall}
												</Text>
											</View>
											<View
												style={{
													width: 200,
												}}>
												<Text
													style={{
														fontFamily: "Avenir",
														fontSize: 13,
														fontWeight: "200",
														color: APP_COLOURS.WHITE,
													}}>
													{d.deviceType}
												</Text>
												<Text
													numberOfLines={1}
													ellipsizeMode='tail'
													style={{
														fontFamily: "Avenir",
														fontSize: 7,
														fontWeight: "200",
														color: APP_COLOURS.WHITE,
														opacity: 0.7,
													}}>
													{d.deviceuniqueId}
												</Text>
											</View>
											<View
												style={{
													width: 50,
												}}>
												<a
													href={`https://www.google.com/maps/@${
														JSON.parse(d.location).coords
															.longitude
													},${
														JSON.parse(d.location).coords.latitude
													}`}>
													<Image
														source={location}
														style={{
															width: 20,
															height: 25,
															resizeMode: "contain",
															marginRight: 5,
															// opacity: 0.3,
														}}
													/>
												</a>
											</View>
										</View>
									);
								})}
						</ScrollView>
					</View>
				</ScrollView>
			</View>
		);
	}

	getUserAttendance = async (user_id) => {
		let url = api_getStaffAttendance;
		let accessToken = await sessionStorage.getItem("accessToken");

		await this.setState({
			loading: true,
			// appstyles: [],
		});

		let obj = {
			user_id: user_id,
		};

		let requestOptions = {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Origin: "",
				dataType: "json",
				Authorization: `Bearer ${accessToken}`,
			},
			redirect: "follow",
			body: JSON.stringify(obj),
		};

		await fetch(url, requestOptions)
			.then((response) => {
				console.log("response", response);
				if (response.status === 200) {
					response
						.json()
						.then(async (responseData) => {
							await console.log("data", responseData);
							await this.setState({
								data: responseData,
								loading: false,
							});
						})
						.catch((error) => {
							alert(error);
							this.setState({
								loading: false,
							});
						});
				} else if (response.status == 401) {
					this.props.logOut();
				} else {
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loading: false,
				});
			});
	};
}
