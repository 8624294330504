import React, { Component } from "react";
import {
	View,
	Modal,
	Image,
	TouchableOpacity,
	Text,
	TextInput,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import Font from "react-font";
import { APP_COLOURS } from "../../APP_VARS";
import tick from "./../../../assets/images/tick.png";

function notSpecialChars(t) {
	return t.replace(/[^\w\s]/gi, "");
}

export default class IDNumberInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id_passport: "",
		};
		this._timeout = 500;
	}
	render() {
		return (
			<View
				style={{
					justifyContent: "center",
				}}>
				<TextInput
					style={{
						marginBottom: 5,
						padding: 14,
						marginTop: 0,
						fontSize: 17,
						borderRadius: 15,
						fontWeight: this.state.id_passport !== "" ? "500" : "200",
						textAlign: "center",
						color: APP_COLOURS.BLACK,
						letterSpacing: 0.26,
						// borderWidth: this.state.id_passport !== "" ? 0.3 : 1,
						borderWidth: this.state.id_passport !== "" ? 0 : 0.3,
						backgroundColor: "#FFF",
						borderColor: APP_COLOURS.BLACK,
					}}
					placeholderTextColor={"#797979"}
					autoCapitalize='sentences'
					clearButtonMode='while-editing'
					autoCorrect={false}
					maxLength={50}
					placeholder={this.props.title}
					// value={this.state.id_passport}
					onChangeText={(t) => {
						let text = notSpecialChars(t);
						this.setState({
							id_passport: text,
						});

						clearTimeout(this._timeout);
						this._timeout = setTimeout(() => {
							this.props.setParentState(text);
						}, 500);
					}}
				/>
			</View>
		);
	}
}
