import React, { Component } from "react";
import { View, Text } from "react-native";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			display: true,
			position: "top",
		},
		title: {
			display: false,
			text: "none",
		},
	},
};

// const labels = [
// 	"Jan 22",
// 	"Feb 22",
// 	"Mar 22",
// 	"Apr 22",
// 	"May 22",
// 	"Jun 22",
// 	"Jul 22",
// ];

export default class LineChart extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<Line
				style={{
					backgroundColor: "transparent",
				}}
				redraw={false}
				options={options}
				data={this.dataSet(this.props.data, this.props.labels)}
			/>
		);
	}

	dataSet = (d, labels) => {
		const data = {
			labels,
			datasets: [
				{
					fill: true,
					label: this.props.title,
					data: d, // [1, 2, 3, 4, 4, 5, 6, 3, 4, 5, 6, 3, 3, 4, 4, 5, 6, 9],
					borderColor: "rgb(45, 220, 200)",
					backgroundColor: "rgba(53, 162, 235, 0.1)",

					lineTension: 0.4,
				},
			],
		};
		return data;
	};
	// borderColor: "rgb(53, 162, 235)",
	// 				backgroundColor: "rgba(53, 162, 235, 0.1)",
}
